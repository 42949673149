<template>
  <form method="GET" class="form bordered-card bordered-card_secondary mb-20 form-columns_3 justify-content-between"
    @submit.prevent="submitFilters(0)" @keydown.enter="submitFilters(0)">
    <TextInput type="text" name="number" label="NBF Participant ID" :max=20 v-model:modelValue="filters.PersonID" />
    <TextInput type="text" label="First Name" :max=50 v-model:modelValue="filters.FirstName" />
    <TextInput type="text" label="Last Name" :max=50 v-model:modelValue="filters.LastName" />
    <DateInput label="Birth Date" v-model:modelValue="filters.BirthDate" />
    <TextInput type="email" label="Email" :max=50 v-model:modelValue="filters.Email" />
    <DateInput label="Last Login" v-model:modelValue="filters.LastLogin" v-model:reset="reset" />

    <div class="d-flex w-100 flex-column flex-md-row gap-10">
        <div class="action-buttons justify-content-start align-items-start">
            <button type="button" @click.prevent="openModal('superadmin-create-user', 'Create User', 'plan-upload plan-card-info plan-c-mrp-claims')" class="w-sm-100 btn btn-primary plan-modal">Create User</button>
        </div>
        <div class="action-buttons justify-content-end flex-column flex-md-row align-items-start">
            <button type="button" class="w-sm-100 btn btn-secondary" @click="submitFilters(1)"> Clear Filter </button>
            <button type="button" class="w-sm-100 btn btn-primary" @click="submitFilters(0)">
                {{ searching ? 'Searching...' : 'Apply Filters' }}
            </button>
        </div>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import { SearchParticipantsFormFilters } from '@/interfaces/admin';
import TextInput from '@components/form/TextInput.vue';
import DateInput from "@components/form/DateInput.vue";
import { useModalStore } from '@/stores/modal';

export default defineComponent({
  props: {
    searching: Boolean,
  },
  components: {
    TextInput,
    DateInput,
  },
  setup(_, { emit }) {
    const reset = ref(false);
    const initialFilters = {
      PersonID: '',
      FirstName: '',
      LastName: '',
      Email: '',
      BirthDate: '',
      LastLogin: '',
    }
    const filters: SearchParticipantsFormFilters = reactive(JSON.parse(JSON.stringify(initialFilters)));
    const openModal = useModalStore().openModal;

    const submitFilters = (type: number) => {
      let loading = true;

      if (type === 1) {
        loading = false;
        Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
        reset.value = true;
      }

      emit('update-filters', filters, loading);
    }

    return {
      reset,
      filters,
      submitFilters,
      openModal
    };
  }
});
</script>