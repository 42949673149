<template>
    <!-- <AlertsComponent :errors="errors" /> -->
    <LoaderComponent v-if="loading" />
    <div v-else class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Latest Monthly Member Work History Reports</h2>
        <div class="bordered-card bordered-card_secondary bg-light-green mb-30 flex-column flex-md-row gap-30">
            <p class="pdr-20"> Latest Report </p>
            <template v-for="(report, key, index) in Object.entries(reports).reverse()" :key="key">
                <div class="d-flex gap-5 fs-sm align-items-center">
                    <i class="iatse-icon-pdf d-flex"><span class="path1"> </span> <span class="path2"> </span></i>

                    <p class="plan-modal"
                        @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: report[0] })">
                        {{ report[1] }}
                    </p>
                </div>
                <div v-if="index < Object.entries(reports).length - 1" class="divider divider_vertical divider_vertical_mobile my-0"></div>
            </template>

        </div>
        <h2 class="mb-20">Member Work History</h2>
        <MessageCard :message="instructions" type="primary" iconClass="iatse-icon-alarm" classes="info_card__page" />

        <!-- Filters -->
        <div class="d-flex flex-column gap-20 mt-20">
            <HistoryFilters v-model:loadingSearch="loadingSearch" :reset="reset" @search="searchData" :errors=errors />
        </div>
        <!-- Results -->
        <HistoryResults v-if="(!loading)" :loading="loadingSearch" v-model:loadingTotals="loadingTotals" :pageChanged="pageChanged"
            @reset-filters="resetFilters" @fetch-pagination-data="fetchPaginationData" />
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { YearData, ContributionFilters, Entity } from '@/interfaces/local';
import { useContributionsStore } from '@/stores/contributions';
import HistoryFilters from '@components/local/contribution-history/HistoryFilters.vue';
import HistoryResults from '@components/local/contribution-history/HistoryResults.vue';
import axios from 'axios';

export default {
    components: {
        HistoryFilters,
        HistoryResults,
    },

    setup() {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const loadingTotals = ref(false);
        const changePage = ref(false);
        const pageChanged = ref(false);
        const reset = ref(false);
        const page = ref(1);
        const errors: Ref<string[]> = ref([]);
        const reports: Ref<string[]> = ref([]);
        const contributionsStore = useContributionsStore();
        const resultsInstructions = '<br />To view the detailed contributions from individual Production/Employer, click on the plus sign to the left of Member Name. Click on the clock icon to view the work unit(s) reported.'
        const mainInstructions = 'This page allows you to search for contributions for your Member(s) based on your choice of parameters. Please note that the broader your search parameter(s) the longer the results will take to load. To clear the current search parameters, please click on the "Clear Filters" button below.';
        const instructions = computed(() =>  mainInstructions + resultsInstructions);

        const resetFilters = () => {
            contributionsStore.resetData();
            changePage.value = false;
            reset.value = true;
            page.value = 1;
        }

        const fetchData = async () => {
            await axios.get('api/local/work-history')
                .then(response => {
                    reports.value = response.data;
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        const searchData = async (payload: ContributionFilters, existingFilters: boolean | null, withTotals?: boolean | null) => {
            loadingSearch.value = true;
            loadingTotals.value = withTotals as boolean;
            contributionsStore.setContributions(null);
            let filters = JSON.parse(JSON.stringify(payload));
            let formData = {
                ...filters,
                Page: page.value,
            };

            if (withTotals) {
                const hasEmployers = filters?.Employers?.some((employer: Entity) => employer.ID !== 0);
                const hasProductions = filters?.Productions?.some((prod: Entity) => prod.ID !== 0);
                const hasFunds = filters?.Funds?.some((fund: Entity) => fund.ID !== 0);
                const hasPayors = filters?.Payors?.some((payor: Entity) => payor.ID !== 0);

                formData = {
                    ...filters,
                    Employers: !hasEmployers ? null : filters.Employers.map((item: Entity) => item.ID),
                    Productions: !hasProductions ? null : filters.Productions.map((item: Entity) => item.ID),
                    Payors: !hasPayors ? null : filters.Payors.map((item: Entity) => item.ID),
                    Funds: !hasFunds ? null : filters.Funds.map((item: Entity) => item.ID),
                    Page: page.value,
                };

                contributionsStore.setFilters(formData);
            }

            if (changePage.value) {
                pageChanged.value = false;
            }

            await axios
                .post('api/local/work-history', { ...formData })
                .then(response => {
                    contributionsStore.setContributions(Object.entries(response.data.Years).length ? response.data.Years : null);
                    contributionsStore.setPaginator(response.data.Paginator);
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;
                    reset.value = false;

                    if (changePage.value) {
                        pageChanged.value = true;
                    }

                    setTimeout(() => errors.value = [], 3500)
                })

            if (withTotals) {
                await axios
                    .post('api/local/work-history-totals', { ...formData })
                    .then(response => {
                        // Group by year
                        if(response.data.Years) {
                            const groupedByYear = response.data.Years.reduce((acc: Record<string, YearData[]>, { Year, FundName, FundAmount }: YearData) => {
                                if (!acc[Year]) {
                                    acc[Year] = [];
                                }

                                acc[Year].push({ Year, FundName, FundAmount });

                                return acc;
                            }, {});

                            response.data.Years = groupedByYear;
                        }

                        contributionsStore.setTotals(response.data);

                    })
                    .catch(error => {
                        if (error.response) {
                            errors.value = error.response.data.errors;
                        }
                    })
                    .finally(() => {
                        loadingTotals.value = false;
                        reset.value = false;
                    })
            }
        };

        const fetchPaginationData = async (pageNumber: number) => {
            page.value = pageNumber;
            changePage.value = true;

            await searchData(contributionsStore.getFilters, true, false);
        }

        return {
            loading,
            loadingSearch,
            loadingTotals,
            instructions,
            reports,
            pageChanged,
            changePage,
            errors,
            reset,
            contributionsStore,
            resetFilters,
            fetchPaginationData,
            searchData,
            openModal: useModalStore().openModal
        }
    }
}
</script>