<template>
    <FundPanel cardId="card-pension" :alerts="alerts">
        <template #icon>
            <i class="iatse-icon-egg-basket"></i>
        </template>
        <template #title>
            Pension
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__pension" :class="{ 'pdt-30 pdb-30': isPensioner }">
                    <div v-if="hasPaymentsOnHold" class="coverage-column w-100 mb-30">
                        <span class="label label_danger pdl-40 pdr-40 sm-pdr-10 sm-pdl-10"> Pension
                            Payments
                            on Hold -
                            Please Call Us at 800-456-3863
                        </span>
                    </div>
                    <div class="fund-cover-header">
                        <div class="coverage-column" :class="{ 'flex-grow-1': isPensioner }">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-clipboard-tick"></i>
                                <p class="links">Pension Benefit Status:</p>
                            </div>
                            <p>
                                <strong>{{ loading ? '...' : status }}</strong>
                                <small v-if="isPensioner"> {{ loading ? '' : date }} </small>
                            </p>
                        </div>
                        <div class="divider"> </div>
                        <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-trade"></i>
                                <p class="links">Pension Credits:</p>
                            </div>
                            <p class="">
                                <strong>{{ loading ? '...' : pensionCredits }}</strong>
                                <small> {{ loading ? '' : date }} </small>
                            </p>
                        </div>
                        <div class="divider"> </div>
                        <div class="coverage-column" :class="{ 'mt-30 sm-mt-10': isPensioner }">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-trade"></i>
                                <p class="links">Vested Credits: <i class="iatse-icon-square-info"></i></p>
                            </div>
                            <p class="">
                                <strong>{{ loading ? '...' : vestedCredits }}</strong>
                                <small> {{ loading ? '' : date }} </small>
                            </p>
                        </div>
                        <div v-if="isPensioner" class="divider"> </div>
                        <div v-if="isPensioner" class="coverage-column gap-0 sm-gap-10 align-items-md-center align-items-start">
                            <div class="fund-cover-row col-md-5 justify-content-start">
                                <i class="iatse-icon-map"></i>
                                <p class="plan-modal links text-underline sm-fs-sm" @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'pension')">
                                    Pension Check Destination: </p>
                            </div>
                            <p><strong>{{ checkDestination ?? '-' }}</strong></p>
                        </div>
                    </div>
                    <div class="fund-cover-buttons">
                        <a href="" class="btn btn-primary plan-modal" :class="{ 'mr-20 sm-mr-0': isPensioner }"
                            @click.prevent="openModal('work-history', 'Pension Work History', null, 'pension', null, null, undefined, { Funds: [1, 2, 9] })">
                            Work History
                        </a>
                        <a v-if="isPensioner" href="" class="btn btn-secondary plan-modal mr-20 sm-mr-0"
                            @click.prevent="openModal('pension-credits-history', 'Pension', 'plan-profile plan-tables pension-credits', 'pension', null, 'Pension Credit History')">
                            Pension Credit History
                        </a>
                        <a v-else href="" class="btn btn-secondary plan-modal mr-20 sm-mr-0"
                            @click.prevent="openModal('pension-credits-history', 'Pension Credit History', 'plan-profile plan-tables pension-credits', 'pension')">
                            Pension Credit History
                        </a>
                        <a v-if="isPensioner" href="" class="btn btn-secondary plan-modal" @click.prevent="openModal(
                            'pensioner-profile', 'Pension', 'plan-profile plan-tables pension-credits', 'pension', null, 'Pensioner Profile',
                            [{
                                name: 'View Pension Credit History', title: 'Pension > Pensioner Profile', secondTitle: 'Pension Credit History',
                                action: 'pension-credits-history', classes: 'plan-profile plan-tables pension-credits',
                                type: 'secondary'
                            }, {
                                name: 'Work History', title: 'Pension > Pensioner Profile', secondTitle: 'Pension Work History', action: 'work-history', classes: '', queryParams: { Funds: [1, 2, 9] },
                                type: 'secondary'
                            }])">
                            View Profile
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template #related-links>
            <template v-if="isPensioner">
                <button class="btn btn-tertiary btn-large plan-modal"
                    @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'pension')">
                    ACH/Check Destination</button>
                <button v-if="hasChecks" class="btn btn-tertiary btn-large plan-modal"
                    @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'pension')">
                    Check Replacement
                </button>
            </template>
            <template v-else>
                <a href="" class="btn btn-tertiary btn-large plan-modal"
                    @click.prevent="openModal('pension-application', 'Pension Application', 'plan-form', 'application')">Pension
                    Application
                </a>
                <a href="" class="btn btn-tertiary btn-large plan-modal"
                    @click.prevent="openModal('pension-application', 'Estimate Request', 'plan-form', 'estimate')">
                    Estimate Request
                </a>
            </template>

            <a href="" class=" btn btn-tertiary btn-large plan-modal" @click.prevent="openModal('add-beneficiary',
                'My Beneficiary(ies)',
                'plan-tables-striped beneficiary',
                null,
                null,
                null,
            )">
                Beneficiary(ies)
            </a>
            <a href="" @click.prevent="openModal('plan-resources', 'Pension Fund Resources', null, 'pension')" class="btn btn-tertiary btn-large plan-modal">Fund's Resources</a>
        </template>
    </FundPanel>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, toRefs, computed, onMounted, reactive } from 'vue';
import { PensionDashboardInfo, PensionPlan, Alert } from '@/interfaces/interfaces';
import { PensionStatus } from '@/interfaces/pension';
import { useModalStore } from '@/stores/modal';
import { usePensionStore } from '@/stores/pension';
import { useDateFormatter } from '@/utils/dateFormatter';
import FundPanel from '@/components/cards/FundPanel.vue';
import axios from 'axios';

export default defineComponent({
    props: {
        alerts: {
            type: Array as () => Alert[],
            required: true
        },
    },
    components: {
        FundPanel,
    },
    setup(props) {
        const loading = ref(true);
        const modalStore = useModalStore();
        const pensionStore = usePensionStore();
        const isPensioner = computed(() => pensionStore.pensionerDetails?.IsPensioner);
        const hasChecks = computed(() => pensionStore.pensionerDetails?.HasChecks);
        const checkDestination = computed(() => pensionStore.pensionerDetails?.CheckDestination?.Destination);
        const hasPaymentsOnHold = computed(() => pensionStore.pensionerDetails?.HasPaymentsOnHold);
        const vestedCredits = computed(() => pensionStore.data?.totalVestingCredits ? pensionStore.data?.totalVestingCredits : 0);
        const pensionCredits = computed(() => pensionStore.data?.totalPensionCredits ? pensionStore.data?.totalPensionCredits : 0);
        const status = computed(() => {
            if (isPensioner.value && pensionStore.pensionerDetails?.BenefitStatus) {
                return pensionStore.pensionerDetails?.BenefitStatus;
            } else if (pensionStore.data?.vestingYear && pensionStore.data?.vestingYear <= new Date().getFullYear()) {
                return 'Vested';
            }
            return 'Not Vested';
        });

        const date = computed(() => {
            if (isPensioner.value) {
                return '';
                // return pensionStore.pensionerDetails?.RetirementDate ? ' (as of ' + formatDate(pensionStore.pensionerDetails?.RetirementDate) + ')' : '';
            }

            return pensionStore.data?.responseDateTime ? ' (as of ' + useDateFormatter(pensionStore.data?.responseDateTime) + ')' : '';
        })

        const fetchData = async () => {
            loading.value = true;

            if (!pensionStore.data) {
                await pensionStore.fetchPensionCredits();
            }

            loading.value = false;
        };

        onMounted(fetchData);

        return {
            hasPaymentsOnHold,
            checkDestination,
            isPensioner,
            hasChecks,
            loading,
            status,
            date,
            vestedCredits,
            pensionCredits,
            useDateFormatter,
            openModal: modalStore.openModal
        };
    }
});
</script>