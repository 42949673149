import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "plan-content"
}
const _hoisted_2 = { class: "plan-details" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_ProfileInfo = _resolveComponent("ProfileInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MessageCard, {
      message: "This page displays information about you on file at the Fund Office. You can update this\n      information by clicking on the Update button under the applicable section if any of it has changed.",
      type: "primary",
      iconClass: "iatse-icon-alarm",
      classes: "info_card_full"
    }),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_ProfileInfo, {
              title: "My Personal Information",
              items: $setup.personalInfoItems,
              onOpenModal: _cache[0] || (_cache[0] = ($event: any) => ($setup.openModal('update-personal-info', 'Update Personal Information', 'plan-upload plan-card-info plan-profile-update gap-20 sm-gap-0')))
            }, null, 8, ["items"]),
            _createVNode(_component_ProfileInfo, {
              title: "My Address",
              items: $setup.addressInfoItems,
              info: $setup.info,
              onOpenModal: _cache[1] || (_cache[1] = ($event: any) => ($setup.openModal('update-address', 'Update Address', 'plan-upload plan-card-info plan-profile-update')))
            }, null, 8, ["items", "info"]),
            _createVNode(_component_ProfileInfo, {
              title: "My Contact Information",
              items: $setup.contactInfoItems,
              onOpenModal: _cache[2] || (_cache[2] = ($event: any) => ($setup.openModal('update-contact-information', 'Update Contact Information', 'plan-upload plan-tables')))
            }, null, 8, ["items"])
          ])
        ]))
  ], 64))
}