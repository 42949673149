<template>
    <!-- Contribution Table -->
    <div class="contribution-table bordered-card">
        <!-- Contribution Account -->
        <div class="bordered-card width-30 w-sm-100 height-100">
            <h4 class="d-flex align-items-end gap-5">
                <span>Days Worked</span>
                <span class="tooltip-wrapper d-flex align-items-end">
                    <i class="iatse-icon-info-tooltip icon_rotate_0"></i>
                    <p class="tooltip-text tooltip-text_secondary tooltip-text__large">
                        Click on contribution period in this “Coverage” column to see the associated work month in the “Days Worked” column. Click the up/down arrows to change the range of work months and the coverage period. Click the work month to see corresponding contributions.
                        <br /> For Plan A Coverage Eligibility/Qualification, refer to
                        <a
                            class="text-white"
                            href="javascript:void(0)"
                            @click="openPDF"
                            >
                                Summary Plan Description
                            </a>
                    </p>
                </span>
            </h4>
            <div class="contribution-left-table navigator">
                <div class="vertical-carousel">
                    <div class="vertical-carousel__controls">
                        <button
                            @click="prevItem"
                            :class="{ 'disabled-button': prevDisabled }"
                            :disabled="prevDisabled">
                        <i class="iatse-icon-arrow-solid vertical-carousel__arrow"></i>
                        </button>
                        <button
                            @click="nextItem"
                            :class="{ 'disabled-button': nextDisabled }"
                            :disabled="nextDisabled">
                        <i class="iatse-icon-arrow-solid vertical-carousel__arrow icon-rotate-180"></i>
                        </button>
                    </div>
                    <div class="vertical-carousel__slides">
                        <div class="contribution-left-lines">
                            <transition-group name="fade" tag="div">
                                <div
                                    v-for="(item, index) in visibleItems"
                                    :key="item.date"
                                    class="contribution-left-line"
                                    :class="{ current: selectedIndex === index }"
                                    @click="selectItem(index)">
                                    <h5 class="sm-ml-0 sm-fs-sm">{{ item.displayDate }}</h5>
                                    <h5 class="sm-fs-sm sm-pdr-0">{{ item.daysWorked }}</h5>
                                </div>
                            </transition-group>
                        </div>
                        <div class="contribution-left-line">
                            <h5 class="sm-fs-sm"> Total Days Accumulated </h5>
                            <h5 class="sm-fs-sm"> {{ totalVisibleDays }} </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Contribution Tables -->
        <div class="contributions-tables">
            <div class="contributions-tables-header">
                <h4> For Coverage: {{ currentCoveragePeriod }}</h4>
                <span class="label label_blue"> {{ eligibilityStatus }} </span>
            </div>
            <div class="contributions-table-wrapper">
                <div class="contributions-table-item no-striped">
                    <h4> Contribution Detail </h4>
                    <table>
                        <thead>
                            <tr>
                                <th>Receipt Date</th>
                                <th>Work Period Ending</th>
                                <th>Employer</th>
                                <th>Production</th>
                                <th>Unit</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody v-if="currentContributions.length > 0">
                            <tr v-for="contribution in currentContributions" :key="contribution.ParticipantWHID">
                                <td>{{ contribution.TranProcDate }}</td>
                                <td>{{ contribution.PeriodEnd }}</td>
                                <td>{{ contribution.EmployerName }}</td>
                                <td>{{ contribution.ProductionName }}</td>
                                <td>{{ contribution.DaysWorked }}</td>
                                <td>{{ contribution.TotalContributions }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="6">No contributions found.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <!-- Instructions -->
            <!-- <div class="bordered-card small w-100">
                <h4 class="small-title"> Instructions </h4>
                <div class="bordered-card-body">
                    <small>Click on contribution period in this “Coverage” column to see the associated work month in the “Days Worked” column. Click the up/down arrows to change the range of work months and the coverage period. Click the work month to see corresponding contributions. </small>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, PropType } from 'vue';
import { PlanACoverageItem } from '@/interfaces/health';

export default defineComponent({
    name: 'DaysWorkedCarousel',

    props: {
        items: {
            type: Array as PropType <PlanACoverageItem[]> ,
            required: true,
            default: () => []
        },

        startingMonth: {
            type: String,
            required: true,
            default: ''
        }
    },

    setup(props) {
        const activeIndex = ref(0);
        const selectedIndex = ref(0);
        const summaryPlanDescriptionLink = 'https://iatse-public.staging.polardesign.com/storage/app/media/documents/IATSE-Natl-HW-Fund-Plan-A-SPD-web-ready_sm.pdf';

        const index = props.items.findIndex(item => item.date === props.startingMonth);
        if (index >= 0) {
            activeIndex.value = index;
        }

        const visibleItems = computed <PlanACoverageItem[]> (() => {
            const currentIndex = activeIndex.value;
            let count = 6;

            // Adjust count if not enough items remaining
            if (currentIndex + count > props.items.length) {
                count = props.items.length - currentIndex;
            }

            return props.items.slice(currentIndex, currentIndex + count);
        });

        const nextItem = () => {
            if (activeIndex.value < props.items.length - 1) {
                const currentItem = props.items[activeIndex.value];
                
                // If the current item is eligible (covered), skip 6 months
                if (currentItem.isEligible) {
                let nextIndex = activeIndex.value + 6;
                
                // Ensure the next index doesn't exceed the total items
                if (nextIndex >= props.items.length) {
                    nextIndex = props.items.length - 1;
                }
                
                activeIndex.value = nextIndex;
                } else {
                // If not eligible, go one month at a time
                activeIndex.value++;
                }
            }

            // @tbd
            if (selectedIndex.value < activeIndex.value) {
                selectedIndex.value = 0;
            }
        };

        const prevItem = () => {
            if (activeIndex.value > 0) {
                const currentItem = props.items[activeIndex.value];
                const prevItem = props.items[activeIndex.value - 1];

                // If the current item is not eligible and the previous item is eligible
                if (!currentItem.isEligible && prevItem && prevItem.isEligible) {
                // Jump back to the start of the previous eligible period
                let prevEligibleIndex = activeIndex.value - 6;
                
                // Ensure we don't go below index 0
                if (prevEligibleIndex < 0) {
                    prevEligibleIndex = 0;
                }

                activeIndex.value = prevEligibleIndex;
                } else if (currentItem.isEligible && prevItem.isEligible) {
                // If both current and previous are eligible, jump back 6 months
                let prevIndex = activeIndex.value - 6;

                // Ensure we don't go below index 0
                if (prevIndex < 0) {
                    prevIndex = 0;
                }

                activeIndex.value = prevIndex;
                } else {
                // If not eligible, move back one month at a time
                activeIndex.value--;
                }
            }
        };

        const nextDisabled = computed(() => {
            return activeIndex.value >= props.items.length;
        });

        const prevDisabled = computed(() => {
            return activeIndex.value <= 0;
        });

        const eligibilityStatus = computed(() => {
            const currentItem = props.items[activeIndex.value];
            return currentItem.isEligible === true ? 'Eligible' : 'TBD';
        });

        const currentContributions = computed(() => {
            // Get the current coverage period from props.items using activeIndex
            const coveragePeriod = visibleItems.value[selectedIndex.value];

            // Check if the coverage period and contributions exist
            if (coveragePeriod && coveragePeriod.contributions) {
                return coveragePeriod.contributions;
            }

            // If no contributions are found, return an empty array
            return [];
        });

        const selectItem = (index: number) => {
            selectedIndex.value = index;
        };

        const currentCoveragePeriod = computed(() => {
            const item = visibleItems.value[selectedIndex.value];

            return item && item.coveragePeriod ? `${item.coveragePeriod}` : 'TBD';
        });

        const totalVisibleDays = computed(() => {
            return visibleItems.value.reduce((total, item) => total + (item.daysWorked || 0), 0);
        });

        const openPDF = () => {
            const url = `${summaryPlanDescriptionLink}#page=20`;
            const viewer = window.open('', '_blank');

            if(viewer) {
                viewer.document.write(`
                <html>
                    <body style="margin:0;">
                        <iframe src="${url}" style="width:100%; height:100vh;" frameborder="0"></iframe>
                    </body>
                </html>
            `);
            }
        };

        return {
            eligibilityStatus,
            visibleItems,
            activeIndex,
            selectedIndex,
            selectItem,
            currentCoveragePeriod,
            currentContributions,
            totalVisibleDays,
            nextItem,
            prevItem,
            openPDF,
            nextDisabled,
            prevDisabled,
            summaryPlanDescriptionLink
        };
    },
});
</script>

<style lang="scss" scoped>
    .bordered-card {
        border: 1px solid #ccc;
        padding: 10px;
        width: 30%;
        height: 100%;
    }

    .navigator button {
        background: none;
        border: none;
        cursor: pointer;
    }

    .disabled-button {
        opacity: 0.5;
        cursor: not-allowed;
    }

    /* Fade effect */
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.2s ease-in-out;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    i.vertical-carousel__arrow {
        &:before {
            color: #17388C;
        }
    }

    .vertical-carousel {
        padding: 12px 0;
        border-top: 1px solid #E1E8EC;
        border-bottom:  1px solid #E1E8EC;
        display: flex;
        width: 100%;
    }

    .vertical-carousel__slides {
        width: 100%;

        .contribution-left-line {
            h5 {
                cursor: pointer;
            }
        }
    }

    .vertical-carousel__controls {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
</style>