<template>
   <AlertsComponent :error="error" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Member Search</h2>
        <MessageCard message="You can filter your member search by first name, middle name, lastname, SSN and date of birth. To view the Member Profile, click on the name below." type="primary" iconClass="iatse-icon-alarm" classes="info_card__page" />
        <form class="d-flex gap-20 flex-wrap mt-20">
            <div class="w-100 d-flex gap-20 flex-md-row flex-column">
                <TextInput type="text" label="First Name" :max="50" placeholder="Enter FirstName"
                    v-model:modelValue="filters.FirstName" />
                <TextInput type="text" label="Middle Name" :max="50" placeholder="Enter MiddleName"
                    v-model:modelValue="filters.MiddleName" />
                <TextInput type="text" label="Last Name" :max="50" placeholder="Enter LastName"
                    v-model:modelValue="filters.LastName" />
            </div>
            <div class="w-100 d-flex gap-20 flex-md-row flex-column">
                <DateInput label="Date of Birth" v-model:modelValue="filters.DateofBirth" v-model:reset="reset"/>
                <TextInput type="text" name="number" label="NBF Participant ID or SSN" placeholder="Search by NBF Participant ID or SSN:" :max=9
                    v-model:modelValue="filters.ParticipantID" @input="validateParticipantID" />
                <div class="text-input"></div>
            </div>
            <div class="d-flex gap-2 justify-content-between w-100">
                <button type="button" class="btn btn-secondary w-sm-100" @click.prevent="resetFilters">Reset</button>
                <button class="btn btn-primary w-fit w-sm-100" @click.prevent="searchData" @keypress.enter="searchData">
                    {{ loading ? 'Searching...' : 'Search' }}</button>
            </div>
        </form>
        <div>
            <div class="d-flex justify-content-between mb-4">
                <!-- <button class="btn btn-primary" @click="printList">Print List</button> -->
            </div>
            <table class="small striped toggle no-underline mt-10" id="table-contract-info">
                <thead class="large large_padding">
                    <tr>
                        <th>Name</th>
                        <th>Date of Birth</th>
                        <th>NBF Participant ID</th>
                        <th>Phone No.</th>
                    </tr>
                </thead>
                <tbody v-if="loading">
                    <tr class="large text-center">
                        <td colspan="4" >
                            <LoaderComponent  />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else-if="membersStore.list?.length">
                    <tr v-for="person in membersStore.list" :key="person.PersonID" class="large">
                        <td>
                            <a href="javascript;" class="plan-modal"
                                @click.prevent="openModal('local-member-profile', 'Member Profile', 'plan-profile plan-tables profile', null, { PersonID: person.PersonID })">
                                {{ person.FullName }}
                            </a>
                        </td>
                        <td v-if="person.DateofBirth">{{ person.DateofBirth  }}</td>
                        <td v-else class="text-danger">Missing Info</td>
                        <td>{{ person.PersonID }}</td>
                        <td>{{ person.Phone }}</td>
                    </tr>
                </tbody>
                <tbody v-else-if="!membersStore.list?.length">
                    <tr class="large text-center">
                        <td colspan="4" >
                           No Records found
                        </td>
                    </tr>
                </tbody>
            </table>
            <PaginationComponent :paginator="paginator" @fetch-data="fetchPaginationData" />
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref } from 'vue';
import { useMembersStore } from '@/stores/members';
import { useModalStore } from '@/stores/modal';
import { usePrint } from '@/composable/usePrint';
import { Person } from "@/interfaces/local";
import { Paginator } from "@/interfaces/admin";
import axios from 'axios';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default {
    components: {
        TextInput,
        DateInput,
        PaginationComponent
    },
    setup() {
        const loading = ref(false);
        const membersStore = useMembersStore();
        const { printElementById } = usePrint();
        const error = ref(null);
        const reset = ref(false);
        const page = ref(1);
        const filters = reactive({
            FirstName: '',
            MiddleName: '',
            LastName: '',
            ParticipantID: '',
            DateofBirth: '',
        })
        const paginator: Paginator = reactive({
            per_page: 0,
            total: 0,
            last_page: 0,
            current_page: 0,
        });

        const printList = printElementById('table-member-search', 'Member List');

        const resetFilters = () => {
            Object.keys(filters).forEach((key) => {
                filters[key as keyof typeof filters] = '';
            });

            reset.value = true;
        }

        const toTitleCase = (str: string) => {
            return str
                .replace(/([a-z])([A-Z])/g, '$1 $2')
        }

        const validateParticipantID = (event: InputEvent) => {
            const input = event.target as HTMLInputElement;
            const inputValue = input.value.trim();
            const numericRegex = /^[0-9]*$/;

            // Check if the input value contains only numeric characters
            if (!numericRegex.test(inputValue)) {
                input.value = inputValue.replace(/[^0-9]/g, '');
            }
        };

        const searchData = async () => {
            loading.value = true;
            membersStore.setList([]);

            await axios.get('api/local/search-member', { params: { page: page.value,  ...filters } })
                .then(response => {
                    membersStore.setList(response.data.Persons.Data as Person[]);
                    Object.assign(paginator, response.data.Persons.Paginator);
                    page.value = paginator.current_page;
                })
                .catch(errorRes => {
                    error.value = errorRes.response.data.error;

                    setTimeout(() => error.value = null, 3500);
                })

            loading.value = false;
        }

         // Fetch Data For Selected Page
         const fetchPaginationData = async(pageNumber: number) => {
            page.value = pageNumber;
            await searchData();
        };

        return {
            loading,
            error,
            reset,
            filters,
            membersStore,
            paginator,
            validateParticipantID,
            resetFilters,
            fetchPaginationData,
            searchData,
            toTitleCase,
            printList,
            openModal: useModalStore().openModal
        };
    }
}
</script>
