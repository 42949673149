import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "modal-body" }
const _hoisted_2 = { class: "d-flex justify-center flex-column w-100" }
const _hoisted_3 = { class: "mt-10" }
const _hoisted_4 = {
  key: 0,
  class: "mt-10"
}
const _hoisted_5 = { class: "text-center mt-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VerificationCodeInput = _resolveComponent("VerificationCodeInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _createElementVNode("p", null, " We have sent you a verification code to your " + _toDisplayString(_ctx.method === 'E' ? 'email' : 'phone') + " please check and add the code below in the boxes. ", 1),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_VerificationCodeInput, {
          modelValue: _ctx.verificationCode,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.verificationCode) = $event)),
          error: _ctx.errors
        }, null, 8, ["modelValue", "error"])
      ]),
      (_ctx.success)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_ctx.success), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "submit",
        class: "btn btn-primary w-100 mt-20",
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
        onKeypress: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]), ["enter"]))
      }, "Enter Code", 32),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("a", {
          href: "#",
          onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.regenerateCode && _ctx.regenerateCode(...args)), ["prevent"])),
          class: "links"
        }, "Resend Code (" + _toDisplayString(_ctx.timer) + " seconds remaining)", 1)
      ])
    ])
  ]))
}