<template>
     <p class="fw-semibold pdb-20 fs-xl">Administrator - Authentication/PIN Request</p>

    <div class="d-flex flex-column gap-20 w-100">
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" name="mixed" label="Company Name " placeholder="Company Name" :max=60
                v-model:modelValue="employerAuth.CompanyName" :is-required="true" :error="errors.CompanyName"/>
            <TextInput type="text" name="mixed" label="EIN/ TAXID " placeholder="EIN/ TAXID" :max=60
                v-model:modelValue="employerAuth.TaxID" :is-required="true" :error="errors.TaxID"/>
        </div>

        <div v-for="(dba, index) in employerAuth.DBANames" :key="index" class="d-flex align-items-end gap-4">
            <TextInput type="text" name="mixed" label="DBA Name " placeholder="DBA Name" :max=60
            v-model:modelValue="dba.name" :error="errors.DBAName"/>
            <!-- <div class="form-group col-md-10 d-flex flex-column gap-2">
                <label for="dba_name">DBA Name</label>
                <input v-model="dba.name" type="text" class="form-control" :id="'dba_name_' + index"
                    :name="'EmployerAuth[DBANames][]'">
            </div> -->
            <div v-if="dba.showDelete" class="btn-delete">
                <i class="iatse-icon-trash icon_delete plan-modal fs-4" @click="deleteDBA(index)"></i>
            </div>
            <button v-else type="button" class="btn btn-primary pdl-15 pdr-15" @click="addDBA">
                <i class="iatse-icon-plus text-white" />
            </button>
        </div>
        <p class="fw-semibold">Address Information</p>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" name="mixed" label="Street 1" placeholder="Street 1" :max=30
                v-model:modelValue="employerAuth.Address.Street1" :is-required="true" :error="errors['Address.Street1']"/>
            <TextInput type="text" name="mixed" label="Street 2 " placeholder="Street 2" :max=30
                v-model:modelValue="employerAuth.Address.Street2" />
        </div>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" name="mixed" label="City" placeholder="City" :max=50
                v-model:modelValue="employerAuth.Address.City" :is-required="true" :error="errors['Address.City']"/>
            <TextInput type="text" name="number" label="Zip/Postal Code " placeholder="Zip/Postal Code" :max=9
                v-model:modelValue="employerAuth.Address.Zip"  :is-required="true" :error="errors['Address.Zip']"/>
            <SelectComponent label="State" v-model:selectedOption="employerAuth.Address.State" :options=states :is-required="true" :error="errors['Address.State']"/>
        </div>
        <p class="fw-semibold">Personal Information</p>

        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" label="First Name" placeholder="First Name" :max=12
                v-model:modelValue="employerAuth.FirstName" :is-required="true" :error="errors?.FirstName"/>
            <TextInput type="text" label="Middle Name" placeholder="Middle Name" :max=12
                v-model:modelValue="employerAuth.MiddleName" />
            <TextInput type="text" label="Last Name" placeholder="Last Name" :max=25
                v-model:modelValue="employerAuth.LastName" :is-required="true" :error="errors?.LastName"/>
        </div>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" name="mixed" label="Title" placeholder="Title" :max=200
                v-model:modelValue="employerAuth.Title" />
            <TextInput type="text" name="phone" label="Phone" placeholder="Enter Phone" :max=16
                v-model:modelValue="employerAuth.Phone" :is-required="true" :error="errors?.Phone"/>
        </div>
        <TextInput type="email" label="Email" placeholder="Email" :max=100
            v-model:modelValue="employerAuth.Email" :is-required="true" :error="errors?.Email"/>
    </div>
    <div class="d-flex mt-40 gap-20 justify-content-center flex-column">
        <!-- reCAPTCHA Button -->
        <ReCaptcha
            :recaptchaError="recaptchaError"
            :loadingRecaptcha="loadingRecaptcha"
            :token="token"
            @update:recaptchaError="recaptchaError = $event"
            @update:loadingRecaptcha="loadingRecaptcha = $event"
            @update:token="token = $event"
        />
        <div class="d-flex gap-20 flex-md-row flex-column">
            <button type="button" class="btn btn-secondary w-100 lh-1 border-radius-8" @click.prevent="handleDiscard" :disabled="loadingSubmit">
                Discard
            </button>
            <!-- <button  type="button" class="btn btn-tertiary w-100 lh-1 border-radius-8">Back</button> -->
            <button type="submit" class="btn btn-primary plan-modal w-100 lh-1 border-radius-8" @click.prevent="handleRegister" @keypress.enter.prevent="handleRegister" :disabled="loadingSubmit">
                Submit
                <svg v-if="loadingSubmit" width="24" height="16" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                    enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="#8EC986"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                            from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                    </path>
                </svg>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, PropType, defineComponent, ref, watch } from 'vue';
import { SelectOption } from '@/interfaces/interfaces';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import ReCaptcha from '@components/form/ReCaptcha.vue';

interface EmployerAuth {
    CompanyName: string;
    TaxID: string;
    DBANames: { name: string, showDelete: boolean }[];
    Address: {
        Street1: string;
        Street2: string;
        City: string;
        Zip: string;
        State: SelectOption;
    };
    FirstName: string;
    MiddleName: string;
    LastName: string;
    Title: string;
    Phone: string;
    Email: string;
}

export default defineComponent({
    props: {
        states: Array as PropType<SelectOption[]>,
        loadingSubmit: Boolean,
        errors: Object as PropType<Record<string, string>>,
        success: Boolean,
    },
    components: {
        TextInput,
        SelectComponent,
        ReCaptcha
    },
    setup(props, { emit }) {
        const initialAddress = {
            Street1: '',
            Street2: '',
            City: '',
            Zip: '',
            State: { key: 0, value: 'Select State'},
        }

        const initialValues: EmployerAuth = {
            CompanyName: '',
            TaxID: '',
            DBANames: [{ name: '', showDelete: false }],
            Address: {...initialAddress},
            FirstName: '',
            MiddleName: '',
            LastName: '',
            Title: '',
            Phone: '',
            Email: '',
        };

        const reset = ref(false);
        const token = ref(null);
        const recaptchaError = ref<string | null>(null);
        const loadingRecaptcha = ref(false);

        const employerAuth: EmployerAuth = reactive(JSON.parse(JSON.stringify(initialValues)));

        watch(() => props.errors?.token, (newValue) => {
            recaptchaError.value = newValue ?? null;
        })

        // Watch for changes in props.success
        watch(() => props.success, (newValue) => {
            if (newValue !== null) {
                Object.assign(employerAuth, JSON.parse(JSON.stringify(initialValues)));
            }
        });

        const handleDiscard = () => {
            reset.value = false;
            Object.assign(employerAuth, initialValues);
            recaptchaError.value = null;
            emit('reset-errors');
            reset.value = true;
        }

        const addDBA = () => {
            employerAuth.DBANames.push({ name: '', showDelete: true });
        };

        const deleteDBA = (index: number) => {
            employerAuth.DBANames.splice(index, 1)
        }

        const handleRegister = () => {
            const DBANames = employerAuth.DBANames.map(item => item.name);
            const employer = JSON.parse(JSON.stringify(employerAuth));
            employer.DBANames = DBANames;

            emit('register', {
                ...employer, token: token.value,
                    Address: {
                    ...employer.Address,
                    State: employer.Address.State.key,
                }
            });
        }

        return {
            recaptchaError,
            token,
            loadingRecaptcha,
            employerAuth,
            addDBA,
            deleteDBA,
            handleRegister,
            handleDiscard
        }
    }
})
</script>