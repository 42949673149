<template>
    <MessageCard message="For your protection and for the benefit of your family, we encourage you to keep beneficiary information up to date. The displayed information is from your records with the Fund Office. If the information is not correct, click on the applicable beneficiary in order to update. If you wish to add a new beneficiary, click the button on the upper right side of your screen." type="primary" iconClass="iatse-icon-alarm"
        classes="info_card_full" />
    <LoaderComponent v-if="loading" />
    <div v-else :class="{ 'plan-content': true, 'centered': !beneficiaries?.length || !beneficiaries }">
        <div v-if="beneficiaries?.length" class="account-actions pdt-0">
            <div class="action-buttons justify-content-end">
                <a href="" class="btn btn-primary plan-modal" @click.prevent="handleAdd">
                    Add Beneficiary
                </a>
            </div>
        </div>
        <div v-if="!beneficiaries?.length || !beneficiaries" class="no-bene-card">
            <img src="../../../assets/images/Elderly_financial.png" alt="Elderly Financial" width="248" height="248">
            <span>Add new beneficiaries and set their funds allocation</span>
            <a href="#" class="btn btn-primary plan-modal" @click.prevent="handleAdd">
                Add Beneficiary
            </a>
        </div>
        <template v-for="beneficiary, key in beneficiaries" :key="key">
            <BeneficiaryProfile :beneficiary="beneficiary" />
        </template>
    </div>
</template>

<script lang="ts">
import { ref, computed, Ref, onMounted, watch, defineComponent } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import BeneficiaryProfile from '@components/cards/BeneficiaryProfile.vue';
import axios from 'axios';

export default defineComponent({
    components: {
        BeneficiaryProfile
    },
    setup(_, { emit }) {
        const loading = ref(true);
        const modalStore = useModalStore();
        const beneStore = useBeneficiaryStore();
        const beneficiaries = computed(() => [...beneStore.beneficiaries, ...beneStore.nonAllocatedBenis]);

        watch(beneficiaries, (newData) => {
            if (newData.length) {
                emit('toggle-header-button', true);
            } else {
                emit('toggle-header-button', false);
            }
        });

        const fetchData = async () => {
            if (!beneStore.beneficiaries.length) {
                await beneStore.fetchBeneficiaries();
            }

            loading.value = false;
        };

        const handleAdd = () => {
            beneStore.isDeleteAction = false;
            beneStore.isEditAction = false;
            beneStore.isAddAction = true;
            modalStore.openModal('add-beneficiary', 'Add Beneficiary', 'plan-form plan-upload')
        }

        onMounted(async () => {
            await fetchData();
            beneStore.isAddAction = false;
            beneStore.isDeleteAction = false;
            beneStore.isEditAction = false;
        });

        return {
            loading,
            beneficiaries,
            handleAdd,
            openModal: modalStore.openModal
        };
    }
});
</script>