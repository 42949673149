<template>
    <div class="modal-body">
      <form class="d-flex justify-center flex-column w-100">
        <p>
          We have sent you a verification code to your {{ method === 'E' ? 'email' : 'phone'}} please check and add the code below in the boxes.
        </p>
        <div class="mt-10">
            <VerificationCodeInput v-model="verificationCode" :error="errors" />
        </div>
        <div v-if="success" class="mt-10">
            <p>{{ success }}</p>
        </div>
        <button type="submit" class="btn btn-primary w-100 mt-20" @click.prevent="handleSubmit" @keypress.enter.prevent="handleSubmit">Enter Code</button>
        <div class="text-center mt-10">
            <a href="#" @click.prevent="regenerateCode" class="links">Resend Code ({{ timer }} seconds remaining)</a>
        </div>
      </form>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useVerificationCode } from '@/composable/useVerificationCode';
import { useParticipantStore } from '@/stores/participant';
import VerificationCodeInput from '@components/form/VerificationCodeField.vue';
import axios from 'axios';

export default defineComponent({
components: {
    VerificationCodeInput,
},

setup() {
    const modalStore = useModalStore();
    const participantStore = useParticipantStore();
    const newEmail: string | null = modalStore.currentModal?.queryParams?.email as string | null;
    const newPhone: string| null = modalStore.currentModal?.queryParams?.phone  as string | null;
    const method: string = modalStore.currentModal?.queryParams?.method as string ?? participantStore.userTwoFaMethod as string ?? 'E';
    const expirationTime = ref(120);

    const {
      verificationCode,
      timer,
      success,
      errors,
      regenerateCode,
      submitCode,
    } = useVerificationCode(method, 2, newEmail, newPhone);

    const handleSubmit = async () => {
        try {
            await submitCode();

            if(!errors.value) {
                modalStore.closeLastModal();

                if(modalStore.currentModal?.modalProps && typeof modalStore.currentModal?.modalProps?.onConfirm === 'function') {
                    modalStore.currentModal?.modalProps?.onConfirm();
                }
            }
        }
        catch (error: any) {
            if (error.response && error.response.data) {
                errors.value = error.response.data.error || 'An error occurred';
            }
            else {
                errors.value = 'Unexpected error occurred';
            }
        }
    };

    onMounted(() => {
        if(!participantStore.userTwoFaMethod) {
            participantStore.getUserEmail();
        }
    })

    return {
        timer,
        expirationTime,
        method,
        verificationCode,
        handleSubmit,
        regenerateCode,
        success,
        errors
    };
},
});
</script>
