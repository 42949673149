<template>
    <div class="aside-contact">
        <img src="../../assets/images/contact.svg" alt="contact image" />
        <div class="aside-contact-text text-300 opacity-80">
            Having Trouble with the Portal. <br />
            Feel free to reach out to us.
        </div>
        <!-- <a href="https://iatse-public.staging.polardesign.com/en/contact" target="_blank"
            class="btn btn-secondary btn-contact">Contact Us</a> -->
            <a href="#" @click.prevent="openModal('contact', 'Contact Us', 'plan-upload plan-card-info plan-profile-update plan-tables pension-credits')"
            class="btn btn-secondary btn-contact plan-modal">Contact Us</a>
    </div>
</template>

<script lang="ts">
import { useModalStore } from '@/stores/modal';

export default {
    name: 'ContactComponent',
    setup() {
        return {
            openModal: useModalStore().openModal
        }
    }
}
</script>