<template>
  <!-- Plan Content -->
  <MessageCard message="This page displays information about you on file at the Fund Office. You can update this
      information by clicking on the Update button under the applicable section if any of it has changed."
    type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />
  <LoaderComponent v-if="loading" />
  <div v-else class="plan-content">
    <!-- General Plan Detail Cards -->
    <div class="plan-details">
      <ProfileInfo title="My Personal Information" :items="personalInfoItems"
        @open-modal="openModal('update-personal-info', 'Update Personal Information', 'plan-upload plan-card-info plan-profile-update gap-20 sm-gap-0')" />
      <ProfileInfo title="My Address" :items="addressInfoItems" :info="info"
        @open-modal="openModal('update-address', 'Update Address', 'plan-upload plan-card-info plan-profile-update')" />
      <ProfileInfo title="My Contact Information" :items="contactInfoItems"
        @open-modal="openModal('update-contact-information', 'Update Contact Information', 'plan-upload plan-tables')" />

      <!-- Upload Documents -->
      <!-- <div class="plan-general-details quick-links">
        <h4 class=""> My Documents </h4>
        <DragDrop customClasses="grey large" @change="handleFileSelection" :reset="reset" />
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';

import ProfileInfo from '@components/cards/ProfileInfo.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import DragDrop from '@components/form/DragDrop.vue';
import { useParticipantStore } from '@/stores/participant';

type ProfileChange = {
  [key: string]: boolean;
}

export default {
  components: {
    ProfileInfo,
    MessageCard,
    // DragDrop
  },
  setup() {
    const modalStore = useModalStore();
    const profileStore = useProfileStore();
    const loading = ref(true);
    const reset = ref(false);

    const getClass = (label: keyof ProfileChange): string => {
      return profileStore.changes[label] ? 'grey-text' : '';
    };

    const info = computed(() => useParticipantStore().getAddressErrors('profile'))

    const personalInfoItems = computed(() => [
      { label: 'Participant ID', value: profileStore.personalData?.PersonID },
      { label: 'Name', value: profileStore.personalData?.FullName, class: 'right', itemClass: getClass('FirstName') || getClass('LastName') },
      { label: 'Date of Birth', value: profileStore.personalData?.DateofBirthFormatted, itemClass: getClass('DateofBirth') },
      { label: 'Gender', value: profileStore.personalData?.Sex?.value, class: 'right', itemClass: getClass('Gender') },
      { label: 'Marital Status', value: profileStore.personalData?.MaritalStatus?.value, itemClass: getClass('MaritalStatus') },
      { label: 'Home Local', value: profileStore.personalData?.HomeLocal?.value, class: 'right', itemClass: getClass('HomeLocal') }
    ]);

    const addressInfoItems = computed(() => [
      { label: 'Address Type', value: profileStore.address?.Type?.value },
      { label: 'Primary', value: profileStore.address?.Primary ? 'Yes' : 'No', class: 'right' },
      { label: 'Address', value: profileStore.address?.Streets ?? '--'},
      { label: 'City', value: profileStore.address?.City, class: 'right' },
      { label: 'State', value: profileStore.address?.State?.key != 0 ? profileStore.address?.State?.value : '-' },
      { label: 'Country', value: profileStore.address?.Country?.key != 0 ? profileStore.address?.Country?.value : '-', class: 'right' },
      { label: 'Postal Code', value: profileStore.address?.ZipCode },
    ]);

    const contactInfoItems = computed(() => [
      { label: 'Home Phone', value: profileStore.contact?.Home ?? '--' },
      { label: 'Mobile Phone', value: profileStore.contact?.Mobile ?? '--', class: 'right' },
      { label: 'Work Phone', value: profileStore.contact?.Work ?? '--' },
      // { label: 'Primary Phone', value: profileStore.contact?.Home ? 'Yes' : 'No', class: 'right' },
      { label: 'Web Email Address', value: profileStore?.userEmail, class: 'right' },
      { label: 'Alternate Email Address', value: profileStore?.primaryEmail ?? '--' }
    ]);

    // Get Profile Data
    const fetchData = async () => {
      try {
        if (!profileStore.personalData || !profileStore.changes.length) {
          loading.value = true;
          await profileStore.fetchPersonalData();
        }

        if (!profileStore.address) {
          loading.value = true;
          await profileStore.fetchAddress();
        }

        if (!profileStore.contact) {
          loading.value = true;
          await profileStore.fetchContactData();
        }

        if (!profileStore.generalData) {
          loading.value = true;
          await profileStore.fetchGeneralData();
        }

        if (!profileStore.countries || !profileStore.statesByCountry) {
          loading.value = true;
          await profileStore.fetchCountriesStates();
        }

        loading.value = false;
      } catch (error) {
        console.error('Error fetching profile data:', error);
      }
    }

    const handleFileSelection = () => {
      console.log('@file selection')
    }

    onMounted(async () => {
      await fetchData();
    });

    return {
      profileStore,
      reset,
      info,
      loading,
      personalInfoItems,
      addressInfoItems,
      contactInfoItems,
      handleFileSelection,
      openModal: modalStore.openModal,
    };
  }
}
</script>