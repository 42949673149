<template>
    <div class="plan-content">
        <div class="filters">
            <div class="filters-section">
                <div class="filter-item">
                    <h5> Name: </h5>
                    <small> {{ filters?.PatientName?.FullName }}</small>
                </div>
                <div class="filter-item">
                    <h5> Status: </h5>
                    <small> {{ filters?.ClaimStatus }}</small>
                </div>
            </div>
            <div class="filters-section">
                <div class="filter-item">
                    <h5> Date Received: </h5>
                    <SortComponent v-model:type="filters.Sorting.ServiceDate.StartDate" name="" :readonly="true" />
                    <!-- <SortComponent v-model:type="filters.Sorting.ServiceDate.EndDate" name="To" :readonly="true" /> -->
                </div>
                <!-- <div class="filter-item">
                    <h5> Submission Date: </h5>
                    <SortComponent v-model:type="filters.Sorting.SubmissionDate.StartDate" :readonly="true" />
                    <SortComponent v-model:type="filters.Sorting.SubmissionDate.EndDate" name="To" :readonly="true" />
                </div> -->
            </div>
        </div>
        <!-- <DataTable :table="history" classes="striped small" /> -->
        <table class="striped small toggle equal-widths claims no-underline">
                <thead>
                    <tr>
                        <th
                            v-for="(header, key) in history.headers"
                            :key="key"
                            >
                            {{ header.title }}
                        </th>
                    </tr>
                </thead>

                <tbody v-if="claimStore.history?.rows.length">
                    <template v-for="(row, mainKey) in  claimStore.history.rows" :key="mainKey">
                        <tr class="parent-row sm-pdl-10 sm-pdr-10">
                            <td :colspan="history?.headers?.length">
                                <div class="table-row_wrapper d-flex flex-column gap-20">
                                    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap gap-20">
                                        <div class="cell d-flex gap-10" @click="toggleCell(mainKey)">
                                            <p class="toggle-cell view-details">
                                                {{ toggleStates[mainKey] ? '-' : '+' }}
                                            </p>
                                            <p class="cell-header hide-desktop"> Date(s) <br /> of Services </p>
                                            <p  class="cell-text text-wrap">{{ row.Parent['Date(s) of Service'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Claim #</p>
                                            <p  class="cell-text text-wrap" :class="{'plan-modal': Number(row.Parent['Claim Number'])}" @click="editClaim(row.Parent['Claim Number'], 'claim')">{{ row.Parent['Claim Number'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Web Referennce #</p>
                                            <p  class="cell-text text-wrap" :class="{'plan-modal': !Number(row.Parent['Claim Number'])}" @click="editClaim(row.Parent['Web Reference'], 'webclaim')">{{ row.Parent['Web Reference'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Patient Name</p>
                                            <p  class="cell-text text-wrap">{{ row.Parent['PatientName'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Patient DOB</p>
                                            <p  class="cell-text text-wrap">{{ row.Parent['PatientDob'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Relationship</p>
                                            <p  class="cell-text text-wrap">{{ row.Parent['PatientRelationship'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Claim Status</p>
                                            <p  class="cell-text text-wrap">{{ row.Parent['Claim Status'] }}</p>
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> Claim Doc(s)</p>
                                            <img class="plan-modal" @click="openModal(row.Parent['Claim Doc(s)'].path, row.Parent['Claim Doc(s)'].name, row.Parent['Claim Doc(s)'].classes, null, row.Parent['Claim Doc(s)'].props)" :src="require('@/assets/images/icons/pdf_primary.svg')" alt="doc icon" width="18" height="18" />
                                        </div>
                                        <div class="cell d-flex gap-10">
                                            <p class="cell-header hide-desktop"> EoB Doc(s)</p>
                                            <img v-if="Number(row.Parent['EoB Doc'])" class="plan-modal" @click="openModal('smartsearch', null, 'modal-success', null, { DocumentID: row.Parent['EoB Doc']})" :src="require('@/assets/images/icons/pdf_primary.svg')" alt="doc icon" width="18" height="18" />
                                            <p v-else class="cell-text tex-wrap"> No EoB Docs</p>
                                        </div>
                                    </div>
                                    <transition name="slide-fade">
                                        <div v-if="toggleStates[mainKey] && row.Children" class="d-flex subitems-columns flex-column gap-20 p-15 sm-pdl-5 sm-pdr-0 sm-pdt-0">
                                            <div class="d-flex subitems-columns_wrapper flex-column"
                                                v-for="(child, index) in  Object.values(row.Children) " :key="index">
                                                <div class="d-flex subitems-columns_wrapper w-100">
                                                    <div class="d-flex flex-column gap-2">
                                                        <h5 class="text-dark fw-bold sm-fs-xs"> Claim Details </h5>
                                                        <div class="d-flex subitems-columns_details flex-md-nowrap flex-wrap">
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> AmtRequested </p>
                                                                <h5 class="sm-fs-xs"> {{ formatCurrency(child.AmtRequested) }} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Pd By Other Carrier </p>
                                                                <h5 class="sm-fs-xs"> {{ formatCurrency(child.OtherCarrier) }} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Admin Fee </p>
                                                                <h5 class="sm-fs-xs"> {{ formatCurrency(child.AdminFee) }} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Amt Paid </p>
                                                                <h5 class="sm-fs-xs"> {{ formatCurrency(child.AmtPaid) }} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Reqst Date </p>
                                                                <h5 class="sm-fs-xs"> {{ child.DateReceived }} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Previous Claim # </p>
                                                                <h5 class="sm-fs-xs"> {{ child.PrevClaim }} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div :class="['divider', { 'hide-mobile': index % 2 === 1 }]"></div>
                                                    <div class="d-flex flex-column gap-2">
                                                        <h5 class="text-dark fw-bold sm-fs-xs"> Payment Details </h5>
                                                        <div class="d-flex subitems-columns_details">
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Payment Info </p>
                                                                <h5 class="sm-fs-xs"> {{ child.PaymentInfo ?? '-'}} </h5>
                                                            </div>
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Check / Deposit Date</p>
                                                                <h5 class="sm-fs-xs"> {{ child.CheckDepositDate ?? '-' }} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="!child.Notes || child.Notes?.length < 20" class="d-flex flex-column gap-2">
                                                        <h5 class="text-dark fw-bold sm-fs-xs"> Claim Notes </h5>
                                                        <div class="d-flex subitems-columns_details">
                                                            <div class="d-flex flex-column gap-2">
                                                                <p> Notes</p>
                                                                <h5 class="sm-fs-xs"> {{ child.Notes ?? '-' }} </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="child.Notes?.length >= 20" class="divider divider_horizontal"></div>
                                                <div v-if="child.Notes?.length >= 20" class="d-flex flex-column gap-2 w-100">
                                                    <h5 class="text-dark fw-bold sm-fs-xs"> Notes </h5>
                                                    <p class="sm-fs-xs"> {{ child.Notes ?? '-'}} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="parent-row">
                        <td class="cell text-center" colspan="6">
                            <p>No data available</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        <PaginationComponent v-if="claimStore.paginator" :paginator="claimStore.paginator" @fetch-data="onPageChange" />
    </div>
</template>

<script lang="ts">
import { defineComponent, watch, ref, onMounted } from 'vue';
import { useClaimStore } from '@/stores/claim';
import { storeToRefs } from 'pinia'
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";

import SortComponent from '@/components/form/SortComponent.vue';
import PaginationComponent from '@components/utils/PaginationComponent.vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    components: {
        SortComponent,
        PaginationComponent
    },
    setup() {
        const claimStore = useClaimStore();
        const { filters, history } = storeToRefs(claimStore);
        const { formatCurrency } = useCurrencyFormatter();

        watch(() => filters, () => {
            claimStore.performSearch();
        }, { deep: true });

        const toggleStates = ref<Record<number, boolean>>({});

        const toggleCell = (index: number) => {
            console.log(index, '>>toggle')
            Object.keys(toggleStates.value).forEach(key => {
                toggleStates.value[Number(key)] = Number(key) === index ? !toggleStates.value[Number(key)] : false;
            });
        }

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const onPageChange = (pageNumber: number) => {
            claimStore.fetchPaginationData(pageNumber);
            claimStore.history.rows?.forEach((_, index) => {
                toggleStates.value[index] = false;
            });
        }

        const editClaim = (claim: number, type: string) => {
            if(Number(claim)) {
                useModalStore().openModal('claim-submission', 'Edit Claim Submission',  'plan-upload plan-card-info', null, { ClaimID: claim.toString(), Type: type})
            }
        }

        onMounted(() => {
            if (claimStore.history.rows?.length) {
                claimStore.history.rows?.forEach((_, index) => {
                    toggleStates.value[index] = false;
                });
            }
        })

        return {
            history,
            filters,
            claimStore,
            toggleStates,
            toggleCell,
            isNumeric,
            formatCurrency,
            onPageChange,
            editClaim,
            openModal: useModalStore().openModal
        }
    },
})
</script>