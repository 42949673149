import { PrintData } from "@/interfaces/interfaces";

export function usePrint() {
    function printElementById(id: string, title: string, data?: PrintData) {
        return function () {
            const contents = document.getElementById(id)?.innerHTML;
            const apiBaseUrl = process.env.VUE_APP_API_ENDPOINT;
            const frame1 = document.createElement("iframe");
            frame1.name = "frame1";
            frame1.style.position = "absolute";
            frame1.style.top = "-1000000px";
            document.body.appendChild(frame1);
            const frameDoc: any =
                frame1.contentWindow || frame1.contentDocument;

            if (frameDoc) {
                frameDoc.document.open();
                frameDoc.document.write(
                    '<html lang="en"><head><title>' + title + "</title>"
                );
                frameDoc.document.write(`
                    <style>
                    @page {
                        size: A4;
                    }

                    @media print {
                        html {
                            -webkit-print-color-adjust: exact; /*chrome & webkit browsers*/
                            color-adjust: exact; /*firefox & IE */
                            background: #fff;
                            font-family: 'Lexend', sans-serif;
                        }

                         html, body {
                            width: 1000px; /* Set a fixed width for the page */
                            margin: 0 auto; /* Center the content */
                            font-size: 12px; /* Set a consistent font size */
                            overflow: visible !important;
                            margin: 0;
                            padding: 0;
                            box-sizing: border-box;
                        }

                        /* Prevent elements from stacking like on mobile */
                        div, section, header, footer {
                            width: auto !important;
                            float: none !important;
                        }

                        /* Disable mobile-specific responsive behaviors */
                        body {
                            max-width: unset !important;
                            display: block !important;
                        }

                        table {
                            width: 100%;
                            width: 1000px;
                            border-collapse: collapse;
                            font-size: 10px;
                        }

                        .hidden {
                            display: block !important;
                        }

                        table.content {
                            font-size: 8px;
                            max-width: calc(100% - 32px);
                            margin: 0 16px;
                        }

                        thead tr {
                            border-bottom: 1px solid #17388C;
                            background: #EBF0FF;
                        }

                        tbody {
                            background: #fff;
                            margin-bottom: 10px;
                        }
                        th, td {
                            padding: 10px;
                            text-align: left;
                            vertical-align: middle;
                        }
                        td {
                            min-height: 40px;
                            vertical-align: middle;
                        }

                        body table tbody tr:nth-child(even) td {
                            background: #EBF0FF;
                            border-bottom: 1px solid #17388C;
                        }

                        tr.total td {
                            background: #F1F8EF !important;
                            color: black;
                            border-bottom: none !important;
                        }

                        tr.total {
                        }

                        tr.subtotal td {
                            font-weight: 600;
                            color: white;
                            background: #17388C !important;
                        }

                        a {
                            color:black;
                            text-decoration: none;
                        }

                        img {
                            display: block !important;
                            max-width: 100% !important;
                        }

                        .table.filters-table tr td {
                            border: 1px solid #D8D8D8;
                            padding: 10px;
                        }

                        header table.header tr td {
                            background: #17388C;
                            color: #fff;
                            vertical-align: middle;
                            min-height: 40px;
                        }

                        header {
                            position: fixed;
                            top: 0;
                            width: 100%;
                            color: #fff;
                            // height: 150px;
                            width: 1000px;
                        }

                        footer {
                            position: fixed;
                            bottom: 0;
                            width: 100%;
                            width: 1000px;
                            background: #fff;
                            border-top: 1px solid rgba(0, 0, 0, 0.15);
                            margin-top: 10px;
                        }

                        footer table tr td {
                            vertical-align: middle;
                            height: 35px;
                        }

                        body {
                            padding-top: 170px;
                            box-sizing: border-box;
                            display: block;
                        }

                        table {
                            page-break-inside: auto;
                        }

                        .page-break {
                            page-break-after: always;
                        }

                        tr {
                            page-break-inside: avoid;
                            page-break-after: auto;
                        }

                        @page {
                            margin-top: 100mm;
                        }
                    }

                    /* Hide buttons when printing */
                    button {
                        display: none;
                    }

                    /* Show details when printing */
                    tr.showDetails {
                        display: table-row !important;
                    }
                    </style>
                `);

                // header
                frameDoc.document.write("</head> <header>");
                frameDoc.document.write(
                    `<table class="header" style="width: 100%; padding: 16px;">
                        <tr>
                            <td style="width: 100%;">
                                <div style="display: flex; justify-content: space-between; align-items: center;">
                                    <img src="${apiBaseUrl}/storage/logo.png"
                                    alt="Logo"
                                    title="IATSE Benefits Fund Logo"
                                    width="107" height="40"
                                    />
                                    <div style="display: flex; gap: 4px; ">
                                        <img src="${apiBaseUrl}/storage/location.svg"
                                        alt="Location"
                                        title="Location"
                                        style="width: 10px; height: 10px;" />
                                            <p style="margin: 0; font-size: 10px; font-weight: 600">
                                            417 Fifth Avenue, 3rd Floor, New York, NY 10016-2204
                                        </p>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                    `
                );

                if (data?.Header) {
                    let headerContent = `
                        <table style="width: 1000px; padding: 12px 0;">
                            <tr>
                                <td style="width: 100%; vertical-align: middle;">
                                    <div style="width:100%; display: flex; justify-content: space-between; gap: 30px; padding: 10px 0">
                    `;

                    if (data.Header.ParticipantName) {
                        headerContent += `
                            <div style="display: flex; align-items: center; gap: 6px;">
                                <span style="font-weight: 500;"> Participant Name: </span>
                                <span style="font-weight: 300;"> ${data.Header.ParticipantName} </span>
                            </div>
                        `;
                    }

                    if (data.Header.EmployerName) {
                        headerContent += `
                            <div style="display: flex; align-items: center; gap: 6px;">
                                <span style="font-weight: 500;"> Employer Name: </span>
                                <span style="font-weight: 300;"> ${data.Header.EmployerName} </span>
                            </div>
                        `;
                    }

                    headerContent += `
                        <div style="display: flex; align-items: center; gap: 6px;">
                            <span style="font-weight: 500;">Report Date: </span>
                            <span style="font-weight: 300;"> ${data.Header.ReportDate}  </span>
                        </div>
                        <div style="display: flex; align-items: center; gap: 6px;">
                            <span style="font-weight: 500;"> Print Date: </span>
                            <span style="font-weight: 300;"> ${data.Header.PrintDate} </span>
                        </div>
                    `;

                    headerContent += `
                                    </div>
                                </td>
                            </tr>
                        </table>
                    `;

                    frameDoc.document.write(headerContent);
                }

                // filters
                if (data?.Filters) {
                    let filtersHtml = `
                        <table class="filters-table" style="width: 100%; margin-bottom: 8px">
                            <tr>
                                <td style="width: 100%; vertical-align: middle;">
                                    <div style="width:100%; display: flex; justify-content: space-between; gap: 6px;">
                    `;

                     // Add DateRange if not null
                     if (data.Filters.DateRange) {
                        filtersHtml += `
                            <div style="padding: 10px; border: 1px solid #D8D8D8; width: 100%; border-radius: 3px; display: flex; justify-content: space-between">
                                <span style="font-weight: 500;"> Date Range: </span>
                                <span style="font-weight: 400; opacity: 80%;"> ${data.Filters.DateRange} </span>
                            </div>
                        `;
                    }

                    // Add Employers if not null
                    if (data.Filters.Employers) {
                        filtersHtml += `
                            <div style="padding: 10px; border: 1px solid #D8D8D8; width: 100%; border-radius: 3px; display: flex; justify-content: space-between">
                                <span style="font-weight: 500;"> Employers: </span>
                                <span style="font-weight: 400; opacity: 80%;"> ${data.Filters.Employers} </span>
                            </div>
                        `;
                    }

                    // Add Contract Types if not null
                    if (data.Filters.ContractTypes) {
                        filtersHtml += `
                            <div style="padding: 10px; border: 1px solid #D8D8D8; width: 100%; border-radius: 3px; display: flex; justify-content: space-between">
                                <span style="font-weight: 500;"> Contract Types: </span>
                                <span style="font-weight: 400; opacity: 80%;"> ${data.Filters.ContractTypes} </span>
                            </div>
                        `;
                    }

                    // Add Productions if not null
                    if (data.Filters.Productions) {
                        filtersHtml += `
                            <div style="padding: 10px; border: 1px solid #D8D8D8; width: 100%; border-radius: 3px; display: flex; justify-content: space-between">
                                <span style="font-weight: 500;"> Productions: </span>
                                <span style="font-weight: 400; opacity: 80%;"> ${data.Filters.Productions} </span>
                            </div>
                        `;
                    }

                    // Close the table
                    filtersHtml += `
                                    </div>
                                </td>
                            </tr>
                        </table>
                    `;

                    // Write the HTML to the frame document
                    frameDoc.document.write(filtersHtml);
                }

                // body
                frameDoc.document.write("</header>");

                // body
                frameDoc.document.write("<body>");

                // main content
                frameDoc.document.write(`
                    <table class='content'>
                        ${contents}
                    </table>
                `);

                // additional info
                if (data?.Info) {
                    frameDoc.document.write(`
                        <table style="width: 100%; margin-top: 20px;">
                            <tr>
                                <td style="width: 100%;">
                                    <p style="font-weight: 600; font-size: 8px; text-align: center; color: #1E1E1E;"> ${data.Info} </p>
                                </td>
                            </tr>
                        </table>
                    `);
                }

                frameDoc.document.write("</body>");

                // footer
                frameDoc.document.write(
                    `<footer>
                        <table style="width: 1000px;">
                            <tr>
                                <td style="width: 100%;">
                                    <div style="width:100%; display: flex; justify-content: space-between; align-items: center; gap: 40px;">
                                        <p style="width: 100%; font-weight: 500">IATSE National Benefit Fund </p>
                                        <div style="width:100%; display: flex; gap: 30px; align-items: center;">
                                            <div style="width:100%; display: flex; gap: 4px; align-items: center; padding-right: 16px;">
                                                <img src="${apiBaseUrl}/storage/location_primary.svg"
                                                alt="Location"
                                                title="Location"
                                                style="max-width: 10px;" />
                                                <span class="opacity: 80%;"> 417 Fifth Avenue, 3rd Floor, New York, NY 10016-2204 </span>
                                            <div>
                                            <div style="width:100%; display: flex; gap: 4px; align-items: center;">
                                                <img src="${apiBaseUrl}/storage/phone.svg"
                                                alt="Location"
                                                title="Location"
                                                style="max-width: 10px;" />
                                                <span class="opacity: 80%;"> 212-580-9092 </span>
                                            <div>
                                        <div>
                                    <div>
                                </td>
                            </tr>
                        </table>
                    </footer>`
                );

                frameDoc.document.write("</html>");
                frameDoc.document.close();

                setTimeout(function () {
                    (window.frames as any)["frame1"].focus();
                    (window.frames as any)["frame1"].print();
                    document.body.removeChild(frame1);
                }, 300);
            }

            return false;
        };
    }

    return { printElementById };
}
