<template>
    <div class="modal-body text-center">
        <div v-if="type == 'capp'">
            <div v-if="success">
                <h3>Payment Successful</h3>
                <p class="pdt-10" v-if="transactionId">Coverage Selection has been made, transaction id: # {{ transactionId }}</p>
                <p class="pdt-10" v-else>Coverage Selection has been made.</p>

                <a href="#" @click.prevent="goToDashboard" class="btn btn-primary mt-20">Go Back</a>
            </div>
            <div v-else>
                <h3>Payment Failed</h3>
                <p class="pdt-10">Payment has not been made</p>

                <a href="#" @click.prevent="closeModal" class="btn btn-primary mt-20">Go back</a>
            </div>
        </div>
       <div v-else>
            <div v-if="success">
                <h3>Payment Successful</h3>
                <p class="pdt-10">Payment has been made</p>

                <a href="#" @click.prevent="goToDashboard" class="btn btn-primary mt-20">Go to dashboard</a>
            </div>
            <div v-else>
                <h3>Payment Failed</h3>
                <p class="pdt-10">Payment has not been made</p>

                <a href="#" @click.prevent="openModal('hw-cobra-statements', 'Make COBRA Payment', 'plan-upload plan-card-info plan-c-mrp-claims')" class="btn btn-primary mt-20">Go back to Invoices</a>
            </div>
       </div>
    </div>
  </template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useModalStore } from '@/stores/modal';

export default defineComponent({

setup(props, { emit }) {
    const modalStore = useModalStore();
    const success = modalStore.currentModal?.queryParams?.success;
    const type =  modalStore.currentModal?.queryParams?.type ?? '';
    const transactionId = modalStore.currentModal?.queryParams?.transactionId;

    const goToDashboard = () => {
        modalStore.closeAllModals();
    };

    const closeModal = () => {
        emit('close');
    }

    return {
        openModal: modalStore.openModal,
        goToDashboard,
        closeModal,
        type,
        success,
        transactionId
    };
},
});
</script>
