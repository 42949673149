<template>
    <AlertsComponent :success="success" :errors="errors" />
    <LoaderComponent v-if="loading" />
    <template v-else>
        <MessageCard :message="instructions" type="primary"
            iconClass="iatse-icon-alarm" />
        <div class="plan-content justify-content-between">
            <form class="form">
                <DependentStep1 v-if="!isValidStep1" @check-data="checkData" @close="closeModal" />
                <DependentStep2 v-else :submitting="loadingSubmit" @check-data="checkData"
                    @submit-data="handleSubmit" />
            </form>
        </div>
    </template>

</template>

<script lang="ts">
import { ref, Ref, onMounted, reactive, defineComponent, computed } from 'vue';
import { useDependentStore } from '@/stores/dependent';
import { useModalStore } from '@/stores/modal';
import { useProfileStore } from '@/stores/profile';

import DependentStep1 from '@components/participant/dependents/DependentStep1.vue';
import DependentStep2 from '@components/participant/dependents/DependentStep2.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import axios from 'axios';

export default defineComponent({
    components: {
        DependentStep1,
        DependentStep2,
        MessageCard
    },
    setup(_, { emit }) {
        const modalStore = useModalStore();
        const dependentStore = useDependentStore();
        const profileStore = useProfileStore();
        const route = 'api/participant/dependents/add';
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const isValidStep1 = ref(false);
        const errors: Ref<string[]> = ref([]);
        const success = ref('');
        const isModalVisible = ref(false);
        const openModal = modalStore.openModal;
        const instructions = computed(() => dependentStore.isEditAction ? 'To make changes, please upload the relevant supporting document(s). Your request will be reviewed by the Fund Office Admin’, and any necessary updates will be made accordingly.' : 'Enter the information for your Spouse/Child and/or each Dependent you wish to add. This information must be submitted with qualifying documentation regarding their relationship to you (Step 2). Our Administrative team will review your request(s) prior to making them part of your permanent record. Note that all fields marked with an asterisk are required fields.')

        const checkData = (isValid: boolean) => {
            isValidStep1.value = isValid;
        }

        const closeModal = () => {
            emit('close');
        }

        const handleSubmit = () => {
            loadingSubmit.value = true;
            const formData = {
                Person: { ...dependentStore.Person, SSN: dependentStore?.Person.SSN ? dependentStore.clearFormatting(dependentStore.Person.SSN) : '' },
                Address: dependentStore.Address,
                Email: dependentStore.Email,
                PhoneNumber: dependentStore?.PhoneNumber ? dependentStore.clearFormatting(dependentStore?.PhoneNumber) : '',
                Documents: dependentStore.Documents,
                SelectedDocumentType: dependentStore.SelectedDocumentType?.key,
                IsEditAction: dependentStore.isEditAction,
                ExistingDependent: dependentStore.EditedDependent,
                CreateAddressType: dependentStore.CreateAddressType,
            };

            axios.post(route, { ...formData, _method: "PATCH" })
                .then((response) => {
                    loadingSubmit.value = false;
                    isModalVisible.value = true;

                    if (response.data.success) {
                        openModal('success-modal', null, 'modal-success', null, {
                            title: dependentStore.isEditAction ? 'Uploaded Documents' : 'Dependent Added',
                            content: {
                                type: "text",
                                value: response.data.success,
                                icon: 'iatse-icon-user-verified'
                            },
                        })

                        dependentStore.fetchDependents();
                        dependentStore.clearDependent();
                    }
                    else {
                        errors.value.push('Something went wrong. Please try again.')
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }

                }).finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3000);
                })
        };

        const fetchData = async () => {
            await dependentStore.fetchGeneralData();

            if (!profileStore.generalData) {
                await profileStore.fetchGeneralData();
            }

            if (!profileStore.countries || !profileStore.statesByCountry) {
                await profileStore.fetchCountriesStates();
            }

            if (!profileStore.addressTypes.length) {
                await profileStore.fetchAddress();
            }

            loading.value = false;
        };

        onMounted(async () => {
            await fetchData();
        });

        return {
            isValidStep1,
            loading,
            success,
            errors,
            instructions,
            isModalVisible,
            loadingSubmit,
            checkData,
            closeModal,
            handleSubmit,
        };
    }
});
</script>