<template>
    <Alerts :success="success" :info="info" :errors="errors?.General" />
    <!-- General Plan Detail Cards -->
    <MessageCard message="All Updates to your address will immediately and permanently change your account with the Fund
                        Office." type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <!-- Plan Content -->
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- Form -->
        <form id="update-address-form" class="form update-address align-items-start" :class="{'position-relative': addressErrors}" method="post">
            <h4> Primary Address
                <i v-if="addressErrors" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-info fs-lg">
                    <span class="tooltip-text left-20">{{ addressErrors }}</span>
                </i>
            </h4>
            <div class="inputs-wrapper mb-20">
                <SelectComponent label="Address Type" v-model:selectedOption="formData.Type"
                    :options="profileStore.addressTypes" :single-arrow="true" :error="errors?.Type ?? errors?.Type"
                    :isRequired="true" />
                <TextInput type="text" name="mixed" label="Address 1 " placeholder="Enter Address 1" :max=30
                    v-model:modelValue="formData.Street1" :required="true" :error="errors?.Street1 ?? errors?.Street1"
                    :isRequired="true" />
                <TextInput type="text" name="mixed" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="formData.Street2" :required="true" />
                <TextInput type="text" name="mixed" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="formData.Street3" :required="true" />
                <TextInput type="text" name="mixed" label="Address 4" placeholder="Enter Address 4" :max=30
                    v-model:modelValue="formData.Street4" :required="true" />
                <TextInput type="text" placeholder="Enter City" :label="['US', 'CA', 'MX'].includes(formData.Country?.key) ? 'City' : 'City/State'" :max=25 v-model:modelValue="formData.City"
                    :required="true" :error="errors?.City" :isRequired="true" />

                <SelectComponent label="State" v-model:selectedOption="formData.State" :options="filteredStates"
                    :single-arrow="true" :error="errors ? errors['State.key'] : null" :isRequired="['US', 'CA', 'MX'].includes(formData.Country?.key)" :readonly="!['US', 'CA', 'MX'].includes(formData.Country?.key)" />
                <SelectComponent label="Country" v-model:selectedOption="formData.Country"
                    :options="profileStore.countries" :error="errors? errors['Country.key'] : null" :isRequired="true"/>

                <TextInput type="text" :name="postalCodeType" label="Postal Code" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="formData.ZipCode" :required="true" :error="errors?.ZipCode"
                    :isRequired="true" />
            </div>

            <div class="action-buttons pdt-40 sm-pdr-0 sm-pdl-0 sm-pdt-20">
                <button type="button" @click.prevent="resetModal" class="btn btn-secondary"> Discard</button>
                <button type="submit" @click.prevent="submitData" class="btn btn-primary">
                    {{ loadingSubmit ? 'Saving...' : 'Save Changes' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, reactive, defineComponent, computed, watch, onMounted } from 'vue';
import { CountryStates, AddressInfo } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import { usePensionStore } from '@/stores/pension';
import { useVacationStore } from '@/stores/vacation';
import { useParticipantStore } from '@/stores/participant';
import useFormSubmit from '@composable/useFormSubmit';
import Alerts from '@components/utils/AlertsComponent.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from "@components/form/SelectComponent.vue";


export default defineComponent({
    components: {
        Alerts,
        MessageCard,
        TextInput,
        SelectComponent
    },
    setup(_, { emit }) {
        const profileStore = useProfileStore();
        const pensionStore = usePensionStore();
        const formData: AddressInfo = reactive({ ...profileStore.address });
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const errors = ref<Record<string, string[]|null> | null>(null);
        const success = ref('');
        const info = ref('');
        const route = 'api/user/profile/my-address';
        const initialData = ref<AddressInfo | null>(null);
        const addressErrors = computed(() => useParticipantStore().getAddressErrors('profile'))
        const postalCodeType = computed(() => ['GB', 'CA', 'IE', 'NL', 'AR', 'MT', 'SE', 'NO', 'FI', 'IS'].includes(formData?.Country?.key as string) ? 'mixed' : 'number');

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            Object.assign(formData, {...initialData.value});
        }

        const requiredInputs = {
            Street1: null,
            Type: null,
            City: null,
            State: null,
            Country: null,
            ZipCode: null,
        };

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = formData?.Country?.key != 0 ? formData?.Country?.key : 'US';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(() => formData.Country?.key, (newValue, oldValue) => {
            const isStateValid = filteredStates.value.some(state => state.key === formData.State?.key);

            if (newValue !== oldValue && formData.State && !isStateValid) {
                formData.State = { key: 0, value: "Select State" };
            }

            if(!['US', 'CA', 'MX'].includes(formData.Country?.key as string) && errors.value) {
                errors.value['State.key'] = null;
            }
        })

        // Watch for postalCodeType changes
        watch(postalCodeType, (newType, oldType) => {
            if (oldType === 'mixed' && newType === 'number') {
                formData.ZipCode = formData.ZipCode?.replace(/\D/g, '');
            }
        });

        // Handle Form Submit
        const submitData = async () => {
            const { handleSubmit } = useFormSubmit({ formData: {...formData, ZipCode: postalCodeType.value == "number" ? formData.ZipCode?.replace(/[^0-9]/g, '') : formData.ZipCode}, route, success, info, errors, loadingSubmit, generalErrors: ref<string[]>([]), reset: false, transformObjects: false });
            const successSubmit = await handleSubmit();

            if (successSubmit) {
                profileStore.setAddress(formData);
                await profileStore.fetchAddress();
                await pensionStore.fetchDemographics();
                await useVacationStore().getPanelDetails();
                await useParticipantStore().fetchGeneralData();

                closeModal();
            }
        }

        onMounted(() => {
            initialData.value = JSON.parse(JSON.stringify(profileStore.address));

            loading.value = false;
        });

        return {
            postalCodeType,
            addressErrors,
            formData,
            profileStore,
            filteredStates,
            errors,
            success,
            info,
            loading,
            loadingSubmit,
            submitData,
            closeModal,
            resetModal,
        };
    }
})
</script>