<template>
  <div class="custom-file-upload">
    <label class="body-text" :for="label">{{ label }}</label>
    <input type="text" class="file-name" v-model="fileName" :multiple="multiple" readonly />
    <button type="button" class="browse-btn btn btn-secondary" @click="triggerFileInput"
      :disabled="disabled">Browse</button>
    <input type="file" :accept="accept" class="file-input" ref="fileUploadInput" @change="handleFileChange" style='display: none;' />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, toRefs, computed } from 'vue';

export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: ".csv, .rtf, .txt, .doc, .docx, .xls, .xlsx, .dwg, .dxf, .eml, .msg, .pdf, .bmp, .jpg, .jpeg, .gif, .pcx, .png, .psd, .tga, .tif, .tiff, .wbmp, .wmf, .ppt, .pptx",
    },
    disabled: Boolean,
    reset: Boolean,
  },
  setup(props, { emit }) {
    const fileUploadInput = ref<HTMLInputElement | null>(null);
    const fileName = ref('');
    const { reset } = toRefs(props);
    const acceptPattern = computed(() => new RegExp(
      `(${props.accept
        .split(',')
        .map(ext => ext.trim().replace(/\./g, '\\.'))
        .join('|')})$`,
      'i'
    ));

    const triggerFileInput = () => {
      fileUploadInput.value?.click();
    };

    watch(() => reset.value, (newValue) => {
      if (newValue) {
        fileName.value = '';
      }
    });

    const handleFileChange = (event: Event) => {
      const input = event.target as HTMLInputElement;

      if (input.files && input.files.length > 0) {
        fileName.value = input.files[0].name;

        const uploadedFiles = Array.from(input.files);
        const invalidFiles =  props.accept !== '*' ? uploadedFiles.filter(file => !acceptPattern.value.test(file.name)) : [];

        if (invalidFiles.length > 0) {
          fileName.value = '';
          fileUploadInput.value = null;
          emit('stop');
          alert(`Some files are not allowed: ${invalidFiles.map(file => file.name).join(', ')}`);
        }
      }
    };

    return {
      fileUploadInput,
      fileName,
      triggerFileInput,
      handleFileChange,
    };
  },
});
</script>