<template>
    <AlertsComponent :error="error" />
    <div class="bordered-card bordered-card_secondary">
        <table class="small striped toggle no-underline">
            <thead class="large large_padding">
                <tr>
                    <th> NBF Participant ID </th>
                    <th> First Name </th>
                    <th> Last Name </th>
                    <th> Suffix </th>
                    <th> Birth Date </th>
                    <th> Email </th>
                    <th> Last Login </th>
                    <th> Actions </th>
                </tr>
            </thead>
            <tbody v-if="persons.length">
                <tr class="large" v-for="person in persons" :key="person.PersonID">
                    <td class="col-md-2 cell-primary plan-modal"
                        @click="openModal('edit-user', 'Participants', 'plan-profile plan-tables plan-profile', null, { UserID: person.UserID })">
                        {{ person.PersonID }} </td>
                    <td class="col-md-1 hide-desktop">
                        <i class="plan-modal iatse-icon-Vector-8 fs-xxs" @click="loginAsUser(person.UserID)"> </i>
                    </td>
                    <td class="col-md-1">{{ person.FirstName }}</td>
                    <td class="col-md-1">{{ person.LastName }}</td>
                    <td class="col-md-1">{{ person.Suffix }}</td>
                    <td class="col-md-1">{{ person.BirthDate }}</td>
                    <td class="col-md-2">{{ person.Email }}</td>
                    <td class="col-md-1">{{ person.LastLogin }}</td>
                    <td class="col-md-1 hide-mobile">
                        <span class="tooltip-wrapper" data-tooltip="Masquerade">
                            <i class="plan-modal iatse-icon-Vector-8 fs-xxs" @click="loginAsUser(person.UserID)"> </i>
                        </span>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr class="text-center">
                    <td colspan="8"> No Participants found</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, PropType } from 'vue';
import { SearchPerson } from '@/interfaces/admin';
import { useModalStore } from '@/stores/modal';
import { useAuthStore } from '@/stores/auth';
import { useInvoicesStore } from '@/stores/cobraInvoices';
import { useRouter } from 'vue-router';
import { useParticipantStore } from '@/stores/participant';
import axios from 'axios';

export default defineComponent({
    props: {
        persons: {
            type: Object as PropType<SearchPerson[]>,
            required: true
        },
    },

    setup(props) {
        const error = ref<string|null>(null);
        const authStore = useAuthStore();
        const router = useRouter();
        const invoicesStore = useInvoicesStore();

        const loginAsUser = async (userID: number) => {
            try {
                // Use the store's action to start impersonation
                await authStore.startImpersonation(userID);
                useParticipantStore().reset();
                invoicesStore.clearStore();
                await useParticipantStore().getUserEmail();

                router.push({ name: 'participant.dashboard' });
                // Optionally, handle any post-login logic, like redirecting the user
            } catch (impersonationError: unknown) {
                error.value = impersonationError as string;

                setTimeout(() => error.value = null, 2000);
                console.error("Impersonation error:", impersonationError);
            }
        };

        // Expose to template
        return {
            error,
            loginAsUser,
            openModal: useModalStore().openModal
        };
    }
});
</script>