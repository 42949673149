<template>
    <AlertsComponent :error="generalError" />
    <SelectComponent v-if="!isEditAction" label="Choose Document Type" v-model:selectedOption="dependentStore.SelectedDocumentType"
        :options=documentTypes customClass="full-width" :error="error" />
    <DragDrop v-if="!isEditAction" customClasses="grey large w-100" @update-files="handleFileSelection" key="drag-drop-dep"
        v-model:reset="reset" @reset="handleReset" :loading="loadingUpload" />

    <div class="bordered-card w-100">
        <h4>Documents Currently On File/Pending Admin Preview</h4>
        <table class="small mb-4">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th></th>
                </tr>
            </thead>
            <tbody v-if="uploadedDocs.length > 0">
                <tr v-for="document in uploadedDocs" :key="document.DocumentID">
                    <td>
                        <a href="javascript;"
                            @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document?.DocumentID, FilePreview: document?.FilePreview, Type: 'dependent' })">
                            {{ document?.Description || document?.DocumentDescription }}
                        </a>
                    </td>
                    <td>{{ new Date(document.Date).toLocaleDateString() }}</td>
                    <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}
                    </td>
                    <td>
                        <div class="btn-delete small w-fit">
                            <i class="iatse-icon-trash icon_delete plan-modal fs-sm" @click="deleteDocument(document)"></i>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr>
                    <td colspan="3">
                        No documents have been uploaded for this dependent.
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="action-buttons pdt-100 sm-pdl-0 sm-pdr-0 sm-pdt-50">
        <button type="button" class="btn btn-secondary" :disabled="loadingUpload || submitting" @click="handleBack">
            Back </button>
        <button type="button" class="btn btn-secondary" :disabled="loadingUpload || submitting" @click="handleSubmit">
            {{ buttonValue }}
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, computed, watch } from 'vue';
import { Document, SelectOption } from '@/interfaces/interfaces';
import { useDependentStore } from '@/stores/dependent';
import { useParticipantStore } from '@/stores/participant';
import { useModalStore } from '@/stores/modal';
import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';

import axios from 'axios';

export default defineComponent({
    components: {
        DragDrop,
        SelectComponent
    },
    props: {
        submitting: Boolean
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const dependentStore = useDependentStore();
        const modalStore = useModalStore();
        const uploadedDocs: Ref<Document[]> = computed(() => [...dependentStore.Documents, ...dependentStore.ExistingDocuments]);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const error: Ref<string | null> = ref(null);
        const generalError: Ref<string | null> = ref(null);
        const selectedDocType = computed(() => dependentStore.SelectedDocumentType);
        const loadingUpload = ref(false);
        const reset = ref(false);
        const buttonValue = computed(() => {
            if (loadingUpload.value) {
                return 'Uploading...'
            }
            else if (props.submitting) {
                return 'Submitting...'
            }
            else {
                return 'Submit'
            }
        });

        const handleReset = () => {
            reset.value = false;
        }

        const handleSubmit = () => {
            emit('submit-data');
        }

        const handleBack = () => {
            useParticipantStore().currentStep = 1;
            emit("check-data", false);
        }

        const deleteDocument = async (document: Document) => {
            const docDescription = document?.Description ?? document?.DocumentDescription;
            const modalProps = {
                title: "Delete Document",
                content: {
                    type: "text",
                    value: 'Are you sure you want to delete the ' + docDescription + ' for this dependent?',
                    icon: "iatse-icon-user-verified",
                },
                confirmButtons: ["Cancel", "Confirm"],
                onConfirm: async () => {
                    await dependentStore.deleteDocument(document?.DocumentID);
                },
            }

            useModalStore().openModal('success-modal', null, 'modal-success', null, modalProps);

        }

        watch(() => dependentStore.SelectedDocumentType, (newValue) => {
            if(newValue?.key) {
                error.value = null;
            }
        })

        const handleFileSelection = async (files: FileList) => {
            loadingUpload.value = true;

            if (!dependentStore.SelectedDocumentType?.key) {
                error.value = 'You must select a document type.';
                loadingUpload.value = false;
                reset.value = true;
                return;
            }

            const formData = new FormData();

            formData.append('DocumentType', String(dependentStore.SelectedDocumentType?.key));
            formData.append('Dependent', JSON.stringify(dependentStore.EditedDependent) ?? null);

            // const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                generalError.value = await dependentStore.uploadFiles(formData);
                dependentStore.SelectedDocumentType = null;
            }

            setTimeout(() => generalError.value = null, 5000);

            reset.value = true;
            loadingUpload.value = false;
        };

        return {
            documentTypes: dependentStore.documentTypes,
            existingPersonID: false,
            selectedDocType,
            dependentStore,
            fileNames,
            uploadedDocs,
            fileInput,
            error,
            generalError,
            reset,
            loadingUpload,
            buttonValue,
            handleFileSelection,
            handleSubmit,
            handleReset,
            handleBack,
            deleteDocument,
            openModal: useModalStore().openModal
        };
    },
});
</script>