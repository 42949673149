import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import Login from "../views/LoginView.vue";
import TermsConditions from "../views/TermsConditions.vue";
import Register from "../views/RegisterView.vue";
import LegacyNewPassword from "../views/LegacyNewPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import UserSettings from "../views/user/UserSettings.vue";
import ProfileView from "../views/user/ProfileView.vue";
import ContactView from "../views/user/ContactView.vue";
import ContactPublicView from "../views/ContactPublicView.vue";
import AlertsView from "../views/user/AlertsView.vue";
import PersonalInformation from "../views/user/profile/PersonalInformation.vue";
import AddressInformation from "../views/user/profile/AddressInformation.vue";
import ContactInformation from "../views/user/profile/ContactInformation.vue";
import ArchivedAlertsView from "../views/user/ArchivedAlertsView.vue";
import DependentsView from "../views/participant/dependents/DependentsView.vue";
import DocumentsView from "../views/participant/dependents/DocumentsView.vue";
import AddDependentView from "../views/participant/dependents/AddDependentView.vue";
import ContributionSummary from "../components/participant/annuity/ContributionSummary.vue";
import ContributionDetails from "../components/participant/annuity/ContributionDetails.vue";
import ParticipantDashboard from "../views/participant/ParticipantDashboard.vue";
import ClaimsView from "../views/participant/claims/ClaimsView.vue";
import ClaimsHistory from "../views/participant/claims/ClaimsHistory.vue";
import ChangeMailingPayment from "../views/participant/claims/ChangeMailingPayment.vue";
import SuperadminDashboard from "../views/superadmin/SuperadminDashboard.vue";
import ParticipantsView from "../views/superadmin/users/ParticipantsView.vue";
import LocalsView from "../views/superadmin/users/LocalsView.vue";
import EmployersView from "../views/superadmin/users/EmployersView.vue";
import ChangeRequestsView from "../views/superadmin/requests/ChangeRequestsView.vue";
import ReviewRequestView from "../views/superadmin/requests/ReviewRequestView.vue";
import FormSubmissions from "../views/superadmin/FormSubmissions.vue";
import ClaimSubmissions from "../views/superadmin/ClaimSubmissions.vue";
import ContactFormView from "../views/superadmin/ContactFormView.vue";
import AlertsNotificationsView from "../views/superadmin/alerts/AlertsView.vue";
import BulkEmailView from "../views/superadmin/BulkEmailView.vue";
import DocumentMappingView from "../views/superadmin/DocumentMappingView.vue";
import LogsView from "../views/superadmin/LogsView.vue";
import IatseStaff from "../views/superadmin/IatseStaff.vue";
import AutorespondersView from "../views/superadmin/autoresponders/AutorespondersView.vue";
import AddAutoresponder from "../views/superadmin/autoresponders/AddAutoresponder.vue";
import WorkHistoryView from "../views/participant/WorkHistoryView.vue";
import VacationSummary from "../views/participant/VacationSummary.vue";
import VacationBenefitsHistory from "../views/participant/VacationBenefitsHistory.vue";
import CheckReplacement from "../views/participant/CheckReplacement.vue";
import EmployerDashboard from "../views/employer/EmployerDashboard.vue";
import DemographicInformation from "../views/employer/DemographicInformation.vue";
import HealthCoverageData from "../views/employer/HealthCoverageData.vue";
import ListOfProductions from "../views/employer/ListOfProductions.vue";
import UserProfile from "../components/user/dashboard/ProfileView.vue";
import LocalDashboard from "../views/local/LocalDashboard.vue";
import SummarizedContractInfo from "../views/local/SummarizedContractInfo.vue";
import CreateReport from '../views/local/CreateReport.vue';
import MemberCoverageSearch from "../views/local/MemberCoverageSearch.vue";
import LocalRoster from "../views/local/LocalRoster.vue";
import MemberSearch from "../views/local/MemberSearch.vue";
import MemberProfile from "../views/local/MemberProfile.vue";
import ContributionHistory from "../views/local/ContributionHistory.vue";
import MostRecentContribution from "../views/local/MostRecentContribution.vue";
import RepresentativeAccounts from "../views/local/RepresentativeAccounts.vue";
import RepresentativePin from "../views/local/RepresentativePin.vue";
import ACAMandateReports from "../views/employer/ACAMandateReports.vue";
import PensionPayments from "../views/participant/PensionPayments.vue";
// import MissingContributionsInquiry from "../views/local/MissingContributionsInquiry.vue";
import PlanResources from "../views/user/PlanResources.vue";
import SmartSearch from "../views/smartsearch/SmartSearch.vue";
import TwoFactorSetup from "../views/TwoFactorSetup.vue";
import TwoFactorAuthorization from "../views/TwoFactorAuthorization.vue";
import ResetPasswordUser from '../views/ResetPasswordUser.vue';

import AlertsComponent from "../components/user/AlertsComponent.vue";
import ERemittanceReport from "@/views/employer/ERemittanceReport.vue";

import { useAuthStore } from '@/stores/auth';

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: ParticipantDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/reset-new-password/:hash",
        name: "reset-new-password",
        component: ResetPasswordUser
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
        meta: { requiresAuth: true },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/terms-conditions",
        name: "terms",
        component: TermsConditions,
    },
    {
        path: '/authorization/setup',
        name: "2fa.setup",
        component: TwoFactorSetup
    },
    {
        path: '/authorization/verify/:token?',
        name: "2fa.verify",
        component: TwoFactorAuthorization
    },
    {
        path: "/one-time-reset-password",
        name: "legacy-reset-password",
        component: LegacyNewPassword,
    },
    {
        path: "/reset-password/:hash",
        name: "reset-password",
        component: ResetPassword,
    },
    {
        path: "/register",
        name: "register",
        component: Register,
    },
    // Settings
    {
        path: "/settings",
        name: "settings",
        component: UserSettings,
        meta: { requiresAuth: true },
    },

    // SmartSearch
    {
        path: "/file/:documentId",
        name: "smartsearch.file.view",
        component: SmartSearch,
        meta: { requiresAuth: true },
    },

    // Profile
    {
        path: "/profile/my-personal-information",
        name: "personalInformation",
        component: PersonalInformation,
        meta: { requiresAuth: true },
    },
    {
        path: "/profile/my-address",
        name: "address",
        component: AddressInformation,
        meta: { requiresAuth: true },
    },
    {
        path: "/profile/my-contact-information",
        name: "contactInformation",
        component: ContactInformation,
    },
    {
        path: "/profile",
        name: "profile",
        component: ProfileView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant",
        name: "participant.dashboard",
        component: ParticipantDashboard,
        children: [
            {
                path: "dashboard",
                component: ParticipantDashboard,
            },
        ],
    },
    {
        path: "/participant/pension/payments",
        name: "participant.pension.payments",
        component: PensionPayments,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/vacation",
        component: VacationSummary,
        meta: { requiresAuth: true },
        children: [
            {
                path: "summary/:year?",
                name: "participant.vacation.summary",
                component: VacationSummary,
                meta: { requiresAuth: true },
            },
        ],
    },
    {
        path: "/participant/vacation/benefits-history",
        name: "participant.vacation.benefits-history",
        component: VacationBenefitsHistory,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/vacation/check-replacement",
        name: "participant.vacation.check-replacement",
        component: CheckReplacement,
        meta: { requiresAuth: true },
    },
    {
        path: "/work-history",
        name: "work-history",
        component: WorkHistoryView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/dashboard/annuity/summary/:year",
        name: "annuity-summary",
        component: ContributionSummary,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/dashboard/annuity/details",
        name: "annuity-details",
        component: ContributionDetails,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/dependents",
        name: "dependents",
        component: DependentsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/dependents/documents",
        name: "dependent-documents",
        component: DocumentsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/dependents/add",
        name: "add-dependent",
        component: AddDependentView,
        meta: { requiresAuth: true },
    },
    {
        path: "/contact",
        name: "contact",
        component: ContactView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/alerts",
        name: "alerts",
        component: AlertsComponent,
        meta: { requiresAuth: true, showAsModal: true },
        beforeEnter: (to, from, next) => {
          if (to.meta.showAsModal) {
            next(false); // Cancel the navigation
          } else {
            next();
          }
        },
    },
    {
        path: "/participant/alerts/archived",
        name: "archived-alerts",
        component: ArchivedAlertsView,
        meta: { requiresAuth: true },
    },

    // Claims
    {
        path: "/participant/claims",
        name: "participant.claims",
        component: ClaimsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/claims-update-address",
        name: "participant.claims.update.address",
        component: ChangeMailingPayment,
        meta: { requiresAuth: true },
    },
    {
        path: "/participant/claims-search",
        name: "participant.claims.search",
        component: ClaimsHistory,
        meta: { requiresAuth: true },
    },

    // Super Admin
    {
        path: "/administrator/dashboard",
        name: "superadmin.dashboard",
        component: SuperadminDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/users/participants",
        name: "superadmin.users.participants",
        component: ParticipantsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/users/locals",
        name: "superadmin.users.locals",
        component: LocalsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/users/employers",
        name: "superadmin.users.employers",
        component: EmployersView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/change-requests",
        name: "superadmin.requests",
        component: ChangeRequestsView,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/review-request/:UIAdminID",
        name: "superadmin.request.review",
        component: ReviewRequestView,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/form-submissions",
        name: "superadmin.form.submissions",
        component: FormSubmissions,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/claim-submissions",
        name: "superadmin.claim.submissions",
        component: ClaimSubmissions,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/autoresponders",
        name: "superadmin.autoresponders",
        component: AutorespondersView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/autoresponders/:AutoresponderID",
        name: "superadmin.autoresponders.edit",
        component: AddAutoresponder,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/document-mapping",
        name: "superadmin.document.mapping",
        component: DocumentMappingView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/logs",
        name: "superadmin.logs",
        component: LogsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/staff",
        name: "superadmin.users.staff",
        component: IatseStaff,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/alerts",
        name: "superadmin.alerts",
        component: AlertsNotificationsView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/bulk-email",
        name: "superadmin.bulk.email",
        component: BulkEmailView,
        meta: { requiresAuth: true },
    },
    {
        path: "/administrator/contact-form",
        name: "superadmin.contact.form",
        component: ContactFormView,
        meta: { requiresAuth: true },
    },

    // Employer
    {
        path: "/employer/dashboard",
        name: "employer.dashboard",
        component: EmployerDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/employer/demographic-information",
        name: "employer.demographic",
        component: DemographicInformation,
        meta: { requiresAuth: true },
    },
    {
        path: "/employer/health-coverage-data/:download?",
        name: "employer.request.health.data",
        component: HealthCoverageData,
        props: true,
        meta: { requiresAuth: true },
    },
    {
        path: "/employer/aca-mandate-reports",
        name: "employer.aca.reports",
        component: ACAMandateReports,
        props: true,
    },
    {
        path: "/employer/e-remittance-report",
        name: "employer.e-remittance.reports",
        component: ERemittanceReport,
        props: true,
    },
    {
        path: "/employer/productions-list",
        name: "employer.productions.list",
        component: ListOfProductions,
        meta: { requiresAuth: true },
    },

    // User
    {
        path: "/user/profile",
        name: "user.profile",
        component: UserProfile,
        meta: { requiresAuth: true },
    },
    {
        path: "/settings",
        name: "settings",
        component: UserSettings,
        meta: { requiresAuth: true },
    },
    {
        path: "/user/demographic-information",
        name: "user.demographic",
        component: DemographicInformation,
        meta: { requiresAuth: true },
    },

    // Local
    {
        path: "/local/dashboard/:successMessage?",
        name: "local.dashboard",
        component: LocalDashboard,
        meta: { requiresAuth: true },
    },
    {
        path: "/contract-information",
        name: "local.contract-information",
        component: SummarizedContractInfo,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/create-report",
        name: "local.create-report",
        component: CreateReport,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/representative-accounts",
        name: "local.representative",
        component: RepresentativeAccounts,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/representative-pin",
        name: "local.representative.pin",
        component: RepresentativePin,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/coverage-search",
        name: "local.coverage-search",
        component: MemberCoverageSearch,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/roster",
        name: "local.roster",
        component: LocalRoster,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/member-profile/:PersonID",
        name: "local.member-profile",
        component: MemberProfile,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/member-search",
        name: "local.member-search",
        component: MemberSearch,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/contribution-history",
        name: "local.contribution-history",
        component: ContributionHistory,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/plan-resources",
        name: "local.plan-resources",
        component: PlanResources,
        meta: { requiresAuth: true },
    },
    {
        path: "/local/recent-contribution/:Employers?/:StartDate?/:EndDate?",
        name: "local.recent-contribution",
        component: MostRecentContribution,
        meta: { requiresAuth: true },
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

/** 
 * 2. Public pages that do NOT require full auth/2FA. 
 *    These are typically your login, register, 2fa, or reset-password pages, etc.
 */
const publicPages = [
    "login",
    "register",
    "2fa.setup",
    "2fa.verify",
    "legacy-reset-password",
    "contact-public",
    "reset-new-password"
  ];
  
  /**
   * 3. Simple helper function to check 2FA from localStorage.
   *    Returns `true` if twoFaValidated is strictly `true`; otherwise false.
   */
  function isTwoFaValidated() {
    const stored = localStorage.getItem("twoFaValidated");
    return stored ? JSON.parse(stored) === true : false;
  }
  
  /**
   * 4. Global Navigation Guard
   */
  router.beforeEach((to, from, next) => {
    // If route name is in publicPages, skip all checks
    if (publicPages.includes(String(to.name))) {
      return next();
    }
  
    // Otherwise, treat it as a protected page:
    const token = localStorage.getItem("authToken");
  
    // A) If user does NOT have a token, redirect to login
    if (!token) {
      return next({ name: "login" });
    }
  
    // B) If user HAS a token but is not 2FA-validated, redirect to 2fa.verify
    if (!isTwoFaValidated()) {
      return next({ name: "2fa.verify" });
    }
  
    // If all checks pass, allow navigation
    next();
  });

export default router;
