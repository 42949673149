<template>
    <LoaderComponent v-if="loading" />
    <template v-else>
        <MessageCard
            message="The displayed information is from your records with the Fund Office. If the information is not correct, click on the applicable dependent in order to update."
            type="primary" iconClass="iatse-icon-alarm" classes="info_card_full"/>
        <div class="plan-content overflow-auto">
            <div class="account-actions pdt-0 sm-pdb-0">
                <div class="action-buttons justify-content-end sm-pdb-10 sm-pdl-0 sm-pdr-0">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('add-beneficiary',
                        'My Beneficiary(ies)',
                        'plan-tables-striped beneficiary',
                        null,
                        null,
                        null,
                    )">
                        View Beneficiary(ies)
                    </a>
                    <a href="" class="btn btn-primary plan-modal" @click.prevent="handleAdd">
                        Add Dependent
                    </a>
                </div>
            </div>
            <div class="bordered-card">
                <DataTable v-if="dependents.rows?.length" :table="dependents" :has-actions="true" :hasInfo="true"
                    classes="striped small equal-headings">
                    <!-- Custom Actions -->
                    <template #actions="{ entity }">
                        <i v-if="entity.CanEdit" class="iatse-icon-edit plan-modal" :class="{ 'disabled-icon': !entity.CanEdit }"
                            @click="handleEdit(entity)"></i>
                        <i v-if="entity.CanDelete" class="iatse-icon-trash plan-modal" :class="{ 'disabled-icon': !entity.CanDelete }"
                            @click="handleDelete(entity)"></i>
                    </template>
                     <!-- Info -->
                     <template #info="{ info }">
                        <i class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip_tertiary fs-lg">
                            <span class="tooltip-text">{{info}}</span>
                        </i>
                    </template>
                </DataTable>
                <div v-else-if="dependents?.rows.length === 0 || !dependents" class="col-md-12 text-center my-4">
                    <span> Currently there are no dependents on file </span>
                </div>
            </div>
        </div>
    </template>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import { DependentEntity } from '@interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useDependentStore } from '@/stores/dependent';
import DataTable from '@components/form/DataTable.vue';
import MessageCard from '@components/cards/MessageCard.vue';

export default {
    components: {
        DataTable,
        MessageCard
    },
    setup() {
        const loading = ref(true);
        const dependentStore = useDependentStore();
        const dependents = computed(() => dependentStore.dependents);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;

        const fetchData = async () => {
            await dependentStore.fetchDependents();
            loading.value = false;
        };

        const handleEdit = (dependent: DependentEntity) => {
            if (dependent.CanEdit) {
                dependentStore.setEditDependent(dependent);
                openModal('add-dependent', 'Edit Dependent', 'plan-form plan-upload');
            }
        }

        const handleDelete = (dependent: DependentEntity) => {
            if (dependent.CanDelete) {
                const modalProps = {
                    title: "Delete Dependent",
                    content: {
                        type: "text",
                        value: 'Are you sure you want to delete this dependent ?',
                        icon: "iatse-icon-user-verified",
                    },
                    confirmButtons: ["Cancel", "Confirm"],
                    onConfirm: () => {
                        dependentStore.deleteDependent(dependent);
                    },
                }

                modalStore.openModal('success-modal', null, 'modal-success', null, modalProps);
            }
        }

        const handleAdd = () => {
            dependentStore.clearDependent();
            openModal('add-dependent', 'Add Dependent', 'plan-form plan-upload');
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            dependents,
            loading,
            openModal,
            handleAdd,
            handleEdit,
            handleDelete,
        };
    }
};
</script>