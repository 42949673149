<template>
    <div class="container mt-5 mb-5">
        <div class="position-fixed top-0 end-0 p-3" style="z-index: 11">
            <div class="alert alert-success" v-show="success">{{ success }}</div>
            <div v-if="errors.length > 0" class="alert alert-danger">
                <ul>
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                </ul>
            </div>
        </div>
        <div class="bordered-card bordered-card_secondary mt-30">
            <h2 class="card-header">Reset Password</h2>
            <div class="d-flex flex-column align-items-end gap-20 w-100">
                <div class="form-group d-flex flex-column gap-2 align-middle w-100">
                    <label for="password">Enter New Password:<span class="text-danger">*</span></label>
                    <div
                        class="input-group form-control d-flex justify-content-between align-items-center h-50">
                        <input v-model="formData.Password" class="input-password reset"
                            :type="showPassword ? 'text' : 'password'" name="password" id="password">
                        <button @click="togglePassword" type="button" class="reset">
                            <i :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                    </div>
                </div>
                <div class="form-group d-flex flex-column gap-2 w-100">
                    <label for="confirm">Confirm New Password:<span class="text-danger">*</span></label>
                    <div
                        class="input-group form-control d-flex justify-content-between align-items-center h-50">
                        <input v-model="formData.Password_confirmation" class="input-password reset"
                            :type="showPasswordConfirmation ? 'text' : 'password'" id="confirm">
                        <button @click="togglePasswordConfirmation" class="reset" type="button">
                            <i :class="showPasswordConfirmation ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
                        </button>
                    </div>
                </div>
                <button class="btn btn-primary" type="button" @click="handleSubmit">
                    {{ loadingSubmit ? 'Submitting...' : 'Submit' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

export default {
    setup() {
        const hash = useRoute().params.hash;
        const showPassword = ref(false);
        const showPasswordConfirmation = ref(false);
        const errors = ref([]);
        const success = ref('');
        const info = ref('');
        const loadingSubmit = ref(false);
        const route = 'api/reset-password'
        const router = useRouter();
        const authStore = useAuthStore();
        const formData = reactive({
            Password: '',
            Password_confirmation: '',
            Hash: hash,
        });

        // Togle between hide/show password
        const togglePassword = () => {
            showPassword.value = !showPassword.value;
        }

        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post(route, { ...formData, _method: "PATCH" })
                .then((response) => {
                    success.value = response.data?.success;
                    const credentials = { email: response.data?.Email, password: formData.Password };

                    if(success.value) {
                        useModalStore().openModal('success-modal', null, 'modal-success', null, {
                            title: 'Password Reset',
                            content: {
                                type: "text",
                                value: 'Your password has been successfully reset. You can now use your new password to log in to your account.',
                                icon: 'iatse-icon-user-verified'
                            },
                            onConfirm: () =>  authStore.authenticateUser(credentials)
                        })
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat();
                    }

                    loadingSubmit.value = false;
                })
                .finally(() => {
                    setTimeout(async () => {
                        success.value = "";
                        errors.value = [];
                    }, 3500);

                    loadingSubmit.value = false;
                })
        };

        return {
            formData,
            errors,
            success,
            loadingSubmit,
            showPassword,
            showPasswordConfirmation,
            handleSubmit,
            togglePassword,
            togglePasswordConfirmation,
        };
    }
};
</script>