import axios from 'axios';
import { useAuthStore } from '@/stores/auth';
import router from '@/router';
import { useModalStore } from '@/stores/modal';

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;

axios.interceptors.request.use(
    (config) => {
        const authStore = useAuthStore();
        const { token, impersonationToken } = authStore;

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        if (impersonationToken) {
            config.headers['X-Impersonation-Token'] = `${impersonationToken}`;
        }

        return config;
    },
    (error) => Promise.reject(error)
);

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const authStore = useAuthStore();

        if (error.response) {
            const { status, data } = error.response;

            if (status === 401) {
                // Handle 401 Unauthorized
                authStore.logout();
                router.push({ name: 'login' });
                useModalStore().closeAllModals();
            }
            else if (status === 403) {
                useModalStore().closeAllModals();
                // Handle 403 Forbidden
                if (data.code === '2FA_REQUIRED') {
                    router.push({ name: '2fa.verify' });
                } else if (data.code === '2FA_SETUP_REQUIRED') {
                    router.push({ name: '2fa.setup' });
                } else {
                    console.error('403 Forbidden:', data.message);
                }
            } else if (status === 423) {
                // Handle 423 Locked
                router.push({ name: 'UserLocked' });
                useModalStore().closeAllModals();
            }
        }

        return Promise.reject(error);
    }
);

export default axios;
