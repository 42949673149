<template>
    <MessageCard
        :message="`<p>
                In order to enroll in Plan C Medical Reimbursement Program you must send us a front and back copy of your other employer or union sponsored group health coverage identification card.  <br>
                <strong> Please be careful when choosing and uploading documents for your MRP enrollment.  Make certain your submission is complete, accurate and all required documents are uploaded before clicking the submit button.  Once you click the submit button you will be prohibited from submitting additional or corrected documents.  Once our staff has completed reviewing your request you will receive an email letting you know to login and visit your dashboard for further updates regarding your enrollment selection.</strong>
            </p>
            <p>
                <strong>Please note:</strong>This website supports the following common file types: PDF, JPG, PNG, TIF, TIFF, BMP, GIF, WMF, EMF, PSD, WBMP, TLA, PCX, TGA. 
                File types not listed are unsupported and hence will not be processed.
            </p>`"
        classes="info_card_full"
    />
    <LoaderComponent v-if="loading" />

    <div v-else class="plan-content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <SelectComponent
                        label="Choose Document Type"
                        v-model:selectedOption="selectedDocType"
                        :options="documentTypes"
                        customClass="full-width mt-20"
                    />

                    <DragDrop
                        customClasses="grey large w-100 mt-20"
                        @update-files="handleFileSelection"
                        :reset="reset"
                        accept=".pdf, .jpg, .jpeg, .png, .tif, .tiff, .bmp, .gif, .wmf, .emf, .psd, .wbmp, .tla, .pcx, .tga"
                        :loading="loadingUpload"
                        key="drag-drop-mrp"
                    />

                    <div class="bordered-card w-100 mt-20">
                        <h4>Documents Currently On File/Pending Admin Preview</h4>
                        <table class="small mb-4">
                            <thead>
                                <tr>
                                    <th>Type</th>
                                    <th>Date</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody v-if="uploadedDocs?.length > 0">
                                <tr v-for="document in uploadedDocs" :key="document.DocumentID">
                                    <td>
                                        <a href="javascript;"
                                           @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document?.DocumentID, FilePreview: document?.DocumentID, Type: 'dependent' })">
                                            {{ document.Description }}
                                        </a>
                                    </td>
                                    <td>{{ document.EffectiveDate }}</td>
                                    <td :class="{ 'text-danger': document.Status === 'Pending' }">{{ document.Status }}</td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3">
                                        No documents have been uploaded to your account.
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="action-buttons pdt-100 w-100 d-flex justify-content-between">
                        <button type="button" class="btn btn-secondary" :disabled="loadingUpload">
                            Back
                        </button>
                        <button type="button" class="btn btn-primary" @click="handleSubmit" :disabled="loadingUpload || !documentUploaded">
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import { Document, SelectOption } from '@/interfaces/interfaces';
import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import { useCoverageStore } from '@/stores/coverage';

interface MrpDocument {
    DocumentID: number;
    Description: string;
    EffectiveDate: string;
    Status: string;
}

export default {
    components: {
        DragDrop,
        SelectComponent
    },
    setup() {
        const modalStore = useModalStore();
        const { openModal } = useModalStore();
        const coverageStore = useCoverageStore();
        const documentTypes = [{ key: 120, value: 'C-MRP Cert/Proof' }];
        const uploadedDocs = ref<MrpDocument[]>([]);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        const error: Ref<string | null> = ref(null);
        const selectedDocType = ref<SelectOption | null>(null);
        selectedDocType.value = documentTypes[0];
        const reset = ref(false);
        const loadingUpload = ref(false);
        const loading = ref(true);
        const documentUploaded = ref(false);

        const fetchData = async () => {
            try {
                const response = await axios.get('api/participant/health-welfare/mrp-documents');
                uploadedDocs.value = response.data.Documents;
            } catch (err) {
                console.error('There was an error!', err);
            }

            loading.value = false
        }

        onMounted(async() =>  await fetchData());

        // Adjusted method signature: now it receives an array of File objects
        const handleFileSelection = async (files: File[]) => {
            reset.value = false; // We'll reset after the attempt

            console.clear();
            console.log('>> intra <<', files);

            if (!selectedDocType.value?.key) {
                error.value = 'The selected document type is invalid.';
                loadingUpload.value = false;
                reset.value = true;
                setTimeout(() => { error.value = ''; }, 3000);
                return;
            }

            // const filesArray = files instanceof DragEvent ? files.dataTransfer?.files : files;

            if (files?.length) {
                loadingUpload.value = true;
                const formData = new FormData();
                formData.append('DocumentType', String(selectedDocType.value.key));

                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                try {
                    const response = await axios.post('api/smartsearch/mrp-document', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    if (response.data.Files && Array.isArray(response.data.Files) && response.data.Files.length > 0) {
                        documentUploaded.value = true;

                        coverageStore.fetchHealthData();
                        useParticipantStore().fetchAlerts();
                        fetchData();
                    }

                    // Assuming response returns updated documents list
                    // uploadedDocs.value = response.data.Documents;
                    error.value = null;
                } catch (err: any) {
                    console.error('Upload failed:', err);
                    error.value = 'There was a problem uploading your documents.';
                } finally {
                    loadingUpload.value = false;
                    reset.value = true;
                }
            } else {
                loadingUpload.value = false;
                reset.value = true;
            }
        };

        const handleSubmit = () => {
            modalStore.closeAllModals();

            setTimeout(() => {
                modalStore.openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Thank You, we have received your request.",
                        content: {
                            type: "text",
                            value: 'Please allow up to 48 hours for our admin team to review your request. Data will not be updated until the review is completed. Once complete you will receive an email letting you know to login and visit your dashboard for further updates regarding your enrollment selection.',
                            icon: "iatse-icon-user-verified"
                        },
                        onConfirmButton: "Close",
                    }
                );
            }, 300);
        };

        return {
            openModal,
            handleSubmit,
            documentTypes,
            uploadedDocs,
            selectedDocType,
            fileNames,
            fileInput,
            loading,
            error,
            reset,
            loadingUpload,
            documentUploaded,
            handleFileSelection
        };
    }
};
</script>
