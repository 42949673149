<template>
    <Alerts :errors="generalErrors" />
    <MessageCard v-if="!loading"
        message="Please review your mailing address and/or payment destination below. <br />
            Please note: Updating mailing address below will update your primary address on file with the Fund Office for all your correspondences. <br />
            Updating direct deposit information below will update the payment setup on file with the Fund Office for all your payments (Claims and other Benefits if applicable)."
        type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <CoveredCard v-if="!loading && requestType.key === 'medicare'" :links="claimStore.claimLinks" :small="true"/>
        <!-- Form -->
        <form v-if="!loading && person && person.DateofBirth" id="check-destination-form" class="form" method="POST"
            @submit.prevent="submitData">
            <div class="w-100 d-flex justify-content-between gap-20 align-items-end">
                <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
                    v-model:modelValue="person.PersonID" :readonly="true" custom-class="w-full" />
                <SelectComponent label="Check Type" v-model:selectedOption="requestType" :options="checkTypes"
                    :singleArrow="true" :readonly="true" custom-class="w-full"/>
            </div>
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" />
            <DateInput label="DOB" v-model:modelValue="person.DateofBirth" :readonly="true" />

            <div class="headers pdb-5 pdt-15">
                <h4  v-if="checkType === 'check'" :class="{'position-relative': addressErrors}">Address for Check Destination currently on File
                    <i v-if="addressErrors" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-secondary fs-lg">
                        <span class="tooltip-text left-20">{{ addressErrors }}</span>
                    </i>
                </h4>
                <h4 v-else>Current Payment Destination</h4>
                <h5 v-if="checkType === 'directdeposit'"> Current Banking Information </h5>
                <h5 v-else> This is your Primary address for all correspondences from the Fund Office </h5>
            </div>
            <div class="radio-group bold highlight">
                <div class="radio-buttons w-40 w-sm-100">
                    <div v-for="(option, index) in options" :key="index" :class="{'highlight bg-light-green': checkType == option.value}" class="radio-button bordered-card bordered-card_secondary pdt-20 pdb-20 align-items-start">
                        <input type="radio" :id="`PaymentOption${index + 1}`"  name="PaymentOption" :value="option.value"
                            v-model="checkType" />
                        <label class="body-text" :for="`PaymentOption${index + 1}`" >
                            {{option.label}}
                        </label>
                    </div>
                </div>
            </div>
            <!-- <SelectComponent label="Type" v-model:selectedOption="checkType" :options="paymentMethods" :singleArrow="true" /> -->
            <div class="w-full" v-if="address && checkType === 'check'">
                <TextInput type="text" name="mixed" label="Address 1" placeholder="Enter Address 1" :max=30
                    v-model:modelValue="address.Street1" :required="true" :is-required="true" />
                <TextInput type="text" name="mixed" label="Address 2" placeholder="Enter Address 2" :max=30
                    v-model:modelValue="address.Street2" />
                <TextInput type="text" name="mixed" label="Address 3" placeholder="Enter Address 3" :max=30
                    v-model:modelValue="address.Street3" />
                <TextInput type="text" placeholder="Enter City" :label="['US', 'CA', 'MX'].includes(address.Country?.key) ? 'City' : 'City/State'" :max=25 v-model:modelValue="address.City"
                    :required="true" :error="errors?.City" :isRequired="true" />
                <SelectComponent label="Country" v-model:selectedOption="address.Country" :options="profileStore.countries"
                    :single-arrow="true" />
                <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" :error="errors ? errors['State.key'] : null" :isRequired="['US', 'CA', 'MX'].includes(address.Country?.key)" :readonly="!['US', 'CA', 'MX'].includes(address.Country?.key)" />
                <!-- <SelectComponent label="State" v-model:selectedOption="address.State" :options="filteredStates"
                    :single-arrow="true" /> -->
                <TextInput type="text" label="ZIP Code" name="number" placeholder="Enter Postal Code" :max=9
                    v-model:modelValue="address.Zip" :required="true" :is-required="true" />
            </div>

            <div class="w-full justify-content-start" v-else>
                <TextInput type="text" name="mixed" label="Bank Name" placeholder="Enter Bank Name" :max=30
                    v-model:modelValue="bank.BankClassDescription" :required="true" :is-required="true" :error="errors?.BankClassDescription"/>
                <TextInput type="text" name="number" label="Routing (ABA)" placeholder="Enter Routing (ABA)" :max=20
                    v-model:modelValue="bank.RoutingCode" :required="true" :is-required="true" :error="errors?.RoutingCode"/>
                <SelectComponent label="Account Type" v-model:selectedOption="bank.AcctType" :options="accountTypes"
                    :single-arrow="true" :error="errors?.AccountType"/>
                <TextInput type="text" name="number" label="Account Number" placeholder="Enter Account Number" :max=20
                    v-model:modelValue="bank.BankAcctNo" :required="true" :is-required="true" :error="errors?.BankAcctNo"/>
                <TextInput type="text" name="number" label="Repeat Account Number" placeholder="Repeat Account Number"
                    :max=20 v-model:modelValue="bank.RepeatBankAcctNo" :required="true" :is-required="true" :error="errors?.RepeatBankAcctNo"/>
            </div>

            <!-- <Textarea label="Notes" v-model:modelValue="comments" /> -->

            <div class="action-buttons flex-md-row flex-column sm-pdl-0 sm-pdr-0">
                <button @click.prevent="resetModal" class="btn btn-secondary" :disabled="loadingSubmit"> Discard </button>
                <button type="submit" class="btn btn-primary" :disabled="loadingSubmit"> {{ loadingSubmit ? 'Submitting...' : 'Submit' }} </button>
            </div>
        </form>
        <div v-else class="d-flex flex-column align-items-center gap-4">
            <h3>To Continue Please...</h3>
            <h6>Please <a href="profile.personal.edit">update your personal
            information</a> to include your correct date of birth.</h6>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, toRefs, onMounted, watch, computed, Ref, defineComponent } from 'vue';
import { usePensionStore } from '@/stores/pension';
import { useVacationStore } from '@/stores/vacation';
import { useProfileStore } from '@/stores/profile';
import { CheckDeposit } from '@/interfaces/health';
import { CountryStates, Person, AddressInfo, RadioOption, SelectOption, VacationCheckRequestLine } from '@/interfaces/interfaces';
import { useParticipantStore } from '@/stores/participant';
import { useModalStore } from '@/stores/modal';
import { useClaimStore } from '@/stores/claim';
import { useCoverageStore } from '@/stores/coverage';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";

import Alerts from '@/components/utils/AlertsComponent.vue';
import TextInput from '@/components/form/TextInput.vue';
import DateInput from '@/components/form/DateInput.vue';
import MessageCard from '@components/cards/MessageCard.vue'
import CoveredCard from '@/components/participant/claims/CoveredCard.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import SelectComponent from '@/components/form/SelectComponent.vue';
import useFormSubmit from '@/composable/useFormSubmit';
import axios from 'axios';


export default defineComponent({
    components: {
        Alerts,
        CoveredCard,
        DateInput,
        MessageCard,
        SelectComponent,
        TextInput,
    },
    props: {
        fund: {
            type: String,
            default: null,
        },
        modalProps: {
            type: String,
            default: null,
        }
    },
    setup(props, { emit }) {
        const { formatCurrency } = useCurrencyFormatter();
        const profileStore = useProfileStore();
        const pensionStore = usePensionStore();
        const vacationStore = useVacationStore();
        const claimStore = useClaimStore();
        const coverageStore = useCoverageStore();
        const balance = computed(() => coverageStore.coverage?.Primary.MrpBalance ?? coverageStore.coverage?.Secondary?.MrpBalance)
        const statesByCountry: Ref<CountryStates[] | null> = ref(null);
        const person = ref<Person>();
        const accountTypes = ref(null);
        const initialAddress = ref({
            Street1: null,
            Street2: null,
            Street3: null,
            Country: null,
            City: null,
            State: null,
            ZipCode: null,
        })
        const initialBank = ref({
            BankClassDescription: null,
            RoutingCode: null,
            AcctType: null,
            BankAcctNo: null,
            RepeatBankAcctNo: null,
        })
        const address = ref<AddressInfo>({...initialAddress.value});
        const bank = ref<CheckDeposit>({...initialBank.value});
        const addressErrors = computed(() => useParticipantStore().getAddressErrors())

        const options: RadioOption[] = [
            { value: 'directdeposit', label: 'Direct Deposit' },
            { value: 'check', label: 'Check' }
        ];

        const checkTypes = [
            { key: 'vacation', value: 'Vacation' },
            { key: 'medicare', value: 'Medicare Reimbursement' },
            { key: 'pension', value: 'Pension' }
        ];

        const paymentMethods = [
            { key: 'directdeposit', value: 'Direct Deposit' },
            { key: 'check', value: 'Check' }
        ];
        const { fund, modalProps } = toRefs(props);
        const checks = ref<VacationCheckRequestLine[]>([]);
        const checkOptions = ref<SelectOption[]>([]);
        const selectedCheck = ref<SelectOption | null>({ key: 0, value: '-Select check' });
        const requestType = ref<SelectOption>(checkTypes[0]);
        const checkType = ref<string|number>(modalProps.value);
        const comments = ref('');
        const errors = ref<Record<string, string[]|null> | null>(null);
        const generalErrors = ref<string[]>([]);
        const success = ref('');
        const info = ref('');
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const updateDepositRoute = 'api/participant/claims-update-deposit';
        const updateAddressRoute = 'api/user/profile/my-address';
        const postRoute = ref(updateDepositRoute);

        // Filter states based on selected country
        const filteredStates = computed(() => {
            const selectedCountry = address.value?.Country?.key ?? '';
            const states = profileStore.statesByCountry;
            const countryStates = states ? (states as CountryStates[]).filter((state) => state.CountryKey == selectedCountry) : [];

            return (countryStates?.map(state => state.States).flat());
        });

        watch(() => address.value.Country?.key, (newValue, oldValue) => {
            if(!loading.value) {
                const isStateValid = filteredStates.value.some(state => state.key == address.value.State?.key);

                if (newValue !== oldValue && address.value.State && !isStateValid) {
                    address.value.State = { key: 0, value: "Select State" };
                }

                if(!['US', 'CA', 'MX'].includes(address.value.Country?.key as string) && errors.value) {
                    errors.value['State.key'] = null;
                }
            }
        })

        watch(() => fund.value, () => {
            updateRequestTypeFromQuery();
        });

        watch(() => modalProps.value, (newValue) => {
            checkType.value = newValue;
        });

        watch(() => checkType.value, (newType) => {
            postRoute.value = newType === 'directdeposit' ? updateDepositRoute : updateAddressRoute;
        });

        // Methods
        const fetchData = async (hasChanged?: boolean) => {
            try {
                const response = await axios.get(`api/participant/check-replacement?fund=${props.fund}`);
                person.value = response.data.Person;
                address.value = response.data.Address;
                bank.value = response.data.Bank;
                initialAddress.value = JSON.parse(JSON.stringify(response.data.Address));
                initialBank.value = JSON.parse(JSON.stringify(response.data.Bank));
                accountTypes.value = response.data.AccountTypes;
                checkOptions.value = response.data.CheckOptions;
                postRoute.value = modalProps.value === 'directdeposit' ? updateDepositRoute : updateAddressRoute;

                if(!modalProps.value && !hasChanged) {
                    checkType.value = bank.value.BankID ? options[0].value : options[1].value;
                }

                if (!profileStore.countries?.length || !profileStore.statesByCountry) {
                    await profileStore.fetchCountriesStates();
                }

                loading.value = false;
            }
            catch (error) {
                console.error('Error:', error);
            }
        }

        const updateRequestTypeFromQuery = () => {
            const queryParamValue = fund.value;

            if (typeof queryParamValue === 'string' && ['vacation', 'medicare', 'pension'].includes(queryParamValue)) {
                requestType.value = checkTypes.find(type => type.key === queryParamValue) || checkTypes[0];
            }
        };

        const closeModal = () => {
            emit('close');
        }

        const resetModal = () => {
            address.value = { ...initialAddress.value};
            bank.value = { ...initialBank.value};
            comments.value = '';

            if(modalProps.value) {
                checkType.value = modalProps.value;
            } else {
                // checkType.value = bank.value?.BankID ? options[0].value : options[1].value;
            }
        }

        const submitData = async () => {
            let formData = null;

            if (checkType.value === 'directdeposit') {
                formData = {
                    PersonID: person.value?.PersonID,
                    FirstName: person.value?.FirstName,
                    LastName: person.value?.LastName,
                    DateofBirth: person.value?.DateofBirth,
                    Bank: { ...bank.value, 'AcctType': bank.value.AcctType?.key  ?? null},
                    Type: requestType.value,
                    Notes: comments.value,
                };

            }
            else {
                formData = {
                    CheckCategory: requestType.value.key,
                    Type: address.value.Type?.key,
                    Street1: address.value.Street1,
                    Street2: address.value.Street2,
                    Street3: address.value.Street3,
                    State: address.value.State,
                    City: address.value.City,
                    Country: address.value.Country,
                    ZipCode: address.value.Zip,
                }
            }

            const route = postRoute.value;
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit, generalErrors, reset: false, transformObjects: false });
            const successSubmit = await handleSubmit();

            if (success.value) {
                useModalStore().openModal('success-modal', null, 'modal-success', null, {
                    title: "Successful",
                    content: {
                        type: "text",
                        value: success.value,
                        icon: 'iatse-icon-user-verified'
                    },
                    onConfirmButton: 'Ok',
                    onConfirm: async() =>  {
                        errors.value = null;
                        await fetchData(true);
                        useModalStore().closeLastModal();

                        switch(fund.value) {
                            case 'vacation' :
                                await profileStore.fetchAddress();
                                await useParticipantStore().fetchGeneralData();
                                await vacationStore.getPanelDetails();
                                break;
                            case 'pension' :
                                await profileStore.fetchAddress();
                                await useParticipantStore().fetchGeneralData();
                                await pensionStore.getPanelDetails();
                                break;
                            case 'medicare' :
                                await claimStore.fetchPaymentMethods();
                                break;
                        }

                        resetModal()
                    }
                })
            }
        }

        onMounted(async () => {
            await fetchData();
            updateRequestTypeFromQuery();
        });

        return {
            addressErrors,
            profileStore,
            statesByCountry,
            filteredStates,
            person,
            comments,
            address,
            bank,
            checks,
            options,
            checkOptions,
            selectedCheck,
            requestType,
            checkTypes,
            paymentMethods,
            accountTypes,
            checkType,
            success,
            errors,
            generalErrors,
            loading,
            loadingSubmit,
            claimStore,
            balance,
            formatCurrency,
            closeModal,
            submitData,
            resetModal,
        }
    }
});
</script>
