<template>
<div>
    <div class="container mt-5">
        <div class="row justify-content-center align-items-center vh-100">
            <!-- Form Column -->
            <div class="col-md-6 d-flex flex-column justify-content-center">
                <div v-if="!showVerification">
                    <form @submit.prevent="submitForm">
                        <div class="mb-20" v-if="legacy == 1">
                            <h2>Reset Password</h2>
                            <p>To ensure your account's security, please update your password to meet our new platform's enhanced security standards. Legacy passwords are no longer supported. Your new password must include a mix of letters, numbers, and special characters for better protection.</p>
                            <TextInput
                            type="password"
                            name="mixed"
                            placeholder="Password"
                            v-model="password"
                            class="mt-20"
                            />
                            <ul v-if="errors.password" class="text-danger pdl-15">
                                <li v-for="(err, index) in errors.password" :key="index">{{ err }}</li>
                            </ul>
                            <TextInput
                            type="password"
                            name="mixed"
                            placeholder="Password Confirmation"
                            v-model="passwordConfirmation"
                            class="mt-20"
                            />
                        </div>
                        <h2>2 Factor Authentication</h2>
                        <label for="radioGroup">We will send a code to your preferred method</label>

                        <h5 class="pdt-30">Preferred Method</h5>
                        <div class="radio-buttons d-flex mt-15">
                            <div class="d-flex gap-2">
                                <input
                                    type="radio"
                                    id="participant"
                                    name="method"
                                    value="P"
                                    v-model="method"
                                    />
                                <label for="participant">Phone number</label>
                            </div>
                            <div class="d-flex gap-2 pdl-30">
                                <input
                                    type="radio"
                                    id="local"
                                    name="method"
                                    value="E"
                                    v-model="method"
                                    />
                                <label for="local">Email Address</label>
                            </div>
                        </div>
                        <ul v-if="errors.method" class="text-danger pdl-15">
                            <li v-for="(err, index) in errors.method" :key="index">{{ err }}</li>
                        </ul>
                        <!-- Phone Input Field -->
                        <div v-if="method === 'P'" class="mt-3">
                            <label for="phone">Phone Number</label>
                            <p class="fs-sm mt-10 mb-10 opacity-8">Please use a personal mobile phone number or email that you have sole control over for your 2FA.  We will record your entry and you will need to access this to retrieve your authentication code whenever you login. </p>

                            <PhoneField type="tel" label="Verified Phone numberd" placeholder="x (xxx) xxx-xxxx" :max=17 v-model="phone" />
                        </div>
                        <div class="d-flex gap-4 mt-4">
                            <button type="submit" class="btn btn-primary">Send Code</button>
                        </div>
                        <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
                    </form>
                </div>
                <div v-else>
                    <h2>Enter Verification Code</h2>
                    <div class="form-group d-flex flex-column gap-4 mb-4">
                        <h2>Verification</h2>
                        <label for="code">Enter the code sent to your {{ method === 'P' ? 'phone number' : 'email address' }}</label>
                        <VerificationCodeInput v-model="code" @keyup.enter="verifyCode"/>
                        <div class="d-flex gap-4 mt-4">
                            <button @click="verifyCode" class="btn btn-primary">Verify Code</button>
                        </div>
                        <div class="mt-3">
                            <a href="#" @click.prevent="resendCode">Resend Code</a>
                            <span v-if="timer > 0"> ({{ timer }} seconds remaining)</span>
                        </div>
                        <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
                    </div>
                </div>
            </div>
            <!-- Image Column -->
            <div class="col-md-6 d-flex justify-content-center align-items-center">
                <img style="max-width: 100%; max-height: 90vh;" src="@/assets/images/2fa-picture.jpg" alt="2FA Image" />
            </div>
        </div>
    </div>
</div>
</template>
  
  <script lang="ts">
  import { ref, onMounted, onUnmounted } from 'vue';
  import axios from 'axios';
  import { useAuthStore } from '@/stores/auth';
  import { useRouter, useRoute } from 'vue-router';
  import VerificationCodeInput from '@components/form/VerificationCodeField.vue';
  import PhoneField from '@components/form/PhoneField.vue';
  import TextInput from '@components/form/TextInput.vue';
  
  export default {
    components: {
        VerificationCodeInput,
        PhoneField,
        TextInput
    },
    setup() {
      const router = useRouter();
      const route = useRoute();
      const authStore = useAuthStore();

      // Legacy
      const password = ref('');
      const passwordConfirmation = ref('');
  
      const method = ref('');
      const phone = ref('');
      const code = ref('');
      const showVerification = ref(false);
      const expirationTime = ref(120);
      const timer = ref(0);
      const timerInterval = ref<number | null>(null);
      const errorMessage = ref('');
      const errors = ref<Record<string, string[]>>({});
      const legacy = route.query.legacy || route.params.legacy;
  
      const startTimer = () => {
        timer.value = expirationTime.value;
        if (timerInterval.value) {
          clearInterval(timerInterval.value);
        }
        timerInterval.value = setInterval(() => {
          if (timer.value > 0) {
            timer.value--;
          } else {
            clearInterval(timerInterval.value!);
            timerInterval.value = null;
          }
        }, 1000);
      };

      const submitForm = async () => {
            try {
                const payload: Record<string, any> = { method: method.value };
                if (method.value === 'P') {
                    payload.phone = phone.value;
                }
                // Conditionally add password if legacy flag is set
                if (legacy) {
                    payload.password = password.value;
                    payload.password_confirmation = passwordConfirmation.value;
                }

                const response = await axios.post('/api/authorization/setup/submit-method', payload);

                if (response.data.success) {
                    showVerification.value = true;
                    // Use expiration time from API if available
                    expirationTime.value = response.data.expirationTime || 120;
                    startTimer();
                    errorMessage.value = '';
                    errors.value = {}; // Clear errors on success
                } else {
                    // Process validation errors from Laravel response
                    if (response.data.validator_errors) {
                        errors.value = response.data.validator_errors;
                    } else {
                        errorMessage.value = response.data.message || 'An error occurred.';
                    }
                }
            } catch (error: any) {
                console.error(error);
                if (error.response?.data?.validator_errors) {
                    // Map validator errors to the errors object
                    errors.value = error.response.data.validator_errors;
                } else {
                    errorMessage.value = error.response?.data?.message || 'An error occurred.';
                }
            }
        };


      const verifyCode = async () => {
        try {
          const payload = {
            verification_code: code.value,
            password: password.value,
            method: method.value,
          };
          const response = await axios.post('/api/authorization/setup/submit-code', payload);
          if (response.data.success) {
            // Verification successful
            // Redirect or perform necessary actions
            authStore.set2FaValidated(true);
            // router.push({ name: authStore.getDashboardRoute });
            router.push({ name: 'terms' });
          } else {
            // Handle error
            errorMessage.value = response.data.message || 'Invalid verification code.';
            code.value = '';
          }
        }
        catch (error: any) {
            if (error.response && error.response.data) {
                // Capture API errors
                errorMessage.value = error.response.data.error || 'An error occurred';
            }
            else {
                errorMessage.value = 'Unexpected error occurred';
            }
            code.value = '';
        }
      };

      const resendCode = async () => {
        try {
          const payload: Record<string, any> = { method: method.value };
          if (method.value === 'P') {
            payload.phone = phone.value;
          }
          const response = await axios.post('/api/authorization/setup/resend-code', payload);
          if (response.data.success) {
            // Use new expiration time if provided
            if (response.data.expirationTime) {
              expirationTime.value = response.data.expirationTime;
            } else {
              expirationTime.value = 120;
            }

            errorMessage.value = '';
            startTimer();
          }
          else {
            // Handle error
            errorMessage.value = response.data.message || 'An error occurred while resending the code.';
          }
        }
        catch (error: any) {
          console.error(error);
          errorMessage.value = error.response?.data?.message || 'An error occurred.';
        }
      };
  
      // Clean up the timer when the component is unmounted
      onUnmounted(() => {
        if (timerInterval.value) {
          clearInterval(timerInterval.value);
        }
      });
  
      return {
        legacy,
        password,
        passwordConfirmation,
        method,
        phone,
        code,
        showVerification,
        expirationTime,
        timer,
        submitForm,
        verifyCode,
        resendCode,
        errorMessage,
        errors
      };
    },
  };
  </script>
  