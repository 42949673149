<template>
    <AlertsComponent :errors="errors" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Create Your Own Report</h2>
        <MessageCard :message="messageCardInfo" type="primary" iconClass="iatse-icon-alarm" classes="info_card__page" />

        <LoaderComponent v-if="loading" />
        <div v-else class="bordered-card bordered-card_secondary mt-20">
            <div class="d-flex justify-content-between align-items-md-center w-100 mb-10 flex-md-row flex-column sm-gap-20">
                <template v-for="step in steps" :key="step.id">
                    <h2 class="sm-fs-sm" v-if="currentStep === step.id">
                        {{ step.label }}
                    </h2>
                </template>
                <div class="d-flex gap-5 flex-md-row flex-column">
                    <p v-for="step in steps" :key="step.id"
                        :class="['fw-semibold', 'ribbon', currentStep === step.id ? 'active text-white' : 'text-dark', currentStep > step.id ? 'completed text-white' : 'text-dark']">
                        <span class="w-100 text-center sm-fs-xs">{{ step.label }}</span>
                    </p>
                </div>
            </div>

            <LocalReportStep1 v-if="currentStep === 1" :filters="filters.FilterFields" :inputErrors="inputErrors"
                @update-filters="updateFilterFields" :saved-templates="savedTemplatesOptions" :template="selectedTemplate" @change-template="useSavedTemplate" />
            <LocalReportStep2 v-if="currentStep === 2" :fields="filters.SelectedFields"
                @update-fields="updateSelectedFields" />
            <LocalReportStep3 v-if="currentStep === 3" :loadingSubmit="loadingSubmit" :sorting="filters.SortedFields" :columns="columns" @update-columns="updateColumns"
                @update-sorting="updateSortedFields" :template="selectedTemplate" @save-template="saveTemplate" />

            <div class="d-flex w-100 justify-content-between mt-10 flex-md-row flex-column sm-gap-10">
                <button class="btn btn-sm btn-secondary lh-1" @click="goToPreviousStep"
                    v-if="currentStep > 1"  :disabled="loadingSubmit">Back</button>
                <button class="btn btn-sm btn-secondary lh-1 ml-20 sm-ml-0" @click="resetForm"
                    v-if="currentStep === 3" :disabled="loadingSubmit">Reset</button>
                <button class="btn btn-primary btn-submit ms-auto" @click="goToNextStep" v-if="currentStep < 3"
                    :disabled="loadingSubmit">
                    Next
                </button>
                <button v-else-if="currentStep === 3" class="btn btn-primary plan-modal btn-submit lh-1 w-sm-100 ms-auto"
                    @click="submitReport" :disabled="loadingSubmit">
                    Run Report and Download
                    <span v-if="loadingSubmit" width="20">
                        <svg version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                            enable-background="new 0 0 0 0" xml:space="preserve">
                            <path fill="#FFFFFF"
                                d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                                    from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                            </path>
                        </svg>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, reactive, Ref, computed } from 'vue';
import { useModalStore } from '@/stores/modal'
import { LocalReportFilters, LocalReportSelectedFields, LocalReportSortedFields, UILocalReport, LocalReportColumn } from '@/interfaces/local';
import { SelectOption } from '@/interfaces/interfaces';
import { useOptionsFormatter } from "@utils/optionFormatter";
import useFormSubmit from '@composable/useFormSubmit';

import LocalReportStep1 from '@/components/local/LocalReportStep1.vue';
import LocalReportStep2 from '@/components/local/LocalReportStep2.vue';
import LocalReportStep3 from '@/components/local/LocalReportStep3.vue';
import axios, { AxiosError } from 'axios';

interface Funds {
    [key: string]: string;
}

export default {
    setup() {
        const currentStep = ref<number>(1);
        const allOption = { ID: 0, Name: 'ALL' };
        const success = ref(null);
        const info = ref(null);
        const errors: Ref<string[]> = ref([]);
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const filePath = ref<string | null>(null);
        const defaultOption = { key: 0, value: 'Select Template'};
        const savedTemplates = ref<UILocalReport[]>([]);
        const savedTemplatesOptions = ref<SelectOption[]>([defaultOption]);
        const selectedTemplate = ref<SelectOption | null>(savedTemplatesOptions.value[0]);
        const steps = [
            { id: 1, label: 'Filters' },
            { id: 2, label: 'Output Columns' },
            { id: 3, label: 'Sort By' },
        ];

        // Default values for LocalReportSelectedFields
        const defaultSelectedFields: LocalReportSelectedFields = {
            WorkPeriod: true,
            Name: true,
            PersonID: true,
            Employer: true,
            Production: true,
            Payor: true,
            ReceiptDate: true
        };

        const defaultSortedFields: LocalReportSortedFields = {
            PostedDate: null,
            Person: null,
            Employer: null,
            Production: null,
            Fund: null,
            AmtDue: null,
            AmtPaid: null,
            Payor: null,
            Status: null
        };

        // Initialize the ref with default values including Fields
        const defaultReportFilters: LocalReportFilters = {
            Local: 0,
            Employers: [allOption],
            Productions: [allOption],
            Payors: [allOption],
            Funds: [allOption],
            PersonID: null,
            FirstName: null,
            LastName: null,
            PeriodStart: null,
            PeriodEnd: null
        };

        const initialRequiredInputs = {
            PeriodStart: '',
            PeriodEnd: ''
        };

        const inputErrors = ref<Record<string, string>>({ ...initialRequiredInputs });

        const defaultSortOptions = [
            { label: 'A-Z', value: 0 },
            { label: 'Z-A', value: 1 }
        ];

        const defaultColumns: LocalReportColumn[] = [
            { key: 'PostedDate', value: 'Work Period', sortOptions: [{ label: 'Newest-Oldest', value: 0 }, { label: 'Oldest-Newest', value: 1 }], show: true },
            { key: 'Person', value: "Member's Name (Last, First)", sortOptions: defaultSortOptions, show: true },
            { key: 'Employer', value: 'Employer', sortOptions: defaultSortOptions, show: true },
            { key: 'Production', value: 'Production', sortOptions: defaultSortOptions, show: true },
            { key: 'Fund', value: 'Fund', sortOptions: defaultSortOptions, show: true },
            { key: 'AmtDue', value: 'Amt Due', sortOptions: defaultSortOptions, show: false },
            { key: 'AmtPaid', value: 'Amt Paid', sortOptions: defaultSortOptions, show: false },
            { key: 'Payor', value: 'Payor', sortOptions: defaultSortOptions, show: false },
            { key: 'Status', value: 'Status', sortOptions: defaultSortOptions, show: false },
        ];

        const messageCardInfo = computed(() => {
            switch (currentStep.value) {
                case 1:
                    return `This reporting feature allows you to design a custom report based on the criteria you identify in the following three steps.`;
                case 2:
                    return "This step allows you to select the criteria that will appear on your report results.";
                case 3:
                    return  "This step allows you to sort your results. Please be patient! This compilation may take several minutes depending on the criteria you have selected. It will be presented to you in a downloadable Excel format. You will have an opportunity to save your search criteria as a template for future use.";
                default:
                    return "Please follow the steps carefully.";
            }
        });

        const columns: LocalReportColumn[] = reactive([...defaultColumns ]);

        const filters = reactive({
            FilterFields: defaultReportFilters,
            SelectedFields: defaultSelectedFields,
            SortedFields: defaultSortedFields,
        });

        const updateFilterFields = (updatedFields: LocalReportFilters) => {
            console.log('>>>>>>> Trigger: updateFilterFields');
            filters.FilterFields = { ...updatedFields };
        };

        const updateSelectedFields = (updatedFields: LocalReportSelectedFields) => {
            console.log('>>>>>>> Trigger: updateSelectedFields');
            filters.SelectedFields = { ...updatedFields };
        };

        const updateSortedFields = (updatedFields: LocalReportSortedFields) => {
            console.log('>>>>>>> Trigger: updateSortedFields');
            filters.SortedFields = { ...updatedFields };
        };

        const goToNextStep = (): void => {
            if(currentStep.value === 1) {
                inputErrors.value = { ...initialRequiredInputs }
                let isValid = true;

                Object.keys(inputErrors.value).forEach((key) => {
                    if (filters.FilterFields && !filters.FilterFields[key as keyof LocalReportFilters]) {
                        inputErrors.value[key] = `${key.replace(/([a-z])([A-Z])/g, '$1 $2')} is required`;

                        isValid = false;
                    }
                    else {
                        inputErrors.value[key] = '';
                    }
                });

                if(isValid) {
                    currentStep.value++;
                }

            } else if(currentStep.value < 3) currentStep.value++;
        };

        const goToPreviousStep = (): void => {
            if (currentStep.value > 1) {
                currentStep.value--;
                filePath.value = null;
            }
        };


        const submitReport = async () => {
            loadingSubmit.value = true;

            const modalProps = {
                title: "Download the File",
                content: {
                    type: "text",
                    value: 'Your ADHOC report will take some time to download due to the large dataset. Please stay logged into the portal, and a pop-up will notify you when your report is ready for download.',
                    icon: "iatse-icon-user-verified",
                },
                confirmButtons: ["Cancel", "Run Report"],
                onConfirm: async () => {
                    await createReport();
                },
            }

            useModalStore().openModal('success-modal', null, 'modal-success', null, modalProps);
        }

        const createReport = async () => {
            loadingSubmit.value = true;
            try {
                const response = await axios.post(
                    '/api/local/create-report', filters
                );
                filePath.value = response.data.success;


                const downloadModalProps = {
                    title: "Download the File",
                    content: {
                        type: "text",
                        value: 'Your ADHOC Report',
                        icon: "iatse-icon-user-verified",
                    },
                    confirmButtons: ["Cancel", "Download"],
                    onConfirm: async () => {
                        await downloadFile();
                    },
                }

                useModalStore().openModal('success-modal', null, 'modal-success', null, downloadModalProps);

            }
            catch (error: unknown) {
                if (error instanceof AxiosError) {
                    errors.value = Object.values(error.response?.data?.errors).flat() as string[];
                } else {
                    console.error("Unexpected error", error);
                }

                filePath.value = null;
            }

            loadingSubmit.value = false;
        };

        const saveTemplate = async (template: SelectOption) => {
            const formData = { Name: template.value, TemplateID: template.key, Filters: filters }
            const route = '/api/local/save-template';
            const method = 'POST'
            const { handleSubmit } = useFormSubmit({ formData, route, success, info, errors, loadingSubmit, method });
            const successSubmit = await handleSubmit();

            if (success.value) {
                const modalProps = {
                    title: "Template Saved",
                    content: {
                        type: "text",
                        value: success.value,
                        icon: "iatse-icon-user-verified",
                    },
                    onConfirmButton: "Ok",
                    onConfirm: () => {
                        useModalStore().closeLastModal();
                        fetchData(false);
                        selectedTemplate.value = template;
                    },
                }

                useModalStore().openModal('success-modal', null, 'modal-success', null, modalProps);
            }
        };

        const resetForm = () => {
            filters.FilterFields = { ...defaultReportFilters };
            filters.SelectedFields = { ...defaultSelectedFields };
            filters.SortedFields = { ...defaultSortedFields };
            currentStep.value = 1;
        }

        const downloadFile = async () => {
            const response = await axios.get(`/api/local/download-report/${filePath.value}`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filePath.value ?? ''); // or extract name from content-disposition header
            document.body.appendChild(link);
            link.click();
            link.parentNode!.removeChild(link);
            window.URL.revokeObjectURL(url);
        }

        const fetchData = async (reset?: boolean) => {
            await axios.get('api/local/create-report')
                .then(response => {
                    savedTemplatesOptions.value = [defaultOption, ...useOptionsFormatter(response.data.TemplatesOptions) as SelectOption[]];
                    savedTemplates.value = response.data.Templates;

                    if(reset) {
                        useSavedTemplate(savedTemplatesOptions.value[0])
                    }
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false)
        };

        const useSavedTemplate = (payload: SelectOption) => {
            const usedTemplate = savedTemplates.value?.find((template: UILocalReport) => template.UILocalReportTemplateID == payload?.key);

            if(usedTemplate && usedTemplate.Filters) {
                filters.SortedFields = { ...usedTemplate.Filters.SortedFields };
                filters.SelectedFields = { ...usedTemplate.Filters.SelectedFields };
                filters.FilterFields = { ...usedTemplate.Filters.FilterFields };

                Object.entries(usedTemplate.Filters.SortedFields).forEach((fieldKey) => {
                    const column = columns.find(col => col.key === fieldKey[0]);

                    if (column) {
                        column.show = true;
                    }
                });
            }

            selectedTemplate.value = payload;
        }

        const updateColumns = (payload: SelectOption) => {
            columns.map(col=> {
                if(col.key === payload.key) {
                    col.show = true
                }
            })
            console.log(payload, '@payload');
        }

        onMounted(async () => await fetchData(true));

        return {
            messageCardInfo,
            currentStep,
            inputErrors,
            savedTemplates,
            savedTemplatesOptions,
            selectedTemplate,
            useSavedTemplate,
            steps,
            filters,
            errors,
            columns,
            updateColumns,
            updateFilterFields,
            updateSelectedFields,
            updateSortedFields,
            goToNextStep,
            goToPreviousStep,
            submitReport,
            loading,
            loadingSubmit,
            filePath,
            resetForm,
            saveTemplate,
            downloadFile,
        };
    },

    components: {
        LocalReportStep1,
        LocalReportStep2,
        LocalReportStep3
    }
}
</script>