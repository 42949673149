<template>
    <div class="dashboard-detail-item pdl-0" :class="{'position-relative': error }">
        <div class="info-card">
            <div class="info-card__icon">
                <i :class="iconClass" class="pdl-20 pdr-20"></i>
            </div>
            <div class="info-card__text">
                <h6>{{ title }}</h6>
                <p :class="{'text-danger': error }">
                    <strong> {{ detail }}</strong>
                    <i v-if="error" class="iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-info fs-lg ml-10">
                        <span class="tooltip-text">{{ error }}</span>
                    </i>
                </p>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'InfoCard',
    props: {
        iconClass: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        detail: {
            type: String,
            required: true,
        },
        error: {
            type: String,
            default: null,
        },
    },
})
</script>