<template>
    <div class="mobile-header" v-if="isAuthenticated">
        <figure>
            <img src="../../assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo">
        </figure>
        <div class="header-navigation">
            <span class="tooltip-wrapper" data-tooltip="Log out">
                <i @click="performLogout" class="plan-modal iatse-icon-noun-login">
                </i>
            </span>
            <p class="header-notifications plan-modal" @click="openModal('alerts', 'Alerts', null)"><i
                    class="iatse-icon-Bell"></i>
                <span v-if="unreadAlerts" class="notification-count"> {{ unreadAlerts }} </span>
            </p>
            <p v-if="!isSuperAdmin" @click="handleProfileOpen" class="plan-modal"><i
                    class="iatse-icon-user-rounded"></i></p>
            <p v-if="isParticipant" @click="openModal('account-settings', 'Account Settings', 'plan-card-info')" class="plan-modal"><i
                    class="iatse-icon-Settings"></i></p>
            <a @click.prevent="openMenu" href="" class="mobile-menu"><i class="iatse-icon-menu"></i></a>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useMembersStore } from '@/stores/members'
import { useDependentStore } from '@/stores/dependent'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useVacationStore } from '@/stores/vacation'
import { usePensionStore } from '@/stores/pension'
import { useProfileStore } from '@/stores/profile';
import { useParticipantStore } from '@/stores/participant'
import { useLocalStore } from '@/stores/local';
import { useEmployerStore } from '@/stores/employer';

export default defineComponent ({
    name: 'MobileHeader',
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const vacationStore = useVacationStore();
        const participantStore = useParticipantStore();
        const dependentStore = useDependentStore();
        const beneStore = useBeneficiaryStore();
        const pensionStore = usePensionStore();
        const profileStore = useProfileStore();
        const localStore = useLocalStore();
        const employerStore = useEmployerStore();
        const unreadAlerts = computed(() => authStore.unreadAlerts);
        const isParticipant = computed(() => authStore.isParticipant);
        const isSuperAdmin = computed(() => authStore.isSuperAdmin);
        const isAuthenticated = computed(() => {
            if(authStore.user?.active2FA) {
                return authStore.isAuthenticated && authStore.twoFaValidated && authStore.termsAccepted;
            }

            return authStore.isAuthenticated && authStore.termsAccepted;
        });

        const openModal = (type: string, title: string, classes: string | null) => {
            emit('openModal', type, title, classes);
        };

        const openMenu = () => {
            emit('open-menu');
        }

        const handleProfileOpen = () => {
            if (isParticipant.value) {
                openModal('profile', 'My Profile', 'plan-profile plan-tables')
            }
            else {
                openModal('local-profile', 'My Profile', 'plan-card-info account-settings')
            }
        }

        // Method for performing logout
        const performLogout = () => {
            dependentStore.resetDependentStore();
            beneStore.reset();
            pensionStore.reset();
            vacationStore.reset();
            profileStore.resetProfile();
            authStore.logout();
            participantStore.reset();
            localStore.reset();
            employerStore.reset();
        };

        return {
            unreadAlerts,
            isParticipant,
            isSuperAdmin,
            isAuthenticated,
            openModal,
            openMenu,
            performLogout,
            handleProfileOpen
        }
    },
});
</script>