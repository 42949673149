<template>
    <form class="form" v-if="dependent && person">
        <div class="w-100 d-flex gap-4 flex-md-row flex-column">
            <TextInput type="text" label="Relationship" v-model:modelValue="dependent.Relationship" :required="true"
                :has-changes="changes?.Relationship" :readonly="true" />
            <TextInput type="text" label="Country" v-model:modelValue="person.CitizenshipCode" :required="true"
                :has-changes="changes?.CitizenshipCode" :readonly="true" />
            <TextInput type="text" name="number" label="Social Security" placeholder="Enter SSN here"
                v-model:modelValue="person.SSN" :required="true" :has-changes="changes.SSN" :readonly="true" />
        </div>

        <div class="w-100 d-flex gap-4 flex-md-row flex-column">
            <TextInput type="text" label="Salutation" placeholder="Salutation" :max=12
                v-model:modelValue="person.Salutation" :readonly="true" :required="true" :has-changes="changes.Salutation" />
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
                v-model:modelValue="person.FirstName" :readonly="true" :required="true" :has-changes="changes.FirstName" />
            <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
                v-model:modelValue="person.MiddleName" :readonly="true" :has-changes="changes.MiddleName" />
        </div>
        <div class="w-100 d-flex gap-4 flex-md-row flex-column">
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="person.LastName" :readonly="true" :has-changes="changes.LastName" />
            <TextInput type="text" label="Suffix" placeholder="Suffix" v-model:modelValue="person.NameSuffix"
                :readonly="true" :required="true" :has-changes="changes.NameSuffix" />
            <DateInput label="Date of Birth" v-model:modelValue="person.DateofBirth" :placeholder="'dd/mm/yyyy'"
                input-type="date" :readonly="true" :has-changes="changes.DateofBirth" />
        </div>
        <div class="w-100 d-flex gap-4 flex-md-row flex-column">
            <TextInput type="text" label="Gender" v-model:modelValue="person.Sex" :readonly="true"
                :has-changes="changes.Sex" />
            <TextInput v-if="person.MaritalStatus" type="text" label="Marital Status" placeholder="Marital Status"
                v-model:modelValue="person.MaritalStatus" :readonly="true" :required="true"
                :has-changes="changes.MaritalStatus" />
            <DateInput v-if="dependent.MarriageDate" label="Marriage/Affidavit Date"
                v-model:modelValue="dependent.MarriageDate" :placeholder="'dd/mm/yyyy'" input-type="date"
                :readonly="true" :has-changes="changes.MarriageDate" />
            <DateInput v-if="dependent.DivorceDate" label="Divorce/Affidavit Date"
                v-model:modelValue="dependent.DivorceDate" :placeholder="'dd/mm/yyyy'" input-type="date"
                :readonly="true" :has-changes="changes.DivorceDate" />
            <DateInput v-if="person.DateofDeath" label="Date of Death/Affidavit Date"
                v-model:modelValue="person.DateofDeath" :placeholder="'dd/mm/yyyy'" input-type="date"
                :readonly="true" :has-changes="changes.DateofDeath" />
        </div>

        <!-- Contact Methods -->
        <TextInput type="tel" name="phone" label="Telephone Number" placeholder="Enter Phone Number here" :max=50
            v-model:modelValue="contact.Phone" :required="true" :has-changes="changes.Phone" :readonly="true" />
        <TextInput type="email" label="Email Address" placeholder="Enter Email here" :max=50
            v-model:modelValue="contact.Email" :required="true" :has-changes="changes.Email" :readonly="true" />

        <!-- Address -->
        <div v-if="contact.Address" class="form w-100">
            <h4 class="w-100">Address</h4>
            <TextInput type="text" label="Address Type" placeholder="Enter Address Type" :max=25
                v-model:modelValue="contact.Address.Type" :required="true" :has-changes="changes.Address?.Type"
                :readonly="true" />
            <TextInput type="text" label="Address" placeholder="Enter Address 1" :max=30 :readonly="true"
                v-model:modelValue="contact.Address.Street1" :required="true" :has-changes="changes.Address?.Street1" />
            <TextInput type="text" label="City" placeholder="Enter City" :max=25
                v-model:modelValue="contact.Address.City" :required="true" :has-changes="changes.Address?.City"
                :readonly="true" />
            <TextInput type="text" label="State" placeholder="Enter State" :max=25
                v-model:modelValue="contact.Address.State" :required="true" :has-changes="changes.Address?.State"
                :readonly="true" />
            <TextInput type="text" label="Country" placeholder="Enter Country" :max=25
                v-model:modelValue="contact.Address.Country" :required="true" :has-changes="changes.Address?.Country"
                :readonly="true" />
            <TextInput type="text" name="number" label="Postal Code" placeholder="Enter Postal Code" :max=9
                :has-changes="changes.Address?.Zip" v-model:modelValue="contact.Address.Zip" :required="true"
                :readonly="true" />
        </div>

    </form>
    <div class="form" v-else>
        Data is not available
    </div>
</template>

<script lang="ts">
import { ReviewDependentData } from '@/interfaces/interfaces';
import { PropType, defineComponent, computed } from 'vue';
import { useDateFormatter } from "@/utils/dateFormatter";
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';

export default defineComponent({
    components: {
        DateInput,
        TextInput,
    },
    props: {
        data: {
            type: Object as PropType<ReviewDependentData | null>,
            required: true
        },
    },
    setup(props) {
        const dependent = props.data?.Dependent ? JSON.parse(JSON.stringify(props.data?.Dependent)) : null;
        const person = props.data?.Person ? JSON.parse(JSON.stringify(props.data?.Person)) : null;
        const changes = props.data?.Changes ? JSON.parse(JSON.stringify(props.data?.Changes)) : [];
        const contact = props.data?.ContactMethod ? JSON.parse(JSON.stringify(props.data?.ContactMethod)) : [];

        return { dependent, person, changes, contact }
    }
})
</script>
