<template>
    <AlertsComponent :error="error" />
    <div class="bordered-card bordered-card_secondary">
        <h4 class="fw-bold mb-5">{{ type }} Profile</h4>
        <div class="d-flex flex-column w-100 gap-10">
            <div class="d-flex gap-2 justify-content-between align-items-center w-100">
                <span class="fw-light fs-sm">{{ type }}:</span>
                <!-- <router-link :to="{ name: 'user.profile' }"> -->
                    <span class="fw-bold sm-fs-xs text-end"> {{ data?.CompanyName }}</span>
                <!-- </router-link> -->
            </div>
            <div class='divider mt-0 mb-0'></div>
            <div class="d-flex justify-content-between gap-2 align-items-start w-100">
                <span class="fw-light fs-sm">Email:</span>
                <span class="fw-bold sm-fs-xs">{{ data?.ContactEmail }}</span>
            </div>
            <div class='divider mt-0 mb-0'></div>
            <div class="d-flex justify-content-between align-items-center gap-2 align-items-center w-100">
                <span class="fw-light fs-sm">Contact Name:</span>
                <span class="fw-bold sm-fs-xs">{{ data?.ContactName }}</span>
            </div>
            <div class='divider mt-0 mb-0'></div>
            <div class="d-flex justify-content-between gap-2 align-items-start w-100">
                <span class="fw-light fs-sm">Contact Phone:</span>
                <p class="fw-bold sm-fs-xs d-flex flex-column gap-2 text-end">
                    {{
                        Array.isArray(data?.ContactPhone)
                            ? data?.ContactPhone.join(' | ')
                            : data?.ContactPhone ?? '--'
                    }}
                </p>
            </div>
            <div v-if="type === 'Local'" class='divider mt-0 mb-0'></div>
            <div v-if="type === 'Local'" class="d-flex justify-content-between align-items-center gap-2 w-100">
                <span class="fw-light fs-sm">PIN Expiration:</span>
                <span class="fw-bold sm-fs-xs">{{ data?.PinExpiration }} Days</span>
            </div>
            <div v-if="type === 'Local'" class='divider mt-0 mb-0'></div>
            <div v-if="type === 'Local'" class="d-flex align-items-center justify-content-between gap-10 w-100">
                <span class="fw-light fs-sm w-50">Business Associate Agreement on file:</span>
                <div class="d-flex gap-10 align-items-center">
                    <small v-if="data?.BusinessAgreementFile" class="label label_blue pdl-5 pdr-5 pdt-5 pdb-5 h-fit lh-1">
                        <span class="bullet"></span> Yes
                    </small>
                    <small v-else class="label label_danger pdl-5 pdr-5 pdt-5 pdb-5 h-fit lh-1">
                        <span class="bullet"></span> No
                    </small>
                    <div class="divider divider_vertical"> </div>
                    <small
                        v-if="data?.BusinessAgreementFile"
                        :class="['label label_green h-fit pdl-5 pdr-5', { 'disabled': loading }]"
                        @click="!loading && downloadFile()"
                    >
                    <svg v-if="loading" width="16" height="24" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                        enable-background="new 0 0 0 0" xml:space="preserve">
                        <path fill="#8EC986"
                            d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                                from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                        </path>
                    </svg>
                        <i v-else class="iatse-icon-download fs-lg"> </i>
                    </small>
                    <small v-else @click="openModal('local-upload-baa', 'Upload H&W Business Associate Agreement')" class="plan-modal btn btn-primary btn-small fs-sm lh-1 pdl-5 pdr-5">
                        Upload file
                    </small>
                </div>
            </div>
        </div>
        <small>This page displays information about your {{type}} on file at the Fund Office. <br />If any of this information needs to be changed or is incorrect, please click <a target="_blank" href="https://iatse-public.staging.polardesign.com/en/contact">here</a> to email the Fund office.</small>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent, ref } from 'vue';
import { ProfileData } from '@/interfaces/interfaces';
import { useAuthStore } from '../../../stores/auth';
import DocumentService from '@/services/documentsService';
import { useModalStore } from '@/stores/modal';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<ProfileData | null>,
            required: true
        },
        type: String
    },
    setup(props) {
        const isLocalAdmin = useAuthStore().isLocalAdmin;
        const loading = ref(false);
        const error = ref('');

        const downloadFile = async() => {
            if(!props.data?.BusinessAgreementFile) {
                return;
            }

            try {
                loading.value = true;
                const blob = await DocumentService.viewDocument(String(props.data.BusinessAgreementFile));

                // Check if the content-type is HTML
                if (blob.type && blob.type == 'text/html') {
                    error.value = 'Something went wrong downloading the file. Please, contact the admin.';

                    setTimeout(() => error.value = '', 3000)
                } else {
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = 'Business Agreement File';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
            }
            catch (error) {
                if (error instanceof Error) {
                    console.error(error.message);
                }
            }

            loading.value = false;
        }

        return {
            loading,
            error,
            isLocalAdmin,
            downloadFile,
            openModal: useModalStore().openModal
        }
    }
})
</script>