<template>
    <div>
        <div class="info_card info_card__danger info_card__header mb-4 flex-md-row flex-column" v-if="isImpersonating">
            <p> You are currently masquerading <span class="text-decoration-underline">{{ impersonationName }} </span>
            </p>
            <button class="btn btn-small btn-outlined" @click="terminateImpersonation">Stop masquerading</button>
        </div>

        <header v-if="isAuthenticated">
            <div class="header-wrapper">
                <h3 :class="[{ 'pdl-10': isLocal || isEmployer }]">
                    Hello, {{ isImpersonating ? impersonationName : userName }}!
                </h3>
                <div class="header-navigation">
                    <!-- <div class="header-notifications plan-modal" @click="openModal('alerts', 'Alerts', null)">
                        <p class="header-notifications"><i class="iatse-icon-Bell"></i>
                            <span v-if="unreadAlerts" class="notification-count"> {{ unreadAlerts }} </span>
                        </p>
                        <p>Notifications</p>
                    </div> -->

                    <!-- Notifications Preview  -->
                    <div class="plan-modal my-profile-wrapper">
                        <div class="plan-modal" @click="openModal('alerts', 'Alerts', null)">
                            <div class="header-notifications">
                                <i class="iatse-icon-Bell"></i>
                                <span v-if="unreadAlerts" class="notification-count"> {{ unreadAlerts }} </span>
                            </div>
                            <p> Notifications </p>
                        </div>
                        <div v-if="alerts.length" class="profile-dropdown-menu align-items-center notifications">
                            <div class="d-flex justify-content-between w-100 pdr-15 pdl-15">
                                <p class="fw-semibold">Notifications</p>
                                <p v-if="unreadAlerts" class="fs-sm text-medium-grey" @click="readNotifications">Mark all as read</p>
                            </div>
                            <div class="divider w-100 divider_horizontal mb-0 mt-10"></div>
                            <div class="notifications-alerts w-100 gap-10">
                                <AlertsComponent :max=10 :read="readAll" :show-unread="true" @read="readNotifications"/>
                            </div>
                            <p class="plan-modal justify-content-center fs-sm fw-semibold w-100 text-center pdt-20" @click="openModal('alerts', 'Alerts', null)">View All Notifications</p>
                        </div>
                    </div>

                    <!-- Account Settings -->
                    <div class="divider"></div>
                    <p v-if="isParticipant || isSuperAdmin" @click="openModal('account-settings', 'Account Settings', 'plan-card-info')" class="plan-modal">
                        <i class="iatse-icon-Settings"></i>Account Settings
                    </p>
                    <a class="plan-modal" v-if="isLocal || isEmployer" href="https://iatse-public.staging.polardesign.com/en/contact" target="_blank"><i class="iatse-icon-call-calling"></i>Contact</a>

                    <!-- Profile Settings -->
                    <div class="divider"></div>
                    <div class="plan-modal my-profile-wrapper">
                        <p class="plan-modal" @click="handleLocalProfileOpen">
                            <i class="iatse-icon-user-rounded"></i> My Profile
                            <i class="iatse-icon-arrow-down"></i>
                        </p>
                        <div class="profile-dropdown-menu">
                        <p class="plan-modal" v-if="isParticipant" @click="handleProfileOpen">Profile Settings</p>
                            <a class="plan-modal" href="https://iatse-public.staging.polardesign.com/en/contact" target="_blank">Help & Support</a>
                            <a class="plan-modal" href="https://iatse-public.staging.polardesign.com/en/privacy-policy" target="_blank">Privacy Policy</a>
                            <!-- <p @click="openModal('archived-alerts', 'Archived Alerts', 'alerts')"
                                class="dropdown-item plan-modal">
                                Archive</p> -->
                            <p class="plan-modal" @click="performLogout">Log Out</p>
                        </div>
                    </div>
                </div>
                <div v-if="isParticipant" class="card-control" :class="{ 'plus': !visibleContent }"
                    @click="toggleVisible">
                    <i class="plus iatse-icon-plus" v-if="!visibleContent"></i>
                    <span class="line" v-if="visibleContent"></span>
                </div>
            </div>
            <transition name="slide-fade">
                <div class="dashboard-details mt-45 card-toggle" v-if="visibleContent && isParticipant ">
                    <div v-for="item in dashboardItems" :key="item.title" class="dashboard-detail-item pdl-0">
                        <InfoCard :iconClass="item.iconClass" :title="item.title" :detail="item.detail" :error="item.error" />
                    </div>
                </div>
            </transition>
            <div v-if="dashboardItems && isParticipant" class="divider"></div>
        </header>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useMembersStore } from '@/stores/members'
import { useDependentStore } from '@/stores/dependent'
import { useBeneficiaryStore } from '@/stores/beneficiary'
import { useVacationStore } from '@/stores/vacation'
import { usePensionStore } from '@/stores/pension'
import { useProfileStore } from '@/stores/profile';
import { useParticipantStore } from '@/stores/participant'
import { useLocalStore } from '@/stores/local';
import { useEmployerStore } from '@/stores/employer';
import { useRouter } from 'vue-router';
import InfoCard from './cards/InfoCard.vue';
import AlertsComponent from '@/components/user/AlertsComponent.vue';

export default defineComponent({
    name: 'HeaderComponent',
    components: {
        InfoCard,
        AlertsComponent
    },
    emits: ['openModal'],
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const vacationStore = useVacationStore();
        const participantStore = useParticipantStore();
        const dependentStore = useDependentStore();
        const beneStore = useBeneficiaryStore();
        const pensionStore = usePensionStore();
        const profileStore = useProfileStore();
        const localStore = useLocalStore();
        const employerStore = useEmployerStore();
        const router = useRouter();
        const isImpersonating = computed(() => authStore.isImpersonating);
        const isSuperAdmin = computed(() => authStore.isSuperAdmin);
        const isParticipant = computed(() => authStore.isParticipant);
        const isLocal = computed(() => authStore.isLocal);
        const isEmployer = computed(() => authStore.isEmployer);
        const isAuthenticated = computed(() => {
            if(authStore.user?.active2FA) {
                return authStore.isAuthenticated && authStore.twoFaValidated && authStore.termsAccepted;
            }

            return authStore.isAuthenticated && authStore.termsAccepted;
        });

        const impersonationName = computed(() => authStore.impersonationName);
        const userName = computed(() => authStore.userName);
        const dashboardItems = computed(() => participantStore.dashboardItems);
        const unreadAlerts = computed(() => participantStore.alerts.unreadCount);
        const alerts = computed(() => participantStore.alerts.data);
        const dashboardRoute = computed(() => authStore.getDashboardRoute);
        const isProfileMenuOpen = ref(false);
        const visibleContent = ref(true);
        const readAll = ref(false);

        // Method for performing logout
        const performLogout = () => {
            useMembersStore().setList([]);
            dependentStore.resetDependentStore();
            beneStore.reset();
            pensionStore.reset();
            vacationStore.reset();
            profileStore.resetProfile();
            authStore.logout();
            participantStore.reset();
            localStore.reset();
            employerStore.reset();
            isProfileMenuOpen.value = false;
        };

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        const readNotifications = () => {
            readAll.value = !readAll.value;
        }

        const terminateImpersonation = async () => {
            try {
                await authStore.stopImpersonation();
                dependentStore.resetDependentStore();
                beneStore.reset();
                pensionStore.reset();
                vacationStore.reset();
                profileStore.resetProfile();
                participantStore.reset();
                localStore.reset();
                employerStore.reset();
                router.push({ name: authStore.stopImpersonationRoute });
            } catch (error) {
                console.error("Impersonation error:", error);
            }
        };

        const handleProfileOpen = () => {
            if (!isSuperAdmin.value) {
                openModal('profile', 'My Profile', 'plan-profile plan-tables')
            }
        }

        const handleLocalProfileOpen = () => {
            if (isLocal.value) {
                openModal('local-profile', 'My Profile', 'plan-card-info account-settings')
            }

            if (isEmployer.value) {
                openModal('local-profile', 'My Profile', 'plan-card-info account-settings')
            }
        }

        const openModal = (type: string, title: string, classes: string | null) => {
            emit('openModal', type, title, classes);
        };

        return {
            isAuthenticated,
            isImpersonating,
            impersonationName,
            isSuperAdmin,
            isParticipant,
            isLocal,
            isEmployer,
            alerts,
            readAll,
            userName,
            isProfileMenuOpen,
            unreadAlerts,
            dashboardRoute,
            dashboardItems,
            visibleContent,
            toggleVisible,
            performLogout,
            handleProfileOpen,
            handleLocalProfileOpen,
            terminateImpersonation,
            readNotifications,
            openModal,
        };
    }
});
</script>
