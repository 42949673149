import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useParticipantStore } from '@/stores/participant';

const ACTION_LOGIN = 1;
const ACTION_CURRENT_METHOD = 2;
const ACTION_NEW_METHOD = 3;

export function useVerificationCode(method: string, action = 1, email:string|null = null, phone:string|null = null, expirationTimeDefault = 120) {
  const timer = ref(0);
  const timerInterval = ref<number | null>(null);
  const expirationTime = ref(expirationTimeDefault);
  const errors = ref('');
  const success = ref('');
  const verificationCode = ref('');
  const isCodeGenerated = ref(false);  // Flag to track if the code has been generated

  const startTimer = () => {
    timer.value = expirationTime.value;
    if (timerInterval.value) {
        clearInterval(timerInterval.value);
    }
    timerInterval.value = setInterval(() => {
        if (timer.value > 0) {
          timer.value--;
        }
        else {
          clearInterval(timerInterval.value!);
          timerInterval.value = null;
        }
      }, 1000);
  };

  const generateCode = async () => {
    try {
      console.log(method, 'method');
      const response = await axios.post('/api/authorization/change-method/generate-code', { method, action, email, phone });

      if (response.data.success) {
        expirationTime.value = response.data.expirationTime || expirationTimeDefault;
        startTimer();
      }
    } catch (error: any) {
      errors.value = error.response.data.error;
      // errors.value = 'An error occurred while generating the code.';
    }
  };

  const regenerateCode = async () => {
    try {
      const response = await axios.post('/api/authorization/change-method/regenerate-code', { method, action, email, phone });

      if (response.data.success) {
        expirationTime.value = response.data.expirationTime || expirationTimeDefault;
        success.value = 'New code was sent.';
        errors.value = '';
        startTimer();
      } else {
        errors.value = response.data.message || 'An error occurred while resending the code.';
      }
    } catch (error) {
      errors.value = 'An error occurred while resending the code.';
    }
  };


  const submitCode = async (changeMethodFlag = false) => {
    try {
      const response = await axios.post('/api/authorization/change-method/submit-code', {
        verification_code: verificationCode.value,
        changeMethodFlag: changeMethodFlag,
        email: changeMethodFlag ? useParticipantStore()?.userEmail : null,
        method,
        action
      });

      success.value = 'Code verified successfully.';
      errors.value = '';
    } catch (error: any) {
      errors.value = error.response?.data?.error || 'An error occurred during verification.';
    }
  };

    // Automatically generate code when composable is mounted
    onMounted(() => {
      if (!isCodeGenerated.value) {  // Check to see if the code has already been generated
        generateCode();
        isCodeGenerated.value = true;  // Set the flag to true once code is generated
      }
    });

  return {
    ACTION_LOGIN,
    ACTION_CURRENT_METHOD,
    ACTION_NEW_METHOD,
    verificationCode,
    timer,
    success,
    errors,
    generateCode,
    regenerateCode,
    submitCode,
  };
}
