<template>
    <div :class="['text-input', customClass, { 'error': error && error.length }]">
        <label v-if="label !== ''" :for="label" class="body-text">
            {{ label }}
            <span v-if="isRequired" class="text-danger"> * </span>
        </label>
        <input
            type="text"
            :id="label"
            :name="name"
            :placeholder="placeholder"
            :readonly="readonly"
            :required="required"
            class="links"
            :value="inputValue"
            maxlength="17"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
        />
        <small v-if="error && error.length" class="text-secondary-red">
            {{ Array.isArray(error) ? error.join('. ') : error }}
        </small>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch } from 'vue';

export default defineComponent({
    name: 'PhoneNumberInput',
    props: {
        label: {
            type: String,
            required: true,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'phone',
        },
        modelValue: {
            type: [String, Number] as PropType<string | number>,
            default: '',
        },
        error: {
            type: [String, Array],
            default: '',
        },
    },

    setup(props, { emit }) {
        const inputValue = ref('');

        watch(() => props.modelValue, (newValue) => {
            const rawValue = typeof newValue === 'number' ? newValue.toString() : (newValue || '');
            inputValue.value = formatPhoneNumber(rawValue);
        }, { immediate: true });

        const onInput = (event: Event) => {
            const target = event.target as HTMLInputElement | null;
            if (!target) return;

            let value = target.value;
            // Remove all non-numeric characters
            let numericValue = value.replace(/\D/g, '');

            // Limit to 11 digits max
            numericValue = numericValue.slice(0, 11);

            // Format for display
            inputValue.value = formatPhoneNumber(numericValue);

            // Emit raw numeric value
            emit('update:modelValue', numericValue);
        }

        const onFocus = (event: Event) => {
            const target = event.target as HTMLInputElement | null;
            if (!target) return;
            // If empty, start with the first character (no hint needed since we start formatting once they type)
            if (target.value.trim() === '') {
                inputValue.value = '';
            }
        }

        const onBlur = (event: Event) => {
            const target = event.target as HTMLInputElement | null;
            if (!target) return;
            // If empty or partial is not meaningful, just leave as is
            if (target.value.trim() === '') {
                emit('update:modelValue', '');
            }
        }

        function formatPhoneNumber(value: string) {
            // value: numeric-only string of up to 11 digits
            const digits = value.replace(/\D/g, '');
            // Format: x (xxx) xxx-xxxx
            // indexes: digit[0], digit[1..3], digit[4..6], digit[7..10]
            let formatted = '';

            if (digits.length > 0) {
                formatted += digits[0]; // x
            }
            if (digits.length > 1) {
                formatted += ' (' + digits.slice(1, 4); // (xxx
            }
            if (digits.length > 4) {
                formatted += ') ' + digits.slice(4, 7); // ) xxx
            }
            if (digits.length > 7) {
                formatted += '-' + digits.slice(7, 11); // -xxxx
            }

            return formatted.trim();
        }

        return {
            inputValue,
            onInput,
            onFocus,
            onBlur
        };
    },
});
</script>

<style scoped>
.text-input {
    display: flex;
    flex-direction: column;
    position: relative;
}

.text-input.error input {
    border-color: red;
}

.text-danger {
    color: red;
}

.text-secondary-red {
    color: #d9534f; /* or a suitable secondary red color */
}

.links {
    padding: 0.5rem;
    font-size: 0.875rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}
</style>
