<template>
    <div style="width: 100%;">
        <table id="work-history">
            <thead>
                <tr>
                    <th>Work Period</th>
                    <th>Production</th>
                    <th>Employer</th>
                    <th>Local</th>
                    <th>Fund</th>
                    <th>Work Reported</th>
                    <th>Credited</th>
                </tr>
            </thead>
            <tbody v-if="loading"><tr><td colspan="7"> <LoaderComponent /> </td></tr></tbody>
            <tbody v-else>
                <!-- Table with totals and subtotals -->
                <template v-for="(yearDetail, year) in data.Years" :key="year">
                    <template v-for="(result, index) in yearDetail.Results" :key="result.ParticipantWHID">
                        <tr :class="{ 'page-break': (index + 1) % 20 === 0 }">
                            <td>{{ result.WorkPeriod }}</td>
                            <td>{{ result.Production }}</td>
                            <td>{{ result.Employer }}</td>
                            <td>{{ result.Local }}</td>
                            <td>{{ result.Plan }}</td>
                            <td>
                                <span v-if="parseFloat(result.DaysWorked) > 0"> {{ result.DaysWorked }} Days <br /></span>
                                <span v-if="parseFloat(result.HoursWorked) > 0"> {{ result.HoursWorked }} Hours <br /></span>
                                <span v-if="parseFloat(result.EarningsReported) > 0"> {{ formatCurrency(result.EarningsReported) }} Contractual Wages<br /></span>
                                <span v-if="parseFloat(result.ContractWages) > 0"> {{ formatCurrency(result.ContractWages) }} Gross Wages<br /></span>
                            </td>
                            <td>{{ result.ContributionsPaid }}</td>
                        </tr>
                        <!-- Row for top page space (temp method) -->
                        <tr v-if="(index + 1) % 20 === 0" class="hidden">
                            <td colspan="7" style="background-color: white; height: 170px;"></td>
                        </tr>
                    </template>

                    <tr class="subtotal">
                        <td colspan="4">
                            <div class="subtotal-cell">Total Contributions for {{ year }}</div>
                        </td>
                        <td>
                            <div class="cell">
                                <p v-for="(amount, fundID) in yearDetail.YearTotals" :key="fundID">
                                    <span v-if="amount > 0">{{ getFundName(fundID) }}</span>
                                </p>
                            </div>
                        </td>
                        <td>
                            <div class="cell">
                                <div v-if="yearDetail.UnitTotals">
                                    <p v-if="yearDetail.UnitTotals.TotalDays > 0">{{ yearDetail.UnitTotals.TotalDays }} Days</p>
                                    <p v-if="yearDetail.UnitTotals.Hours > 0">{{ yearDetail.UnitTotals.Hours }} Hours
                                    </p>
                                    <p v-if="yearDetail.UnitTotals.TotalEarnings > 0">
                                        {{ formatCurrency(yearDetail.UnitTotals.TotalEarnings) }} Contractual Wages
                                    </p>
                                    <p v-if="yearDetail.UnitTotals.TotalWages > 0">
                                        {{ formatCurrency(yearDetail.UnitTotals.TotalWages) }} Gross Wages
                                    </p>
                                </div>
                            </div>
                        </td>
                        <td>
                            <div class="cell">
                                <p v-for="(amount, fundID) in yearDetail.YearTotals" :key="fundID">
                                    <span v-if="amount > 0">{{ formatCurrency(amount) }}</span>
                                </p>
                            </div>
                        </td>
                    </tr>
                </template>
                <tr v-if="!Object.values(data.Years)?.length">
                    <td colspan="7" class="text-center">
                        No results found.
                    </td>
                </tr>
                <!-- Grand Total Row -->
                <tr class="total-divider"> </tr>
                <tr class="total sm-mb-20" v-if="data.GrandTotals">
                    <td colspan="4">
                        <div class="grand-total-cell">Grand Total Contributions</div>
                    </td>
                    <td>
                        <div class="cell">
                            <p v-for="(amount, fundID) in data.GrandTotals" :key="fundID">
                                <span v-if="amount > 0">{{ getFundName(fundID) }}</span>
                            </p>
                        </div>
                    </td>
                    <td>
                        <div class="cell">
                            <div v-if="data.GrandTotalUnits">
                                <p v-if="data.GrandTotalUnits.TotalDays > 0">{{ data.GrandTotalUnits.TotalDays }} Days</p>
                                <p v-if="data.GrandTotalUnits.Hours > 0">{{ data.GrandTotalUnits.Hours }} Hours</p>
                                <p v-if="data.GrandTotalUnits.TotalEarnings > 0">
                                    {{ formatCurrency(data.GrandTotalUnits.TotalEarnings) }} Contractual Wages
                                </p>
                                <p v-if="data.GrandTotalUnits.TotalWages > 0">
                                    {{ formatCurrency(data.GrandTotalUnits.TotalWages) }} Gross Wages
                                </p>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="cell">
                            <p v-for="(amount, fundID) in data.GrandTotals" :key="fundID">
                                <span v-if="amount > 0">{{ formatCurrency(amount) }}</span>
                            </p>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType } from 'vue';
import { WorkHistoryData } from '@/interfaces/interfaces';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { useFunds } from '@/composable/useFundsMapping';

export default defineComponent({
    props: {
        data: {
            type: Object as PropType<WorkHistoryData>,
            required: true
        },
        loading:Boolean
    },

    setup() {
        const { formatCurrency } = useCurrencyFormatter();
        const { getFundName } = useFunds();

        return {
            formatCurrency, getFundName
        };
    }
});
</script>