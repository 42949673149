<template>
    <header>
      <div class="container mt-35 sm-mt-0 sm-p-0 logo-header">
        <div class="row sm-p-0">
          <!-- Logo Section -->
          <a
            href="https://iatse-public.staging.polardesign.com/en/"
            target="_blank"
            class="logo-wrapper tooltip-wrapper tooltip-wrapper_tertiary col-md-4 d-flex justify-content-end order-1 order-md-2 mb-20 plan-modal"
            data-tooltip="Visit IATSE National Benefits Fund"
          >
            <div class="w-fit w-sm-100 bg-primary rounded px-4 py-3 h-fit rounded-none">
              <figure>
                <img src="@/assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo" />
              </figure>
            </div>
          </a>
          <div class="col-md-8 order-2 order-md-1 hide-mobile">
            <h2 class="sm-mb-20 text-center text-lg-start">User Login</h2>
          </div>
        </div>
      </div>
    </header>
  
    <section class="container">
      <div class="login">
        <h2 class="sm-fs-xl mb-20">Log in to your account</h2>
        <!-- Role Selection -->
        <div class="role-selection mt-10 mb-20">
          <label class="pdr-15">
            <input type="radio" name="role" value="P" v-model="credentials.role" />
            Participant
          </label>
          <label class="pdr-15">
            <input type="radio" name="role" value="L" v-model="credentials.role" />
            Local
          </label>
          <label class="pdr-15">
            <input type="radio" name="role" value="E" v-model="credentials.role" />
            Employer
          </label>
          <label>
            <input type="radio" name="role" value="A" v-model="credentials.role" />
            Admin
          </label>
        </div>
        <form id="login-form" @submit.prevent="login" method="POST">
          <div class="inputs-wrapper w-50 w-sm-100 sm-fs-base gap-0">
            <TextInput
              type="email"
              label="Email Address"
              placeholder="Email"
              :max="50"
              v-model:modelValue="credentials.email"
              :is-required="true"
              :error="errors?.email"
              :required="true"
              custom-class="w-100 mb-20"
            />
            <TextInput
              type="password"
              label="Password"
              placeholder="Password"
              :max="50"
              v-model:modelValue="credentials.password"
              :is-required="true"
              :error="errors?.password"
              :required="true"
              custom-class="mb-20"
            />
            <small class="w-100 fw-light fs-sm mb-20">
              Minimum 8 characters, One Uppercase, One Lowercase, One Symbol and One Number
            </small>
            <p class="w-100 pdb-20 text-danger fs-sm" v-if="error">
              {{ error }}
            </p>
            <CheckBox label="Remember me" id="remember" :showLabel="true" className="text-x-large" />
          </div>

          <button type="submit" class="btn btn-primary w-sm-100 sm-fs-base" :disabled="loading">
            <span v-if="loading">Logging in...</span>
            <span v-else>Login</span>
          </button>
        </form>

        <span
          class="body-text forgot-credentials-text sm-fs-sm d-flex gap-10 flex-column flex-md-row sm-mt-20 align-items-md-start align-items-center"
        >
          <span>
            Forgot Credentials?
            <a href="https://iatse-public.staging.polardesign.com/en/forgot-credentials" target="_blank">
              Click here
            </a>
          </span>
          <span class="ml-10 mr-10 hide-mobile">|</span>
          <span>
            Don't have an account?
            <router-link :to="{ name: 'register' }">
              Click here to register
            </router-link>
          </span>
        </span>
      </div>
    </section>
  </template>
  
  <script lang="ts">
  import { ref, onMounted } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import axios, { AxiosError } from 'axios';
  import TextInput from '@components/form/TextInput.vue';
  import CheckBox from '@components/form/CheckBox.vue';
  
  export default {
    components: {
      TextInput,
      CheckBox,
    },
    setup() {
      const authStore = useAuthStore();
      const error = ref('');
      // Updated credentials object to include the role parameter (default to "P")
      const credentials = ref({ email: '', password: '', role: 'P' });
      const loading = ref(false);
  
      const login = async () => {
        loading.value = true;
        error.value = '';
  
        try {
          await authStore.authenticateUser(credentials.value);
          loading.value = false;
        } catch (loginError: unknown) {
          loading.value = false;
  
          if (axios.isAxiosError(loginError)) {
            error.value = 'Login failed: ' + loginError.response?.data?.message;
          } else {
            error.value = 'An unknown error occurred';
          }
        }
      };
  
      onMounted(() => {
        authStore.logout();
        authStore.clearUserStore();
      });
  
      return { credentials, login, loading, error };
    },
  };
  </script>