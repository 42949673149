import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  id: "email-change-form",
  class: "form account-settings"
}
const _hoisted_2 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_3 = { class: "w-full d-flex gap-3 align-items-end flex-column" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    (_ctx.errors && _ctx.errors.General)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.errors?.General), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_TextInput, {
        type: "email",
        modelValue: _ctx.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
        label: "Email Address",
        placeholder: "Enter Email",
        error: _ctx.errors?.email ?? null,
        max: 100
      }, null, 8, ["modelValue", "error"]),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(["btn toggle-control lh-sm w-100", {'btn-primary': !_ctx.isButtonDisabled, 'btn-secondary': _ctx.isButtonDisabled}]),
        disabled: _ctx.isButtonDisabled,
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
        onKeypress: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]), ["enter"]))
      }, " Update ", 42, _hoisted_4)
    ])
  ]))
}