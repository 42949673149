<template>
    <AlertsComponent :success="success" :info="info" :errors="errors" />
    <MessageCard :message="messageCardInfo" classes="info_card_full" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <MultiStep v-if="currentStep > 0" :steps=3 :activeStep="currentStep" classes="steps-wrapper_secondary"/>
       <form id="claim-submission-form" class="form claim-submission" :class="{ 'step-3': currentStep === 3 }"
            method="POST">
            <CoveredCard :links="links" :small="true" />
            <CertificationCondition v-if="currentStep === 0" v-model:agreement="agreement" />
            <ClaimStep1 v-if="currentStep === 1" :patients="patients" />
            <ClaimStep2 v-if="currentStep === 2" :loadingUpload="loadingUpload" :submitting="submitting"
                :deleting="deleting" :reset="reset" :notes="notes" @update:notes="notes = $event"
                @handle-upload="handleUpload" @handle-delete="handleDelete" />
            <ClaimStep3 v-if="currentStep === 3" />

            <div class="action-buttons sm-pdr-0 sm-pdl-0">
                <a href="" class="btn btn-secondary plan-modal" @click.prevent="handleBack"
                    :disabled="loadingUpload || submitting"> Back </a>
                <button class="btn btn-primary plan-modal" @click.prevent="handleNext"
                    :disabled="loadingUpload || submitting || !agreement">
                    {{ submitting ? 'Submitting' : (currentStep === 3 ? 'Submit' : 'Next') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, reactive, computed, defineComponent } from 'vue';
import { useClaimStore } from '@/stores/claim';
import { useModalStore } from '@/stores/modal';
import { Person, TableData, RowData } from '@/interfaces/interfaces';
import { Document } from '@/interfaces/health';
import axios from 'axios';

import CertificationCondition from '@/components/participant/claims/CertificationCondition.vue';
import ClaimStep1 from '@/components/participant/claims/ClaimStep1.vue';
import ClaimStep2 from '@/components/participant/claims/ClaimStep2.vue';
import ClaimStep3 from '@/components/participant/claims/ClaimStep3.vue';
import CoveredCard from '@components/participant/claims/CoveredCard.vue';
import MultiStep from "@components/form/MultiStep.vue";

export default defineComponent({
    props: {
        modalProps: Object,
    },
    components: {
        CertificationCondition,
        ClaimStep1,
        ClaimStep2,
        ClaimStep3,
        CoveredCard,
        MultiStep,
    },
    setup(props, { emit }) {
        const loading = ref(true);
        const loadingUpload = ref(false);
        const submitting = ref(false);
        const deleting = ref(false);
        const loadingSave = ref(false);
        const closeForm = ref(false);
        const agreement = ref(false);
        const claimStore = useClaimStore();
        const modalStore = useModalStore();
        const success = ref(null);
        const info = ref(null);
        const reset = ref(false);
        const errors: Ref<string[] | string | null> = ref(null);
        const person: Ref<Person | null> = ref(null);
        const uploadedDocuments: Ref<Document[]> = ref([]);
        const notes = ref(claimStore.claim?.Notes ?? '');
        const currentStep = ref(0);
        const defaultStepMessage = 'PLEASE NOTE: Due to the NBF Office closure effective January 4, 2021, in order to expedite your MRP Claims reimbursement payment / processing, please submit a completed MRP claims direct deposit form to claims@iatsenbf.org, if not already done. The form can be found under Heatlh and Welfare - Forms/Documents. You can also submit the updated direct deposit form online, in the section where you would normally submit a MRP claim or also email the form to psc@iatsenbf.org. This is currently the only way for us to send your payment in a more timely manner. Otherwise, your payment will be delayed.'
        const message="NOTE: If you presently do not have Direct Deposit setup for your Claims and if you wish to have your claim payment(s) directly deposited into your bank account, please submit a completed MRP claims direct deposit form to claims@iatsenbf.org. It may take up to two weeks for processing of your request as we wait for your bank to verify your banking information. Once we receive verification, we can then start to deposit your claims reimbursement payments directly to your bank account. The form can be found in the Applications/Forms/Documents link on the Fund website. You can also submit the completed direct deposit form online, in the section where you would normally submit an MRP claim. You can also email it to psc@iatsenbf.org."

        const messageCardInfo = computed(() => {
            switch (currentStep.value) {
                case 0:
                    return `WARNING: If any person makes a false or fraudulent statement in connection with a claim, including submitting false or fraudulent information or concealing a material fact, the Fund may take action to recover any amounts paid by the Fund (plus interest and costs) and take any other legal action as it deems appropriate. <br /> Please note that all claims for reimbursement must be received by the Fund within 12 months of the date of service or the date the premium is paid (in the case of a request for premium reimbursement). In addition, you (or your dependent, as applicable) must have been enrolled in the Plan C MRP option on the date of the service (or the date the premium was paid, as applicable) and at the time the reimbursement is submitted to the Fund.`;
                case 1:
                    return message + "<br/> <br />Please review patient selected for existing claim. Click the button on the bottom right to add a new dependent.<br />Reminder: Any new dependent recently added must be approved by the Fund Office before a claim can be submitted.";
                case 2:
                    return message + " <br/> <br />To complete your claim submission, you must upload all necessary documentation, as applicable, such as:a. another group health plan’s explanation of benefitsb. an itemized bill or receipt from the providerc. another group health plan’s premium statement along with your proof of payment such as a cancelled check (only premiums that you paid with post-tax money may be reimbursed)d. The claim number from your original claim if you are responding to an information request from the fundPlease note: The website supports the following common document types: '.PDF', '.JPG', '.PNG', '.BMP', '.TIFF', '.TIF', '.GIF'. It also supports some additional document types. Click here to view a comprehensive list of all supported document types. Document types not listed are unsupported and hence will not be processed." 
                case 3:
                    return message + "<br /> <br /> Reminder: Please review your mailing address and/or payment destination below. Please note: Updating mailing address in Step 2a will update your primary address on file with the Fund Office for all your correspondences. Updating direct deposit information in Step 2b will update the payment setup on file with the Fund Office for all your payments (Claims and other Benefits – if applicable).";
                default:
                    return "Please follow the steps carefully.";
            }
        });

        const patients: TableData = reactive({
            headers: [
                { title: 'Select' },
                { title: 'Name' },
                { title: 'DOB' },
                { title: 'Relationship' },
                { title: 'Eligibility Effective Date' },
                { title: 'Eligibility Term Date' },
            ],
            rows: [],
        })

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) {
                loading.value = true;
            }

            await claimStore.fetchPaymentMethods();

            await axios.get('api/participant/claims-new', { params: { ClaimID: props.modalProps?.ClaimID, Type: props.modalProps?.Type ?? 'add' } })
                .then(response => {
                    person.value = response.data.Person;
                    patients.rows = response.data.PatientsTable;
                    const existingClaim = response.data.ExistingClaim;
                    const defaultPatient = existingClaim ? existingClaim.PersonID : response.data.Person?.PersonID;

                    claimStore.setClaim({
                        ...existingClaim,
                        Type: props.modalProps?.Type ?? 'add',
                        ClaimID: props.modalProps?.ClaimID,
                    });

                    agreement.value = props.modalProps?.Type === 'webclaim' || props.modalProps?.Type === 'claim';

                    claimStore.setPatient(claimStore.claim.Patient ?? defaultPatient);
                })

            loading.value = false;
        };

        const handleSubmit = async () => {
            submitting.value = true;
            success.value = null;
            errors.value = [];
            claimStore.setClaim({
                ...claimStore.claim,
                Patient: claimStore.patient,
                Documents: claimStore.claim?.Documents?.filter(doc => !doc?.DocumentID) ?? null
                // Documents: claimStore.claim?.Documents && claimStore.claim?.Documents?.length > 0 ? claimStore.claim?.Documents : null
            });

            await axios.post('api/participant/claims-new', { ...claimStore.claim })
                .then(response => {
                    success.value = response.data.success;
                    info.value = response.data.info;

                    if (success.value) {
                        errors.value = [];
                        claimStore.fetchClaims();
                        claimStore.clearClaim();
                        emit('close');
                    }
                    else {
                        errors.value = response.data.error;
                    }


                })
                .catch(error => {
                    const res = error.response.data;

                    if (res.error) {
                        errors.value = res.error;
                    }
                    else {
                        errors.value = Object.values(res.errors).flat() as string[];
                    }
                })
                .finally(() => {
                    submitting.value = false;
                    setTimeout(() => {
                        errors.value = []
                        info.value = null
                    }, 5000)
                });
        };

        const handleUpload = async (files: FileList) => {
            loadingUpload.value = true;
            reset.value = false;
            const formData = new FormData();

            if (files.length) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                await axios
                    .post('api/participant/claims/add-doc', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                    .then(response => {
                        success.value = response.data.success;
                        claimStore.claim.Documents = claimStore.claim?.Documents ? [
                            ...claimStore.claim?.Documents,
                            ...(Array.isArray(response.data) ? response.data : [])
                        ] : response.data;
                        reset.value = true;
                    })
                    .catch(error => {
                        console.error('There was an error uploading document!', error);
                    })
                    .finally(() => setTimeout(() => {
                        success.value = null;
                    }, 3500));

                reset.value = true;
            }

            loadingUpload.value = false;
        };

        const handleDelete = async (fileHash: string) => {
            deleting.value = true;

            await axios.delete(`api/participant/claims/delete-file/${fileHash}`)
                .then(response => {
                    success.value = response.data.success;
                    const filteredDocs = JSON.parse(JSON.stringify(uploadedDocuments.value)).filter((doc: Document) => doc.FileHash !== fileHash);
                    uploadedDocuments.value = filteredDocs;
                })
                .catch(() => {
                    errors.value = 'There was an error deleting document!'
                });

            deleting.value = false;

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500)
        }

        const handleNext = () => {
            if (currentStep.value === 3) {
                handleSubmit();
            }
            else if (currentStep.value === 2 && uploadedDocuments.value.length === 0 && !claimStore.claim?.Documents?.length) {
                errors.value = 'Please upload at least one document';

                setTimeout(() => {
                    errors.value = [];
                }, 3500)
            }
            else {
                currentStep.value += 1;
            }
        }

        const handleBack = () => {
            if (currentStep.value === 0) {
                emit('close');
            }
            else {
                currentStep.value -= 1;
            }
        }

        onMounted(async () => await fetchData(true));

        return {
            loading,
            loadingUpload,
            submitting,
            deleting,
            person,
            patients,
            notes,
            success,
            info,
            errors,
            closeForm,
            agreement,
            loadingSave,
            currentStep,
            reset,
            links: claimStore.claimLinks,
            uploadedDocuments,
            messageCardInfo,
            handleNext,
            handleBack,
            handleSubmit,
            handleUpload,
            handleDelete,
            openModal: modalStore.openModal
        };
    }
});
</script>