<template>
    <div class="table-wrapper">
        <table class="small striped toggle no-underline mt-30" id="table-contract-info">
            <thead class="large large_padding">
                <tr>
                    <th class="w-5"></th>
                    <th>Member Name</th>
                    <th>Employer</th>
                    <th>Production</th>
                    <th>Work Period</th>
                    <th class="col-md-2">Fund</th>
                    <!-- <th>Amt. Due $ </th> -->
                    <th>Amt. Paid $ </th>
                </tr>
            </thead>
            <!-- Contributions -->
            <tbody v-if="!contributionsStore.contributions">
                <tr>
                    <td colspan="7" class="text-center"> {{ loading ? 'Loading...' : 'No records found' }}</td>
                </tr>
            </tbody>
            <tbody v-if="contributionsStore.contributions">
                <template v-for="({year, yearWorkHistory}) in sortedContributions" :key="year">
                <!-- <template v-for="(yearWorkHistory, year) in contributionsStore.contributions" :key="year"> -->
                    <template v-for="(entry, member) in yearWorkHistory" :key="member">
                        <tr class="large">
                            <td @click="toggleDetails(member)" class="plan-modal w-5">
                                {{ entry.showDetails ? ' - ' : ' + ' }}
                            </td>
                            <td>{{ entry?.MemberName }}</td>
                            <td>{{ entry?.Employer }}</td>
                            <td>{{ entry?.Production }}</td>
                            <td v-html="entry?.WorkPeriod"></td>
                            <td class="col-md-2">
                                <div class="w-100 d-flex flex-column gap-10" v-for="(amountPaid, fund) in entry?.Plan" :key="fund">
                                    <span>{{ fund }}</span>
                                </div>
                            </td>
                            <td>
                                <div class="w-100 d-flex flex-column gap-10" v-for="(amountPaid, fund) in entry?.Plan" :key="fund">
                                    <span>{{ formatNumber(amountPaid) }}</span>
                                </div>
                            </td>
                        </tr>
                        <tr></tr>
                        <!-- Contribution Details -->
                        <tr v-show="entry?.showDetails" class="showDetails grey-border">
                            <td colspan="10">
                                <table class="small striped toggle no-underline mt-10">
                                    <thead class="large large_padding">
                                        <tr class="bg-light-green">
                                            <th>Work Period</th>
                                            <th>Receipt Date</th>
                                            <th>Work Units</th>
                                            <th>Fund</th>
                                            <th>Amount Due $</th>
                                            <th>Amount Paid $</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="([receiptDate, item]) in Object.entries(entry?.Items)" :key="receiptDate"
                                            class="large">
                                            <td v-html="item?.WorkPeriod"></td>
                                            <td>{{ item?.ReceiptDate }} </td>
                                            <td>
                                                <i class="iatse-icon-clock fs-base position-relative plan-modal icon-tooltip icon-tooltip-info icon-tooltip_units fs-lg">
                                                    <span class="tooltip-text" >
                                                        <p v-for="(value, unit) in item?.WorkUnits" :key="unit">{{ unit }} - {{ value }} </p>
                                                    </span>
                                                </i>
                                            </td>
                                            <td>
                                                <div class="w-100 d-flex flex-column gap-10" v-for="(amountPaid, fund) in item?.Plan" :key="fund">
                                                    <div class="w-100 d-flex gap-20 mb-5">
                                                        <span>{{ fund }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-danger">
                                                <div class="w-100 d-flex flex-column gap-10" v-for="(amountPaid, fund) in item?.Plan" :key="fund">
                                                    <span>{{ formatNumber(amountPaid) }}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="w-100 d-flex flex-column gap-10" v-for="(amountPaid, fund) in item?.Plan" :key="fund">
                                                    <span>{{ formatNumber(amountPaid) }}</span>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="large">
                                            <td class="col-md-3" colspan="6">
                                                <div class="w-100 d-flex align-items-end flex-column gap-10" v-for="(amountPaid, fund) in entry?.Plan" :key="fund">
                                                    <div class="col-md-2 d-flex gap-20 mb-5 bg-fourth text-white pdl-10 pdr-10 pdt-10 pdb-10 rounded">
                                                        {{ fund }} : {{ formatNumber(amountPaid) }}
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </template>
                </template>
            </tbody>
        </table>
        <PaginationComponent v-if="!loading && contributionsStore.contributions && contributionsStore?.paginator" :paginator="contributionsStore?.paginator" @fetch-data="fetchPaginationData" />

        <!-- Grand Totals -->

        <div v-if="loadingTotals" class="w-100">
            <p class="text-center w-100 fs-sm"> Loading Totals...</p>
        </div>

        <template v-else>
            <template v-if="!loadingTotals && contributionsStore.totals?.Years">
            <div v-for="(totals, year) in contributionsStore.totals.Years" :key="year" class="mt-5 sm-gap-0 fs-sm bordered-card bg-light-green flex-column flex-md-row w-100 p-0">
                <span class="fw-bold p-4 col-md-2">Total for {{ year }}</span>
                <div class="divider divider_vertical divider_vertical_mobile"></div>
                <div class="totals-grid">
                    <div v-for="total, index in totals" :key="index" class="grid-item flex-md-row flex-column">
                    <span class="col-md-6">{{ total.FundName }}</span>
                    <span class="col-md-6">$ {{ formatNumber(total.FundAmount) }}</span>
                </div>
                </div>
            </div>
            </template>
            <div v-if="!loadingTotals && contributionsStore.totals?.GrandTotals" class="mt-5 fs-sm sm-gap-0 bordered-card bg-light-green w-100 flex-column flex-md-row p-0">
                <span class="fw-bold p-4 col-md-2">Grand Total</span>
                <div class="divider divider_vertical divider_vertical_mobile"></div>
                <div class="totals-grid">
                    <div v-for="total, index in contributionsStore.totals?.GrandTotals" :key="index" class="grid-item flex-md-row flex-column">
                        <span class="col-md-6">{{ total.FundName }}</span>
                        <span class="col-md-6">$ {{ formatNumber(total.Value) }}</span>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script lang="ts">
import { defineComponent, Ref, watch, ref, onMounted, computed } from 'vue';
import { History, GrandTotal, MemberWorkHistory } from '@/interfaces/local';
import { Paginator } from '@/interfaces/admin';
import { usePrint } from '@/composable/usePrint';
import { useNumberFormat } from '@/composable/useNumberFormat';
import { useContributionsStore } from '@/stores/contributions';
import PaginationComponent from '@components/utils/PaginationComponent.vue';

export default defineComponent({
    props: {
        loading: Boolean,
        loadingTotals: Boolean,
        pageChanged: Boolean,
        changePage: Boolean,
    },

    components: {
        PaginationComponent,
    },

    setup(props, { emit }) {
        const loadingContributions = ref(true);
        const contributionsStore = useContributionsStore();
        const grandTotal: Ref<GrandTotal | null> = ref(null);
        const history: Ref<MemberWorkHistory[]> = ref([]);
        const paginator: Ref<Paginator | null> = ref(null);
        const { printElementById } = usePrint();
        const { formatNumber } = useNumberFormat();

        const printList = printElementById('table-contribution-history', 'Contribution History');

        const sortedContributions = computed(() => {
            if (!contributionsStore.contributions) {
                return []; // Return an empty array if contributions is null
            }

            const sortedEntries = Object.entries(contributionsStore.contributions)
                    .sort(([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA)) // Descending order
                    .map(([year, yearWorkHistory]) => ({ year, yearWorkHistory }));

            return sortedEntries;
        })

        const toggleDetails = (payload: string) => {
            contributionsStore.toggleDetails(payload)
        }

        const resetFilters = () => {
            emit('reset-filters');
        }

        const fetchPaginationData = (pageNumber: number) => {
            emit('fetch-pagination-data', pageNumber);
        }

        const fetchData = () => {
            paginator.value = contributionsStore.getPaginator;

            setTimeout(() => loadingContributions.value = false, 1500)
        }

        watch(() => props.loadingTotals, (loadingTotals) => {
            if (!loadingTotals) {
                grandTotal.value = contributionsStore.fetchTotals;
            }
        });

        watch(() => props.pageChanged, (pageChanged) => {
            if (pageChanged) {
                fetchData();
            }
        });

        onMounted(() => fetchData())

        return {
            history,
            paginator,
            grandTotal,
            loadingContributions,
            contributionsStore,
            sortedContributions,
            printList,
            formatNumber,
            resetFilters,
            toggleDetails,
            fetchPaginationData
        }
    }
})

</script>