<template>
    <FundPanel cardId="card-annuity" v-if="data.ENABLED">
        <template #icon>
            <i class="iatse-icon-fund-annuity"></i>
        </template>
        <template #title>
            Annuity
        </template>
        <template #fund-cover>
            <div class="col-md-9">
                <div class="fund-cover fund-cover__annuity d-flex align-items-start flex-column">
                    <div class="fund-cover-header w-100">
                        <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-dollar-square"></i>
                                <p class="links"><strong>Principal Balance</strong></p>
                            </div>
                            <p class=""><strong>{{ loading ? '...' : balanceAmount }}</strong> <small>
                                    {{  loading ? '' :'(as of ' + principalBalance?.accountEffectiveDate  + ')' }}</small></p>
                        </div>
                        <div class="divider"> </div>
                        <!-- <div class="coverage-column">
                            <div class="fund-cover-row">
                                <i class="iatse-icon-info-circle"></i>
                                <p class="links">Note</p>
                            </div>
                            <h6 class="mt-10">Click to access your Principal Account details and payments</h6>
                        </div> -->
                    </div>
                    <div class="fund-cover-buttons">
                        <a href="" class="btn btn-primary plan-modal"
                            @click.prevent="openModal('work-history', 'Annuity Work History', null, 'annuity', null, null, undefined, { Funds: [5, 7, 8]})">Work
                            History
                        </a>
                        <a href="https://www.principal.com/" target="_blank" class="btn btn-secondary">
                            <i class="iatse-icon-principal"></i>
                            <span>Principal Login</span>
                        </a>
                    </div>
                </div>
            </div>
        </template>
        <template #related-links>
            <a href="" @click.prevent="openModal('annuity-application', 'Annuity Application - Eligibility Determination Request', 'plan-form')"
                class="btn btn-tertiary btn-large plan-modal">
                Annuity Application</a>
            <a :href="data.SalaryDeferralCoupon" target="_blank" class="btn btn-tertiary btn-large">Salary
                Deferral Coupon</a>
            <a href="" class=" btn btn-tertiary btn-large plan-modal"
                @click.prevent="openModal(
                    'add-beneficiary',
                    'My Beneficiary(ies)',
                    'plan-tables-striped beneficiary',
                    null,
                    null,
                    null,
                )">
                Beneficiary(ies) </a>
            <a href="" @click.prevent="openModal('plan-resources', 'Annuity Fund Resources', null, 'annuity')" class="btn btn-tertiary btn-large plan-modal">Fund's Resources</a>
        </template>
    </FundPanel>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue';
import { AnnuityDashboardData, PrincipalBalance } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useDateFormatter } from '@/utils/dateFormatter';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import FundPanel from '@/components/cards/FundPanel.vue';
import axios from 'axios';

export default defineComponent({
    props: {
        data: {
            type: Object as () => AnnuityDashboardData,
            required: true
        }
    },
    components: {
        FundPanel
    },

    setup(props) {
        const loading = ref(true);
        const modalStore = useModalStore();
        const principalBalance = ref<PrincipalBalance|null>(null);
        const visibleContent = ref(true);
        const { formatCurrency } = useCurrencyFormatter();
        const balanceAmount = computed(() => Number(principalBalance.value?.accountBalance) ? formatCurrency(principalBalance.value?.accountBalance as number) : formatCurrency(0))

        onMounted(async () => {
            try {
                const response = await axios.get('api/participant/annuity/balance');
                principalBalance.value = response.data;
            } catch (error) {
                console.error('Error fetching data:', error);
            }

            loading.value = false;
        });

        const toggleVisible = () => {
            visibleContent.value = !visibleContent.value;
        }

        return {
            loading,
            principalBalance,
            visibleContent,
            balanceAmount,
            formatCurrency,
            toggleVisible,
            useDateFormatter,
            openModal: modalStore.openModal
        };
    }
});
</script>