<template>
    <MessageCard
        message="<h2 class='text-medium-green'> Coming Soon </h2>  <br /> To add or change an existing beneficiary or to assign which Fund applies to such beneficiary(ies) please click on the links below to download the applicable beneficiary form for each Fund. Please complete the form(s) and return to the Fund Office via email to psc@iatsenbf.org or you can fax it to us at 212-730-7706."
        type="primary" iconClass="iatse-icon-alarm" classes="info_card_full"
    />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <div v-for="link, key in forms" :key="key" class="bordered-card">
            <a :href="link" target="_blank">{{ key }}</a>
        </div>

        <!-- Documents Uploaded Table -->
        <div class="bordered-card bordered-card_secondary w-100 mb-60" v-if="beneStore.documents?.length">
            <p class="mb-4 sm-fs-xs">DOCUMENTS CURRENTLY ON FILE/PENDING ADMIN PREVIEW</p>
            <table class="small mb-4">
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Beneficiary</th>
                        <th>Date</th>
                        <th>Status</th>
                        <!-- <th>Action</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(document, index) in beneStore.documents" :key="index">
                        <td>
                            <a href="javascript;"
                                @click.prevent="openModal('smartsearch', null, 'modal-success', null, { DocumentID: document.DocumentID })">
                                {{ document?.Description }}
                            </a>
                        </td>
                        <td>{{ document.Beneficiary }}</td>
                        <td>{{ document.Date }}</td>
                        <td :class="{ 'text-danger': document.Status === 'Pending' }">
                            {{ document.Status }}
                        </td>
                        <!-- <td v-if="document.Status === 'Pending'">
                            <i class="iatse-icon-trash plan-modal"
                                @click="handleDeleteDoc(document.DocumentID)"></i>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, Ref, onMounted, computed, defineComponent, watch } from 'vue';
import { SelectOption } from "@/interfaces/interfaces";
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { useParticipantStore } from '@/stores/participant';
import DragDrop from '@components/form/DragDrop.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import axios from 'axios';
import LoaderComponent from '@/components/utils/LoaderComponent.vue';

export default defineComponent({
    // components: {
    //     DragDrop,
    //     SelectComponent
    // },
    setup() {
        const modalStore = useModalStore();
        const beneStore = useBeneficiaryStore();
        const loadingUpload = ref(false);
        const loading = ref(true);
        const reset = ref(false);
        const error: Ref<string | null> = ref(null);
        const success = ref('');
        const isEditAction = computed(() => beneStore.isEditAction || modalStore.currentModal?.title === 'Edit Beneficiary');
        const newBene = computed(() => beneStore.newBeneficiary);
        const formData = new FormData();
        const documentTypes: Ref<SelectOption[] | null> = ref(beneStore.documentTypes);
        const selectedDocType: Ref<SelectOption> = ref({ key: 0, value: '-Select Document Type-' });

        const openModal = modalStore.openModal;

        const forms = {
            "Health & Welfare Fund - Beneficiary Designation Form": "https://iatse-public.staging.polardesign.com/storage/app/media/forms/Health-and-Welfare-Beneficiary-Form.pdf",
            "Annuity Fund - Beneficiary Designation Form": "https://iatse-public.staging.polardesign.com/storage/app/media/forms/IA-Annuity-Beneficiary-Designation-Form-12.2.19-revised-2022-08-clean.pdf",
            "Pension Fund - Beneficiary Designation Form": "https://iatse-public.staging.polardesign.com/storage/app/media/forms/IA-Pension-Beneficiary-Form-with-Instructions-07012020.pdf",
            "Vacation Fund - Beneficiary Designation Form": "https://iatse-public.staging.polardesign.com/storage/app/media/forms/Vacation-Fund-Beneficiary-Form.pdf",
        };

        watch(() => selectedDocType.value, (newValue) => {
            if(newValue?.key) {
                error.value = null;
            }
        })

        const preventUpload = ref(false);

        const preventFileUpload = () => {
            preventUpload.value = true;
        }

        const allowFileUpload = () => {
            preventUpload.value = false;
        }

        const handleDeleteDoc = async (docId: number | string) => {
            try {
                const response = await axios.delete(`/api/smartsearch/beneficiary-file/${docId}`);
                if (response.status === 200) {
                    newBene.value.Documents = newBene.value.Documents?.filter((doc: any) => doc.DocumentID !== docId);
                } else {
                    console.error('Failed to delete the document');
                }
            } catch (error) {
                console.error('An error occurred while deleting the document:', error);
            }
        }

        const handleDrop = (files: File[]) => {
            loadingUpload.value = true;
            console.log('prevent', preventUpload.value);

            if(!preventUpload.value) {
                uploadFiles(files)
            }

            preventUpload.value = false;
            loadingUpload.value = false;
        }

        const handleFileSelection = async (event: Event) => {
            loadingUpload.value = true;

            if(!preventUpload.value) {
                const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

                if(files) {
                    uploadFiles(files);
                }
            }

            preventUpload.value = false;
            loadingUpload.value = false;
        };

        const uploadFiles = async (files: File[] | FileList) => {
            const formData = new FormData();
            reset.value = false;

            if (!selectedDocType.value.key) {
                error.value = 'The selected document type is invalid.';
                loadingUpload.value = false;
                reset.value = true;

                return;
            }

            formData.append('DocumentType', String(selectedDocType.value?.key));
            formData.append('BeneficiaryID', String(newBene.value.BeneficiaryID));

            if (files) {
                for (let i = 0; i < files.length; i++) {
                    formData.append('Files[]', files[i]);
                }

                await beneStore.uploadFiles(formData);

                reset.value = true;
                selectedDocType.value = { key: 0, value: '-Select Document Type-' };
            }
        };

        onMounted(async () => {
            loading.value = true;
            useParticipantStore().currentStep = 1;
            await beneStore.fetchBeneDocuments();
            loading.value = false;
        });

        return {
            loading,
            forms,
            reset,
            newBene,
            beneStore,
            loadingUpload,
            success,
            error,
            selectedDocType,
            isEditAction,
            documentTypes,
            openModal,
            handleDeleteDoc,
            handleFileSelection,
            preventFileUpload,
            handleDrop,
            allowFileUpload
        };
    }
})
</script>