<template>
    <AlertsComponent :errors="generalErrors" />
    <header>
        <div class="container mt-35 sm-mt-0 sm-p-0 logo-header">
            <div class="row">
                <!-- Logo Section -->
                <a href="https://iatse-public.staging.polardesign.com/en/" target="_blank" class="logo-wrapper tooltip-wrapper tooltip-wrapper_tertiary col-md-4 d-flex justify-content-end order-1 order-md-2 mb-20 plan-modal" data-tooltip="Visit IATSE National Benefits Fund">
                    <div class="w-fit w-sm-100 bg-primary rounded px-4 py-3 h-fit rounded-none">
                        <figure>
                            <img src="@/assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo" />
                        </figure>
                    </div>
                </a>

                <div class="col-md-8 order-2 order-md-1">
                    <h2 class="text-center text-lg-start">Choose an entity from below to create an account.</h2>
                    <div class="d-flex gap-20 mt-30">
                        <div class="radio-group primary bold">
                            <div class="radio-buttons gap-20 w-100 flex-column flex-md-row">
                                <div class="radio-button enlarged h-100 bordered-card bordered-card_secondary bg-light-green w-100 align-items-center">
                                    <input type="radio" id="participant"  name="UserType" v-model="selectedUserType" :value="1" />
                                    <label class="body-text" for="participant" >
                                        Participants
                                    </label>
                                </div>
                                <div class="radio-button enlarged h-100 bordered-card bordered-card_secondary bg-light-green w-100 align-items-center">
                                    <input type="radio" id="local"  name="UserType" v-model="selectedUserType" :value="5" />
                                    <label class="body-text" for="local" >
                                        Locals
                                    </label>
                                </div>
                                <div class="radio-button enlarged h-100 bordered-card bordered-card_secondary bg-light-green w-100 align-items-center">
                                    <input type="radio" id="employer"  name="UserType"  v-model="selectedUserType" value="6" />
                                    <label class="body-text" for="employer" >
                                        Employers
                                    </label>
                                </div>
                                <div class="radio-button enlarged h-100 bordered-card bordered-card_secondary bg-light-green w-100 align-items-center">
                                    <input type="radio" id="payroll_company"  name="UserType" v-model="selectedUserType" value="7" />
                                    <label class="body-text" for="payroll_company" >
                                        Payroll Company<br/>
                                        <small class="sm-fs-xxs">(Paying Agent)</small>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="bg-sixth mt-35 mb-50">
        <div class="container">
            <div class="row">
                <div class="col-md-12 sm-mb-20">
                    <LocalOptions v-if="selectedUserType != 1" :selectedUserType="selectedUserType" :registerType="registerType" @change-type="handleTypeChange" />
                </div>
            </div>
            <div class="row mt-40 sm-mt-0">
                <form class="col-md-6">
                    <ParticipantFields v-if="selectedUserType === 1" :securityQuestions="securityQuestions" :errors="errors"
                        :loadingSubmit="loadingSubmit" @register="submitForm" @reset-errors="resetErrrors">
                    </ParticipantFields>
                    <LocalFields v-else-if="selectedUserType == 5 || selectedUserType == 3"
                        :securityQuestions="securityQuestions" :states="states" @register="submitForm" @reset-errors="resetErrrors"
                        :loadingSubmit="loadingSubmit" :localRequest="localRequest" :authRequest="authRequest" :errors="errors"
                        :registerType="registerType" :success="success" />
                    <EmployerFields v-else-if="selectedUserType == 6 || selectedUserType == 7"
                        :securityQuestions="securityQuestions" :employerRequest="employerRequest" @reset-errors="resetErrrors"
                        :employerType="employerType" :states="states" :loadingSubmit="loadingSubmit"
                        @register="submitForm" :errors="errors" :success="success"/>
                    <p class="mt-15 fs-sm text-center pdb-20">
                        <span>Already have an Account?</span>
                        <router-link :to="{ name: 'login' }"> Log in</router-link>
                    </p>
                </form>
                <div class="col-md-6 hide-mobile">
                    <img style="max-width: 100%; max-height: 90vh;" class="rounded img-fluid" src="@/assets/images/2fa-picture.jpg" alt="2FA Image" />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref, watch } from 'vue';
import { ILocalAuth, SelectOption } from '@/interfaces/interfaces';
import { PendingLocal, PendingEmployer } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRoute, useRouter } from 'vue-router';
import { useOptionsFormatter } from "@utils/optionFormatter";
import ParticipantFields from '@/components/register/ParticipantFields.vue';
import EmployerFields from '@/components/register/employer/EmployerFields.vue';
import LocalOptions from '@/components/register/local/LocalOptions.vue';
import LocalFields from '@/components/register/local/LocalFields.vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';

export default {
    components: {
        ParticipantFields,
        EmployerFields,
        LocalFields,
        LocalOptions,
    },
    setup() {
        const selectedUserType: Ref<string | number> = ref(1);
        const errors: Ref<string[]> = ref([]);
        const generalErrors: Ref<string[]> = ref([]);
        const securityQuestions: Ref<SelectOption[]> = ref([]);
        const localRequest: Ref<PendingLocal | null> = ref(null);
        const authRequest: Ref<ILocalAuth | null> = ref(null);
        const states: Ref<SelectOption[]> = ref([]);
        const employerRequest: Ref<PendingEmployer | null> = ref(null);
        const registerType: Ref<string> = ref('auth');
        const employerType: Ref<string> = ref('auth');
        const success = ref(null);
        const authStore = useAuthStore();
        const router = useRouter();
        const hash = useRoute().query.Hash;
        const loadingSubmit = ref(false);
        const localRole = ref('local');

        watch(selectedUserType, () => errors.value = []);
        watch(registerType, () => errors.value = []);
        watch(employerType, () => errors.value = []);

        const handleTypeChange = (payload: string, type: string) => {
            if(type === 'local') {
                registerType.value = payload;
            } else {
                employerType.value = payload;
            }
        }

        const resetErrrors = () => {
            errors.value = [];
        }

        // Fetch Data
        const fetchData = async () => {
            await axios.get('api/register', { params: { Hash: hash, RequestID: useRoute()?.query?.RequestID ?? null } })
                .then(response => {
                    securityQuestions.value = useOptionsFormatter(response.data.SecurityQuestions);
                    localRequest.value = response.data.UILocalRequest;
                    authRequest.value = response.data.AuthRequest;
                    registerType.value = response.data.RegisterType;
                    employerType.value = response.data.RegisterType;
                    employerRequest.value = response.data.UIEmployerRequest;
                    states.value = useOptionsFormatter(response.data.States);
                    selectedUserType.value = response.data.UserType == 3 ? 5 : response.data.UserType;
                })
        };

        // Function to submit the form
        const submitForm = async (user: any) => {
            loadingSubmit.value = true;
            let userType = selectedUserType.value;

            if(userType == 5) {
                userType = registerType.value == 'rep_register' ? 3 : 5;
            }

            try {
                const formData = {
                    UserType: userType,
                    EmployerType: employerType.value ?? null,
                    LocalType: registerType.value ?? null,
                    AuthRequest: authRequest.value?.UILocalRequestID ?? null,
                    User: user
                };

                const response = await axios.post('api/register', { ...formData });
                success.value = response.data.success;
                const route = response.data?.route;
                const role = response.data?.role;
                errors.value = [];
                user.value = null;

                if(success.value && !route) {
                    useModalStore().openModal('success-modal', null, 'modal-success', null, {
                        title: registerType.value === 'auth' ? 'Auth Request' : 'Register Request',
                        content: {
                            type: "text",
                            value: response.data.success,
                            icon: 'iatse-icon-user-verified'
                        },
                    })
                }

                if (route) {
                    const credentials = { email: user.Email, password: user.Password, role: role };

                    setTimeout(async () => {
                        await authStore.authenticateUser(credentials);
                        // await authStore.loginUser();

                    }, 500);
                }
            } catch (error: any) {
                if (error.response.data.error) {
                    errors.value = error.response.data.error;
                    generalErrors.value = error.response.data.error?.General ? Object.values(
                        error.response.data.error?.General
                    ).flat() as string[] : [];
                }
            }

            loadingSubmit.value = false;

            setTimeout(() => {
                success.value = null;
                generalErrors.value = [];
            }, 5000);
        };

        onMounted(async () => {
            authStore.clearUserStore();
            authStore.logoutWithoutPush();
            await fetchData()
        });

        return {
            errors,
            generalErrors,
            success,
            selectedUserType,
            securityQuestions,
            registerType,
            localRequest,
            authRequest,
            states,
            localRole,
            employerType,
            employerRequest,
            loadingSubmit,
            submitForm,
            resetErrrors,
            handleTypeChange
        };
    },
};
</script>