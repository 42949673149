<template>
    <Alerts :error="error" />
    <!-- Documents Uploaded Table -->
    <div class="plan-content">
        <div class="table-wrapper">
            <h4> Documents on File/Pending Admin Review </h4>
            <DataTable v-if="uploadedDocs.rows?.length" :table="uploadedDocs" classes="striped small" />
        </div>

        <!-- Form -->
        <form id="update-personal-info-form" class="form update-personal-info" method="post" v-if="!existingPersonID">
            <div class="d-flex gap-40 full-width align-items-end flex-column flex-md-row">
                <SelectComponent label="Document Type" v-model:selectedOption="selectedDocType"
                    :options=documentTypes />
                <UploadFile label="Upload Files" :multiple="false" @change="handleFileSelection" :disabled="loading"
                    :reset="reset" @stop="preventFileUpload" />
            </div>
            <DragDrop customClasses="grey large w-10 full-width" @change="handleFileDrop" @stop="preventFileUpload" :reset="reset" key="drag-drop-personal-info" :loading="loading" />

            <div class="action-buttons pdt-50 sm-pdr-0 sm-pdl-0 sm-pdt-20">
                <button type="button" class="btn btn-secondary" @click="handleStep1">
                    Back
                </button>
                <button type="button" class="btn btn-primary" :disabled="loadingSubmit" @click.prevent="handleSubmit">
                    {{ loadingSubmit ? 'Saving...' : 'Save' }}
                </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, watch } from 'vue';
import { SelectOption } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import axios from 'axios';

import SelectComponent from '@components/form/SelectComponent.vue';
import DataTable from '@components/form/DataTable.vue';
import UploadFile from '@components/form/UploadFile.vue';
import DragDrop from '@components/form/DragDrop.vue';
import Alerts from '@components/utils/AlertsComponent.vue';

export default defineComponent({
    props: {
        loadingSubmit: Boolean
    },
    components: {
        SelectComponent,
        DataTable,
        UploadFile,
        DragDrop,
        Alerts
    },
    setup(_, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const selectedDocType: Ref<SelectOption> = ref({ key: 0, value: '-Select Document Type-' });
        const profileStore = useProfileStore();
        const uploadedDocs = ref({
            headers: [
                { title: 'Type' },
                { title: 'Date' },
                { title: 'Status' }
            ],
            rows: profileStore.documents,
        });
        const documentTypes: Ref<SelectOption[] | null> = ref(profileStore.documentTypes);
        const fileNames = ref('');
        const fileInput = ref<HTMLInputElement | null>(null);
        let formData = new FormData();
        const error: Ref<string | null> = ref(null);
        const loading = ref(false);
        const reset = ref(false);
        const preventUpload = ref(false);

        const preventDefault = (ev: Event) => {
            ev.preventDefault();
        }

        const browseFiles = () => {
            fileInput.value?.click();
        }

        const handleSubmit = () => {
            emit('submit');
        }

        const handleStep1 = () => {
            emit("check-data", false);
        }

        watch(error, (newValue) => {
            if (newValue) {
                setTimeout(() => {
                    error.value = '';
                }, 3000);
            }
        })

        const checkType = () => {
            loading.value = true;
            const type: string = selectedDocType.value.key as string;

            if (!type || preventUpload.value) {
                error.value = preventUpload.value ? '' : 'You must select a document type.';
                loading.value = false;
                reset.value = true;
                preventUpload.value = false;

                return false;
            }

            return true;
        }

        const preventFileUpload = () => {
            preventUpload.value = true;
        }

        const handleFileDrop = async (event: Event) => {
            reset.value = false;

            if (checkType()) {
                formData = new FormData();
                const type: string = selectedDocType.value.key as string;
                formData.append('DocumentType', type);
                const files = (event.target as HTMLInputElement)?.files || (event as DragEvent)?.dataTransfer?.files;

                if (files) {
                    await uploadFiles(files);
                }
            }

            loading.value = false;
        };

        const handleFileSelection = async (event: Event) => {
            reset.value = false;

            if (checkType()) {
                formData = new FormData();
                const type: string = selectedDocType.value.key as string;
                formData.append('DocumentType', type);
                const files = (event.target as HTMLInputElement)?.files;

                if (files) {
                    await uploadFiles(files);
                }
            }

            loading.value = false;
        };

        // Upload Files in Storage
        const uploadFiles = async (files: FileList) => {
            for (let i = 0; i < files.length; i++) {
                formData.append('Files[]', files[i]);
            }

            await axios.post('api/smartsearch/personal-file', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(response => {
                    response.data.Rows.forEach((row: SelectOption) => {
                        profileStore.documents.push(row);
                    })

                    if (profileStore.personalData) {
                        profileStore.personalData.UploadedDocs = true;
                    }

                    reset.value = true;
                    selectedDocType.value = { key: 0, value: '-Select Document Type-' };
                })
                .catch(error => {
                    error.value = 'There was an error uploading document!';
                    reset.value = true;

                    console.error('There was an error uploading document!', error);
                });
        }

        return {
            existingPersonID: false,
            selectedDocType,
            documentTypes,
            fileNames,
            uploadedDocs,
            fileInput,
            error,
            loading,
            reset,
            preventDefault,
            preventFileUpload,
            browseFiles,
            handleFileSelection,
            handleFileDrop,
            handleSubmit,
            handleStep1,
        };
    },
});
</script>