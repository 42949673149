<template>
    <Alerts :error="localErrors?.General" :success="success" />
    <MessageCard message="If you wish to change your password, email or security question, please do    so in the corresponding boxes below." type="primary" iconClass="iatse-icon-alarm"
        classes="info_card_full" />

    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- Update Email -->
        <UpdateEmail />

        <!-- Update Phone -->
        <UpdatePhone />

        <!-- Update Password -->
        <UpdatePassword />

        <!-- 2 Factor Authentication -->
        <form id="two-fa-form">
            <p>Preferred Method</p>
            <div class="radio-group primary bold">
                <div class="radio-buttons align-items-start flex-md-row flex-column">
                    <div
                        class="radio-button flex-column w-fit align-items-start rounded"
                        :class="{ 'bg-white': preferredMethod == 'P' }"
                        >
                        <label for="participant-phone" class="d-flex pdr-15">
                        <input
                            type="radio"
                            id="participant-phone"
                            name="method"
                            v-model="preferredMethod"
                            class="pdr-5"
                            value="P"
                            :disabled="!userPhone"
                            @change="updatePreferredMethod('P')"
                        /><span class="pdl-10">Mobile Phone</span></label>
                        <template v-if="preferredMethod == 'P' && !loadingPreferred">
                            <small class="pdt-10">Phone number for Authentication</small>
                            <p class="fw-medium pdt-10">{{ phone }} <i class="iatse-icon-edit ml-10 fw-bold plan-modal"  @click.prevent="updatePhone"></i></p>
                        </template>
                    </div>
                    <div
                        class="radio-button flex-column w-fit align-items-start rounded"
                        :class="{ 'bg-white': preferredMethod == 'E' }"
                        >
                        <label for="participant-email" class="d-flex">
                            <input
                                type="radio"
                                id="participant-email"
                                name="method"
                                v-model="preferredMethod"
                                class="pdr-5"
                                value="E"
                                @change="updatePreferredMethod('E')"
                            />
                            <span class="pdl-10">Email Code</span>
                        </label>
                        <template v-if="preferredMethod == 'E' && !loadingPreferred">
                            <small class="pdt-10">Email Address for Authentication</small>
                            <p class="fw-medium pdt-10">{{ userEmailSet }} <i class="iatse-icon-edit ml-10 fw-bold plan-modal" @click.prevent="updateEmail"></i></p>
                        </template>
                    </div>
                </div>
            </div>
            <small v-if="!phone">Please add your phone number above to enable this option.</small>
        </form>

        <!-- Update Security Questions -->
        <form id="security-questions-form " class="form account-settings" @submit.prevent="updateSecurityQuestion">
            <h4> Security Questions </h4>
            <template v-if="selectedQuestions.length">
                <SecurityQuestionSelect
                    label="Security Question"
                    :index=1
                    :userQuestion="userQuestions[0]"
                    :selected-question="selectedQuestions[0]"
                    :questionId="userQuestions[0]?.ID"
                    :resetInputs="resetInputs"
                    :options="securityQuestionsArray"
                    :errors="localErrors"
                    :showAddNewQuestion="showAddNewQuestion"
                    @handle-questions="handleQuestions"
                    @toggle-edit="editQuestion"
                    @delete="removeQuestion" />
            </template>

            <div class="w-full sm-pdb-40" v-if="!selectedQuestions.length">
                <a href="" @click.prevent="addNewQuestion" class="btn btn-secondary add-btn">
                    <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Security Question
                </a>
            </div>

            <div class="d-flex w-full gap-2 sm-pdb-20 flex-row justify-content-md-start justify-content-end" v-if="hasEditable">
                <button type="submit" class="btn btn-primary action-btn"> Update </button>
                <a href="" @click.prevent="discardChanges" class="btn btn-secondary action-btn"> Discard </a>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import { useVerificationCode } from '@/composable/useVerificationCode';
import axios from 'axios';

import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import Alerts from '@components/utils/AlertsComponent.vue';
import UpdateEmail from '../UpdateEmail.vue';
import UpdatePhone from '../UpdatePhone.vue';
import UpdatePassword from '../UpdatePassword.vue';

type SettingsState = {
    password: boolean;
    securityQuestion?: boolean;
    twoFactorAuth?: boolean;
    newPhone: boolean;
    email?: boolean;
};

type SettingType = keyof SettingsState;

export default {
    components: {
        SecurityQuestionSelect,
        UpdatePassword,
        MessageCard,
        Alerts,
        UpdateEmail,
        UpdatePhone,
    },
    setup() {
        const openModal = useModalStore().openModal;
        const securityQuestions: Ref<SecurityQuestion[]> = ref([]);
        const securityQuestionsArray: Ref<Array<{ key: number, value: string }>> = ref([]);
        const userQuestions: Ref<SecurityQuestion[]> = ref([]);
        const showAddNewQuestion = ref(false);
        const localErrors = ref<Record<string, string>>({General: ''});
        const success = ref('');
        const userPhone = ref('');
        const selectedQuestions: Ref<SelectedQuestion[]> = ref([]);
        const loading = ref(true);
        const loadingPreferred = ref(false);
        const resetInputs = ref(false);
        const userEmail = ref(useParticipantStore().userEmail);
        const userEmailSet = computed(() => useParticipantStore().userEmail);
        const phone = computed(() => useParticipantStore().userPhone);
        const preferredMethod = computed({
            get: () => useParticipantStore().userTwoFaMethod,
            set: (value) => {
                useParticipantStore().userTwoFaMethod = value;
            },
        });
        const oldPreferredMethod = computed(() => useParticipantStore().oldPreferredMethod);
        const updateSettingsState = ref<SettingsState>({
            password: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newPhone: false
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newPhone: false
        });

        const hasEditable = computed(() => Object.values(selectedQuestions.value).some(question => question.IsEditable === true));

        const discardChanges = () => {
            resetInputs.value = true;
            console.log('>>>here')

            selectedQuestions.value = selectedQuestions.value.map((question: SelectedQuestion) => {
                if (question.IsEditable) {

                    if(question.ExistingQuestion) {
                        const existingQuestion = userQuestions.value.find(q => q.SecurityQuestionID === question.Question)

                        return {
                            ...question,
                            Answer: "",
                            Question: existingQuestion?.SecurityQuestionID ?? 0,
                            IsEditable: true,
                        };
                    }

                    return {
                        ...question,
                        Answer: "",
                        Question: 0,
                        IsEditable: true,
                    };
                }


                return question;
            });
        }

        const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return securityQuestions.value?.filter(q => {
                return !Object.entries(selectedQuestionsId.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.SecurityQuestionID as number);
            });
        })

        const editQuestion = (index: number) => {
            showAddNewQuestion.value = false;

            (selectedQuestions.value[index] as SelectedQuestion).IsEditable = !(selectedQuestions.value[index] as SelectedQuestion)?.IsEditable;
        }

        // Handle Security Questions Changes
        const handleQuestions = (payload: SelectedQuestion, index: number) => {
            resetInputs.value = false;
        }

        const addNewQuestion = () => {
            showAddNewQuestion.value = true;

            const questionObj: SelectedQuestion = {
                Answer: "",
                ExistingQuestion: 0,
                Question: 0,
                IsEditable: true,
            }

            selectedQuestions.value.push(questionObj);
        }

        // Get Security Questions
        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/user/settings')
                .then(response => {
                    securityQuestions.value = response.data.SecurityQuestions;
                    securityQuestionsArray.value = Object.entries(response.data.SecurityQuestionsArray).map(([key, value]) => ({
                        key: Number(key),
                        value: String(value)
                    }));
                    userPhone.value = response.data.Phone;

                    if (response.data.UserSecurityQuestions.length > 0) {
                        response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
                            selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
                        });
                    }

                    const updatedUserQuestions: SelectedQuestion[] = [];
                    userQuestions.value = response.data.UserSecurityQuestions;
                    userQuestions.value.map((question: SecurityQuestion) => {
                        const questionObj: SelectedQuestion = {
                            Answer: "",
                            ExistingQuestion: question.ID,
                            Question: question.SecurityQuestionID as number,
                            IsEditable: false,
                        }

                        updatedUserQuestions.push(questionObj);
                    });

                    selectedQuestions.value = updatedUserQuestions;
                })

            if(!useParticipantStore().userEmail || !useParticipantStore().userTwoFaMethod || !useParticipantStore().userPhone) {
                await useParticipantStore().getUserEmail();
                userEmail.value = useParticipantStore().userEmail;
            }

            if (isLoading) {
                loading.value = false;
            }
        };

        const removeQuestion = async(id: number) => {
            await axios.delete(`api/user/settings-remove-question/${id}`)
                .then(response => {
                    success.value = response.data.success;
                })
                .catch((error: any) => {
                    if (error.response) {
                        localErrors.value = error.response.data.errors;
                    }
                })

            if(success.value) {
                await fetchData(false);
            }
        }

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            loadingUpdate.value[settingType] = true;

            await axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;
                    fetchData(false);

                    setTimeout(() => {
                        success.value = '';
                        localErrors.value = {General: ''};
                    }, 3500);

                    updateSettingsState.value[settingType] = false;
                })
                .catch((error: any) => {
                    if (error.response) {
                        localErrors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    loadingUpdate.value[settingType] = false;
                });
        };

        // Email form submit
        const updateEmail = () => {
            openModal(
                'update-email',
                'Update Email',
                'modal-success',
                null,
                { Type: 'modal'},
                undefined,
                undefined,
                );
        };

        // Phone form submit
        const updatePhone = () => {
            useModalStore().openModal(
                'update-phone',
                'Update Phone',
                'modal-success',
                null,
                { Type: 'modal'},
                undefined,
                undefined,
                );
        };

        // Security Question form submit
        const updateSecurityQuestion = () => {
            const formData = {
                security_questions: selectedQuestions.value,
            };

            updateSettings('securityQuestion', formData);
        };

       // Set 2 Factor Authentication
       const updatePreferredMethod = async (method: string) => {
            if (method !== oldPreferredMethod.value) {
                const { errors, submitCode } = useVerificationCode(method, 3);

                try {
                    loadingPreferred.value = true;
                    await submitCode(true);
                    await useParticipantStore().getUserEmail();

                    if (!errors.value) {
                        useModalStore().openModal(
                            "success-modal",
                            null,
                            "modal-success",
                            null,
                            {
                                title: "Preferred method changed successfully",
                                content: {
                                    type: "text",
                                    value: 'Your preferred method has been successfully updated, all upcoming codes will be sent to your new method',
                                    icon: "iatse-icon-user-verified"
                                },
                                onConfirmButton: "Close",
                                onConfirm: () => {
                                    useModalStore().closeAbove(0);
                                }
                            }
                        );
                    } else {
                        localErrors.value.General = errors.value;
                    }
                } catch (error) {
                    useParticipantStore().getUserEmail();
                    console.error('Error during submission:', error);
                }
            }
            else {
                localErrors.value.General = 'This is already your preferred method';
            }

            loadingPreferred.value = false;

            setTimeout(() => {
                localErrors.value.General = '';
            }, 3500);
        };

        // This function could be called after successful verification in EnterCodeTwoFactor.vue
        // For instance, after the user enters a correct code:
        const handle2faSuccess = (newMethod: string) => {
            preferredMethod.value = newMethod;
        };

        const addNewPhone = () => {
            updateSettingsState.value['newPhone'] = !updateSettingsState.value['newPhone'];
        }

        onMounted(async () => await fetchData(true));

        return {
            localErrors,
            loadingPreferred,
            success,
            loading,
            openModal,
            hasEditable,
            phone,
            userPhone,
            preferredMethod,
            oldPreferredMethod,
            securityQuestions,
            securityQuestionsArray,
            userQuestions,
            selectedQuestions,
            userEmail,
            userEmailSet,
            loadingUpdate,
            resetInputs,
            updateSettingsState,
            discardChanges,
            handleQuestions,
            updateSettings,
            updateEmail,
            updatePhone,
            updateSecurityQuestion,
            availableQuestionsForSelect,
            addNewPhone,
            updatePreferredMethod,
            showAddNewQuestion,
            addNewQuestion,
            removeQuestion,
            editQuestion,
        };
    },
}
</script>

<style>
.input-password {
    width: 90%;
}

.reset {
    border: none;
    outline: none;
    align-self: flex-start;
    background-color: transparent;
}

.input-password:focus,
.input-password:active {
    border: none;
    box-shadow: none;
}
</style>