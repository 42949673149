<template>
  <AlertsComponent :info="info" :errors="errors" />
  <MessageCard
    message="Enter the information you wish to update in the applicable field. Any changes and/or accompanying documentation will be reviewed by our administrative team prior to being made permanent. Note that all fields marked with an asterisk are required fields."
    type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />
  <MultiStep :steps=2 :activeStep="currentStep" classes="steps-wrapper_secondary sm-pdt-10"/>
  <LoaderComponent v-if="loading" />
  <!-- Step 1 -->
  <div v-else-if="currentStep === 1" class="plan-content">
    <form id="update-personal-info-form" class="form update-personal-info">
      <TextInput type="text" label="Participant ID" placeholder="Enter Participant ID" :max=25
        v-model:modelValue="formData.PersonID" :readonly="true" />
      <DateInput label="DOB" v-model:modelValue="formData.DateofBirth" :error="inputErrors.DateofBirth"
        :isRequired="true" />
      <SelectComponent label="Salutation" v-model:selectedOption="formData.Salutation" :options=profileStore.salutations
        :singleArrow="true" :error="inputErrors.Salutation" />
      <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=12
        v-model:modelValue="formData.FirstName" :error="inputErrors.FirstName" :isRequired="true" />
      <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
        v-model:modelValue="formData.MiddleName" />
      <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
        v-model:modelValue="formData.LastName" :error="inputErrors.LastName" :isRequired="true" />
      <SelectComponent label="Suffix" v-model:selectedOption="formData.NameSuffix" :options=profileStore.nameSuffixes
        :singleArrow="true" :error="inputErrors.Suffix" />
      <SelectComponent label="Gender" v-model:selectedOption="formData.Sex" :options=profileStore.genders
        :singleArrow="true" :error="inputErrors.Gender" />
      <SelectComponent label="Marital Status" v-model:selectedOption="formData.MaritalStatus"
        :options=profileStore.maritalStatuses :singleArrow="true" :error="inputErrors.MaritalStatus" />
      <div :class="['custom-select links', { 'error': inputErrors.HomeLocal }]">
        <label class="body-text"> Home Local </label>
        <Multiselect v-model="formData.HomeLocal" :options="locals" placeholder="Type to search local" :limit="1"
          :loading="loadingSearch" :searchable="true" :close-on-select="true" openDirection="bottom" label="value"
          track-by="key" @search-change="handleSearch">
        </Multiselect>
      </div>

      <div class="action-buttons pdt-50 sm-pdr-0 sm-pdl-0 sm-pdt-20">
        <button type="button" class="btn btn-secondary" @click="resetModal">
          Discard
        </button>
        <button type="button" class="btn btn-primary" @click.prevent="goToNextStep">
          Save and Continue
        </button>
      </div>
    </form>
  </div>

  <!-- Step 2 -->
  <UpdateInfoStep2 v-else-if="currentStep == 2" :loadingSubmit="loadingSubmit" @submit="submitData"
    @check-data="goToPreviousStep" />
</template>

<script lang="ts">
import { ref, computed, reactive, onMounted, defineComponent } from 'vue';
import { SelectOption, Person } from '@/interfaces/interfaces';
import { useProfileStore } from '@/stores/profile';
import { useModalStore } from '@/stores/modal';
import { usePensionStore } from '@/stores/pension';
import useFormSubmit from '@composable/useFormSubmit';

import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import MessageCard from '@components/cards/MessageCard.vue';
import MultiStep from "@components/form/MultiStep.vue";

import Multiselect from 'vue-multiselect';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
  components: {
    TextInput,
    SelectComponent,
    DateInput,
    Multiselect,
    MessageCard,
    MultiStep,
  },
  setup(_, { emit }) {
    const loading = ref(true);
    const loadingSearch = ref(false);
    const profileStore = useProfileStore();
    const changes = reactive({ ...profileStore.changes });
    const formData = computed(() => profileStore.personalData);
    const showResults = ref(false);
    const locals = ref<SelectOption[]>([]);
    const searchContainer = ref(null);
    const loadingSubmit = ref(false);
    const errors = ref<string[]>([]);
    const info = ref('');
    const success = ref('');
    const currentStep = ref<number>(1);
    const initialData = ref<Person | null>(null);

    const initialRequiredInputs = {
      FirstName: false,
      LastName: false,
      DateofBirth: false,
    };

    const inputErrors = ref<Record<string, boolean>>({ ...initialRequiredInputs });

    const goToNextStep = () => {
      if (validateFields()) {
        currentStep.value++;
      }
    };

    const goToPreviousStep = () => {
      currentStep.value--;
    };

    // Handle Search Locals
    const searchLocals = async (searchedLocal: string) => {
      try {
        const response = await axios.get('/api/user/locals', {
          params: {
            query: searchedLocal,
          },
        });

        locals.value = response.data;
      } catch (error) {
        console.error('Error searching locals:', error);
      }
    }

    // Handle Locals Search
    const handleSearch = async (query: string) => {
      locals.value = [];
      showResults.value = false;
      loadingSearch.value = true;

      await searchLocals(query);

      if (locals.value.length > 0) {
        showResults.value = true;
        loadingSearch.value = false;
      } else {
        showResults.value = false;
        locals.value = [];
      }
    };

    const closeModal = () => {
      emit('close');
    }

    const resetModal = () => {
      profileStore.personalData = {...initialData.value};
      console.log(profileStore.personalData)
    }

    // Validate Step 1 Fields
    const validateFields = () => {
      let isValid = true;
      inputErrors.value = { ...initialRequiredInputs }

      Object.keys(inputErrors.value).forEach((key) => {
        if (formData.value && !formData.value[key as keyof Person]) {
          isValid = false;
          inputErrors.value[key] = true;
        }
        else {
          inputErrors.value[key] = false;
        }
      });

      if (!isValid) {
        console.error('Validation errors:', inputErrors, formData.value);
      }

      return isValid;
    };

    // Handle Form Submit
    const submitData = async () => {
      const isValid = validateFields();

      if (!isValid) {
        return;
      }

      const route = 'api/user/profile/my-personal-information';
      const { handleSubmit } = useFormSubmit({ formData: formData.value, route, success, info, errors, loadingSubmit });
      const successSubmit = await handleSubmit();

      if (successSubmit) {
        useModalStore().openModal(
          "success-modal",
          null,
          "modal-success",
          null,
          {
            title: "Updated",
            content: {
              type: "text",
              value: success.value,
              // value: "Your Personal Information has been updated.",
              icon: "iatse-icon-user-verified",
            },
            onConfirmButton: "Go Back To My Profile",
            onConfirm: () => {
              profileStore.fetchPersonalData();
              closeModal();
            },
          },
        );
      }
    }

    onMounted(() => {
      Object.keys(profileStore.changes).forEach((key) => {
        inputErrors.value[key] = profileStore.changes[key];
      });

      initialData.value = JSON.parse(JSON.stringify(profileStore.personalData));

      loading.value = false;
    });

    return {
      profileStore,
      currentStep,
      formData,
      locals,
      changes,
      loading,
      info,
      errors,
      loadingSearch,
      loadingSubmit,
      showResults,
      searchContainer,
      inputErrors,
      goToNextStep,
      goToPreviousStep,
      handleSearch,
      closeModal,
      resetModal,
      submitData
    };
  },
})
</script>
