import { defineStore } from "pinia";
import { ref, reactive } from "vue";
import { PersonDemographicInfo } from "@/interfaces/employer";
import {
    SearchLocal,
    SearchLocalsFormFilters,
    PendingLocal,
    Paginator,
  } from "@/interfaces/admin";
import { ProfileData, SelectOption } from '@interfaces/interfaces';
import { getFundResources, getAnnouncements } from '@/services/publicService';
import { FundMaterials, Announcements, Member, PendingMember } from '@interfaces/local';

import axios from "axios";
import { useOptionsFormatter } from "@/utils/optionFormatter";

interface LocalDashboard {
    Profile: ProfileData | null,
    CappReport: string| null,
}

export const useLocalStore = defineStore("local", () => {
    const demographics = ref<PersonDemographicInfo[]>([]);
    const general = ref(null);
    const dashboard = ref<LocalDashboard | null>(null);
    const resources = ref<FundMaterials | null>(null);
    const announcements = ref<Announcements | null>(null);
    const representative = reactive({
        members: [] as Member[],
        pendingMembers: [] as PendingMember[],
    });

    const locals = reactive({
        filters: {
            OrgName: '',
            Local: null,
            Type: {key: 1, value: 'Manage All Accounts'},
        } as SearchLocalsFormFilters,
        page: 1,
        paginator: { per_page: 15, total: 100, last_page: 100, current_page: 1 } as Paginator,
        users: [] as SearchLocal[],
        pendingRequests: [] as PendingLocal[],
        filterOptions: [] as SelectOption[],
    });

    // Actions
    const fetchDashboard = async () => {
        try {
            const response = await axios.get("api/local/dashboard");
            dashboard.value = response.data;
        } catch (error) {
            console.error("There was an error fetching the dashboard!", error);
        }
    };

    // Actions
    const fetchResources = async () => {
        resources.value =await getFundResources();
        announcements.value = await getAnnouncements();
    };

    const fetchDemographics = async () => {
        try {
            const response = await axios.get("api/user/demographic-information");
            demographics.value = response.data.Records;
        } catch (error) {
            console.error("There was an error fetching demographics!", error);
        }
    };

    const fetchGeneralInfo = async () => {
        try {
            const response = await axios.get("api/local/profile");
            general.value = response.data.Data;
        } catch (error) {
            console.error("There was an error fetching general info!", error);
        }
    };

    const fetchRepresentativeAccounts = async() => {
        try {
            const response = await axios.get("api/local/representative-accounts");
            representative.members = response.data.Members;
            representative.pendingMembers = response.data.PendingMembers;
        } catch (error) {
            console.error("There was an error fetching general info!", error);
        }
    }

    const fetchLocals = async () => {
        try {
          const response = await axios.get("api/administrator/users/locals", {
            params: { ...locals.filters, page: locals.page },
          });

          locals.users = response.data.Locals;
          locals.pendingRequests = response.data.PendingLocals;
          locals.filterOptions = useOptionsFormatter(response.data.LocalsOptions);
          Object.assign(locals.paginator, response.data.Paginator);
        }
        catch (error) {
          console.error("Error:", error);
        }
    };

    const getBAAMessage = () => {
        const template = dashboard.value?.Profile?.BaaTemplateDoc;

        if (template) {
            return `Click <a href="${template}" target="_blank">here</a> to download a BAA. You can also download the file from the Form & Documents section on your Local dashboard`;
        }

        return '';
    }

    const reset = () => {
        demographics.value = [];
        general.value = null;
        dashboard.value = null;
        resources.value = null;
        announcements.value = null;
    };

    return {
        // State
        demographics,
        general,
        dashboard,
        resources,
        announcements,
        representative,
        locals,

        // Actions
        fetchDashboard,
        fetchLocals,
        fetchDemographics,
        fetchGeneralInfo,
        fetchResources,
        fetchRepresentativeAccounts,
        getBAAMessage,
        reset,
    };
});
