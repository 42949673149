<template>
    <MessageCard message="Please enter the security code below" type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />
    <div class="container mt-40 mb-40">
        <transition name="slide-fade">
            <div v-if="ssn" class="d-flex flex-column gap-20 mb-20">
                <h4>{{ type }} Social Security #: </h4>
                <h4 class="border rounded p-4">{{ ssn }}</h4>
            </div>
        </transition>
        <p v-if="recaptchaError" class="text-danger mb-20">{{ recaptchaError?.join('.') }}</p>
        <!-- reCAPTCHA Button -->
        <transition name="slide-fade">
            <ReCaptcha
                v-if="!ssn"
                :recaptchaError="recaptchaError"
                :loadingRecaptcha="loadingRecaptcha"
                :token="tokenValue"
                @update:recaptchaError="recaptchaError = $event"
                @update:loadingRecaptcha="loadingRecaptcha = $event"
                @update:token="submitRecaptcha"
            />
        </transition>
    </div>
</template>

<script lang="ts">
import { ref } from 'vue';
import { useModalStore } from '@/stores/modal';
import ReCaptcha from '@components/form/ReCaptcha.vue';
import axios from 'axios'

export default {
    components: {
        ReCaptcha
    },

    setup() {
        const tokenValue = ref<string | null>(null);
        const recaptchaError = ref<string[] |null>(null);
        const loadingRecaptcha = ref(false);
        const ssn = ref(null);
        const type = useModalStore().currentModal?.modalProps?.Type ?? 'Participant';
        const participantID = useModalStore().currentModal?.modalProps?.entity;

        const submitRecaptcha = async(token: string) => {
            try {
                const response = await axios.post('api/participant/ssn', { token, participantID });
                ssn.value = response.data;

                setTimeout(() => ssn.value = null, 60000)
                recaptchaError.value = null;
            } catch (error: any) {
                recaptchaError.value = error.response.data.errors.token;
                console.error("There was an error!", error);
            }
        }

        return {
            ssn,
            type,
            tokenValue,
            recaptchaError,
            loadingRecaptcha,
            submitRecaptcha
        };
    },
};
</script>