<template>
    <Alerts :success="success" :errors="errors" />
    <MessageCard message="Select parameters below to search for a specific claim or all claims for a specific date range." type="primary" iconClass="iatse-icon-alarm" classes="info_card_full" />

    <!-- Plan Content -->
    <div class="plan-content">
        <!-- Form -->
        <LoaderComponent v-if="loading" />

        <form v-else id="claim-search-form" class="form claim-search align-items-end" method="post">
            <p> To view details for each Claim, click on the plus sign to the left of Claim #. If you wish to change the filter settings, click 'Change My Current Filters' button to return to the filter page.
                    <br>
                    <b>Please note:</b> All claims paid/processed by ASO are here for information purposes and will reflect payments made.  All supporting documents that accompanied the claim and the associated EOB’s are not imaged onto this site.  Claims processed by the Fund Office on or after 1/1/2016 will provide a view of all attachments and the EOB for each claim.</p>

            <CoveredCard :links="links" :small="true" />
            <TextInput type="text" name="number" label="Enter Claim" placeholder="Enter here" :max=100
                v-model:modelValue="filters.Claim" />
            <TextInput type="text" name="number" label="Enter Web Reference" placeholder="Enter Web Reference" :max=25
                v-model:modelValue="filters.WebReference" />
            <PlanGeneral text="If you do not have a claim, use filters below to search for claim" />

            <div class="custom-select links">
                <label class="body-text"> Patient Name </label>
                <Multiselect v-model="filters.PatientName" :options="patients" placeholder="Type to search"
                    :searchable="true" :close-on-select="true" label="FullName" track-by="ID">
                </Multiselect>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Claim Status </label>
                <Multiselect v-model="filters.ClaimStatus" :options="statuses" placeholder="Type to search"
                    :searchable="true" :close-on-select="true" openDirection="bottom">
                </Multiselect>
            </div>
            <DateInput label="Services date" v-model:modelValue="filters.ServiceDate.StartDate" />
            <DateInput label="" v-model:modelValue="filters.ServiceDate.EndDate" />

            <DateInput label="Submission Date" v-model:modelValue="filters.SubmissionDate.StartDate"/>
            <DateInput label="" v-model:modelValue="filters.SubmissionDate.EndDate" />

            <div class="action-buttons sm-pdr-0 sm-pdl-0">
                <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeModal"> Back </a>
                <button class="btn btn-primary plan-modal" @click.prevent="searchData" @keypress.enter="searchData">
                    {{ loadingSearch ? 'Searching...' : 'Search' }} </button>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { onMounted, defineComponent, reactive, ref, Ref, watch } from 'vue';
import { ClaimFilters, Patient } from '@/interfaces/health';
import { useClaimStore } from '@/stores/claim';
import { useModalStore } from '@/stores/modal';
import axios from 'axios';

import Alerts from "@/components/utils/AlertsComponent.vue";
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import PlanGeneral from '@components/cards/PlanGeneral.vue';
import CoveredCard from '@components/participant/claims/CoveredCard.vue';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';

export default defineComponent({
    components: {
        Alerts,
        CoveredCard,
        DateInput,
        Multiselect,
        PlanGeneral,
        TextInput,
    },
    setup(_, { emit }) {
        const loading = ref(true);
        const loadingSearch = ref(false);
        const success = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const patients: Ref<Patient[]> = ref([]);
        const statuses = ref([]);
        const claimStore = useClaimStore();
        const filters: ClaimFilters = reactive({ ...JSON.parse(JSON.stringify(claimStore.filters)) });
        const openModal = useModalStore().openModal;

        const closeModal = () => {
            claimStore.filters.Page = 1;
            emit('close');
        }

        watch(() => [filters.Claim, filters.WebReference, filters.ClaimStatus, filters.PatientName, filters.ServiceDate, filters.SubmissionDate], (newFilters) => {
            if(newFilters) {
                claimStore.filters.Page = 1;
            }
        }, {deep: true})

        const fetchData = async () => {
            loading.value = true;

            await axios.get('api/participant/claims-search')
                .then(response => {
                    patients.value = response.data.Patients;
                    statuses.value = response.data.Statuses;
                    filters.ClaimStatus = statuses.value[0];
                    filters.PatientName = patients.value[0];
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        // Get Claims History
        const searchData = async () => {
            loadingSearch.value = true;

            await axios
                .post('api/participant/claims-history-participant', {
                    ...filters
                })
                .then(response => {
                    claimStore.setHistory(response.data.History, filters);
                    claimStore.paginator = response.data.Paginator;
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = Object.values(
                            error.response.data.errors
                        ).flat() as string[];
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    if (!errors.value?.length) {
                        openModal('claim-history-results', 'Claim History Search', 'plan-card-info')
                    }

                    setTimeout(() => {
                        success.value = null
                        errors.value = [];
                    }, 3500);
                })
        }

        onMounted(async () => await fetchData());

        return {
            loading,
            loadingSearch,
            success,
            errors,
            filters,
            patients,
            statuses,
            links: claimStore.claimLinks,
            searchData,
            closeModal,
            openModal,
        };
    }
})
</script>