<template>
    <AlertsComponent :errors="errors" :success="success" />
    <div class="pdl-10 pdr-10 mt-30">
        <div class="d-flex justify-content-between align-items-md-center w-100 flex-md-row flex-column">
            <h2>Manage Local Representative Accounts</h2>
            <button class="btn btn-primary plan-modal sm-mt-10" @click="openModal('local-representative-pin', 'Request PIN For Local Representative', 'plan-upload plan-card-info plan-profile-update')">
                Request PIN for Local Representative
            </button>
        </div>
        <LoaderComponent v-if="loading" />
        <div v-else>
            <table class="small striped toggle no-underline vertical-align-start mt-30">
                <thead class="large large_padding">
                    <tr>
                        <th>Login Email Address</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Days to Expiration</th>
                        <th>Designation</th>
                        <th>Phone</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody v-if="localStore.representative.members?.length > 0 || localStore.representative.pendingMembers?.length > 0">
                    <tr v-for="member in localStore.representative.members" :key="member.UserID" class="large">
                        <td>{{ member.Email }}</td>
                        <td>{{ member.FirstName }}</td>
                        <td>{{ member.LastName }}</td>
                        <td>{{ member.DaysToAccountExpiration }}</td>
                        <td>{{ member.Designation }}</td>
                        <td>{{ member.Phone }}</td>
                        <td >
                            <div class="actions">
                                <!-- Activate/Deactivate -->
                                <span v-if="!member.IsActive" @click="handleChangeActive(member.UserID)" class="tooltip-wrapper" data-tooltip="Activate User">
                                    <i
                                        class="plan-modal fs-xs iatse-icon-noun-reset">
                                    </i>
                                </span>
                                <span v-else @click="handleChangeActive(member.UserID)" class="tooltip-wrapper" data-tooltip="Deactivate User">
                                    <i  class="plan-modal iatse-icon-deactivate-user"></i>
                                </span>

                                <p> / </p>
                                <!-- Reset Pin -->
                                <span  @click="resetPin(member.UserID)" class="tooltip-wrapper" data-tooltip="Reset PIN">
                                    <i
                                        :class="['plan-modal', 'iatse-icon-reset', { 'icon-green': member.DaysToAccountExpiration === 0 }]">
                                    </i>
                                </span>
                                <p> / </p>
                                <!-- Reset Password -->
                                <span @click="resetPassword(member.UserID)" class="tooltip-wrapper" data-tooltip="Reset Password">
                                    <i class="plan-modal iatse-icon-noun-reset-password">
                                    </i>
                                </span>
                            </div>
                        </td>
                    </tr>
                    <tr v-for="pendingMember in localStore.representative.pendingMembers" :key="pendingMember.UILocalRequestID"
                        class="large">
                        <td>{{ pendingMember.Email }}</td>
                        <td>{{ pendingMember.FirstName }}</td>
                        <td>{{ pendingMember.LastName }}</td>
                        <td>{{ pendingMember.DaysToAccountExpiration }}</td>
                        <td>{{ pendingMember.Designation }}</td>
                        <td>{{ pendingMember.Phone }}</td>
                        <td class="expiration-date">Not Activated</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { Member, PendingMember } from '@/interfaces/local';
import { onMounted, ref, Ref, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useLocalStore } from '@/stores/local';

import axios from 'axios';

export default {
    setup() {
        const modalStore = useModalStore();
        const localStore = useLocalStore();
        const loading = ref(true);
        const loadingSubmit = ref(false);
        const members = computed(() => localStore.representative.members);
        const pendingMembers = computed(() => localStore.representative.pendingMembers);
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const route = 'api/local/representative-accounts';

        const fetchData = async (isLoading: boolean) => {
            if (isLoading) loading.value = true;

            await localStore.fetchRepresentativeAccounts();

            if (isLoading) loading.value = false;
        };

        // Handle Reset Pin
        const resetPin = async (userID: number) => {
            const index = searchUser(userID);

            try {
                members.value[index].ResettingPin = true;
                const response = await axios.post(
                    `${route}/reset-pin/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error: any) {
                if (error.response) {
                    errors.value = Object.values(error?.response.data.error).flat() as string[];
                }
            }

            members.value[index].ResettingPin = false;
            await refreshData();
        };

        // Handle Activate Account
        const handleChangeActive = async (userID: number) => {
            const index = searchUser(userID);
            members.value[index].Activating = true;
            const status = members.value[index]?.Status?.toLowerCase();

            try {
                const response = await axios.post(
                    `${route}/${status}/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error) {
                console.error("Error changing account status:", error);
            }

            await refreshData();
            members.value[index].Activating = false;
        };

        // Handle Reset Password
        const resetPassword = async (userID: number) => {
            const index = searchUser(userID);
            members.value[index].ResettingPassword = true;

            try {
                const response = await axios.post(
                    `${route}/reset-password/${userID}`,
                    { _method: "PATCH" }
                );
                success.value = response.data.success;
            }
            catch (error: any) {
                errors.value = Object.values(error.response.data.errors).flat() as string[];
            }

            await refreshData();
            members.value[index].ResettingPassword = false;
        };

        const searchUser = (userID: number) => {
            return members.value.findIndex(member => member.UserID === userID);
        }

        const refreshData = async () => {
            await fetchData(false);

            setTimeout(() => {
                success.value = null;
                errors.value = [];
            }, 3500);
        };

        onMounted(async () => await fetchData(true));

        return {
            loading,
            loadingSubmit,
            localStore,
            members,
            pendingMembers,
            success,
            errors,
            resetPin,
            resetPassword,
            handleChangeActive,
            openModal: modalStore.openModal
        };
    }
}
</script>