import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "plan-content"
}
const _hoisted_2 = {
  key: 0,
  class: "iatse-icon-info-circle-solid icon_rotate_0 icon_danger icon-tooltip icon-tooltip-info fs-lg"
}
const _hoisted_3 = { class: "tooltip-text left-20" }
const _hoisted_4 = { class: "inputs-wrapper mb-20" }
const _hoisted_5 = { class: "action-buttons pdt-40 sm-pdr-0 sm-pdl-0 sm-pdt-20" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Alerts = _resolveComponent("Alerts")!
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Alerts, {
      success: _ctx.success,
      info: _ctx.info,
      errors: _ctx.errors?.General
    }, null, 8, ["success", "info", "errors"]),
    _createVNode(_component_MessageCard, {
      message: "All Updates to your address will immediately and permanently change your account with the Fund\n                        Office.",
      type: "primary",
      iconClass: "iatse-icon-alarm",
      classes: "info_card_full"
    }),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("form", {
            id: "update-address-form",
            class: _normalizeClass(["form update-address align-items-start", {'position-relative': _ctx.addressErrors}]),
            method: "post"
          }, [
            _createElementVNode("h4", null, [
              _createTextVNode(" Primary Address "),
              (_ctx.addressErrors)
                ? (_openBlock(), _createElementBlock("i", _hoisted_2, [
                    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.addressErrors), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_SelectComponent, {
                label: "Address Type",
                selectedOption: _ctx.formData.Type,
                "onUpdate:selectedOption": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.Type) = $event)),
                options: _ctx.profileStore.addressTypes,
                "single-arrow": true,
                error: _ctx.errors?.Type ?? _ctx.errors?.Type,
                isRequired: true
              }, null, 8, ["selectedOption", "options", "error"]),
              _createVNode(_component_TextInput, {
                type: "text",
                name: "mixed",
                label: "Address 1 ",
                placeholder: "Enter Address 1",
                max: 30,
                modelValue: _ctx.formData.Street1,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.Street1) = $event)),
                required: true,
                error: _ctx.errors?.Street1 ?? _ctx.errors?.Street1,
                isRequired: true
              }, null, 8, ["modelValue", "error"]),
              _createVNode(_component_TextInput, {
                type: "text",
                name: "mixed",
                label: "Address 2",
                placeholder: "Enter Address 2",
                max: 30,
                modelValue: _ctx.formData.Street2,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.Street2) = $event)),
                required: true
              }, null, 8, ["modelValue"]),
              _createVNode(_component_TextInput, {
                type: "text",
                name: "mixed",
                label: "Address 3",
                placeholder: "Enter Address 3",
                max: 30,
                modelValue: _ctx.formData.Street3,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.Street3) = $event)),
                required: true
              }, null, 8, ["modelValue"]),
              _createVNode(_component_TextInput, {
                type: "text",
                name: "mixed",
                label: "Address 4",
                placeholder: "Enter Address 4",
                max: 30,
                modelValue: _ctx.formData.Street4,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formData.Street4) = $event)),
                required: true
              }, null, 8, ["modelValue"]),
              _createVNode(_component_TextInput, {
                type: "text",
                placeholder: "Enter City",
                label: ['US', 'CA', 'MX'].includes(_ctx.formData.Country?.key) ? 'City' : 'City/State',
                max: 25,
                modelValue: _ctx.formData.City,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formData.City) = $event)),
                required: true,
                error: _ctx.errors?.City,
                isRequired: true
              }, null, 8, ["label", "modelValue", "error"]),
              _createVNode(_component_SelectComponent, {
                label: "State",
                selectedOption: _ctx.formData.State,
                "onUpdate:selectedOption": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formData.State) = $event)),
                options: _ctx.filteredStates,
                "single-arrow": true,
                error: _ctx.errors ? _ctx.errors['State.key'] : null,
                isRequired: ['US', 'CA', 'MX'].includes(_ctx.formData.Country?.key),
                readonly: !['US', 'CA', 'MX'].includes(_ctx.formData.Country?.key)
              }, null, 8, ["selectedOption", "options", "error", "isRequired", "readonly"]),
              _createVNode(_component_SelectComponent, {
                label: "Country",
                selectedOption: _ctx.formData.Country,
                "onUpdate:selectedOption": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.formData.Country) = $event)),
                options: _ctx.profileStore.countries,
                error: _ctx.errors? _ctx.errors['Country.key'] : null,
                isRequired: true
              }, null, 8, ["selectedOption", "options", "error"]),
              _createVNode(_component_TextInput, {
                type: "text",
                name: _ctx.postalCodeType,
                label: "Postal Code",
                placeholder: "Enter Postal Code",
                max: 9,
                modelValue: _ctx.formData.ZipCode,
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.formData.ZipCode) = $event)),
                required: true,
                error: _ctx.errors?.ZipCode,
                isRequired: true
              }, null, 8, ["name", "modelValue", "error"])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("button", {
                type: "button",
                onClick: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.resetModal && _ctx.resetModal(...args)), ["prevent"])),
                class: "btn btn-secondary"
              }, " Discard"),
              _createElementVNode("button", {
                type: "submit",
                onClick: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.submitData && _ctx.submitData(...args)), ["prevent"])),
                class: "btn btn-primary"
              }, _toDisplayString(_ctx.loadingSubmit ? 'Saving...' : 'Save Changes'), 1)
            ])
          ], 2)
        ]))
  ], 64))
}