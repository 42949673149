<template>
    <div class="plan-content">
      <div class="plan-cards-wrapper w-100">
        <div class="card-header">
          <h5>Retiree Profile</h5>
          <div class="card-control plus">
            <i class="plus iatse-icon-plus"></i>
            <span class="line"></span>
          </div>
        </div>
  
        <!-- DEMOGRAPHICS SECTION (Participant vs. Spouse) -->
        <div class="data-section w-100">
          <div class="d-flex justify-content-between w-100" v-if="dataLoaded">
            <h3>Demographics</h3>
            <!-- Toggle to show spouse details (if present) -->
            <a href="" class="links" @click.prevent="toggleDetails" v-if="planSummary.SpouseDetails !== null">
              {{ showSpouseDetails ? 'View Participant Details' : 'View Spouse Details' }}
            </a>
          </div>

          <transition name="fade" mode="out-in">
            <div v-if="dataLoaded" :key="showSpouseDetails ? 'spouse' : 'participant'">
              <!-- Participant details (default) -->
              <div v-if="!showSpouseDetails" class="fund-details">
                <div class="fund-detail">
                  <h5>Name</h5>
                  <h4>{{ planSummary.PersonDetails.Name }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Participant ID</h5>
                  <h4>{{ planSummary.PersonDetails.PersonID }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>SSN</h5>
                  <h4>{{ planSummary.PersonDetails.SSN }}</h4>
                  <p class="plan-modal text-underline text-primary" @click.prevent="openModal('recaptcha', 'Show SSN', 'modal-success')">Show SSN</p>
                </div>
                <div class="fund-detail">
                  <h5>Gender</h5>
                  <h4>{{ planSummary.PersonDetails.Gender }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Marital Status</h5>
                  <h4>{{ planSummary.PersonDetails.MaritalStatus }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Date of Marriage</h5>
                  <h4>{{ planSummary.PersonDetails.MarriageDate ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Date of Birth</h5>
                  <h4>{{ planSummary.PersonDetails.DateOfBirth }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Street</h5>
                  <h4>{{ planSummary.PersonDetails.Street }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>City</h5>
                  <h4>{{ planSummary.PersonDetails.City }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>State</h5>
                  <h4>{{ planSummary.PersonDetails.State ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Country</h5>
                  <h4>{{ planSummary.PersonDetails.Country?.value ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>ZIP</h5>
                  <h4>{{ planSummary.PersonDetails.Zip ?? 'N/A' }}</h4>
                </div>
              </div>
  
              <!-- Spouse details -->
              <div v-else-if="planSummary.SpouseDetails !== null" class="fund-details">
                <div class="fund-detail">
                  <h5>Name</h5>
                  <h4>{{ planSummary.SpouseDetails.Name }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Participant ID</h5>
                  <h4>{{ planSummary.SpouseDetails.PersonID }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>SSN</h5>
                  <h4>{{ planSummary.SpouseDetails.SSN }}</h4>
                  <p class="plan-modal text-underline text-primary" @click.prevent="openModal('recaptcha', 'Show SSN', 'modal-success', null, {entity: planSummary.SpouseDetails.PersonID, Type: 'Spouse' })">Show SSN</p>
                </div>
                <div class="fund-detail">
                  <h5>Gender</h5>
                  <h4>{{ planSummary.SpouseDetails.Gender }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Marital Status</h5>
                  <h4>{{ planSummary.SpouseDetails.MaritalStatus }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Date of Marriage</h5>
                  <h4>{{ planSummary.SpouseDetails.MarriageDate ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Date of Birth</h5>
                  <h4>{{ planSummary.SpouseDetails.DateOfBirth }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Street</h5>
                  <h4>{{ planSummary.SpouseDetails.Street }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>City</h5>
                  <h4>{{ planSummary.SpouseDetails.City }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>State</h5>
                  <h4>{{ planSummary.SpouseDetails.State ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>Country</h5>
                  <h4>{{ planSummary.SpouseDetails.Country?.value ?? 'N/A' }}</h4>
                </div>
                <div class="fund-detail">
                  <h5>ZIP</h5>
                  <h4>{{ planSummary.SpouseDetails.Zip }}</h4>
                </div>
              </div>
            </div>
          </transition>
        </div><!-- /.data-section -->
  
        <!-- TOGGLER FOR RETIREE TOTALS -->
        <div class="d-flex w-100 justify-content-between">
          <div class="person-toggler">
            <!-- Loop through all keyed persons in retireeTotals -->
            <button
              v-for="(retireeData, personId) in retireeTotals"
              :key="personId"
              class="toggle-button"
              :class="{ active: selectedPersonId === personId }"
              @click="selectedPersonId = personId"
            >
              {{ retireeData.FullName }}
            </button>
          </div>
          <a
            class="btn btn-primary"
            @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')"
          >
            Payments History
          </a>
        </div>
  
        <!-- SHOW SELECTED PERSON'S TOTALS -->
        <!--
        <div class="w-100">
            <table class="striped smal" v-if="selectedPersonId">
                <thead>
                    <tr>
                        <th>Benefit</th>
                        <th>Most Recent Activity</th>
                        <th>Current Period</th>
                        <th>Ben Left</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="selectedPersonId">
                        <td>Part B</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{{ formatCurrency(retireeTotals[selectedPersonId].PartB) }}</td>
                    </tr>
                    <tr v-if="selectedPersonId">
                        <td>Part D</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{{ formatCurrency(retireeTotals[selectedPersonId].PartD) }}</td>
                    </tr>
                    <tr v-if="selectedPersonId">
                        <td>Part B</td>
                        <td>-</td>
                        <td>-</td>
                        <td>{{ formatCurrency(retireeTotals[selectedPersonId].Medigap) }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        -->
  
        <!-- Account Actions -->
        <div class="plan-summary__footer row w-100">
          <div class="col-12 col-md-4">
            <h4>Other Coverages</h4>
            <div class="account-actions">
              <OtherCoverages :otherCoverages="planSummary.OtherCoverages"/>
            </div>
          </div>
          <div class="col-12 col-md-8">
            <h4>Account Actions</h4>
            <div class="action-buttons mt-10">
              <a
                href=""
                class="btn btn-secondary plan-modal"
                @click.prevent="openModal('add-beneficiary', 'My Beneficiary(ies)', 'plan-tables-striped beneficiary', null, null, null)"
              >
                Beneficiary(ies)
              </a>
              <a
                href="#"
                class="btn btn-secondary btn-large plan-modal"
                @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')"
              >
                Claims
              </a>
              <a
                href=""
                class="btn btn-secondary btn-large plan-modal"
                @click.prevent="openModal('dependents', 'My Dependents', null)"
              >
                My Dependents
              </a>
            </div>
          </div>
        </div> <!-- end plan-summary__footer -->
      </div><!-- /.plan-cards-wrapper -->
    </div><!-- /.plan-content -->
  </template>
  
  <script lang="ts">
  import { ref, onMounted } from 'vue'
  import axios from 'axios'
  import { useModalStore } from '@/stores/modal'
  import { useCurrencyFormatter } from '@/composable/useCurrencyFormatter'
  import OtherCoverages from '@/components/participant/health-welfare/OtherCoverages.vue'
  import { PlanRetireeSummary, RetireeBenefitsNew } from '@/interfaces/health'
  
  export default {
    components: { OtherCoverages },
  
    setup() {
      const dataLoaded         = ref(false)
      const showSpouseDetails  = ref(false)
      const openModal          = useModalStore().openModal
      const { formatCurrency } = useCurrencyFormatter()
  
      // Master data from the API
      const planSummary        = ref<PlanRetireeSummary>({
        PersonDetails: {
          ParticipantID: '',
          SSN: '',
          Gender: '',
          Name: '',
          DateofBirth: '',
          DateOfBirth: '',
          Age: 0,
          AddressFormatted: '',
        },
        SpouseDetails: null,
        RetireeTotals: [],
      })
  
      // Keyed retiree totals: { [personId: string]: RetireeBenefitsNew }
      const retireeTotals     = ref<{ [personId: string]: RetireeBenefitsNew }>({})
      // Which PersonID is selected from the toggler
      const selectedPersonId  = ref<string | null>(null)
  
      onMounted(() => {
        fetchData()
      })
  
      const fetchData = async () => {
        try {
                const response = await axios.get('/api/participant/health-welfare/plan-retiree-summary')
                planSummary.value = response.data

                // DIRECTLY store the object returned in 'RetireeTotals':
                const rawTotals = response.data.RetireeTotals

                if (rawTotals && typeof rawTotals === 'object') {
                retireeTotals.value = rawTotals

                // Optionally select the first PersonID by default
                const allKeys = Object.keys(rawTotals)
                if (allKeys.length > 0) {
                    selectedPersonId.value = allKeys[0]
                }
                }

                dataLoaded.value = true
            } catch (error) {
                console.error('Error fetching data:', error)
            }
        }



  
      const toggleDetails = () => {
        showSpouseDetails.value = !showSpouseDetails.value
      }
  
      return {
        formatCurrency,
        openModal,
        planSummary,
        dataLoaded,
        showSpouseDetails,
        toggleDetails,
  
        retireeTotals,
        selectedPersonId,
      }
    },
  }
  </script>
  
  <style scoped>
  /* Example fade transition styles */
  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease;
  }
  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }
  </style>
  