import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-secondary-red"
}
const _hoisted_2 = {
  key: 1,
  class: "text-secondary-red"
}
const _hoisted_3 = ["for"]
const _hoisted_4 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_5 = ["type", "id", "name", "placeholder", "pattern", "maxlength", "readonly", "required", "value"]
const _hoisted_6 = {
  key: 0,
  class: "text-secondary-red"
}
const _hoisted_7 = {
  key: 1,
  class: "text-secondary-red"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['text-input', _ctx.customClass, { 'error': _ctx.error?.length || _ctx.hasChanges}])
  }, [
    (_ctx.errorPlacement === 'top' && _ctx.displayErrorMessage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.error && _ctx.error?.length)
            ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(Array.isArray(_ctx.error) ? _ctx.error.join('. ') : _ctx.error), 1))
            : _createCommentVNode("", true),
          (_ctx.localError && _ctx.localError !== _ctx.error)
            ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.localError), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.label != '')
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          for: _ctx.label,
          class: "body-text"
        }, [
          _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
          (_ctx.isRequired)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, " * "))
            : _createCommentVNode("", true)
        ], 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      type: _ctx.inputType,
      id: _ctx.label,
      name: _ctx.name,
      placeholder: _ctx.placeholder,
      pattern: _ctx.pattern ? _ctx.pattern : undefined,
      maxlength: _ctx.max,
      readonly: _ctx.readonly,
      required: _ctx.required,
      class: "links",
      value: _ctx.inputValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onInput && _ctx.onInput(...args)))
    }, null, 40, _hoisted_5),
    (_ctx.errorPlacement === 'bottom' && _ctx.displayErrorMessage)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
          (_ctx.error && _ctx.error?.length)
            ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(Array.isArray(_ctx.error) ? _ctx.error.join('. ') : _ctx.error), 1))
            : _createCommentVNode("", true),
          (_ctx.localError && _ctx.localError !== _ctx.error)
            ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.localError), 1))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    (_ctx.type === 'password')
      ? (_openBlock(), _createElementBlock("i", {
          key: 3,
          class: _normalizeClass([[
                !_ctx.passwordVisible || _ctx.readonly ? 'iatse-icon-eye-hide' :  'iatse-icon-eye',
                (_ctx.error && _ctx.error?.length) || (_ctx.localError) ? 'has-error' : '',
                _ctx.errorPlacement
            ], "show-password"]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.togglePasswordVisibility && _ctx.togglePasswordVisibility(...args)))
        }, null, 2))
      : _createCommentVNode("", true)
  ], 2))
}