<template>
    <div class="plan-content">
        <LoaderComponent v-if="loading" />
        <div v-else class="plan-details flex-column">
            <ProfileInfo title="Personal Information" :actionButton="null" :items="personItems"
                classes="plan-general-superadmin light" />
            <ProfileInfo title="Contact Information" :actionButton="null" :items="contactItems"
                classes="plan-general-superadmin light" />
            <ProfileInfo title="Addresses" :actionButton="null" :items="addressItems" :multiple="true"
                classes="plan-general-superadmin light" />
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, Ref, defineComponent, computed } from 'vue';
import { Person, AddressMethod, Contact } from '@/interfaces/local';
import { useRoute } from 'vue-router';

import ProfileInfo from '@components/cards/ProfileInfo.vue';
import axios from 'axios';

export default defineComponent({
    props: {
        modalProps: {
            type: Object,
            required: true
        }
    },
    components: {
        ProfileInfo
    },
    setup(props, { emit }) {
        const loading = ref(true);
        const person: Ref<Person | null> = ref(null);
        const address: Ref<AddressMethod | null> = ref(null);
        const contact: Ref<Contact | null> = ref(null);

        const personItems = computed(() => [
            { label: 'Name', value: person.value?.FullName },
            { label: 'Date of Birth', value: person.value?.DateofBirth ?? '--'},
            { label: 'Gender', value: person.value?.Gender ?? '--'},
            { label: 'Marital Status', value: person.value?.MaritalStatus ?? '--' },
            { label: 'Home Local', value: person.value?.HomeLocal }
        ]);

        const contactItems = computed(() => [
            { label: 'Home Phone:', value: contact.value?.Phones.Home ?? '--' },
            { label: 'Mobile Phone:', value: contact.value?.Phones.Mobile ?? '--' },
            { label: 'Work Phone:', value: contact.value?.Phones.Work ?? '--' },
            { label: 'E-Mail Address:', value: contact.value?.Emails.Primary ?? '--' },
            { label: 'Alternate E-Mail:', value: contact.value?.Emails.Secondary ?? '--' },
        ]);

        const addressItems = computed(() => {
            return [[
                { label: 'Address Type:', value: address.value?.Type },
                { label: 'Primary', value: address.value?.Primary , class: 'right'},
                { label: 'Address', value: address.value?.Address ?? '-' },
                { label: 'City', value: address.value?.City ?? '-' , class: 'right'},
                { label: 'State', value: address.value?.State ?? '-' },
            ], [
                { label: 'Country', value: address.value?.Country ?? '-' },
                { label: 'Postal Code', value: address.value?.PostalCode ?? '-', class: 'right' },
                { label: 'Bad Address', value: address.value?.Bad ?? '-' },
            ]];
        })

        const closeModal = () => {
            emit('close');
        }

        const fetchData = async () => {
            loading.value = true;

            await axios.get(`api/local/member-profile/${props.modalProps.PersonID}`)
                .then(response => {
                    person.value = response.data.Person;
                    address.value = response.data.AddressMethod;
                    contact.value = response.data.Contact;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })

            loading.value = false;
        };

        onMounted(async () => await fetchData());

        return {
            personItems,
            contactItems,
            addressItems,
            loading,
            person,
            address,
            contact,
            closeModal
        };
    }
})
</script>