<template>
    <div :class="['text-input', customClass, { 'error': error?.length || hasChanges}]">
        <template v-if="errorPlacement === 'top' && displayErrorMessage" >
            <small v-if="error && error?.length" class="text-secondary-red"> {{ Array.isArray(error) ? error.join('. ') : error }} </small>
            <small v-if="localError && localError !== error" class="text-secondary-red"> {{ localError }} </small>
        </template>
        <label v-if="label != ''" :for="label" class="body-text">{{ label }} <span v-if="isRequired" class="text-danger"> * </span>
        </label>
        <input :type="inputType" :id="label" :name="name" :placeholder="placeholder" :pattern="pattern ? pattern : undefined"
            :maxlength="max" :readonly="readonly" :required="required" class="links" :value="inputValue"
            @input="onInput"
        />
        <template v-if="errorPlacement === 'bottom' && displayErrorMessage">
            <small v-if="error && error?.length" class="text-secondary-red"> {{ Array.isArray(error) ? error.join('. ') : error }} </small>
            <small v-if="localError && localError !== error" class="text-secondary-red"> {{ localError }} </small>
        </template>
        <i
            v-if="type === 'password'"
            :class="[
                !passwordVisible || readonly ? 'iatse-icon-eye-hide' :  'iatse-icon-eye',
                (error && error?.length) || (localError) ? 'has-error' : '',
                errorPlacement
            ]"
            class="show-password"
            @click="togglePasswordVisibility"
        ></i>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, watch, computed } from 'vue';
import { useSSNValidator } from '@/composable/useSSNValidator';

export default defineComponent({
    props: {
        type: {
            type: String as PropType<'text' | 'password' | 'email' | 'tel'>,
            default: 'text',
        },
        label: {
            type: String,
            required: true,
            default: '',
        },
        placeholder: {
            type: String,
            default: '',
        },
        max: {
            type: Number,
            default: 50,
        },
        pattern: {
            type: String,
            default: '',
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        isRequired: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: '',
        },
        name: {
            type: String,
            default: 'text-group',
        },
        modelValue: {
            type: String || Number,
            default: '',
        },
        reset: {
            type: Boolean,
            default: false
        },
        error: {
            type: Boolean,
            default: false
        },
        hasChanges: {
            type: Boolean,
            default: false
        },
        errorPlacement: {
            type: String,
            default: 'bottom'
        },
        displayErrorMessage: {
            type: Boolean,
            default: true
        },
    },

    setup(props, { emit }) {
        const { formatSSN, isSSNValid } = useSSNValidator();
        const inputValue = ref<string|null>(props.modelValue ?? '');
        const passwordVisible = ref(false);
        const localError = ref<string|boolean|null>(null);

        // Toggle password visibility
        const togglePasswordVisibility = () => {
            if(props.readonly) {
                return;
            }

            passwordVisible.value = !passwordVisible.value;
        };

        watch(() => props.readonly, (newValue) => {
            if(newValue) {
                passwordVisible.value = false;
            }
        })

        const inputType = computed(() => {
            return passwordVisible.value ? 'text' : props.type;
        });

        watch(() => props.modelValue, (newValue) => {
            inputValue.value = newValue;

            if(!newValue) {
                return;
            }

            if (props?.name === 'mixed') {
                inputValue.value = newValue;
            } else if (props.name === 'ssn') {
                const result = formatSSN(newValue);
                localError.value = result.error;
                inputValue.value = result.ssn;
            } else if (props.name === 'phone') {
                inputValue.value = formatPhoneNumber(newValue);
            } else if (props.name === 'number') {
                inputValue.value = formatNumber(newValue);
            } else if (props?.type === 'text') {
                inputValue.value = removeSpecialChars(newValue)
            }

            emit('update:modelValue', inputValue.value);
        });

        const onInput = (event: Event) => {
            const target = event.target as HTMLInputElement | null;

            if (target) {
                const value = target.value;
                let newValue = value;

                if (props?.name === 'mixed') {
                    inputValue.value = newValue;
                } else if (props.name === 'ssn') {
                    const result = formatSSN(newValue);
                    localError.value = result.error;
                    inputValue.value = result.ssn;
                } else if (props.name === 'phone') {
                    inputValue.value = formatPhoneNumber(newValue);
                } else if (props.name === 'number') {
                    inputValue.value = formatNumber(newValue);
                } else if (props?.type === 'text') {
                    inputValue.value = removeSpecialChars(newValue)
                }

                target.value = newValue ?? '';
                emit('update:modelValue', newValue);
            }
        }

        function removeSpecialChars(value: string) {
            return value?.replace(/[^a-zA-Z\s'-]/g, '');
        }

        const formatNumber = (value: string) => {
            // Remove all non-numeric characters
            return value?.replace(/\D/g, '');
        }

        const formatPhoneNumber = (value: string) => {
            // Remove all non-numeric characters
            let phone = value?.replace(/\D/g, '');

            // Format phone number (US format as an example: (XXX) XXX-XXXX)
            if (phone.length <= 3) {
                return phone;
            } else if (phone.length <= 6) {
                return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
            } else {
                return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
            }
        }

        // const formatSSN = (value: string) => {
        //     let ssn = value?.replace(/\D/g, '');  // Remove non-numeric characters
        //     let ssnValue = ''

        //     if (ssn.length <= 3) {
        //         ssnValue = ssn;
        //     } else if (ssn.length <= 5) {
        //         ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3)}`;
        //     } else {
        //         ssnValue = `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`;
        //     }

        //     return ssnValue;
        // }

        return {
            inputValue,
            inputType,
            passwordVisible,
            localError,
            onInput,
            togglePasswordVisibility,
        };
    },
});
</script>