<template>
    <div class="plan-content mb-30">
        <div class="plan-details">
            <div class="plan-general-details h-100 gap-10">
                <h4>IATSE National Benefits Fund Office </h4>
                <div class="plan-general-container">
                    <div class="plan-general-detail-container">
                        <div class="plan-general-detail-line">
                            <h4>417 Fifth Avenue</h4>
                            <h4>3rd Floor</h4>
                            <h4>New York, NY 10016-2204</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-general-details h-100 gap-10 w-100">
                <h4>Main Numbers</h4>
                <div class="plan-general-container">
                    <div class="plan-general-detail-container gap-20">
                        <div class="plan-general-detail-line">
                            <h5>Main Number </h5>
                            <h4>212-580-9092</h4>
                        </div>
                        <div class="plan-general-detail-line">
                            <h5>Toll Free Number </h5>
                            <h4>800-456-3863 </h4>
                        </div>
                        <div class="plan-general-detail-line">
                            <h5>Fax Number</h5>
                            <h4>212-787-3607 </h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="plan-general-details h-100 gap-10">
                <h4>Email Addresses</h4>
                <div class="plan-general-container">
                    <div class="plan-general-detail-container flex-column w-100 align-items-start">
                        <div class="plan-general-detail-line flex-row align-items-center gap-10">
                            <h5>Participant Services</h5>
                            <h4>psc@iatsenbf.org </h4>
                        </div>
                        <div class="plan-general-detail-line flex-row  align-items-center gap-10">
                            <h5>Webmaster </h5>
                            <h4>webadmin@iatsenbf.org</h4>
                        </div>
                        <small>Email Webmaster for any website technical issues.</small>
                    </div>
                </div>
            </div>
        </div>

        <form v-if="userName" @submit.prevent="handleSubmit" @keypress.enter="handleSubmit" class="form update-address align-items-start mt-20" method="post">
            <div class="inputs-wrapper mb-20 d-flex flex-wrap gap-20">
                <SelectComponent label="Topic" v-model:selectedOption="formData.Topic" :options=topics
                :singleArrow="true" :error="errors?.Topic" />
                <TextInput type="text" label="Full Name" placeholder="Enter Full Name" :max=50
                    v-model:modelValue="formData.FullName" :error="errors?.FullName" :isRequired="true" />
                <TextInput type="email" label="Email Address" placeholder="Enter Email Address" :max=50
                    v-model:modelValue="formData.Email" :isRequired="true" :error="errors?.Email"/>
                <TextInput type="email" label="Confirm Email Address" placeholder="Enter Email Address Confirmation" :max=50
                    v-model:modelValue="formData.Email_confirmation" :isRequired="true" :error="errors?.Email_confirmation"/>
                <TextInput type="text" name="phone" label="Phone Number" placeholder="Enter Phone Number" :max=17
                    v-model:modelValue="formData.PhoneNumber" :error="errors?.PhoneNumber" :isRequired="true" />
                <Textarea label="Message" message="Notes field accepts minimum 10 characters" v-model:modelValue="formData.Message" :max="1000" :error="errors?.Message" :is-required="true"/>
                <div class="d-flex flex-column align-items-end mt-20 ms-auto">
                    <button type="submit" class="btn btn-primary">{{ loadingSubmit ? 'Submitting...' : 'Submit'}}</button>
                </div>
            </div>
        </form>
    </div>
</template>

<script lang="ts">
import { useAuthStore } from '@/stores/auth';
import { Ref, computed, onMounted, reactive, ref, watch } from 'vue';
import { useOptionsFormatter } from '@/utils/optionFormatter';
import { SelectOption } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import Textarea from '@/components/form/TextareaComponent.vue';
import axios from 'axios';

interface FormData {
    FullName: string,
    Email: string
    Email_confirmation: string
    PhoneNumber: string
    Message: string
    Topic: SelectOption
}


interface ErrorFormData {
    FullName: string | null,
    Email: string | null,
    Email_confirmation: string | null,
    PhoneNumber: string | null,
    Message: string | null,
    Topic: string | null,
}

export default {
    components: {
        TextInput,
        SelectComponent,
        Textarea,
    },

    setup() {
        const authStore = useAuthStore();
        const topics: Ref<SelectOption[]> = ref([]);
        const userName = computed(() => authStore.userName);
        const userEmail = computed(() => authStore.userEmail);
        const formData: FormData = reactive({
            FullName: userName.value ?? '',
            Email: userEmail.value ?? '',
            Topic: { key: 0, value: 'Select Topic'},
            Email_confirmation: userEmail.value ?? '',
            PhoneNumber: '',
            Message: '',
        });
        const route = 'api/contact';
        const loadingSubmit = ref(false);
        const success = ref(null);
        const errors: Ref<Partial<ErrorFormData>> = ref({});

        watch(() => formData.Topic, (newValue) => {
            if(newValue?.key && errors.value?.Topic) {
                errors.value.Topic = null;
            }
        })

        const firstCard = computed(() => {
            return [
                { label: 'Main Number', value: '417 Fifth Avenue' },
                { label: 'Toll Free Number', value: '3rd Floor' },
                { label: 'Fax Number', value: ' New York, NY 10016-2204' },
            ]
        });

        const thirdCard = computed(() => {
            return [
                { label: 'Participant Services', value: 'psc@iatsenbf.org' },
                { label: 'Webmaster', value: 'webadmin@iatsenbf.org - Email Webmaster for any website technical issues.' },
            ]
        })

        const secondCard = computed(() => {
            return [
                { label: 'Main Number', value: '212-580-9092' },
                { label: 'Toll Free Number', value: '800-456-3863' },
                { label: 'Fax Number', value: ' 212-787-3607' },
            ]
        });

        // Fetch Data
        const fetchData = async () => {
            await axios.get(route)
                .then(response => {
                    topics.value = useOptionsFormatter(response.data.Topics);
                })
        };

        // Send Email
        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post('api/participant/contact', { ...formData, Topic: formData.Topic.key, PhoneNumber: formData.PhoneNumber.replace(/\D/g, '') })
                .then((response) => {
                    success.value = response.data?.success;
                    loadingSubmit.value = false;

                    if(success.value) {
                        useModalStore().openModal('success-modal', null, 'modal-success', null, {
                            title: 'Contact Us',
                            content: {
                                type: "text",
                                value: response.data.success,
                                icon: 'iatse-icon-user-verified'
                            },
                        })
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = error.response.data.errors
                    }

                    loadingSubmit.value = false;
                });
        };

        onMounted(async () => await fetchData());

        return {
            topics,
            userName,
            userEmail,
            formData,
            loadingSubmit,
            errors,
            success,
            firstCard,
            secondCard,
            thirdCard,
            handleSubmit
        }

    }
};
</script>
