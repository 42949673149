import { ref } from "vue";
import { defineStore } from "pinia";
import { PersonDemographicInfo, EmployerDashboard } from "@/interfaces/employer";
import { FundMaterials, Announcements } from '@interfaces/local';
import { getFundResources, getAnnouncements } from '@/services/publicService';
import axios from "axios";

export const useEmployerStore = defineStore('employer', () => {
    const demographics = ref<PersonDemographicInfo[]>([]);
    const general = ref<any>(null);
    const dashboard = ref<EmployerDashboard | null>(null);
    const type = ref<string | null>(null);
    const resources = ref<FundMaterials | null>(null);
    const announcements = ref<Announcements | null>(null);

    const fetchDemographics = async () => {
        try {
        const response = await axios.get("api/user/demographic-information");
        demographics.value = response.data.Records;
        } catch (error) {
        console.error("There was an error!", error);
        }
    };

    const fetchGeneralInfo = async () => {
        try {
        const response = await axios.get("api/employer/profile");
        general.value = response.data.Data;
        } catch (error) {
        console.error("There was an error!", error);
        }
    };

    const getDashboard = async () => {
        try {
        const response = await axios.get('api/employer/dashboard');
        dashboard.value = response.data;
        type.value = response.data.IsPayor ? 'Payroll' : 'Employer';
        } catch (error) {
        console.error('There was an error!', error);
        }
    };

    // Actions
    const fetchResources = async () => {
        resources.value =await getFundResources();
        announcements.value = await getAnnouncements();
    };

    const reset = () => {
        demographics.value = [];
        general.value = null;
        dashboard.value = null;
        type.value = null;
        resources.value = null;
        announcements.value = null;
    };

  return {
    demographics,
    general,
    dashboard,
    type,
    resources,
    announcements,
    fetchDemographics,
    fetchGeneralInfo,
    getDashboard,
    fetchResources,
    reset
  };
})
