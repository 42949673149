<template>
    <p class="fw-semibold pdb-20">Participant Registration</p>
    <div class="d-flex flex-column gap-20 w-100">
        <TextInput type="text" name="number" label="Participant ID or Last 4 SSN" placeholder="Participant ID or Last 4 SSN" :max=11
            v-model:modelValue="participant.ID_SSN" :is-required="true" :error="errors.ID_SSN" />
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" label="First Name" placeholder="First Name" :max=12
                v-model:modelValue="participant.FirstName" :is-required="true" :error="errors.FirstName"/>
            <TextInput type="text" label="Last Name" placeholder="Last Name" :max=25
                v-model:modelValue="participant.LastName" :is-required="true" :error="errors.LastName"/>
        </div>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="email" label="Email" placeholder="Email" :max=100
                v-model:modelValue="participant.Email" :is-required="true" :error="errors.Email"/>
            <DateInput label="Date of birth"  :max=25 v-model:reset="reset"
                v-model:modelValue="participant.DateofBirth" :is-required="true" :error="errors.DateofBirth"/>
        </div>
        <div>
            <TextInput type="password" label="Create Password" placeholder="Enter Password" :max=50
            v-model:modelValue="participant.Password" :is-required="true" :error="errors.Password"/>
            <small class="fw-light fs-sm">Minimum 8 characters, One Uppercase, One Lowercase, One Symbol and One Number</small>
        </div>

        <TextInput type="password" label="Confirm Password" placeholder="Enter Password" :max=50
            v-model:modelValue="participant.Password_confirmation" :is-required="true" :error="errors.Password_confirmation"/>
        <div class="pdt-10">
            <p class="fw-semibold">Security Questions</p>
            <small>Please select a question and enter an answer, which will be used if you ever forget your password.</small>
        </div>
        <SelectComponent label="Security Question" v-model:selectedOption="participant.SecurityQuestion" :options=securityQuestions :is-required="true" :error="errors.SecurityQuestion"/>
        <TextInput type="text" name="mixed" label="Answer" placeholder="Enter Answer" :max=50
            v-model:modelValue="participant.Answer" :is-required="true" :error="errors.Answer"/>
    </div>

    <div class="d-flex mt-40 gap-20 justify-content-center flex-column">
        <!-- reCAPTCHA Button -->
        <ReCaptcha
            :recaptchaError="recaptchaError"
            :loadingRecaptcha="loadingRecaptcha"
            :token="token"
            @update:recaptchaError="recaptchaError = $event"
            @update:loadingRecaptcha="loadingRecaptcha = $event"
            @update:token="token = $event"
        />
        <div class="d-flex gap-20 flex-md-row flex-column">
            <button type="button" class="btn btn-secondary w-100 lh-1 border-radius-8" @click.prevent="handleDiscard" :disabled="loadingSubmit">
                Discard
            </button>

            <button type="submit" class="btn btn-primary w-100 lh-1 border-radius-8" @click.prevent="handleRegister" @keypress.enter.prevent="handleRegister" :disabled="loadingSubmit">
                Submit
                <svg v-if="loadingSubmit" width="24" height="16" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
                    enable-background="new 0 0 0 0" xml:space="preserve">
                    <path fill="#8EC986"
                        d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                        <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                            from="0 50 50" to="360 50 50" repeatCount="indefinite" />
                    </path>
                </svg>
            </button>
        </div>
    </div>
</template>

<script lang="ts">
import { SelectOption } from '@/interfaces/interfaces';
import { PropType, defineComponent, reactive, ref, watch } from 'vue';
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import ReCaptcha from '@components/form/ReCaptcha.vue';

export default defineComponent({
    props: {
        securityQuestions: Array as PropType<SelectOption[]>,
        errors: Object as PropType<Record<string, string>>,
        loadingSubmit: Boolean
    },
    components: {
        TextInput,
        DateInput,
        SelectComponent,
        ReCaptcha
    },
    setup(props, { emit }) {
        const initialParticipant = {
            ID_SSN: '',
            Email: '',
            FirstName: '',
            LastName: '',
            DateofBirth: '',
            Password: '',
            Password_confirmation: '',
            SecurityQuestion: { key: 0 , value: 'Select Question'},
            Answer: '',
        }
        const participant = reactive(JSON.parse(JSON.stringify(initialParticipant)));
        const token = ref(null);
        const recaptchaError = ref<string |null>(null);
        const loadingRecaptcha = ref(false);
        const reset = ref(false);

        watch(() => props.errors?.token, (newValue) => {
            recaptchaError.value = newValue ?? null;
        })

        const handleDiscard = () => {
            reset.value = false;
            Object.assign(participant, JSON.parse(JSON.stringify(initialParticipant)));
            recaptchaError.value = null;
            emit('reset-errors');
            reset.value = true;
        }

        const handleRegister = async () => {
            emit('register', {...participant, SecurityQuestion: participant.SecurityQuestion?.key, token: token.value});
        }

        return {
            recaptchaError,
            participant,
            token,
            reset,
            loadingRecaptcha,
            handleRegister,
            handleDiscard
        };
    },
})
</script>