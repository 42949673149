import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "modal-overlay"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SidebarComponent = _resolveComponent("SidebarComponent")!
  const _component_MobileHeader = _resolveComponent("MobileHeader")!
  const _component_HeaderComponent = _resolveComponent("HeaderComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MultiStep = _resolveComponent("MultiStep")!
  const _component_ModalComponent = _resolveComponent("ModalComponent")!

  return (_openBlock(), _createElementBlock("main", {
    class: _normalizeClass(["main", { 'overflow-hidden': _ctx.modals?.length }]),
    onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startClickOutside && _ctx.startClickOutside(...args))),
    onMouseup: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.endClickOutside && _ctx.endClickOutside(...args)))
  }, [
    (_ctx.isAuthenticated && !_ctx.hideSidebar && !_ctx.isRestrictedRoute)
      ? (_openBlock(), _createBlock(_component_SidebarComponent, {
          key: 0,
          active: _ctx.activeMenu,
          "is-employer": _ctx.isEmployer,
          "is-local": _ctx.isLocal,
          onOpenMenu: _ctx.openMenu
        }, null, 8, ["active", "is-employer", "is-local", "onOpenMenu"]))
      : _createCommentVNode("", true),
    (_ctx.isAuthenticated && !_ctx.isRestrictedRoute)
      ? (_openBlock(), _createBlock(_component_MobileHeader, {
          key: 1,
          onOpenModal: _ctx.openModal,
          onOpenMenu: _ctx.openMenu
        }, null, 8, ["onOpenModal", "onOpenMenu"]))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass(["main-content", { 'authenticated': _ctx.isAuthenticated && !_ctx.isRestrictedRoute, 'fixed': _ctx.activeMenu, 'admin': _ctx.isSuperAdmin, 'bg-sixth' : _ctx.isLoginPage }]),
      id: "content"
    }, [
      (!_ctx.hideHeader && _ctx.showHeader && !_ctx.isRestrictedRoute)
        ? (_openBlock(), _createBlock(_component_HeaderComponent, {
            key: 0,
            onOpenModal: _ctx.openModal,
            onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
          }, null, 8, ["onOpenModal"]))
        : _createCommentVNode("", true),
      _createVNode(_component_router_view)
    ], 2),
    _createElementVNode("div", {
      class: "modal-container",
      onClick: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["stop"]))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modals, (modal, index) => {
        return (_openBlock(), _createBlock(_component_ModalComponent, {
          key: index,
          isVisible: modal.isVisible,
          index: index,
          modalType: modal.type,
          title: modal.title,
          classNames: modal.classes,
          fund: modal.fund,
          modalProps: modal.modalProps,
          secondTitle: modal.secondTitle,
          headerButtons: modal.headerButtons,
          hasCloseConfirm: modal.hasCloseConfirm,
          onClose: _ctx.closeModal,
          onCloseAbove: _ctx.closeAbove,
          onHandleSubmit: _ctx.handleSubmit
        }, _createSlots({ _: 2 }, [
          (modal.type === 'add-dependent' || modal.type === 'assign-fund')
            ? {
                name: "steps",
                fn: _withCtx(() => [
                  _createVNode(_component_MultiStep, {
                    steps: 2,
                    activeStep: _ctx.currentStep,
                    classes: "steps-wrapper_header"
                  }, null, 8, ["activeStep"])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["isVisible", "index", "modalType", "title", "classNames", "fund", "modalProps", "secondTitle", "headerButtons", "hasCloseConfirm", "onClose", "onCloseAbove", "onHandleSubmit"]))
      }), 128))
    ]),
    (_ctx.modals?.length || _ctx.activeMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1))
      : _createCommentVNode("", true)
  ], 34))
}