<template>
    <header>
        <div class="container mt-35 sm-mt-0 sm-p-0 logo-header">
            <div class="row sm-p-0">
                <!-- Logo Section -->
                <div class="logo-wrapper col-md-4 d-flex justify-content-end order-1 order-md-2 mb-20 lh-sm">
                    <div class="w-fit w-sm-100 bg-primary rounded px-4 py-3 h-fit rounded-none">
                        <figure>
                            <img src="@/assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo" />
                        </figure>
                    </div>
                </div>
                <div class="col-md-8 order-2 order-md-1">
                <h2 class="sm-mb-20 text-center text-lg-start"> Reset Password </h2>
                </div>
            </div>
        </div>
    </header>

  <div class="container bg-sixth">
    <div class="row mt-40 sm-mt-0 col-md-12">
        <form class="col-md-6 mt-35 mb-50 d-flex flex-column gap-20">
            <div class="form-group d-flex flex-column gap-2 align-middle w-100">
                <TextInput type="password" label="Enter New Password" placeholder="Password" :max=50 v-model:modelValue="formData.password" :is-required="true" :error="errors?.password" :required="true"/>
                <small class="w-100 fw-light fs-sm mb-20">
                    Minimum 8 characters, One Uppercase, One Lowercase, One Symbol and One Number
                </small>
            </div>
            <div class="form-group d-flex flex-column gap-2 w-100">
                <TextInput type="password" label="Confirm New Password" placeholder="Password" :max=50 v-model:modelValue="formData.password_confirmation" :is-required="true" :error="errors?.password" :required="true"/>
                <small class="w-100 fw-light fs-sm mb-20">
                    Minimum 8 characters, One Uppercase, One Lowercase, One Symbol and One Number
                </small>
            </div>
            <button type="submit" class="btn btn-primary" @click.prevent="handleSubmit" @keypress.enter.prevent="handleSubmit">
                {{ loadingSubmit ? 'Submitting...' : 'Submit' }}
            </button>
        </form>
        <div class="col-md-6 hide-mobile">
            <img style="max-width: 100%; max-height: 90vh;" class="rounded img-fluid" src="@/assets/images/2fa-picture.jpg" alt="2FA Image" />
        </div>
    </div>
  </div>
</template>


<script>
import { ref, reactive, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import TextInput from '@components/form/TextInput.vue'
import axios from 'axios';

export default {
    components: {
        TextInput
    },
    setup() {
        const hash = useRoute().params.hash;
        const showPassword = ref(false);
        const showPasswordConfirmation = ref(false);
        const errors = ref([]);
        const success = ref('');
        const info = ref('');
        const loadingSubmit = ref(false);
        const route = 'api/send-reset-password';
        const router = useRouter();
        const authStore = useAuthStore();
        const formData = reactive({
            password: '',
            password_confirmation: '',
            hash: hash,
        });

        // Togle between hide/show password
        const togglePassword = () => {
            showPassword.value = !showPassword.value;
        }

        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const handleSubmit = async () => {
            loadingSubmit.value = true;

            await axios
                .post(route, { ...formData, _method: "POST" })
                .then((response) => {
                    success.value = response.data?.success;

                    if(success.value) {
                        errors.value = [];

                        useModalStore().openModal('success-modal', null, 'modal-success', null, {
                            title: 'Password Reset',
                            content: {
                                type: "text",
                                value: 'Your password has been successfully reset. You can now use your new password to log in to your account.',
                                icon: 'iatse-icon-user-verified'
                            },
                            onConfirmButton: "Go to Login",
                            onConfirm: () => router.push({ name: 'login' })
                        })
                    }
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = error.response.data.errors
                    }

                    loadingSubmit.value = false;
                })
                .finally(() => {
                    loadingSubmit.value = false;
                })
        };

        onMounted(() => {
            authStore.clearUserStore();
            authStore.logoutWithoutPush();
        })

        return {
            formData,
            errors,
            success,
            loadingSubmit,
            showPassword,
            showPasswordConfirmation,
            handleSubmit,
            togglePassword,
            togglePasswordConfirmation,
        };
    }
};
</script>