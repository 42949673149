<template>
   <header>
        <div class="container mt-35 sm-mt-0 sm-p-0 logo-header logo-header-2fa">
            <div class="row">
                <!-- Logo Section -->
                <a href="https://iatse-public.staging.polardesign.com/en/" target="_blank" class="logo-wrapper tooltip-wrapper tooltip-wrapper_tertiary col-md-4 d-flex justify-content-end order-1 order-md-2 mb-20 plan-modal" data-tooltip="Visit IATSE National Benefits Fund">
                  <div class="w-fit w-sm-100 bg-primary rounded px-4 py-3 h-fit rounded-none">
                      <figure>
                          <img src="@/assets/images/logo.svg" alt="Logo" title="IATSE Benefits Fund Logo" />
                      </figure>
                  </div>
              </a>
            </div>
        </div>
    </header>
    <div>
        <!-- Verification Code Form -->
        <div class="container mt-5">
            <div class="row justify-content-center align-items-center vh-100 sm-vh-auto">
                <!-- Form Column -->
                <div class="col-md-6 d-flex flex-column justify-content-center align-items-lg-start align-items-center">
                    <h2>Check Your {{ method }}</h2>
                    <div class="form-group d-flex flex-column gap-4 mb-4 align-items-lg-start align-items-center">
                        <label for="code" class="text-lg-left text-center">We've sent you a code to {{ sentTo }}</label>
                        <VerificationCodeInput v-model="verificationCode" extra-classes="centered" @keyup.enter="verifyCode"/>
                        <div class="d-flex gap-4 mt-4">
                            <button @click="verifyCode" class="btn btn-primary w-100">Submit Code</button>
                        </div>
                        <div class="mt-3 d-flex justify-content-center">
                            <div>
                                <a href="#" @click.prevent="regenerateCode">Resend Code</a>
                                <span v-if="timer > 0"> ({{ timer }} seconds remaining)</span>
                            </div>
                        </div>
                        <div v-if="errorMessage" class="text-danger mt-2">{{ errorMessage }}</div>
                    </div>
                </div>

                <!-- Image Column -->
                <div class="col-md-6 hide-mobile">
                    <img style="max-width: 100%; max-height: 90vh;" src="@/assets/images/2fa-picture.jpg" alt="2FA Image" />
                </div>
            </div>
        </div>
    </div>
</template>


<script lang="ts">
    import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
    import axios from 'axios';
    import { useAuthStore } from '@/stores/auth';
    import { useRoute, useRouter } from 'vue-router';
    import VerificationCodeInput from '@components/form/VerificationCodeField.vue';

    export default {
    components: {
        VerificationCodeInput,
    },
      setup() {
        const router = useRouter();
        const route = useRoute();
        const authStore = useAuthStore();
        const token = computed(() => route.query.token as string ?? '');
        const verificationCode = ref('');
        const expirationTime = ref(120); // Default to 60 seconds
        const timer = ref(0);
        const timerInterval = ref<number | null>(null);
        const errorMessage = ref('');

        const sentTo = ref('');
        const method = ref('');

        watch(() => token.value, (newToken) => {
          authStore.setToken(newToken);
        })

        const startTimer = () => {
          timer.value = expirationTime.value;
          if (timerInterval.value) {
            clearInterval(timerInterval.value);
          }
          timerInterval.value = setInterval(() => {
            if (timer.value > 0) {
              timer.value--;
            } else {
              clearInterval(timerInterval.value!);
              timerInterval.value = null;
            }
          }, 1000);
        };

        const verifyCode = async () => {
          try {
            const payload = {
              verification_code: verificationCode.value
            };
            const response = await axios.post('/api/authorization/submit-code', payload);
            if (response.data.success) {
              // Verification successful
              // Redirect or perform necessary actions
               authStore.set2FaValidated(true);
               verificationCode.value = '';
               console.log('>>>> Failed code 1 <<<<<');
               navigateToConditions();
            } else {
              // Handle error
              errorMessage.value = response.data.error || 'Invalid verification code.';
              console.log('>>>> Failed code 2 <<<<<');
              verificationCode.value = '';
            }
          } catch (error: any) {
            errorMessage.value = error.response?.data?.error || 'An error occurred.';
            console.log('>>>> Failed code 3 <<<<<');
            verificationCode.value = '';
          }
        };

        const regenerateCode = async () => {
            try {
                const response = await axios.get('/api/authorization/regenerate-code');

                if (response.data.success) {
                    // Use new expiration time if provided
                    if (response.data.expirationTime) {
                        expirationTime.value = response.data.expirationTime;
                    }
                    else {
                        expirationTime.value = 60; // Default to 60 seconds
                    }

                    startTimer();
                }
                else {
                // Handle error
                errorMessage.value = response.data.message || 'An error occurred while resending the code.';
                }
            }
            catch (error: any) {
                console.error(error);
                errorMessage.value = error.response?.data?.message || 'An error occurred.';
            }
        };


        const generateCode = async () => {
            if(token.value) {
              authStore.setToken(token.value);
              await authStore.setUserWithToken(token.value);
            }

            if(!authStore.user?.preferredMethod) {
              await router.push({ name: '2fa.setup'});
            }

            try {
                const response = await axios.get('/api/authorization/generate-code');

                if (response.data.success) {
                    if (response.data.expirationTime) {
                        expirationTime.value = response.data.expirationTime;
                    }
                    else {
                        expirationTime.value = 120;
                    }

                    sentTo.value = response.data.PreferredMethod.value;
                    method.value = response.data.PreferredMethod.method;
                    startTimer();
                }
            }
            catch (error: any) {
                errorMessage.value = error.response?.data?.message || 'An error occurred while generating the code.';
            }
        };

        // Clean up the timer when the component is unmounted
        onUnmounted(() => {
          if (timerInterval.value) {
            clearInterval(timerInterval.value);
          }
        });

        onMounted(async() => await generateCode());

        const navigateToConditions = () => {
          router.push({ name: 'terms' }); // Push to the terms & conditions route
      };

        return {
          verificationCode,
          expirationTime,
          sentTo,
          method,
          timer,
          verifyCode,
          regenerateCode,
          errorMessage,
        };
      },
    };
</script>