<template>
  <div :class="['bordered-card-wrapper', customClass]">
    <!-- Mobile Card Header -->
    <div class="mobile-card-header hide-desktop">
      <h2>{{ title }}</h2>
      <div v-if="beneficiary?.Additional?.PendingDeletion" class="label_pending label_pending__danger">
        <small class="label label_danger"> Pending Deletion </small>
        <i class="iatse-icon-info-circle"> </i>
      </div>
      <div v-else-if="beneficiary?.Additional?.PendingBene" class="label_pending">
        <small class="label label_blue"> Pending Approval </small>
        <i class="iatse-icon-info-circle"> </i>
      </div>
      <div v-else-if="beneficiary?.Additional?.PendingChange" class="label_pending">
        <small class="label label_blue"> Pending Address Change </small>
        <i class="iatse-icon-info-circle"> </i>
      </div>
      <div v-if="actionButtons" class="action-buttons">
        <i class="iatse-icon-edit plan-modal"
          @click.prevent="openModal('add-beneficiary', 'Edit Beneficiary', 'plan-form plan-upload')"></i>
        <!-- <i class="iatse-icon-trash plan-modal"
          @click.prevent="openModal('success-modal', null, 'modal-success', null, modalProps)"></i> -->
      </div>
    </div>

    <!-- Bordered Card Rows - Single-->
    <div v-if="mode === 'single'" class="bordered-card-rows-wrapper">
      <div class="bordered-card-rows">
        <div v-for="(item, key) in data" :key="key" class="bordered-card-row">
          <h5>{{ capitalize(key) }}</h5>
          <h4>{{ item }}</h4>

          <!-- Divider -->
          <div v-if="hasDivider && key !== Object.keys(item).length - 1" class="divider"></div>
        </div>
      </div>

      <!-- Delete Item -->
      <div v-if="deleteItem" class="delete-item plan-modal"
        @click.prevent="openModal('success-modal', null, 'modal-success', null, modalProps)">
        <i class="iatse-icon-trash"></i>
      </div>
    </div>

    <!-- Bordered Card Rows - Multiple-->
    <template v-else>
      <div v-for="(item, index) in data" :key="index" class="bordered-card-rows-wrapper">
        <div class="bordered-card-rows">
          <template v-for="(value, key, keyIndex) in item" :key="key">
            <div class="bordered-card-row">
              <h5>{{ capitalize(key) }}</h5>
              <h4>{{ value }}</h4>
            </div>
            <!-- Divider -->
            <div v-if="hasDivider && keyIndex !== Object.keys(item).length - 1" class="divider"></div>
          </template>

        </div>
        <!-- Add Item -->
        <button v-if="addItem" :class="['plan-modal btn', isAdded(index) && !discard ? 'btn-secondary' : 'btn-primary']"
          @click.prevent="handleAdd(index)">
          {{ isAdded(index) && !discard ? 'Edit Beneficiary' : 'Add as Beneficiary' }}
        </button>

        <!-- Delete Card Item -->
        <div v-if="deleteItem" class="delete-item plan-modal" @click.prevent="removeItem(index)">
          <i class="iatse-icon-trash"></i>
        </div>
        <div v-if="deleteItem" class="edit-item plan-modal" @click.prevent="editItem(index)">
          <i class="iatse-icon-edit"></i>
        </div>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, PropType, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useBeneficiaryStore } from '@/stores/beneficiary';
import { Beneficiary } from "@/interfaces/beneficiary";

export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'single',
    },
    data: {
      type: Array as () => Record<string, string | number>[],
      required: true,
    },
    actionButtons: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    deleteItem: {
      type: Boolean,
      default: false,
    },
    addItem: {
      type: Boolean,
      default: false,
    },
    hasDivider: {
      type: Boolean,
      default: false,
    },
    beneficiary: {
      type: Object as PropType<Beneficiary>,
      required: true
    },
    discard: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const selectedBeneficiaries = reactive<number[]>([]);
    const beneStore = useBeneficiaryStore();

    const capitalize = (text: string | number): string | number => {
      if (typeof text === 'string') {
        return text.charAt(0).toUpperCase() + text.slice(1);
      }

      return text;
    }

    const handleAdd = (index: number) => {
      if (isAdded(index)) {
        emit('edit-dep', index);
      }
      else {
        selectedBeneficiaries.push(index);
        emit('add', selectedBeneficiaries);
      }
    }

    watch(() => props.discard, (newValue) => {
      if(newValue) {
        Object.assign(selectedBeneficiaries, []);
      }
    })

    // Check if a beneficiary is already added
    const isAdded = (index: number): boolean => {
      const isIncluded = beneStore.newBeneficiaries?.some(beneficiary => beneficiary.DependentID == index || beneficiary.PartnerID == index);
      return selectedBeneficiaries.includes(index) || isIncluded;
    };

    const removeItem = ((index: number) => emit('delete', index));
    const editItem = ((index: number) => emit('edit', index));

    return {
      capitalize,
      removeItem,
      editItem,
      openModal: useModalStore().openModal,
      handleAdd,
      isAdded,
      modalProps: {
        title: 'Remove Beneficiary',
        content: {
          type: 'option',
          name: 'Beneficiary',
          icon: 'iatse-icon-user-verified',
          value: [
            { label: 'Other', value: 'Distribute allocation equally among other beneficiaries' },
            { label: 'Primary', value: 'Distribute allocation to primary beneficiary' },
          ],
        },
      }
    }
  },
});
</script>