<template>
    <div class="d-flex flex-column gap-20 w-100">
        <SelectComponent :options=savedTemplates :singleArrow="true" v-model:selectedOption="selectedTemplate"
            customClass="w-25 w-sm-100" :hasDefaultOption="false" @change="emitChangeTemplate" />
        <div class="bg-light-green flex-md-row flex-column rounded d-flex align-items-md-center w-100 pdl-20 sm-pdl-5 pdt-25 pdb-25 gap-40">
            <h5 class="sm-pdl-10">Local</h5>
            <RadioButton :options="localTypes" name="LocalType" v-model:modelValue="localFilters.Local" id="LocalType"
                @change="emitUpdate" customClass="primary small bold" />
        </div>

        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <TextInput type="text" label="Member's First Name" placeholder="Enter Member's First Name" :max=25
                v-model:modelValue="localFilters.FirstName" @change="emitUpdate" />
            <TextInput type="text" label="Member's Last Name" placeholder="Enter Member's Last Name" :max=25
                v-model:modelValue="localFilters.LastName" @change="emitUpdate" />
            <div class="custom-select d-flex flex-row align-items-end gap-15">
                <DateInput label="Work Period" v-model:modelValue="localFilters.PeriodStart" :isRequired="true" @change="emitUpdate" :error="localErrors.PeriodStart"/>
                <DateInput v-model:modelValue="localFilters.PeriodEnd" @change="emitUpdate" :error="localErrors.PeriodEnd"/>
            </div>
        </div>
        <div class="d-flex gap-20 w-100 flex-column flex-md-row">
            <div class="custom-select links">
                <label class="body-text"> Employer (s) </label>
                <Multiselect v-model="localFilters.Employers" placeholder="Type to search" label="Name" track-by="ID"
                    :options="employers" :loading="loadingProductions" :multiple="true" :hide-selected="true"
                    :searchable="true" :close-on-select="true" @select="handleEmployersChange"
                    @open="handleEmployersOpen" @search-change="handleEmployersSearch" @change="emitUpdate">
                </Multiselect>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Production (s) </label>
                <Multiselect v-model="localFilters.Productions" placeholder="Type to search" label="Name" track-by="ID"
                    :options="productions" :multiple="true" :hide-selected="true" :searchable="true"
                    :close-on-select="true" @select="handleProductionChange" @open="handleProductionsOpen"
                    @search-change="handleProductionsSearch" :loading="loadingProductions" @change="emitUpdate">
                </Multiselect>
            </div>
            <div class="custom-select links">
                <label class="body-text"> Payor (s) </label>
                <Multiselect v-model="localFilters.Payors" placeholder="Type to search" label="Name" track-by="ID"
                    :options="payors" :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handlePayors" @open="handlePayorsOpen" @search-change="handlePayorsSearch"
                    @change="emitUpdate">
                </Multiselect>
            </div>
        </div>

        <div class="d-flex gap-20 flex-column flex-md-row">
            <div class="custom-select links">
                <label class="body-text"> Fund (s) </label>
                <Multiselect v-model="localFilters.Funds" placeholder="Type to search" label="Name" track-by="ID"
                    :options="funds" :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                    @select="handleFunds" @search-change="handleFundsSearch" @change="emitUpdate">
                </Multiselect>
            </div>
            <TextInput type="text" name="number" label="Member’s NBF ID / SSN" placeholder="Enter Member’s NBF ID / SSN" :max=25
                v-model:modelValue="localFilters.PersonID" @change="emitUpdate" />
            <div class="custom-select links">
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, PropType, defineComponent, computed, watch } from 'vue';
import { LocalReportFilters, Employer } from '@/interfaces/local';
import { RadioOption, SelectOption } from '@/interfaces/interfaces';
import { useAuthStore } from '@/stores/auth';
import Multiselect from 'vue-multiselect';
import DateInput from '@components/form/DateInput.vue';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import 'vue-multiselect/dist/vue-multiselect.css';
import axios from 'axios';

interface FundOption {
    Id: number,
    Name: string
}

export default defineComponent({
    components: {
        Multiselect,
        TextInput,
        DateInput,
        SelectComponent
    },

    props: {
        filters: {
            type: Object as PropType<LocalReportFilters>,
            required: true,
        },
        savedTemplates: Array as PropType<SelectOption[]>,
        template: {
            type: Object as PropType<SelectOption>,
            required: true,
        },
        inputErrors: {
            type: Object,
            required: true,
        },
    },

    setup(props, { emit }) {
        const localFilters = reactive({ ...props.filters });
        const localErrors = reactive({ ...props.inputErrors });
        const selectedTemplate = ref<SelectOption | null>({ ...props.template });
        const loadingEmployers = ref(false);
        const loadingProductions = ref(false);
        const employers = ref([]);
        const productions: Ref<Employer[]> = ref([]);
        const payors: Ref<Employer[]> = ref([]);
        const funds: Ref<FundOption[]> = ref([]);
        const errors: Ref<string[]> = ref([]);
        const allOption = { ID: 0, Name: 'ALL' };
        const userName = useAuthStore()?.userName;

        const localTypes: RadioOption[] = [
            { label: "Work in jurisdiction", value: 0 },
            { label: 'Home Local', value: 1 },
            { label: 'Both', value: 2 },
        ];

        watch(() => props.filters, (newFilters) => {
            Object.assign(localFilters, newFilters);
        }, {deep: true});

        watch(() => props.template, (newTemplate) => {
            selectedTemplate.value = newTemplate;
        }, {deep: true});

        watch(() => props.inputErrors, (newErrors) => {
            Object.assign(localErrors, newErrors);
        }, {deep: true});

        const emitUpdate = () => {
            console.log(">>> Emit");
            emit('update-filters', localFilters);
        };

        const emitChangeTemplate = (payload: SelectOption) => {
            console.log(">>> Emit Change Template");
            emit('change-template', payload);
        };

        const hasEmployers = computed(() => localFilters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => localFilters.Productions?.some(prod => prod.ID !== 0));
        const hasPayors = computed(() => localFilters.Payors?.some(payor => payor.ID !== 0));

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            loadingEmployers.value = true;

            try {
                const response = await axios.get(`api/local/employers?query=${query}`);
                employers.value = response.data;
            }
            catch (error) {
                console.error('Error searching employer:', error);
            }

            loadingEmployers.value = false;
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            loadingProductions.value = true;
            const employerIds = localFilters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            try {
                const response = await axios.get(`api/local/adhoc-productions?s=${query}&EmployerIDs=${employerIds}&local=${localFilters.Local}`);
                productions.value = response.data;
            }
            catch (error) {
                console.error('Error searching production:', error);
            }

            loadingProductions.value = false;
        };

        // Handle payors search
        const handlePayorsSearch = async (s: string) => {
            loadingProductions.value = true;
            const employerIds = localFilters.Employers?.map((employer: Employer) => {
                if (employer.ID !== 0)
                    return employer.ID
            });

            const productionIds = localFilters.Productions?.map((payor: Employer) => {
                if (payor.ID !== 0)
                    return payor.ID
            });

            try {
                const response = await axios.get(`api/local/adhoc-payors?s=${s}&EmployerIDs=${employerIds}&ProductionsIDs=${productionIds}&local=${localFilters.Local}`);
                payors.value = response.data;
            }
            catch (error) {
                console.error('Error searching payors:', error);
            }

            // loadingProductions.value = false;
        };

        const handleFundsSearch = async (s: string) => {
            try {
                const response = await axios.get(`api/local/adhoc-funds?s=${s}`);
                funds.value = response.data;
            }
            catch (error) {
                console.error('Error searching payors:', error);
            }
        };

        const handleEmployerChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                localFilters.Employers = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(localFilters.Employers)).filter((type: Employer) => type.ID !== 0)
                localFilters.Employers = [...filteredTypes];
                handleProductions();
            }
        }

        const handleProductions = async () => {
            await handleProductionsSearch('');

            if (localFilters.Productions && localFilters.Productions[0]?.ID !== 0) {
                localFilters.Productions = localFilters.Productions?.filter((prod: Employer) => productions.value.some(p => p.ID === prod.ID)) || [];
            }
        }

        const handlePayors = async () => {
            await handlePayorsSearch('');

            if (localFilters.Payors && localFilters.Payors[0]?.ID !== 0) {
                localFilters.Payors = localFilters.Payors?.filter((pay: Employer) => payors.value.some(p => p.ID === pay.ID)) || [];
            }
        }

        const handleFunds = async () => {
            await handleFundsSearch('');

            if (localFilters.Funds && localFilters.Funds[0]?.ID !== 0) {
                localFilters.Funds = localFilters.Funds?.filter((fund: Employer) => funds.value.some(p => p.Id === fund.ID)) || [];
            }
        }

        const handleProductionChange = (newSelection: Employer) => {
            if (newSelection.ID === 0) {
                localFilters.Productions = [allOption];
            }
            else {
                const filteredTypes: Employer[] = JSON.parse(JSON.stringify(localFilters.Productions)).filter((type: Employer) => type.ID !== 0)
                localFilters.Productions = [...filteredTypes];
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = localFilters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                handleProductionsSearch(keyword);
            }
        };

        const handlePayorsOpen = async (s: string) => {
            const employerIds = localFilters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = s ?? '';

            if (!employerIds?.includes(0)) {
                handlePayorsSearch(keyword);
            }
        };

        return {
            localFilters,
            localErrors,
            localTypes,
            selectedTemplate,
            employers,
            loadingEmployers,
            loadingProductions,
            productions,
            payors,
            funds,
            errors,
            hasEmployers,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleProductionsOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handlePayors,
            handleFunds,
            handlePayorsSearch,
            handleFundsSearch,
            handlePayorsOpen,
            emitUpdate,
            emitChangeTemplate,
        };
    }
})
</script>

<style scoped>
.local-options {
    padding-bottom: 20px;
    display: flex;
    justify-content: start;
}

.local-options>div {
    padding-left: 15px;
}

.local-options>div label {
    padding-left: 7px;
}
</style>