import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withKeys as _withKeys } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_2 = { class: "w-full d-flex gap-3 align-items-end flex-column" }
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PhoneField = _resolveComponent("PhoneField")!

  return (_openBlock(), _createElementBlock("form", {
    id: "email-change-form",
    class: "form account-settings",
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"])),
    onKeydown: _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]), ["enter"]))
  }, [
    (_ctx.errors && _ctx.errors.General)
      ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(_ctx.errors?.General), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PhoneField, {
        type: "tel",
        label: "Phone number",
        placeholder: "x (xxx) xxx-xxxx",
        max: 17,
        modelValue: _ctx.phone,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phone) = $event))
      }, null, 8, ["modelValue"]),
      _createElementVNode("button", {
        type: "submit",
        class: _normalizeClass(["btn toggle-control lh-sm w-100", {'btn-primary': !_ctx.isButtonDisabled, 'btn-secondary': _ctx.isButtonDisabled}]),
        disabled: _ctx.isButtonDisabled
      }, " Update ", 10, _hoisted_3)
    ])
  ], 32))
}