<template>
    <form id="confirmationForm" class="form">
        <div class="divider divider_green mb-30"> </div>
        <input type="hidden" id="actionInput" />

        <Textarea label="Comment" palceholder="Type a comment" v-model:modelValue="comment" :readonly="status != 1" :error="error" :isRequired="true"/>
        <div v-if="status === '1'" class="action-buttons sm-pdr-0 sm-pdl-0">
            <button type="submit" class="btn btn-secondary" @click.prevent="handleReview('Reject')" :disabled="loadingReview"> Reject </button>
            <button class="btn btn-primary" :disabled="approveButton.Disabled || loadingReview"
                @click.prevent="handleReview('Approve')">  {{ approveButton.Value }} </button>
        </div>
    </form>
</template>

<script lang="ts">
import { ref, defineComponent, toRefs, PropType } from 'vue';
import Textarea from '@/components/form/TextareaComponent.vue';

export default defineComponent({
    props: {
        approveButton: {
            type: Object as PropType<Record<string, boolean>>,
            required: true
        },
        comments: String,
        loadingReview: Boolean,
        errors: String,
        status: String as PropType<string | number>,
    },
    components: {
        Textarea
    },
    setup(props, { emit }: { emit: (event: string, ...args: any[]) => void }) {
        const { comments: existingComment } = toRefs(props);
        const comment = props.comments !== '' ? existingComment : ref('');
        const error = ref<string|null>(null);

        const handleReview = (type: string) => {
            if (comment.value === '') {
                error.value = 'Comments field is required';

                return;
            }

            emit('handle-review', type, comment);
        };

        return {
            comment,
            error,
            handleReview,
        };
    }
});
</script>