<template>
    <AlertsComponent :errors="errors?.General" />
    <div class="plan-content">
        <div class="d-flex align-items-center gap-20 w-100">
            <DateInput label="Date" v-model:modelValue="data.Date" :readonly="true"/>
            <TextInput type="text" label="IP Address" :max=50 v-model:modelValue="data.IPAddress" :readonly="true" />
        </div>
        <div class="d-flex flex-column gap-15 w-100">
            <h2 class="sm-fs-base"> Verification </h2>
            <span class="fw-light sm-fs-sm"> (Note: Password should be the same as used for logging in)</span>
        </div>

        <TextInput type="text" name="mixed" label="Name" :max=50 v-model:modelValue="data.Name" :is-required="true" :error="errors.Name" />
        <TextInput type="password" label="Password" :max=100 v-model:modelValue="data.Password" :is-required="true" :error="errors.Password" />
    </div>
    <div class="w-100 d-flex align-items-center justify-content-between gap-4 sm-mb-100">
        <button class="btn btn-secondary" @click="handleBack" :disabled="loadingSubmit">Back</button>
        <button class="btn btn-primary" @click="handleNext" :disabled="loadingSubmit">Verification</button>
    </div>
</template>

<script lang="ts">
import { useAuthStore } from '@/stores/auth';
import { useParticipantStore } from '@/stores/participant';
import { defineComponent, ref, Ref, toRefs, reactive } from 'vue';
import { useFormattedDate } from '@/composable/useFormattedDate';
import { useModalStore } from '@/stores/modal';
import TextInput from '@components/form/TextInput.vue';
import DateInput from '@components/form/DateInput.vue';

import axios from 'axios';

export default defineComponent({
    props: {
        ipAddress: String,
        download: String
    },
    components: {
        TextInput,
        DateInput,
    },
    setup(props, { emit }) {
        const loadingSubmit = ref(false);
        const errors: Ref<string[]> = ref([]);
        const authStore = useAuthStore();
        const participantStore = useParticipantStore();
        const {formatDate} = useFormattedDate();
        const data = reactive({
            Date: formatDate(new Date().toISOString().split('T')[0]),
            Name: participantStore.userEmail,
            IPAddress: props.ipAddress,
            Password: '',
            Download: props.download
        });

        const handleBack = () => {
            emit('handle-back');
        }

        const handleNext = async () => {
            loadingSubmit.value = true;

            await axios
                .post('api/employer/health-coverage-data', { ...data })
                .then((response) => {
                    useModalStore().openModal(
                        'validate-user-2fa',
                        'Validate User',
                        'modal-success',
                        null,
                        {
                            onConfirm: () => {
                                emit('handle-next', response.data.Requests);
                            }
                        },
                        null,
                        null,
                        {email: participantStore.userEmail ?? '' }
                    );
                })
                .catch((error) => {
                    if (error.response) {
                        errors.value = error.response.data.errors
                    }
                })
                .finally(() => {
                    loadingSubmit.value = false;
                    setTimeout(() => errors.value = [], 3500);
                });
        }

        return {
            loadingSubmit,
            data,
            errors,
            handleBack,
            handleNext
        };
    }
})
</script>