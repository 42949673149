<template>
    <div class="checkbox recaptcha" :class="recaptchaError ? 'error' : ''">
        <div class="checkbox-input">
            <input type="checkbox" class="links recaptcha" id="recaptcha" @click.prevent="handleRecaptcha" :disabled="loadingRecaptcha || token">
            <div class="loader" v-if="loadingRecaptcha"></div>
            <i class="iatse-icon-green-tick checkmark" v-if="!loadingRecaptcha && token"></i>
            <label class="body-text" for="recaptcha">I'm not a robot</label>
        </div>
        <div class="recaptcha-wrapper">
            <span class="iatse-icon-reCAPTCHA"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>
            <small class="text">reCAPTCHA</small>
            <small class="text">Privacy-Terms</small>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useReCaptcha, IReCaptchaComposition } from 'vue-recaptcha-v3';

export default defineComponent({
    props: {
        recaptchaError: {
            type: Boolean,
            required: true,
        },
        loadingRecaptcha: {
            type: Boolean,
            required: true,
        },
        token: {
            type: String,
            default: null,
        },
    },
    emits: ['update:recaptchaError', 'update:loadingRecaptcha', 'update:token'],
    setup(_, { emit }) {
        const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() ?? {};

        const handleRecaptcha = async () => {
            emit('update:loadingRecaptcha', true);
            try {
                await recaptchaLoaded?.();

                if (!recaptchaLoaded || !executeRecaptcha) {
                    emit('update:recaptchaError', 'Recaptcha is not initialized');
                    return;
                }

                const response = await executeRecaptcha('login');
                emit('update:token', response);

                if (response) {
                    emit('update:recaptchaError', false);
                }
            } catch (error) {
                console.error("Recaptcha execution error:", error);
                emit('update:recaptchaError', 'Recaptcha execution failed');
            }

            setTimeout(() => {
                emit('update:loadingRecaptcha', false);
            }, 500);

            setTimeout(() => {
                emit('update:token', null);
            }, 60000); // Token expires after 1 minute
        };

        return {
            handleRecaptcha
        };
    },
});
</script>
