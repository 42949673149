<template>
    <div class="modal-body">
        <div class="plan-header">
            <div class="plan-breadcrumbs">
                <div class="close-btn close-plan-modal" @click="closeModal"> <i class="iatse-icon-close"></i></div>
            </div>
        </div>
        <i :class="modalProps.content.icon"> </i>
        <h2> {{ modalProps.title }} </h2>
        <p v-if="modalProps.content.type === 'text'" v-html="modalProps.content.value"></p>
        <!-- <p v-if="modalProps.content.type === 'text'"> {{ modalProps.content.value }} </p> -->
        <RadioButton v-else :options="modalProps.content.value" :name="modalProps.content?.name"
            v-model:modelValue="selectedValue" />
    </div>

    <div v-if="orderedButtons.length" class="modal-actions">
        <a
            v-for="(btn, index) in orderedButtons"
            :key="index"
            href=""
            class="btn plan-modal"
            :class="{
                'btn-primary': (index === 0 && (modalProps.order ?? 0) === 1) || (index === 1 && (modalProps.order ?? 0) === 0),
                'btn-secondary': (index === 0 && (modalProps.order ?? 0) === 0) || (index === 1 && (modalProps.order ?? 0) === 1)
            }"
            @click.prevent="handleButtonClick(index)"
            >
            {{ btn }}
        </a>
    </div>

    <div v-else-if="modalProps.onConfirm" class="modal-actions">
        <a v-if="modalProps.onConfirmButton" href="" class="btn btn-secondary plan-modal" @click.prevent="handleSubmit">
            {{ modalProps.onConfirmButton }} </a>
        <template v-else>
            <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeModal"> Cancel </a>
            <a href="" class="btn btn-primary plan-modal" @click.prevent="handleSubmit"> Save </a>
        </template>
    </div>

    <div v-else-if="modalProps.closeSelf" class="modal-actions">
        <a href="" class="btn btn-secondary plan-modal" @click.prevent="close"> Ok </a>
    </div>

    <div v-else class="modal-actions">
        <a href="" class="btn btn-secondary plan-modal" @click.prevent="closeAbove">
            {{ modalProps.onConfirmButton ? modalProps.onConfirmButton : 'Ok' }} </a>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import { ModalProps } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import RadioButton from '@/components/form/RadioButton.vue';

export default defineComponent({
    props: {
        modalProps: {
            type: Object as PropType<ModalProps>,
            default: () => ({
                title: 'Success',
                content: {
                    type: 'text',
                    value: 'Default content',
                    icon: 'iatse-icon-user-verified'
                },
                order: 0
            })
        },
        index: Number
    },
    components: {
        RadioButton
    },
    setup(props, { emit }) {
        const selectedValue = ref(props.modalProps.content?.selected);
        const orderedButtons = computed(() => {
            if (props.modalProps?.confirmButtons && props.modalProps.order === 1) {
                return [...props.modalProps.confirmButtons].reverse();
            }

            return props.modalProps?.confirmButtons || [];
        });

        watch(() => props.modalProps.content?.selected, (newValue) => {
            selectedValue.value = newValue;
        });

        const handleButtonClick = (index: number) => {
        if (index === 0) {
            // Handle the secondary action (close modal)
            if ((props.modalProps.order ?? 0 ) === 0) {
                closeModal();
            } else {
                handleSubmit();
            }
        } else {
            // Handle the primary action (submit)
            if ((props.modalProps.order ?? 0 ) === 0) {
                handleSubmit();
            } else {
                closeModal();
            }
        }
        };

        const closeModal = () => {
            props.modalProps.onConfirm ? emit('close') : closeAbove();
        }

        const close = () => {
            emit('close-above', Number(props.index) - 1);
        }

        const closeAbove = () => {
            emit('close-above', Number(props.index) - 2);
        }

        const handleSubmit = () => {
            emit('handle-submit', selectedValue.value);
        }

        return {
            selectedValue,
            orderedButtons,
            handleButtonClick,
            closeModal,
            closeAbove,
            close,
            openModal: useModalStore().openModal,
            handleSubmit,
        }
    }
})
</script>