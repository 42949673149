export function useFormattedDate() {
  const formatDate = (dateString: string): string => {
    // Split the input date (assuming 'YYYY-MM-DD' format)
    const [year, month, day] = dateString.split('-').map(Number);
    console.log('>>>DATE', year, month, day);

    // Create a date with local time to avoid UTC conversion issues
    const date = new Date(Date.UTC(year, month - 1, day));
    console.log('>>>format', date);

    const returnDate = `${month}/${day}/${year}`;
    console.log('>>>returnDate', returnDate);

    return returnDate;
  };

  return {
    formatDate
  };
}
