<template>
    <AlertsComponent :error="errors?.General" />
    <div class="pdl-10 pdr-10 mt-30">
        <h2 class="mb-20">Summarized Contract Information</h2>
        <MessageCard message="This page allows you to look for specific CBA information based on different search parameters that are on file at the Fund Office."
        type="primary" iconClass="iatse-icon-alarm"  classes="info_card__page" />
        <form class="d-flex flex-column gap-4 mt-20">
            <div class="d-flex flex-column gap-20 sm-gap-10">
                <div v-if="isLocal" class="d-flex align-items-md-end flex-md-row flex-column gap-20 sm-gap-10 w-100">
                    <div class="d-flex align-items-end gap-20 w-50 w-sm-100">
                        <DateInput label="Work Period" v-model:reset="reset" v-model:modelValue="filters.WorkPeriod.StartDate" :isRequired="true" :error="errors['WorkPeriod.StartDate']"/>
                        <DateInput v-model:reset="reset" v-model:modelValue="filters.WorkPeriod.EndDate" :error="errors['WorkPeriod.EndDate']"/>
                    </div>
                    <div class="custom-select links w-50 w-sm-100">
                        <label class="body-text"> Employer(s): </label>
                        <Multiselect v-model="filters.Employers" :options="data.employers" placeholder="Type to search"
                            :multiple="true" :hide-selected="true" :searchable="true" :close-on-select="true"
                            @select="handleEmployerChange" label="Name" track-by="ID"
                            @search-change="handleEmployersSearch" @remove="handleProductions"
                            :loading="loading.employers">
                        </Multiselect>
                        <small v-if="errors.Employers?.length" class="text-secondary-red"> {{ errors.Employers.join('. ') }} </small>
                    </div>
                </div>
                <div v-else class="d-flex align-items-end gap-20 w-100">
                    <DateInput v-model:reset="reset" label="Work Period Start" v-model:modelValue="filters.WorkPeriod.StartDate" :isRequired="true" :error="errors['WorkPeriod.StartDate']"/>
                    <DateInput v-model:reset="reset" label="Work Period End" v-model:modelValue="filters.WorkPeriod.EndDate" :isRequired="true" :error="errors['WorkPeriod.EndDate']"/>
                </div>
                <div class="d-flex align-items-end gap-20 w-100 flex-md-row flex-column">
                    <div class="custom-select links">
                        <label class="body-text"> Production(s) <span v-if="hasEmployers"> for selected
                                Employer(s)</span>: </label>
                        <Multiselect v-model="filters.Productions" :options="data.productions"
                            placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                            :close-on-select="true" @select="handleProductionChange" label="Name" track-by="ID"
                            @open="handleProductionsOpen" @search-change="handleProductionsSearch"
                            :loading="loading.productions">
                        </Multiselect>
                    </div>
                    <div class="custom-select links">
                        <label class="body-text"> Contract Type: </label>
                        <Multiselect v-model="filters.ContractTypes" :options="data.contractTypes"
                            placeholder="Type to search" :multiple="true" :hide-selected="true" :searchable="true"
                            :close-on-select="true" label="Name" track-by="ID" @select="handleContractChange"
                            @search-change="handleContractSearch" @open="handleContractOpen"
                            :loading="loading.contractTypes">
                        </Multiselect>
                    </div>
                </div>
                <div class="d-flex justify-content-between align-items-center gap-20 w-100 mt-10">
                    <div class="d-flex justify-content-between  gap-20">
                        <button type="button" class="btn btn-secondary" @click.prevent="resetFilters">Clear Filters</button>
                        <button class="btn btn-primary" @click.prevent="searchData" @keydown.enter="searchData">
                            {{ loadingSearch ? 'Searching...' : 'Apply Filters' }}
                        </button>
                    </div>
                    <i class="iatse-icon-printer fs-xxl text-primary plan-modal sm-fs-xl" v-if="contractInformation.length"  @click="printList"></i>
                </div>
            </div>
        </form>
        <div v-if="!loadingSearch && contractInformation.length">
            <table class="small striped toggle no-underline mt-30" id="table-contract-info">
                <thead class="large large_padding">
                    <tr>
                        <th v-if="isLocal" class="col-md-1">Employer</th>
                        <th class="col-md-1">Production</th>
                        <th class="col-md-1">Contract Type</th>
                        <th class="col-md-1">Contract Sub <br> Type</th>
                        <th class="col-md-1">Contract Begin <br>Date </th>
                        <th class="col-md-1">Contract End <br> Date </th>
                        <th class="col-md-1">Rate Group <br> Name</th>
                        <th class="col-md-2">Rate </th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(entry, index) in contractInformation" :key="index">
                        <tr class="large" :class="{ 'page-break': (index + 1) % 26 === 0 }">
                            <td v-if="isLocal" class="col-md-1">{{ entry.Employer }}</td>
                            <td class="col-md-1">{{ entry.Production }}</td>
                            <td class="col-md-1">{{ entry.Contract }}</td>
                            <td class="col-md-1">{{ entry.ContractSubType }}</td>
                            <td class="col-md-1">{{ entry.ContractBeginDate }}</td>
                            <td class="col-md-1">{{ entry.ContractEndDate }}</td>
                            <td class="col-md-1">{{ entry.RateGroupName }}</td>
                            <td class="col-md-2">
                                <div class="d-flex flex-column gap-2" v-for="(rate, key, mainIndex) in entry.Rate" :key="key">
                                    <span class="fw-bold"> {{ key }} </span>
                                    <span v-for="(value, index) in rate" :key="index">
                                        {{ value }}
                                    </span>
                                    <div v-if="mainIndex < Object.keys(entry.Rate).length - 1" class="divider mt-5 mb-5"> </div>
                                </div>
                            </td>
                        </tr>
                        <!-- Row for top page space (temp method) -->
                        <tr v-if="(index + 1) % 26 === 0" class="hidden">
                            <td colspan="7" style="background-color: white; height: 130px;"></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script lang="ts">
import { reactive, ref, Ref, computed, watch } from 'vue';
import { Coverage } from "@/interfaces/local";
import { usePrint } from '@/composable/usePrint';
import { useAuthStore } from '@/stores/auth';
import { useFormattedDate } from '@/composable/useFormattedDate';
import { PrintData } from '@/interfaces/interfaces';
import Multiselect from 'vue-multiselect';
import DateInput from '@components/form/DateInput.vue';
import axios from 'axios';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Filters {
    WorkPeriod: {
        StartDate?: string,
        EndDate?: string,
    };
    Employers: Employer[] | null;
    Productions: Employer[] | null;
    ContractTypes: Employer[] | null;
}

interface Employer {
    ID: string | number;
    Name: string;
}

interface Data {
    employers: Employer[];
    productions: Employer[];
    contractTypes: Employer[];
}

export default {
    components: {
        Multiselect,
        DateInput,
    },

    setup() {
        const loadingSearch = ref(false);
        const reset = ref(false);
        const { formatDate } = useFormattedDate();
        const loading = reactive({
            employers: false,
            productions: false,
            contractTypes: false,
        })
        const allOption = { ID: 0, Name: 'ALL' };
        const contractInformation: Ref<Coverage[]> = ref([]);
        const { printElementById } = usePrint();
        const errors: Ref<string[]> = ref([]);
        const success = ref(null);
        const data: Data = reactive({
            employers: [],
            productions: [],
            contractTypes: [],
        })
        const initialFilters = {
            WorkPeriod: {
                StartDate: '',
                EndDate: '',
            },
            Employers: [allOption],
            Productions: [allOption],
            ContractTypes: [allOption],
        };
        const filters: Filters = reactive({ ...JSON.parse(JSON.stringify(initialFilters)) });
        const hasEmployers = computed(() => filters.Employers?.some(employer => employer.ID !== 0));
        const hasProductions = computed(() => filters.Productions?.some(prod => prod.ID !== 0));
        const hasContractTypes = computed(() => filters.ContractTypes?.some(contract => contract.ID !== 0));
        const isEmployer = useAuthStore().isEmployer;
        const isLocal = useAuthStore().isLocal;
        const userType = isEmployer ? 'employer' : 'local';
        const printData: PrintData = reactive({
            Header: {
                EmployerName: useAuthStore()?.user?.name ?? '',
                ReportDate: formatDate(new Date().toDateString()),
                PrintDate: formatDate(new Date().toDateString()),
            },
            Filters: {
                Productions: 'All',
                ContractTypes: 'All',
                DateRange: filters.WorkPeriod.StartDate + ' - ' + filters.WorkPeriod.EndDate
            }
        });

        watch(() => filters.ContractTypes, (newVal) => {
            if(printData.Filters?.ContractTypes) {
                printData.Filters.ContractTypes = newVal?.length
                ? newVal.map((type: Employer) => type.Name).join(', ')
                : 'All';
            }
        });

        watch(() => filters.Productions, (newVal) => {
            if(printData.Filters?.Productions) {
                printData.Filters.Productions = newVal?.length
                ? newVal.map((prod: Employer) => prod.Name).join(', ')
                : 'All';
            }
        });

        watch(() => filters.WorkPeriod, (newVal) => {
            if(printData.Filters?.DateRange) {
                printData.Filters.DateRange = newVal
                ?  formatDate(newVal.StartDate ?? '') + ' - ' +  formatDate(newVal.EndDate ?? '')
                : '';
            }
        }, { deep: true });

        const printList = printElementById('table-contract-info', 'Summarized Contract Information', printData);

        const resetFilters = () => {
            contractInformation.value = [];
            Object.assign(filters, JSON.parse(JSON.stringify(initialFilters)));
            reset.value = true;
        }

        // Handle employers search
        const handleEmployersSearch = async (query: string) => {
            await handleSelectionSearch('employers', query);
        };

        // Handle productions search
        const handleProductionsSearch = async (query: string) => {
            await handleSelectionSearch('productions', query);
        };

        // Handle contract types search
        const handleContractSearch = async (query: string) => {
            await handleSelectionSearch('contractTypes', query);
        };

        const handleContractChange = async (newSelection: Employer) => {
            await handleSelectionChange('ContractTypes', newSelection);
        }

        const handleEmployerChange = async (newSelection: Employer) => {
            await handleSelectionChange('Employers', newSelection);
        }

        const handleProductionChange = async (newSelection: Employer) => {
            await handleSelectionChange('Productions', newSelection);
        }

        // Handle Search in Multiselect
        const handleSelectionSearch = async (type: string, query: string) => {
            loading[type as keyof Data] = true;
            let route = `api/${userType}/${type}?query=${query}`

            if (type === 'productions') {
                const employerIds = filters.Employers?.map((employer: Employer) => {
                    if (employer.ID !== 0)
                        return employer.ID
                });

                route += `&&EmployerIDs=${employerIds}`;
            }

            try {
                const response = await axios.get(route);
                data[type as keyof Data] = response.data;
            }
            catch (error) {
                console.error(`Error searching ${type}:`, error);
            }

            loading[type as keyof Data] = false;
        }

        // Handle Change Selection
        const handleSelectionChange = async (type: string, newSelection: Employer) => {
            if (type !== 'WorkPeriod') {
                if (newSelection.ID === 0) {
                    filters[type as keyof typeof filters] = [allOption];
                }
                else {
                    const filteredTypes: Employer[] = JSON.parse(JSON.stringify(filters[type as keyof typeof filters])).filter((type: Employer) => type.ID !== 0)
                    filters[type as keyof typeof filters] = [...filteredTypes];

                    if (type === 'Employers') {
                        await handleProductions();
                    }
                }
            }
        };

        // Filter selected productions for selected employers
        const handleProductions = async () => {
            await handleProductionsSearch('');

            // if is not selected ALL option, filter selected productions
            if (filters.Productions && filters.Productions[0]?.ID !== 0) {
                const filteredProductions = filters.Productions?.filter(prod => data.productions.some(p => p.ID === prod.ID)) || [];
                filters.Productions = filteredProductions;
            }
        }

        const handleProductionsOpen = async (query: string) => {
            const employerIds = filters.Employers?.map((employer: Employer) => employer.ID);
            const keyword = query ?? '';

            if (!employerIds?.includes(0)) {
                await handleProductionsSearch(keyword);
            }
        };

        const handleContractOpen = async () => {
            setTimeout(() => {
                handleContractSearch('');
            }, 1000)
        };

        // Get Contract Information
        const searchData = async () => {
            loadingSearch.value = true;
            contractInformation.value = [];
            const formData = { ...filters };
            formData.Productions = !hasProductions.value ? null : formData.Productions;
            formData.ContractTypes = !hasContractTypes.value ? null : formData.ContractTypes;

            if (isLocal) {
                formData.Employers = !hasEmployers.value ? null : formData.Employers;
            }

            await axios
                .post(`api/${userType}/contract-information`, {
                    ...formData
                })
                .then(response => {
                    contractInformation.value = response.data;
                })
                .catch(error => {
                    if (error.response) {
                        errors.value = error.response.data.errors
                    }
                })
                .finally(() => {
                    loadingSearch.value = false;

                    setTimeout(() => {
                        success.value = null
                        errors.value = []
                    }, 3500);
                })
        }

        return {
            reset,
            loading,
            errors,
            success,
            filters,
            data,
            isEmployer,
            isLocal,
            hasEmployers,
            loadingSearch,
            contractInformation,
            handleEmployerChange,
            handleProductions,
            handleProductionChange,
            handleContractChange,
            handleProductionsOpen,
            handleContractOpen,
            handleEmployersSearch,
            handleProductionsSearch,
            handleContractSearch,
            resetFilters,
            searchData,
            printList,
        };
    }
}
</script>