import { defineStore } from "pinia";
import { ref, computed } from "vue";
import { useProfileStore } from "@/stores/profile";
import { useInvoicesStore } from '@/stores/cobraInvoices';
import axios from "axios";
import router from "@/router";
import { useParticipantStore } from "./participant";
import { useCoverageStore } from '@/stores/coverage';
import { usePensionStore } from "./pension";
import { useVacationStore } from "./vacation";
import { useLocalStore } from "./local";
import { useEmployerStore } from "./employer";
import { useBeneficiaryStore } from "./beneficiary";

interface Credentials {
    email: string;
    password: string;
}

interface User {
    name: string;
    roleName: string;
    unreadAlerts: number;
    email: string;
    participantId: string | number;
    preferredMethod: string | null;
    active2FA: boolean | null;
}

export const useAuthStore = defineStore('auth', () => {
    // State
    const isImpersonatingStorage = localStorage.getItem("isImpersonating");
    const isImpersonating = ref(isImpersonatingStorage === "1");
    const token = ref<string | null>(localStorage.getItem("authToken"));
    const stopImpersonationRoute = ref("superadmin.users.participants");
    const impersonationToken = ref<string | null>(localStorage.getItem("impersonationToken"));
    const impersonationName = ref<string>(localStorage.getItem("impersonationName") || "");
    const user = ref<User | null>(JSON.parse(localStorage.getItem("user") || "null"));
    const twoFaValidated = ref<boolean | null>(JSON.parse(localStorage.getItem("twoFaValidated") || "null"));
    const authenticated = ref<string | null>(localStorage.getItem("authenticated"));
    const termsAccepted = ref<string | null>(localStorage.getItem("termsAccepted"));

    // Getters
    const isAuthenticated = computed(() => !!token.value && !!termsAccepted.value);
    const isEmployer = computed(() => {
        const employerRoles = ["ROLE_EMPLOYER", "ROLE_EMPLOYER_PAYOR", "ROLE_EMPLOYER_ADMIN"];
        return user.value ? employerRoles.includes(user.value.roleName) : false;
    });
    const isLocal = computed(() => {
        const localRoles = ["ROLE_LOCAL", "ROLE_LOCAL_ADMIN"];
        return user.value ? localRoles.includes(user.value.roleName) : false;
    });
    const isLocalAdmin = computed(() => {
        return user.value ? user.value.roleName === "ROLE_LOCAL_ADMIN" : false;
    });
    const isSuperAdmin = computed(() => {
        return user.value ? user.value.roleName === "ROLE_ADMINISTRATOR" : false;
    });
    const isParticipant = computed(() => {
        return user.value ? user.value.roleName === "ROLE_PARTICIPANT" : false;
    });

    const userName = computed(() => user.value?.name);
    const userEmail = computed(() => user.value?.email);
    const userRole = computed(() => user.value?.roleName);
    const unreadAlerts = computed(() => user.value?.unreadAlerts);

    const getDashboardRoute = computed(() => {
        if (isSuperAdmin.value) {
            return isImpersonating.value ? "participant.dashboard" : "superadmin.dashboard";
        } else if (isParticipant.value) {
            return "participant.dashboard";
        } else if (isEmployer.value) {
            return "employer.dashboard";
        } else if (isLocal.value) {
            return "local.dashboard";
        }
        return "participant.dashboard";
    });

    // Actions
    const getCsrfCookie = async () => {
        try {
            await axios.get("sanctum/csrf-cookie");
        } catch (error) {
            console.error("Error getting CSRF cookie:", error);
        }
    };

    const authenticateUser = async (credentials: Credentials) => {
        await getCsrfCookie();
        clearAuthenticated();
        const response = await axios.post("api/auth", credentials);

        setToken(response.data.token);
        setUser({
            name: response.data.name,
            roleName: response.data.role,
            unreadAlerts: response.data.unreadAlerts,
            email: response.data.email,
            participantId: response.data.participantId,
            preferredMethod: response.data.preferredMethod,
            active2FA: response.data.active2FA,
        });

        console.log(response.data, '@@res');

        if (response.data.legacy == 1 ) {
            await router.push({ name: '2fa.setup', query: { legacy: '1' }});
        } else if (!response.data.preferredMethod) {
            console.log('>>>. pref method not setup')
            await router.push({ name: '2fa.setup'});
        } else if(response.data.active2FA && response.data.preferredMethod) {
            console.log('>>>. second - pref method not setup')
            await router.push({ name: '2fa.verify' });
        } else {
            await router.push({ name: 'terms' });
            console.log('>>>. go to term')
        }
    };

    const loginUser = async () => {
        setTermsAccepted('yes');
        setAuthenticated('yes');
        await router.push({ name: getDashboardRoute.value });
    };

    const updateUnreadAlerts = (newCount: number) => {
        if (user.value) {
            user.value.unreadAlerts = newCount;
        }
    };

    const setToken = (newToken: string | null) => {
        token.value = newToken;
        if (newToken) {
            localStorage.setItem("authToken", newToken);
        } else {
            localStorage.removeItem("authToken");
        }
    };

    const setImpersonateToken = (newToken: string | null, name: string) => {
        impersonationToken.value = newToken;
        impersonationName.value = name;

        if (newToken) {
            localStorage.setItem("impersonationToken", newToken);
            localStorage.setItem("impersonationName", name);
            localStorage.setItem("isImpersonating", "1");

            isImpersonating.value = true;
        } else {
            localStorage.removeItem("impersonationToken");
            localStorage.setItem("impersonationName", "name");
            localStorage.setItem("isImpersonating", "0");
            isImpersonating.value = false;
            impersonationName.value = "";
        }
    };

    const setUser = (newUser: User) => {
        user.value = newUser;
        localStorage.setItem("user", JSON.stringify(newUser));
    };

    const setUserWithToken = async (newToken: string | null) => {
        const response = await axios.get(`api/user-token?Token=${newToken}`);

        const newUser = {
            name: response.data.name,
            roleName: response.data.role,
            unreadAlerts: response.data.unreadAlerts,
            email: response.data.email,
            participantId: response.data.participantId,
            preferredMethod: response.data.preferredMethod,
            active2FA: response.data.active2FA,
        }

        setUser(newUser);
    };

    const set2FaValidated = (newStatus: boolean) => {
        twoFaValidated.value = newStatus;
        localStorage.setItem("twoFaValidated", JSON.stringify(newStatus));
    };

    const setAuthenticated = (newStatus: string) => {
        authenticated.value = newStatus;
        localStorage.setItem("authenticated", JSON.stringify(newStatus));
    };

    const setTermsAccepted = (newStatus: string) => {
        termsAccepted.value = newStatus;
        localStorage.setItem("termsAccepted", JSON.stringify(newStatus));
    };

    const clearAuthenticated = () => {
        authenticated.value = null;
        termsAccepted.value = null;
        localStorage.removeItem("authenticated");
        localStorage.removeItem("termsAccepted");
    };

    const clearUser2FaValidated = () => {
        user.value = null;
        localStorage.removeItem("twoFaValidated");
    };

    const isTwoFaValidated = () => {
        const stored = localStorage.getItem("twoFaValidated");
        // If stored value doesn't exist or is not "true" when parsed, return false by default.
        return stored ? JSON.parse(stored) === true : false;
    };

    const clearUser = () => {
        user.value = null;
        localStorage.removeItem("user");
    };

    const startImpersonation = async (userID: number) => {
        try {
            const response = await axios.post("/api/administrator/impersonate/start", {
                UserID: userID,
            });

            setImpersonateToken(response.data.token, response.data.name);
            setUser({
                name: response.data.name,
                roleName: response.data.role,
                unreadAlerts: response.data.unreadAlerts,
                email: response.data.email,
                participantId: response.data.participantId,
                preferredMethod: response.data.preferredMethod,
                active2FA: response.data.active2FA,
            });

            impersonationToken.value = response.data.token;
            getStopImpersonationRoute();
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.log("++ impersonate error ++" + error?.response?.data?.message);
                throw error.response?.data?.message;
              } else {
               throw'An unknown error occurred';
              }
        }
    };

    const stopImpersonation = async () => {
        try {
            const response = await axios.get("api/administrator/impersonate/stop");

            impersonationToken.value = null;
            isImpersonating.value = false;
            impersonationName.value = "";
            clearUserStore();

            setImpersonateToken(null, "");

            setUser({
                name: response.data.name,
                roleName: response.data.role,
                unreadAlerts: 0,
                email: response.data.email,
                participantId: 0,
                preferredMethod: null,
                active2FA: false,
            });
        } catch (error) {
            console.log("++ impersonate stop error ++" + error);
            throw error;
        }
    };

    const clearUserStore = () => {
        console.log('>>> clear store <<<');
        useProfileStore().resetUserProfile();
        useProfileStore().resetProfile();
        useParticipantStore().reset();
        useCoverageStore().clearCoverageData();
        useInvoicesStore().clearStore();
        usePensionStore().reset();
        useBeneficiaryStore().reset();
        useVacationStore().reset();
        useLocalStore().reset();
        useEmployerStore().reset();
    }

    const logout = () => {
        setToken(null);
        setImpersonateToken(null, "");
        clearAuthenticated();
        clearUser();
        clearUser2FaValidated();
        clearUserStore();
        router.push({ name: "login" });
    };

    const logoutWithoutPush = () => {
        setToken(null);
        setImpersonateToken(null, "");
        clearAuthenticated();
        clearUser();
        clearUser2FaValidated();
        clearUserStore();
    };

    const getStopImpersonationRoute = () => {
        let route = "superadmin.dashboard";

        if (isParticipant.value) {
            route = "superadmin.users.participants";
        } else if (isEmployer.value) {
            route = "superadmin.users.employers";
        } else if (isLocal.value || isLocalAdmin.value) {
            route = "superadmin.users.locals";
        }

        stopImpersonationRoute.value = route;
    };

    return {
        // State
        token,
        stopImpersonationRoute,
        isImpersonating,
        impersonationToken,
        impersonationName,
        user,
        authenticated,
        twoFaValidated,
        isTwoFaValidated,
        termsAccepted,
        // Getters
        isAuthenticated,
        isEmployer,
        isLocal,
        isLocalAdmin,
        isSuperAdmin,
        isParticipant,
        userName,
        userEmail,
        userRole,
        unreadAlerts,
        getDashboardRoute,
        // Actions
        getCsrfCookie,
        authenticateUser,
        loginUser,
        updateUnreadAlerts,
        setToken,
        setUserWithToken,
        setImpersonateToken,
        setUser,
        set2FaValidated,
        clearUser,
        startImpersonation,
        stopImpersonation,
        logout,
        logoutWithoutPush,
        clearUserStore,
        getStopImpersonationRoute,
    };
});
