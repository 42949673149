<template>
     <form id="password-change-form" class="form account-settings" @keypress.enter.prevent="handleSubmit">
        <h4 class="w-100 sm-fs-sm"> Password </h4>
        <small v-if="errors && errors.General?.length" class="text-danger">{{ errors?.General?.join(' ') }}</small>
        <div class="w-full d-flex flex-md-row gap-20 align-items-end flex-column">
            <TextInput type="password" label="Current Password" placeholder="Enter your Current password"
                v-model:modelValue="currentPassword" :error="errors?.current_password ?? null" errorPlacement="top" :displayErrorMessage=false />
            <TextInput type="password" label="New Password" placeholder="Enter New password"
                v-model:modelValue="password" name="password" :error="errors?.password ?? null" errorPlacement="top" :displayErrorMessage=false />

            <TextInput type="password" label="Confirm New Password" placeholder="Enter Confirm New password"
                v-model:modelValue="passwordConfirmation" name="password_confirmation" :error="errors?.password_confirmation ?? null" :displayErrorMessage=false errorPlacement="top" />
            <button type="submit" class="btn toggle-control lh-sm" @click.prevent="handleSubmit"
                :disabled="loadingUpdate || isButtonSubmitDisabled" :class="{'btn-primary': !isButtonSubmitDisabled, 'btn-secondary': isButtonSubmitDisabled}"> Update </button>
            <!-- <button class="btn btn-secondary toggle-control"
                @click.prevent="toggleUpdateSetting(true)" :disabled="loadingUpdate || !isButtonSubmitDisabled"> Discard </button> -->
        </div>
    </form>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import TextInput from '@components/form/TextInput.vue';
import axios from 'axios';

export default defineComponent({
components: {
    TextInput,
},
setup() {
    const modalStore = useModalStore();
    const currentPassword = ref('');
    const password = ref('');
    const passwordConfirmation = ref('');
    const showPassword = ref(false);
    const showPasswordConfirmation = ref(false);
    const isButtonSubmitDisabled = ref(true);
    const isPasswordVerified = ref(false);
    const loadingUpdate = ref(false);
    const success = ref('');
    const errors = ref<Record<string, string| string[]>>({General: []});

    watch(() => [password.value, passwordConfirmation.value, currentPassword.value],
        ([newPassword, newConfirmation, newCurrent]) => {
            if(newPassword === '' && newConfirmation === '' && newCurrent === '') {
                isButtonSubmitDisabled.value = true;
            }
            else {
                isButtonSubmitDisabled.value = false;
            }
    });

    // Toggle the visibility of editable fields
    const toggleUpdateSetting = (discard?: boolean) => {
        isButtonSubmitDisabled.value = false;
        errors.value = { General : []}

        if(discard) {
            password.value = '';
            passwordConfirmation.value = '';
        }
    };

    // Verify current password
    const verifyPassword = async () => {
        loadingUpdate.value = true;

        try {
            const verifyResponse = await axios.post('/api/user/verify-password', {
                current_password: currentPassword.value,
            });

            if (verifyResponse.data.success) {
                isPasswordVerified.value = true;
                toggleUpdateSetting();
            }
        } catch (error: any) {
            isPasswordVerified.value = false;

            if (error.response) {
                errors.value = error.response.data.errors;
                errors.value.General = Object.values(
                    error.response.data.errors
                ).flat() as string[];
            }
        }

        isButtonSubmitDisabled.value = false;
        loadingUpdate.value = false;
    };

    // Togle between hide/show password
    const togglePassword = () => {
        showPassword.value = !showPassword.value;
    }

    const togglePasswordConfirmation = () => {
        showPasswordConfirmation.value = !showPasswordConfirmation.value;
    }

    const handleSubmit = async () => {
        loadingUpdate.value = true;

        if(isButtonSubmitDisabled.value) {
            return;
        }

        await verifyPassword()

        if(!isPasswordVerified.value) {
            return;
        }

        const formData = {
            password: password.value,
            password_confirmation: passwordConfirmation.value,
        };

        axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
            .then(response => {
                success.value = response.data.success;
                errors.value = {General: []};

                setTimeout(() => {
                    success.value = '';
                }, 3500);

                setTimeout(() => {
                    password.value = '';
                    passwordConfirmation.value = '';
                    currentPassword.value = '';
                }, 1000);

                useModalStore().closeAbove(0);

                modalStore.openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Updated Successfully",
                        content: {
                            type: "text",
                            value: 'Your Password has been updated successfully!',
                            icon: "iatse-icon-user-verified"
                        },
                        onConfirmButton: "Close",
                        onConfirm: async () => {
                            isButtonSubmitDisabled.value = true;
                        },
                    },);
            })
            .catch((error: any) => {
                if (error.response) {
                    errors.value = error.response.data.errors;
                    errors.value.General = Object.values(
                        error.response.data.errors
                    ).flat() as string[];
                }

                isButtonSubmitDisabled.value = false;
            })

        loadingUpdate.value = false;
    };

    return {
        success,
        errors,
        password,
        currentPassword,
        passwordConfirmation,
        showPassword,
        showPasswordConfirmation,
        isPasswordVerified,
        loadingUpdate,
        isButtonSubmitDisabled,
        togglePassword,
        togglePasswordConfirmation,
        handleSubmit,
        verifyPassword,
        toggleUpdateSetting,
    };
},
});
</script>
