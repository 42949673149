<template>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12 text-center">
                The screen displays a record of payments you have made towards Plan C coverage
            </div>
        </div>
    </div>
    <!-- Contribution Tables -->
    <div class="contributions-tables cobra-payments">
        <div class="contributions-table-wrapper">
            <div class="contributions-table-item no-striped">
                <table>
                    <thead>
                        <tr>
                            <th>Payment Date</th>
                            <th>Receipt Date</th>
                            <th>Payment Ref #</th>
                            <th>Check / Payment Amount</th>
                            <th>Source</th>
                        </tr>
                    </thead>
                    <tbody v-if="dataLoaded && paymentDetails.length > 0">
                        <tr v-for="(detail, index) in paymentDetails" :key="index">
                            <td>{{ detail.PaymentDate }}</td>
                            <td>{{ detail.ReceiptDate }}</td>
                            <td>{{ detail.PaymentRef }}</td>
                            <td>{{ formatCurrency(detail.Amount) }}</td>
                            <td>{{ detail.Source }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr>
                            <td colspan="7">No statements found.</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex w-100 justify-content-center justify-content-md-end mt-3">

                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import { CappPaymentDetail } from '@/interfaces/health';
import { useCoverageStore } from '@/stores/coverage';

export default {
    setup() {
        const dataLoaded = ref(false);
        const modalStore = useModalStore();
        const openModal = modalStore.openModal;
        const { formatCurrency } = useCurrencyFormatter();
        const paymentDetails = ref<CappPaymentDetail[]>([]);
        const fromDate = modalStore.currentModal?.queryParams?.From as string;
        const toDate = modalStore.currentModal?.queryParams?.To as string;

        console.log

        const fetchData = () => {
            const queryParams: Record<string, string> = {};
            if (fromDate) queryParams.From = fromDate;
            if (toDate) queryParams.To = toDate;

          // Fetch data from the API, sending a flag if periods are already fetched
            axios
                .get('/api/participant/health-welfare/capp-payment-details', {
                    params: queryParams,
                })
                .then((response) => {
                    // If periods have not been fetched yet, store them

                    // Store other plan summary details
                    paymentDetails.value = response.data;
                    dataLoaded.value = true;
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        };

        onMounted(() => {
            fetchData();
        });

        return {
            dataLoaded,
            paymentDetails,
            formatCurrency,
            openModal
        };
    }
}
</script>