import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "container mt-40 mb-40" }
const _hoisted_2 = {
  key: 0,
  class: "d-flex flex-column gap-20 mb-20"
}
const _hoisted_3 = { class: "border rounded p-4" }
const _hoisted_4 = {
  key: 0,
  class: "text-danger mb-20"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_ReCaptcha = _resolveComponent("ReCaptcha")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MessageCard, {
      message: "Please enter the security code below",
      type: "primary",
      iconClass: "iatse-icon-alarm",
      classes: "info_card_full"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, { name: "slide-fade" }, {
        default: _withCtx(() => [
          ($setup.ssn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("h4", null, _toDisplayString($setup.type) + " Social Security #: ", 1),
                _createElementVNode("h4", _hoisted_3, _toDisplayString($setup.ssn), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      ($setup.recaptchaError)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString($setup.recaptchaError?.join('.')), 1))
        : _createCommentVNode("", true),
      _createVNode(_Transition, { name: "slide-fade" }, {
        default: _withCtx(() => [
          (!$setup.ssn)
            ? (_openBlock(), _createBlock(_component_ReCaptcha, {
                key: 0,
                recaptchaError: $setup.recaptchaError,
                loadingRecaptcha: $setup.loadingRecaptcha,
                token: $setup.tokenValue,
                "onUpdate:recaptchaError": _cache[0] || (_cache[0] = ($event: any) => ($setup.recaptchaError = $event)),
                "onUpdate:loadingRecaptcha": _cache[1] || (_cache[1] = ($event: any) => ($setup.loadingRecaptcha = $event)),
                "onUpdate:token": $setup.submitRecaptcha
              }, null, 8, ["recaptchaError", "loadingRecaptcha", "token", "onUpdate:token"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 64))
}