<template>
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-details">
            <ProfileInfo title="Total Credits" type="pension" :items="firstCard" :has-divider="false"
                :use-container="true" />
            <ProfileInfo title="Key Dates" type="pension" :items="secondCard" :has-divider="false"
                :use-container="true" />
        </div>

        <div class="bordered-card overflow-visible">
            <h4 class="pdb-10 sm-fs-sm"> Payments and Contributions </h4>
            <table class="striped small equal-widths toggle no-underline">
                <thead>
                    <tr>
                        <th
                            v-for="(header, key) in pensionStore.history.headers"
                            :key="key"
                            :class="{ 'pdl-40': key === 0 && pensionStore.hasChildren}"
                            >
                            {{ header.title }}
                            <span v-if="header.title === 'Comments'" class="tooltip-wrapper">
                                <i class="iatse-icon-info-tooltip icon_rotate_0"></i>
                                <p class="tooltip-text tooltip-text_secondary">
                                    For definitions of the terms ‘Break in Service,’ ‘Permanent Break in Service,’ ‘Uncredited,’ ‘Vested,’ and ‘Participation,’ please refer to the
                                    <a class="text-white" :href="`${summaryPlanDescriptionLink}`" target="_blank"> Summary Plan Description (SPD). </a>
                                </p>
                            </span>
                        </th>
                    </tr>
                </thead>

                <tbody v-if="Object.entries(pensionStore.history.rows).length">
                    <template v-for="([year, data], mainKey) in Object.entries(pensionStore.history.rows).reverse()"
                        :key="mainKey">
                        <tr class="parent-row sm-pdl-10 sm-pdr-10">
                            <td class="vertical-top">
                                <p v-if="data.Children" class="cell toggle-cell">
                                    <span class="view-details" @click="toggleCell(mainKey)"> {{ toggleStates[mainKey] ? '-' : '+' }}</span>
                                    <span class="text-underline" @click="openHistory(year)">{{ year }}</span>
                                </p>
                                <p v-else class="cell">
                                    <span v-if="pensionStore.hasChildren" class="view-details"></span>
                                    <span class="text-underline" @click="openHistory(year)">{{ year }}</span>
                                </p>
                            </td>
                            <td colspan="10">
                                <div class="table-row_wrapper d-flex flex-column gap-20">
                                    <div v-if="!toggleStates[mainKey]" class="d-flex justify-content-between flex-wrap flex-md-nowrap gap-20">
                                        <div v-for="(item, key) in data.Parent" :key="key" class="cell d-flex gap-2 w-sm-45 text-wrap">
                                            <p class="cell-header hide-desktop"> {{ key }} </p>
                                            <div v-if="Array.isArray(item)" class="cell-text w-100">
                                                <p v-for="(subItem, index) in item" :key="index" class="w-100 d-flex justify-content-between">
                                                      <!-- Display the message -->
                                                    {{ subItem.message }}
                                                    <!-- Display the instruction with a hover tooltip -->
                                                    <span v-if="subItem.instructions" class="tooltip-wrapper">
                                                        <i class="iatse-icon-info-tooltip icon_rotate_0"></i>
                                                        <a
                                                            class="tooltip-text tooltip-text_secondary"
                                                            href="javascript:void(0)"
                                                            @click="openPDF(subItem.instructions)"
                                                            >
                                                                Summary Plan Description
                                                            </a>
                                                        <!-- <a class="tooltip-text tooltip-text_secondary" :href="`${summaryPlanDescriptionLink}#page=${subItem.instructions}`" target="_blank"> Summary Plan Description (Page {{ subItem.instructions }})</a> -->
                                                    </span>
                                                </p>
                                            </div>
                                            <p v-else class="cell-text">
                                                {{ formatItem(item) }}
                                            </p>
                                        </div>
                                    </div>
                                    <transition name="slide-fade">
                                        <div v-if="toggleStates[mainKey] && data.Children" class="subitems-columns">
                                            <template v-for="(child, index) in  Object.values(data.Children) "
                                                :key="index">
                                                <div class="d-flex subitems-columns_wrapper justify-content-between">
                                                    <div class="d-flex cell flex-column gap-2"
                                                        v-for="(childValue, childKey) in child" :key="childKey">
                                                        <p class="cell-header hide-desktop">{{ childKey }}</p>
                                                        <div v-if="Array.isArray(childValue)" class="cell-text">
                                                            <!-- <p v-for="(subItem, index) in childValue" :key="index">
                                                                {{ subItem }}
                                                            </p> -->
                                                        </div>
                                                        <p v-else class="cell-text">
                                                            {{ formatItem(childValue) }}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div v-if="index < data.Children.length - 1" class="divider-line m-10">
                                                </div>
                                            </template>
                                            <div class="divider-line black mt-10"></div>
                                            <div class="divider-line black mb-10"></div>

                                            <div
                                                class="d-flex justify-content-between gap-4 subitems-total text-dark fw-bold">
                                                <div v-for="(item, key) in data.Parent" :key="key"
                                                    class="cell d-flex gap-2">
                                                    <p class="cell-header hide-desktop"> {{ key }} </p>
                                                    <div v-if="Array.isArray(item)" class="w-100 cell-text">
                                                        <p v-for="(subItem, index) in item" :key="index" class="w-100 d-flex justify-content-between gap-10">
                                                            <!-- Display the message -->
                                                            {{ subItem.message }}
                                                            <!-- Display the instruction with a hover tooltip -->
                                                            <span v-if="subItem.instructions" class="tooltip-wrapper">
                                                            <i class="iatse-icon-info-tooltip icon_rotate_0"></i>
                                                                <a
                                                                    class="tooltip-text tooltip-text_secondary"
                                                                    href="javascript:void(0)"
                                                                    @click="openPDF(subItem.instructions)"
                                                                    >
                                                                        Summary Plan Description
                                                                    </a>
                                                                <!-- <a class="tooltip-text tooltip-text_secondary" :href="`${summaryPlanDescriptionLink}#page=${subItem.instructions}`" target="_blank"> Summary Plan Description (Page {{ subItem.instructions }})</a> -->
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <p v-else class="cell-text">
                                                        {{ formatItem(item) }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </transition>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
                <tbody v-else>
                    <tr class="text-center">
                        <td colspan="11"> No data available</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="text-center mt-10 w-100 sm-fs-xs">F = Future Service Credits, P = Past Service Credits, M = Merged Credits</div>

        <!-- Related Links -->
        <div class="account-actions">
            <h4> Related Links </h4>
            <div class="action-buttons sm-pdl-0 sm-pdr-0">
                <button class="btn btn-secondary plan-modal" @click="openModal('add-beneficiary',
                    'My Beneficiary(ies)',
                    'plan-tables-striped beneficiary',
                    null,
                    null,
                    null,
                )">
                    Beneficiary(ies)
                </button>
                <template v-if="isPensioner">
                    <button class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-destination', 'Payment Destination Setup', 'plan-form', 'pension')">
                        ACH/Check Destination</button>
                    <button v-if="hasChecks" class="btn btn-tertiary btn-large plan-modal"
                        @click="openModal('check-replacement', 'Check Replacement', 'plan-form', 'pension')">
                        Check Replacement
                    </button>
                </template>
                <template v-else>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Pension Application', 'plan-form', 'application')">Pension
                        Application
                    </a>
                    <a href="" class="btn btn-tertiary btn-large plan-modal"
                        @click.prevent="openModal('pension-application', 'Estimate Request', 'plan-form', 'estimate')">
                        Estimate Request
                    </a>
                </template>
                <a href="" @click.prevent="openModal('plan-resources', 'Pension Forms & Documents', null, 'pension')" class="btn btn-tertiary btn-large plan-modal">Forms & Documents </a>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from 'vue';
import { useModalStore } from '@/stores/modal';
import { usePensionStore } from '@/stores/pension';
import { PensionStatus } from '@/interfaces/pension';
import { useNumberFormat } from '@/composable/useNumberFormat';

import ProfileInfo from '@/components/cards/ProfileInfo.vue';

export default defineComponent({
    components: {
        ProfileInfo,
    },
    setup() {
        const pensionStore = usePensionStore();
        const isPensioner = computed(() => pensionStore.pensionerDetails?.IsPensioner);
        const hasChecks = computed(() => pensionStore.pensionerDetails?.HasChecks);
        const loading = ref(true);
        const toggleStates = ref<Record<number, boolean>>({});
        const openModal = useModalStore().openModal;
        const { formatNumber } = useNumberFormat();
        const summaryPlanDescriptionLink = "https://iatse-public.staging.polardesign.com/storage/app/media/documents/2019-IATSE-Pension-Plan-B-SPD.pdf";

        function formatItem(item: string| number) {
            return !isNaN(Number(item)) && String(item)?.length > 1 ? formatNumber(Number(item)) : item;
        }

        const openPDF = (page: number) => {
            const url = `${summaryPlanDescriptionLink}#page=${Number(page) + 3}`;
            const viewer = window.open('', '_blank');

            if(viewer) {
                viewer.document.write(`
                <html>
                    <body style="margin:0;">
                        <iframe src="${url}" style="width:100%; height:100vh;" frameborder="0"></iframe>
                    </body>
                </html>
            `);
            }
        };

        const firstCard = computed(() => {
            return [
                { label: 'Vesting Credits', value: formatItem(pensionStore.data?.totalVestingCredits ?? 0) },
                { label: 'Past Service', value: formatItem(pensionStore.data?.totalPastServiceCredits ?? 0) },
                { label: 'Future Service', value: formatItem(pensionStore.data?.totalFutureServiceCredits ?? 0) },
                { label: 'Pension Credits', value: formatItem(pensionStore.data?.totalPensionCredits ?? 0) },
            ]
        })

        const secondCard = computed(() => {
            return [
                { label: 'Year Vested ', value: pensionStore.data?.vestingYear },
                { label: 'Participation Date', value: pensionStore.data?.participationDate ?? '-' },
            ]
        });

        const fetchData = async () => {
            if (!pensionStore.data || !pensionStore.history) {
                await pensionStore.fetchPensionCredits();
            }

            if (pensionStore.history.rows) {
                Object.values(pensionStore.history.rows).forEach((_, index) => {
                    toggleStates.value[index] = false;
                });
            }

            loading.value = false;
        }

        const isNumeric = (value: any) => {
            return !isNaN(value - parseFloat(value));
        }

        const toggleCell = (index: number) => {
            Object.keys(toggleStates.value).forEach(key => {
                console.log(toggleStates.value[Number(key)], '@toggle');
                toggleStates.value[Number(key)] = Number(key) === index ? !toggleStates.value[Number(key)] : false;
            });
        }

        const openHistory = (year: string) => {
            const fromDate = new Date(`${year}-01-01`);
            const toDate = new Date(`${year}-12-31`);

            openModal('work-history', 'Pension Work History',  null, 'pension', null, null, undefined, { Funds: [1, 2, 9] , FromDate: fromDate.toDateString(), ToDate: toDate.toDateString() });
        }

        onMounted(async () => {
            await fetchData();
        });

        return {
            loading,
            isPensioner,
            firstCard,
            secondCard,
            hasChecks,
            pensionStore,
            toggleStates,
            summaryPlanDescriptionLink,
            openPDF,
            isNumeric,
            toggleCell,
            openHistory,
            openModal,
            formatItem
        };
    }
});
</script>