<template>
    <div class="d-flex gap-20 w-100 flex-md-row flex-column">
        <div class="bordered-card w-30 w-sm-100 sm-mb-10">
            <div class="d-flex flex-column gap-20">
                <p class="fs-xs">Current <br /> {{ coverageLabel }} Balance</p>
                <h2 class="text-primary">{{ formatCurrency(balance)}} </h2>
            </div>
        </div>
        <div class="bordered-card transparent gap-15 align-items-center justify-content-between flex-md-row flex-column">
            <h2 :class="{'fs-base-lg fw-normal sm-fs-sm w-sm-100': small, 'fs-base-lg fw-normal w-30 sm-fs-sm w-sm-100': !small}">What is covered/ Reimbursable?</h2>
            <a :href="links.list" target="_blank" class="btn btn-secondary plan-modal w-sm-100"> <i
                class="iatse-icon-list-view-rectangle"> </i> See List </a>
            <a :href="links.form" target="_blank" class="btn btn-primary w-sm-100">
                Download Claim Form
            <img src="@/assets/images/icons/pdf.svg" alt="pdf icon" /> </a>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useCoverageStore } from '@/stores/coverage';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";

export default defineComponent({
  props: {
    links: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const coverageStore = useCoverageStore();
    const { formatCurrency } = useCurrencyFormatter();

    // Check if the primary coverage’s PlanClass is RMRP-eligible (1023 or 1024).
    const isRmrpPlanClass = computed(
      () =>
        coverageStore.coverage?.Primary?.PlanClass === 1023 ||
        coverageStore.coverage?.Primary?.PlanClass === 1024
    );

    // Label that switches between "RMRP" and "MRP"
    const coverageLabel = computed(() => (isRmrpPlanClass.value ? 'RMRP' : 'MRP'));

    // Compute the balance based on whether it's RMRP or MRP
    const balance = computed(() => {
        const coverageStore = useCoverageStore();

        const primary = coverageStore.coverage?.Primary;
        const secondary = coverageStore.coverage?.Secondary;

        const isRmrpPlanClass =
            primary?.PlanClass === 1023 || primary?.PlanClass === 1024;

        // Coalesce to 0 in case of null or undefined
        let primaryBalance = 0;
        if (primary) {
            primaryBalance = isRmrpPlanClass
            ? (primary.RmrpBalance ?? 0) // <-- coalesce here
            : (primary.MrpBalance ?? 0); // <-- coalesce here
        }

        // If the primary doesn't exist or the above is 0, you might want to fallback:
        const secondaryBalance = secondary?.MrpBalance ?? 0;

        // finalBalance always ends up a number
        const finalBalance = primaryBalance || secondaryBalance;
        // return never negative
        return finalBalance < 0 ? 0 : finalBalance;
    });

    return {
      formatCurrency,
      balance,
      coverageLabel,
      isRmrpPlanClass
    };
  }
});
</script>