<template>
    <div class="bordered-card bordered-card_secondary gap-0">
        <h4 class="fw-bold mb-25">Recent Announcements</h4>
        <template v-for="(fundAnnouncements, fundKey) in announcements" :key="fundKey">
            <div class="primary-group d-flex justify-content-between align-items-center w-100 plan-modal"
                @click="toggleGroup(fundKey)" :class="{
                    'mb-20': !(fundKey === Object.keys(announcements)[Object.keys(announcements).length - 1]) && !showGroup[fundKey],
                    'open': showGroup[fundKey]
                }">
                <div class="d-flex gap-10 align-items-center">
                    <i :class="getFundIcon(fundAnnouncements[0]?.fund?.title)" class="fs-xl"></i>
                    <h5 class="fw-bold"> {{ fundAnnouncements[0]?.fund?.title }} </h5>
                </div>
                <!-- Toggle secondary group items on click -->
                <button class="toggle-btn">
                    <span v-if="!showGroup[fundKey]" class="fs-xl lh-1">+</span>
                    <span v-else class="fs-xl lh-1">-</span>
                </button>
            </div>
            <transition name="slide-fade">
                <div v-if="showGroup[fundKey]"
                    class="secondary-group bg-blue d-flex flex-column justify-content-between pdl-10 pdr-10 pdb-10 pdt-10 gap-15 w-100 mb-20">
                    <template v-for="(announcement, key) in fundAnnouncements" :key="key">
                        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap gap-20">
                            <div class="d-flex flex-wrap flex-md-nowrap gap-10">
                                <span class="label label_white order-1 order-md-0 fs-xs"> <span class="bullet">
                                    </span>{{ announcement.date }} </span>
                                <p class="fw-light order-3 order-md-1">{{ announcement.title }} </p>
                            </div>
                            <h5 v-if="!showMore[fundKey + key]" class="order-2 order-md-2">
                                <a href="javascript;"
                                    @click.prevent="toggleMore(fundKey + key)">Read More</a></h5>
                            <h5 v-if="showMore[fundKey + key]" class="order-2 order-md-2"><a href="javascript;"
                                    @click.prevent="toggleMore(fundKey + key)">Read Less</a></h5>
                        </div>
                        <transition name="slide-fade">
                            <p v-if="showMore[fundKey + key]" v-html="announcement.content"></p>
                        </transition>
                    </template>
                </div>
            </transition>
        </template>
    </div>
</template>

<script lang="ts">
import { PropType, ref, defineComponent } from 'vue';
import { Announcements } from '@interfaces/local';

export default defineComponent({
    props: {
        announcements: [] as PropType<Announcements>
    },

    setup() {
        const showGroup = ref<Record<string, boolean>>({});
        const showMore = ref<Record<string, boolean>>({});

        const fundIcons: Record<string, string> = {
            "Annuity": "iatse-icon-fund-annuity",
            "Health and Welfare": "iatse-icon-fund-hw",
            "Pension": "iatse-icon-egg-basket",
            "Vacation": "iatse-icon-fund-vacation",
            "Employer": "iatse-icon-employer",
            "Contract & Contribution Information": "iatse-icon-contract",
        };

        // Function to get the icon class for a fund
        const getFundIcon = (fundName: string): string => {
            return fundIcons[fundName] || "iatse-icon-default";
        };

        // Toggle the visibility of secondary items for each group
        const toggleGroup = (fundKey: string) => {
            showGroup.value[fundKey] = !showGroup.value[fundKey];
        };

        // Toggle the visibility of more text for each group
        const toggleMore = (key: string) => {
            Object.keys(showMore.value).forEach(k => {
                if (k !== key) {
                    showMore.value[k] = false;
                }
            });

            showMore.value[key] = !showMore.value[key];
        };

        return {
            showGroup,
            showMore,
            toggleGroup,
            toggleMore,
            getFundIcon
        }
    }
})
</script>