<template>
   <div class="d-flex flex-column gap-20 w-100">
       <SelectComponent label="Organization" placeholder="Enter at least 3 characters to search" @search-change="handleSearch" v-model:selectedOption="localAuth.Organization" :options=organizations :is-required="true" :error="errors.Organization"/>
       <div class="d-flex gap-20 w-100 flex-column flex-md-row">
           <TextInput type="text" label="First Name" placeholder="First Name" :max=12
               v-model:modelValue="localAuth.FirstName" :is-required="true" :error="errors.FirstName"/>
           <TextInput type="text" label="Middle Name" placeholder="Middle Name" :max=12
               v-model:modelValue="localAuth.MiddleName" />
           <TextInput type="text" label="Last Name" placeholder="Last Name" :max=25
               v-model:modelValue="localAuth.LastName" :is-required="true" :error="errors.LastName"/>
       </div>
       <div class="d-flex gap-20 w-100 flex-column flex-md-row">
           <TextInput type="email" label="Email" placeholder="Email" :max=100
               v-model:modelValue="localAuth.Email" :is-required="true" :error="errors.Email"/>
           <TextInput type="text" name="phone" label="Phone" placeholder="Enter Phone" :max=16
               v-model:modelValue="localAuth.Phone" :is-required="true" :error="errors.Phone"/>
       </div>
       <div class="d-flex gap-20 w-100 flex-column flex-md-row">
           <TextInput type="text" name="mixed" label="Title" placeholder="Title" :max=200
               v-model:modelValue="localAuth.Title" />
           <TextInput type="text" name="mixed" label="Local # " placeholder="Local #" :max=20
               v-model:modelValue="localAuth.OrgName" :is-required="true" :error="errors.OrgName"/>
       </div>
   </div>

   <div class="d-flex mt-40 gap-20 justify-content-end flex-column ms-auto">
       <button type="submit" class="btn btn-primary plan-modal w-100" @click.prevent="handleSubmit" @keypress.enter="handleSubmit" :disabled="loadingSubmit">
           Submit
           <svg v-if="loadingSubmit" width="24" height="16" version="1.1" id="L9" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100"
               enable-background="new 0 0 0 0" xml:space="preserve">
               <path fill="#8EC986"
                   d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                   <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s"
                       from="0 50 50" to="360 50 50" repeatCount="indefinite" />
               </path>
           </svg>
       </button>
   </div>
</template>

<script lang="ts">
import { reactive, defineComponent, ref, Ref, watch, onMounted } from 'vue';
import { ILocalAuth } from '@/interfaces/interfaces';
import { useModalStore } from '@/stores/modal';
import { useLocalStore } from '@/stores/local';
import TextInput from '@components/form/TextInput.vue';
import SelectComponent from '@components/form/SelectComponent.vue';
import axios from 'axios';

export default defineComponent({
   components: {
       TextInput,
       SelectComponent,
   },
   setup() {
       const localAuth: ILocalAuth = reactive({
           FirstName: '',
           MiddleName: '',
           LastName: '',
           Title: '',
           Phone: '',
           OrgName: '',
           Organization: { key: 0, value: 'Select Option'},
           Email: '',
       });
       const organizations = ref([]);
       const loadingSearch = ref(false);
       const loadingSubmit = ref(false);
       const success = ref(null);
       const errors: Ref<string[]> = ref([]);

       // Watch for changes in props.success
       watch(() => success.value, (newValue) => {
           if (newValue !== null) {
               localAuth.FirstName = '';
               localAuth.MiddleName = '';
               localAuth.LastName = '';
               localAuth.Title = '';
               localAuth.Phone = '';
               localAuth.OrgName = '';
               localAuth.Organization = { key: 0, value: 'Select Option'};
               localAuth.Email = '';
           }
       });

       const handleSubmit = async () => {
            loadingSubmit.value = true;

            try {
                const formData = {
                    AuthRequest: localAuth?.UILocalRequestID ?? null,
                    User: {...localAuth, Organization: localAuth.Organization?.key , token: 'true'},
                    UserType: 5,
                    LocalType: 'auth',
                };

                const response = await axios.post('api/register', { ...formData });
                success.value = response.data.success;
                const route = response.data?.route;
                errors.value = [];

                if(success.value && !route) {
                    useModalStore().openModal('success-modal', null, 'modal-success', null, {
                        title: 'PIN Local Auth Request',
                        content: {
                            type: "text",
                            value: response.data.success,
                            icon: 'iatse-icon-user-verified'
                        },
                    })

                    await useLocalStore().fetchLocals();
                }
            } catch (error: any) {
                if (error.response.data.error) {
                    errors.value = error.response.data.error;
                }
            }

            loadingSubmit.value = false;

            setTimeout(() => {
                success.value = null;
            }, 5000);
        };

       // Handle Organizations Search
       const handleSearch = async (query: string) => {
           loadingSearch.value = true;

           if (query.length > 0) {
               try {
                   const response = await axios.get(`api/organizations?query=${query}`);
                   organizations.value = response.data;
               } catch (error) {
                   console.error('Error searching organizations:', error);
               }
           }

           loadingSearch.value = false;
       };

        // Fetch Data
        const fetchData = async () => {
            await axios.get('api/register', { params: { RequestID: useModalStore()?.currentModal?.queryParams?.RequestID ?? null } })
            .then(response => {
                Object.assign(localAuth, response.data.AuthRequest);
            })
        };


        onMounted(async () => {
            await fetchData()
        });

       return {
            errors,
            localAuth,
            loadingSubmit,
            loadingSearch,
            organizations,
            handleSubmit,
            handleSearch,
       }
   }
})
</script>