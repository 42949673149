<template>
  <div class="close-btn close-plan-modal plan-modal bg-dark-grey w-100 ms-auto d-flex justify-content-end align-items-start p-2 cursor-pointer position-absolute top-0 rounded-top" @click="closeModal"> <i class="iatse-icon-close"></i></div>
  <LoaderComponent v-if="loading" />
  <template v-else>
    <!-- For viewing PDFs in the browser -->
    <!-- <iframe v-if="isPdf" :src="documentUrl" width="100%" height="1000px" id="smartsearch"></iframe> -->
    <object v-if="isPdf" :data="documentUrl" type="application/pdf" width="100%" height="1000px" id="smartsearch" class="d-flex flex-column justify-content-center align-items-center mt-20">
      <p class="text-center">Sorry, your browser doesn't support embedded PDFs.
        <a :href="documentUrl" download>Download PDF</a>
      </p>
    </object>

    <!-- For Word Documents, display a download link -->
    <a class="text-center w-100" v-else-if="isDocx" :href="documentUrl" download>Download Word Document</a>

    <!-- For Excel Documents, display a download link -->
    <a class="text-center w-100" v-else-if="isExcel" :href="documentUrl" download>Download Excel Document</a>

    <!-- For Images, display the image in an <img> tag -->
    <img v-else-if="isImage" :src="documentUrl" alt="Image" width="100%" />
    <p class="text-center w-100" v-else>Couldn't load the document.</p>
  </template>

</template>

<script lang="ts">
import { ref, onMounted, defineComponent } from 'vue';
import DocumentService from '@/services/documentsService';

export default defineComponent({
  props: {
    modalProps: {
      type: Object,
      required: true
    }
  },
  setup(props, { emit }) {
    const loading = ref(true);
    const documentUrl = ref<string | null>(null);
    const isPdf = ref(false);
    const isImage = ref(false);
    const isDocx = ref(false);
    const isExcel = ref(false);
    const isHtml = ref(false);
    const blob = ref<Blob | null>(null);

    const fetchDocument = async () => {
      // View SmartSearch uploaded document
      if (props.modalProps.DocumentID) {
        try {
          blob.value = await DocumentService.viewDocument(props.modalProps.DocumentID);
          documentUrl.value = URL.createObjectURL(blob.value);

          console.log(documentUrl.value)

        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error('An error occurred', error);
          }
        }
      } else if (props.modalProps.FilePreview) { // View storage uploaded document
        try {
          blob.value = await DocumentService.viewStorageDocument(props.modalProps.FilePreview, props.modalProps?.Type);
          documentUrl.value = URL.createObjectURL(blob.value);
        } catch (error) {
          if (error instanceof Error) {
            console.error(error.message);
          } else {
            console.error('An error occurred', error);
          }
        }
      }

      const contentType = blob.value?.type;

      if (contentType === 'application/pdf') {
        isPdf.value = true;
      } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        isDocx.value = true;
      } else if (contentType?.startsWith('image/')) {
        isImage.value = true;
      } else if (contentType === 'application/vnd.ms-excel' || contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        isExcel.value = true;
      } else if (contentType === 'text/html') {
        isHtml.value = true;
      }

      loading.value = false;
    };

    const closeModal = () => {
      emit('close');
    }

    onMounted(async () => await fetchDocument());

    return {
      documentUrl, loading, isPdf, isDocx, isImage, isExcel, isHtml, closeModal
    };
  },
});
</script>