<template>
    <AlertsComponent :error="error" />

    <table class="small striped toggle no-underline">
        <thead class="large large_padding">
            <tr>
                <th> EIN / Tax ID </th>
                <th> Company <br /> Name </th>
                <th> Employer <br /> / Payor </th>
                <th> Name</th>
                <th> Title</th>
                <th> Email Address </th>
                <th> Last Login </th>
                <th> Type </th>
                <!-- <th> Days to Expiration </th> -->
                <th> Account actions </th>
            </tr>
        </thead>
    <tbody>
            <tr class="large" v-for="employer in  employers " :key="employer.EmployerID">
                <td class="col-md-2 plan-modal"
                    @click="openModal('edit-user', 'Employers', 'plan-profile plan-tables', null, { UserID: employer.UserID })">
                    {{ employer.TaxID }}
                </td>
                <td class="col-md-4">{{ employer.CompanyName }}</td>
                <td class="col-md-4">{{ employer.Type }}</td>
                <td class="col-md-4"> {{ employer.Name }} </td>
                <td class="col-md-4"> {{ employer.Title }} </td>
                <td class="col-md-4"> {{ employer.Email }} </td>
                <td class="col-md-4"> {{ employer.LastLogin }} </td>
                <td class="col-md-4">{{ employer.UserType }}</td>
                <!-- <td class="col-md-4">{{ employer.DaysToExpiration }}</td> -->
                <td class="col-md-2">
                    <div class="actions">
                        <!-- Activate/Deactivate -->
                    <span v-if="employer.Inactive == 1" class="tooltip-wrapper" data-tooltip="Activate User">
                        <i @click="activate(employer.UserID)" class="plan-modal fs-xs iatse-icon-noun-reset"></i>
                    </span>
                        <span v-else class="tooltip-wrapper" data-tooltip="Deactivate User">
                            <i @click="deactivate(employer.UserID)" class="plan-modal iatse-icon-deactivate-user"></i>
                        </span>
                        <p> / </p>
                        <!-- Reset Pin -->
                        <span class="tooltip-wrapper" data-tooltip="Reset PIN">
                            <i @click="resetPin(employer.UserID)"
                                :class="['plan-modal', 'iatse-icon-reset', { 'icon-green': employer?.DaysToExpiration === 0 }]"></i>
                        </span>
                        <p> / </p>
                        <!-- Login -->
                        <span class="tooltip-wrapper" data-tooltip="Masquerade">
                            <i @click="loginAsUser(employer.UserID)" class="plan-modal iatse-icon-noun-login">
                            </i>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { SearchEmployer } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';

export default defineComponent({
    props: {
        employers: {
            type: Object as PropType<SearchEmployer[]>,
            required: true
        },
    },
    setup(_, { emit }) {
        const authStore = useAuthStore();
        const error = ref<string|null>(null);
        const router = useRouter();

        const resetPin = (userID: number) => {
            emit('reset-pin', userID);
        }

        const activate = (userID: number) => {
            emit('activate', userID);
        }

        const deactivate = (userID: number) => {
            emit('deactivate', userID);
        }

        const loginAsUser = async (userID: number) => {
            try {
                await authStore.startImpersonation(userID);
                useParticipantStore().reset();
                await useParticipantStore().getUserEmail();

                router.push({ name: 'employer.dashboard' });
            }
            catch (impersonationError: unknown) {
                error.value = impersonationError as string;

                setTimeout(() => error.value = null, 2000);
                console.error("Impersonation error:", impersonationError);
            }
        };

        return {
            error,
            loginAsUser,
            resetPin,
            activate,
            deactivate,
            openModal: useModalStore().openModal
        };
    }
});
</script>