<template>
    <Alerts :success="success" :info="info" :errors="errors" />

    <LoaderComponent v-if="loading" />
    <div v-else class="table-wrapper">
        <h4> View or modify current Mailing Address and Payment setup</h4>

        <!-- Change Mailing/Deposit Cards -->
        <div class="table-item">
            <TextInput type="text" name="mixed" label="My Mailing Address" placeholder="Enter My Mailing Address" :max=50
                v-model:modelValue="address" :readonly="true" />
            <a href="" class="btn btn-secondary plan-modal"
                @click.prevent="openModal('check-destination', 'Payment Destionation Setup', 'plan-form', 'medicare')">
                Change my Mailing Address
            </a>
        </div>

        <div class="table-item">
            <TextInput type="text" name="mixed" label="My Payment Setup Currently is"
                placeholder="Enter My Payment Setup Currently is" :max=50 v-model:modelValue="directDeposit" :readonly="true"/>
            <a href="" class="btn btn-secondary plan-modal" @click.prevent="openModal('check-destination', 'Payment Destionation Setup', 'plan-form', 'medicare')">
                <span v-if="switchCheck">Switch to check </span>
                <span v-else>Switch to Direct Deposit </span>
            </a>
        </div>
    </div>

</template>

<script lang="ts">
import { Ref, ref, defineComponent, computed, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useClaimStore } from '@/stores/claim';

import Alerts from "@components/utils/AlertsComponent.vue";
import TextInput from '@components/form/TextInput.vue';

export default defineComponent({
    components: {
        Alerts,
        TextInput,
    },
    setup() {
        const loading = ref(false);
        const loadingSave = ref(false);
        const closeForm = ref(false);
        const success = ref(null);
        const info = ref(null);
        const errors: Ref<string[] | string | null> = ref(null);
        const claimStore = useClaimStore();
        const changeDeposit = ref(false);
        const address = computed(() => claimStore.paymentMethods?.Address ?? '');
        const switchCheck = computed(() => claimStore.paymentMethods?.CheckDirectDeposit?.BankClassDescription ?? false);
        const directDeposit = computed(() => {
            if(claimStore.paymentMethods?.CheckDirectDeposit?.BankClassDescription) {
                const deposit = claimStore.paymentMethods?.CheckDirectDeposit?.BankClassDescription ?? 'N/A';
                return  `DIRECT DEPOSIT to ${deposit}`;
            }

            return 'CHECK to mailing address';
        })

        const handleChangeDeposit = (payload: boolean) => {
            changeDeposit.value = payload;
        }

        return {
            loading,
            address,
            changeDeposit,
            directDeposit,
            switchCheck,
            closeForm,
            loadingSave,
            success,
            info,
            errors,
            handleChangeDeposit,
            openModal: useModalStore().openModal
        }
    }
})
</script>