<template>
    <MessageCard  v-if="!max" message="Please read the messages below. When you are done, check the box next to the
        message and it will be archived. <br />
        This screen also contains a repository of messages you've previously viewed which are stored here for historic reference." type="primary" iconClass="iatse-icon-alarm"
        classes="info_card_full" />
    <AlertsComponent :success="success" />
    <LoaderComponent v-if="loading"/>
    <div v-else class="plan-content">
        <div v-if="alerts.length" class="alerts-wrapper pdr-10">
            <template v-for="(alertItem, key) in alerts" :key="key">
                <div v-if="!max" class="alerts-header">
                    <p class="text-500-bold">
                        {{ alertItem.key }}
                    </p>
                    <div class="alerts-header-alert-checkbox" v-if="key === 0 && hasUnreadAlerts">
                        <CheckBox label="Mark All As Read" className="alert-checkbox" id="all" @change="readAll" :showLabel="true"
                            :left="true" />
                    </div>
                </div>
                <div class="alerts-cards sm-pdb-40">
                    <div class="bordered-card" :class="{'active': alert.AlertRead == 0}" v-for="alert in alertItem.value" :key="alert.AlertID">
                        <div class="bordered-card-header">
                            <h5 class="fw-semibold text-start"> {{ alert.type?.Name }} </h5>
                            <!-- <small>{{ alert.AlertID }}</small> -->
                            <!-- <p class="text-grey fs-xs"> {{ useDateFormatter(alert.DateCreated) }} </p> -->
                            <p class="text-grey fs-xs"> {{ useTimeDateFormatter(alert.DateCreated) }}</p>
                        </div>

                        <div class="bordered-card-body">
                            <p v-html="alert.Message" @click="handleClick($event, alert)"> </p>
                            <CheckBox v-if="Number(alert.AlertRead) == 0 && !max" label="Mark as Read" className="alert-checkbox" :id="alert.AlertID" v-model:modelValue="alert.AlertRead"
                                :showLabel="false" @change="markRead(alert.AlertID, alert.AlertRead)" />
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <div v-else class="alerts-wrapper sm-pdb-40">
            <p class="text-center w-100">{{ max ? 'No unread alerts' : 'No alerts' }}</p>
        </div>
    </div>
</template>

<script lang="ts">
import CheckBox from '../form/CheckBox.vue';

import { watch, onMounted, ref, computed, defineComponent, onUnmounted } from 'vue';
import { useTimeDateFormatter, useDateFormatter } from "@/utils/dateFormatter";
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import { SelectOption, Alert } from '@/interfaces/interfaces';
import axios from 'axios';
import router from '@/router';

export default defineComponent({
    props: {
        max: {
            type: Number,
            default: 0
        },
        read: {
            type: Boolean,
            default: false
        },
        showUnread: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CheckBox,
    },
    setup(props, { emit }) {
        const modalStore = useModalStore();
        const participantStore = useParticipantStore();
        const alerts = computed(() => {
            let alerts = participantStore.alerts.data || [];

            // Keep only those with unread alerts
            if (props.showUnread) {
                // alerts = participantStore.getUnread();
            }

            if (props.max) {
                alerts = alerts.slice(0, props.max);
            }

            return alerts;
        });

        const hasUnreadAlerts = computed(() =>participantStore.alerts.unreadCount > 0);
        const loading = ref(true);
        const success = ref('');
        const openModal = useModalStore().openModal;

        watch(() => props.read, (newValue) => {
            if(newValue) {
                readAll();
                emit('read');
            }
        })

        watch(() => props.max, (newValue) => {
            if(newValue) {
                participantStore.fetchAlerts(props.max);
            }
        })

        const markRead = async (alertId: number, status: string | boolean) => {
            const changeStatusUrl = status ? `api/participant/alerts/read/${alertId}` : `api/participant/alerts/unread/${alertId}`;

            await axios.get(changeStatusUrl)
                .then(response => {
                    success.value = response.data.success;
                    participantStore.fetchAlerts(props.max);

                    setTimeout(() => success.value = '', 2000);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        const readAll = async () => {
            await axios.post('api/participant/alerts/readAll')
                .then(response => {
                    success.value = response.data.success;
                    participantStore.fetchAlerts(props.max);

                    setTimeout(() => success.value = '', 2000);
                })
                .catch(error => {
                    console.error('There was an error!', error);
                });
        }

        const fetchData = async () => {
            loading.value = true;
            await participantStore.fetchAlerts(props.max);
            loading.value = false;
        };

        const handleClick = (event: MouseEvent, alert: Alert) => {
            const target = event.target as HTMLElement;
            console.log('>>target', target)
            console.log('>>alert', alert)

            if (target.tagName === 'A') {
                const link = target.getAttribute('href');

                if(!link) {
                    if(Number(alert.AlertTypeID) == 57) {
                        openModal('dependents', 'My Dependents', '');
                    }

                    if(Number(alert.AlertTypeID) == 53) {
                        router.push({ name: 'user.demographic' });
                        emit('close');
                    }

                    return;
                }

                event.preventDefault();

                // Open the modal
                if(alert.DocumentID) {
                    openModal('smartsearch', null, 'modal-success', null, { DocumentID: String(alert.DocumentID) });
                }

                if(Number(alert.AlertTypeID) == 59) {
                    openModal('employer-aca-report', 'Employee Health Coverage Information Request', null, null, null, null, null, { download: '1' })
                }

                if(Number(alert.AlertTypeID) == 5) {
                    openModal(
                        'add-beneficiary',
                        'My Beneficiary(ies)',
                        'plan-tables-striped beneficiary',
                        null,
                        null,
                        null,
                        [{ name: 'Add Beneficiary', type: 'primary', action: 'add-beneficiary', classes: 'plan-form plan-upload' }]
                    );
                }
            }
        };


        onMounted(async () => {
            await fetchData();
        });


        return {
            alerts,
            loading,
            success,
            hasUnreadAlerts,
            openModal: useModalStore().openModal,
            readAll,
            markRead,
            useTimeDateFormatter,
            useDateFormatter,
            handleClick,
        };
    },
});
</script>
