<template>
    <form id="email-change-form" class="form account-settings">
        <small v-if="errors && errors.General" class="text-danger">{{ errors?.General }}</small>
        <div class="w-full d-flex gap-3 align-items-end flex-column">
            <TextInput type="email" v-model:modelValue="email" label="Email Address" placeholder='Enter Email' :error="errors?.email ?? null" :max=100 />
            <button type="submit" class="btn toggle-control lh-sm w-100" :class="{'btn-primary': !isButtonDisabled, 'btn-secondary': isButtonDisabled}" :disabled="isButtonDisabled"  @click.prevent="handleSubmit" @keypress.enter.prevent="handleSubmit"> Update </button>
        </div>
    </form>
  </template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import TextInput from '@components/form/TextInput.vue';
import axios from 'axios';

export default defineComponent({
components: {
    TextInput,
},

setup() {
    const modalStore = useModalStore();
    const email = ref<string>(useParticipantStore().userEmail ?? '');
    const errors = ref<Record<string, string>>({General: ''});
    const success = ref('');
    const isButtonDisabled = ref(true);

    watch(() => email.value, (newValue) => {
        if(newValue !== useParticipantStore().userEmail) {
            focusButton(false);
        } else {
            focusButton(true);
        }
    })

    watch(() => useParticipantStore().userEmail, () => {
        email.value = useParticipantStore().userEmail ?? '';
    })

    // Focus/activate button when typing in specific input
    const focusButton = (state: boolean) => {
        isButtonDisabled.value= state;
    }

    const validateEmail = async (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emailErrors  = await useParticipantStore().checkUser(email);
        errors.value.General = 'Invalid email. Please check and ensure it meets the required validation criteria.';

        if(email === useParticipantStore().userEmail) {
            errors.value.General = 'Email should be different from the old one.';
            return false;
        }

        if(emailErrors?.length) {
            errors.value.General = emailErrors.join('. ');
            return false;
        }

        return emailRegex.test(email);
    }

    const handleSubmit = async () => {
        if (!isButtonDisabled.value && await validateEmail(email.value)) {
            errors.value.General = '';

            useModalStore().openModal(
                'validate-user-2fa',
                'Verify Email Address',
                'modal-success',
                null,
                {
                    onConfirm: () => {
                        const response = submitData();
                    }
                },
                null,
                null,
                {email: email.value, method: 'E'}

            );
        }
    }

    const submitData = async () => {
        const formData = {
            email: email.value,
        };

        axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
            .then(response => {
                success.value = response.data.success;
                errors.value = {General: ''};

                setTimeout(() => {
                    success.value = '';
                }, 3500);

                useModalStore().closeAbove(0);

                modalStore.openModal(
                    "success-modal",
                    null,
                    "modal-success",
                    null,
                    {
                        title: "Updated Successfully",
                        content: {
                            type: "text",
                            value: 'Your Email has been updated successfully!',
                            icon: "iatse-icon-user-verified"
                        },
                        onConfirmButton: "Close",
                        onConfirm: async () => {
                            await useParticipantStore().getUserEmail();
                            focusButton(true);
                        },
                    },);
            })
            .catch((error: any) => {
                if (error.response) {
                    errors.value = error.response.data.errors;
                }
            })
            .finally(() => {
                setTimeout(() => {
                    if(errors.value && errors.value?.General) {
                        errors.value.General = '';
                    }
                }, 3500);
            })
    };

    return {
        email,
        success,
        errors,
        isButtonDisabled,
        handleSubmit,
    };
},
});
</script>
