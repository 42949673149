import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "plan-content justify-content-between" }
const _hoisted_2 = { class: "form inputs-wrapper" }
const _hoisted_3 = { class: "action-buttons pdt-50 d-flex justify-content-between w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AlertsComponent = _resolveComponent("AlertsComponent")!
  const _component_LoaderComponent = _resolveComponent("LoaderComponent")!
  const _component_MessageCard = _resolveComponent("MessageCard")!
  const _component_SelectComponent = _resolveComponent("SelectComponent")!
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AlertsComponent, {
      success: _ctx.success,
      errors: _ctx.errors
    }, null, 8, ["success", "errors"]),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoaderComponent, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_MessageCard, {
            message: "By Completing the information below, a request will be sent to the Fund’s Administrative team for processing.",
            type: "primary",
            iconClass: "iatse-icon-alarm",
            classes: "info_card_full"
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("form", _hoisted_2, [
              _createVNode(_component_SelectComponent, {
                label: "Carrier",
                instructions: _ctx.instructions,
                selectedOption: _ctx.formData.Carrier,
                "onUpdate:selectedOption": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.Carrier) = $event)),
                options: _ctx.carrierOptions,
                singleArrow: true
              }, null, 8, ["instructions", "selectedOption", "options"]),
              _createVNode(_component_TextInput, {
                type: "text",
                label: "ParticipantID",
                placeholder: "Enter ParticipantID",
                max: 12,
                modelValue: _ctx.formData.ParticipantID,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.ParticipantID) = $event)),
                readonly: true,
                customClass: "disabled"
              }, null, 8, ["modelValue"]),
              (_ctx.formData.HasActiveFamilyCoverage)
                ? (_openBlock(), _createBlock(_component_SelectComponent, {
                    key: 0,
                    label: "ID For",
                    selectedOption: _ctx.formData.Dependent,
                    "onUpdate:selectedOption": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formData.Dependent) = $event)),
                    options: _ctx.dependents,
                    singleArrow: true
                  }, null, 8, ["selectedOption", "options"]))
                : _createCommentVNode("", true),
              _createVNode(_component_Textarea, {
                label: "Message (Max 500 characters)",
                placeholder: "Type here...",
                modelValue: _ctx.formData.Notes,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formData.Notes) = $event)),
                max: 500,
                customClass: "fw-light fs-small"
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-secondary",
              onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.resetModal && _ctx.resetModal(...args)))
            }, " Discard "),
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-primary",
              onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
            }, " Submit ")
          ])
        ], 64))
  ], 64))
}