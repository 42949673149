<template>
  <div class="filters-wrapper gap-20 justify-content-between align-items-start">
    <div class="filters w-100 align-items-start">
      <p class="filters-text pdt-10"> Filters: </p>
      <form class="filters w-100 flex-column align-items-start" @submit.prevent="submitFilters">
        <div class="work-history-filters d-flex flex-column flex-lg-row gap-15 align-items-start w-100">
          <SelectComponent v-model:selectedOption="filters.period" :options=dateFilterOptions :singleArrow="true" custom-class="w-100" />
          <div class="custom-select links w-100">
            <multiselect
              label="OrgName"
              track-by="EmployerID"
              v-model="selectedEmployers"
              :options="employersOptions"
              :multiple="true"
              :showNoOptions="false"
              :placeholder="emplDropdownOpened? 'Start typing to search for Employers' : 'All Employers'"
              @open="toggleDropdownOpen('empl')"
              @close="toggleDropdownOpen('empl')"
              @search-change="searchEmployers"
              @select="updateEmployerSelection">
            </multiselect>
          </div>
          <div class="custom-select links w-100">
            <multiselect
              label="OrgName"
              track-by="ProductionID"
              v-model="selectedProductions"
              :options="productionsOptions"
              :multiple="true"
              :placeholder="prodDopdownOpened? 'Start typing to search for Productions' : 'All Productions'"
              @open="toggleDropdownOpen('prod')"
              @close="toggleDropdownOpen('prod')"
              @search-change="searchProductions"
              @select="updateProductionSelection"></multiselect>
          </div>
          <div class="custom-select links w-100">
            <multiselect
              label="name"
              track-by="id"
              v-model="selectedFunds"
              :options="fundsOptions"
              :multiple="true"
              placeholder="All Funds"
              >
            </multiselect>
          </div>
        </div>
        <div v-if="filters.period?.key === 'custom_range'" class="d-flex gap-20 w-50 w-sm-100">
          <DateInput v-model:modelValue="filters.from_date" />
          <DateInput v-model:modelValue="filters.to_date" :error="errors"/>
        </div>
      </form>
    </div>
    <button type="button" :disabled="loading" class="btn btn-secondary text-nowrap lh-1 pdt-10 pdb-10" @click.prevent="printHistory"> <i class="iatse-icon-printer"> </i>Print PDF</button>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, watch, onMounted } from 'vue';
import { usePrint } from '@/composable/usePrint';
import { PrintData, SelectOption } from '@/interfaces/interfaces';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { useFormattedDate } from '@/composable/useFormattedDate';

import axios from 'axios';
import Multiselect from 'vue-multiselect';
import SelectComponent from '@components/form/SelectComponent.vue';
import DateInput from '@components/form/DateInput.vue';
import 'vue-multiselect/dist/vue-multiselect.css';

interface Employer {
  OrganizationID: number;
  OrgName: string;
  EmployerID: number;
}

interface Production {
  OrganizationID: number;
  OrgName: string;
  ProductionID: number;
}

interface Filters {
  period: SelectOption;
  from_date: string | null;
  to_date: string | null;
  employers: number[];
  productions: number[];
  funds: number[];
}

interface Funds {
  [key: string]: string;
}

interface FundOption {
  id: number,
  name: string
}

export default defineComponent({
  props: { loading: Boolean },
  components: { Multiselect, SelectComponent, DateInput },
  setup(_, { emit }) {
    const errors = ref<string[]>([]);
    const emplDropdownOpened = ref(false);
    const prodDopdownOpened = ref(false);
    const employersOptions = ref<Employer[]>([]);
    const productionsOptions = ref<Employer[]>([]);
    const fundsOptions = ref<FundOption[]>([]);
    const selectedEmployers = ref([]);
    const selectedProductions = ref([]);
    const selectedFunds = ref([]);
    const authStore = useAuthStore();
    const { printElementById } = usePrint();
    const { formatDate } = useFormattedDate();
    const dateFilterOptionsValues = [
      {key: 'current_year', value: 'Current Year'},
      {key: 'previous_year', value: 'Previous Year'},
      {key: 'custom_range', value: 'Custom Range (2 years max)'},
    ];
    const dateFilterOptions = ref([...dateFilterOptionsValues])
    const filters: Filters = reactive({
      period: dateFilterOptionsValues[0],
      from_date: null,
      to_date: null,
      employers: [],
      productions: [],
      funds: useModalStore().currentModal?.queryParams?.Funds as number[] ?? [],
    });

    watch(selectedEmployers, () => submitFilters(), {deep: true});
    watch(selectedProductions, () => submitFilters(), {deep: true});
    watch(filters, () => submitFilters(), {deep: true});

    const printData: PrintData = reactive({
      Header: {
        ParticipantName: authStore?.user?.name ?? '',
        ReportDate: formatDate(new Date().toDateString()),
        PrintDate: formatDate(new Date().toDateString()),
      },
      Filters: {
        DateRange: filters.from_date + ' - ' + filters.to_date,
        Employers: 'All',
        Productions: 'All',
        Funds: 'All',
      },
      Info: 'D= Day(s) Reported, H= Hour(s), Reported W=Week(s), Reported CW= Contractual Wages'
    });

    const printHistory = printElementById('work-history', 'Work History', printData);

    const toggleDropdownOpen = (type: string) => {
      if(type === 'empl') {
        employersOptions.value = [];
        emplDropdownOpened.value = !emplDropdownOpened.value;
      } else {
        productionsOptions.value = [];
        prodDopdownOpened.value = !prodDopdownOpened.value;
      }
    }

    const searchEmployers = async (searchTerm: string) => {
      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(`/api/participant/employers?searchTerm=${searchTerm}`);
          employersOptions.value = response.data;
        } catch (error) {
          console.error('Error fetching employers:', error);
        }
      }
    };

    const updateEmployerSelection = (newSelection: Employer) => {
      const orgId = newSelection.EmployerID;

      // Check if the OrganizationID already exists in the filters.employers array
      const index = filters.employers.indexOf(orgId);

      if (index === -1) {
        // If the OrganizationID does not exist, add it to the array
        filters.employers.push(orgId);
      } else {
        // If the OrganizationID exists, remove it from the array
        filters.employers.splice(index, 1);
      }
    }

    const searchProductions = async (searchTerm: string) => {
      if (searchTerm.length >= 3) {
        try {
          const response = await axios.get(`/api/participant/productions?searchTerm=${searchTerm}`);
          productionsOptions.value = response.data;
        } catch (error) {
          console.error('Error fetching productions:', error);
        }
      }
    };

    watch(() => selectedEmployers.value, (newVal) => {
      if(printData.Filters?.Employers) {
        printData.Filters.Employers = newVal.length
          ? newVal.map((employer: Employer) => employer.OrgName).join(', ')
          : 'All';
      }
    });

    watch(() => selectedProductions.value, (newVal) => {
      if(printData.Filters?.Productions) {
          printData.Filters.Productions = newVal.length
          ? newVal.map((prod: Production) => prod.OrgName).join(', ')
          : 'All';
      }
    });

    watch(() => selectedFunds.value, (newVal) => {
      if(printData.Filters?.Funds) {
          printData.Filters.Funds = newVal.length
          ? newVal.map((fund: FundOption) => fund.name).join(', ')
          : 'All';
      }

      filters.funds = newVal.length
        ? newVal.map((fund: FundOption) => fund.id)
        : (useModalStore().currentModal?.queryParams?.Funds ?? []) as number[];
    });

    const updateProductionSelection = (newSelection: Production) => {
      const orgId = newSelection.ProductionID;

      // Check if the OrganizationID already exists in the filters.employers array
      const index = filters.productions.indexOf(orgId);

      if (index === -1) {
        // If the OrganizationID does not exist, add it to the array
        filters.productions.push(orgId);
      } else {
        // If the OrganizationID exists, remove it from the array
        filters.productions.splice(index, 1);
      }
    }

    const updateFundSelection = (newSelection: FundOption) => {
      const id = newSelection.id;
      console.log(id);

      // Check if the OrganizationID already exists in the filters.employers array
      const index = filters.funds.indexOf(id);

      if (index === -1) {
        filters.funds.push(id);
      } else {
        filters.funds.splice(index, 1);
      }
    }

    // fetchData method definition
    const fetchData = async () => {
      try {
        const response = await axios.get<Funds>(`/api/participant/funds?funds=${useModalStore().currentModal?.queryParams?.Funds ?? []}`);
        const fundsData: Funds = response.data;
        // Transform the funds object into an array of FundOption objects
        fundsOptions.value = Object.entries(fundsData).map(([id, name]) => ({
          id: Number(id), // Convert string to number
          name
        }));
      }
      catch (error) {
        console.error('Error fetching fund data:', error);
      }
    };

    onMounted(async() => {
      getCurrentFunds
      await fetchData();
    });

    const getCurrentFunds = () => {
      const currentFund = useModalStore().currentModal?.fund;

      if(currentFund === 'vacation') {
        filters.funds = [6];
      }
    };

    const isValidDate = (date: string): boolean => {
      const parsedDate = new Date(date);

      // Check if the date is valid and the year is after 1900
      return !isNaN(parsedDate.getTime()) && parsedDate.getFullYear() > 1900;
    };

    const submitFilters = () => {
      if(filters.period?.key !== 'custom_range') {
        emit('update-filters', {
          ...filters,
          period: filters.period?.key,
          employers: selectedEmployers.value.map((employer: Employer) => employer.EmployerID),
          productions: selectedProductions.value.map((production: Production) => production.ProductionID)
        });
      } else if(filters.from_date && filters.to_date && isValidDate(filters.from_date) && isValidDate(filters.to_date)) {
        errors.value = [];
        const fromDate = new Date(filters.from_date);
        const toDate = new Date(filters.to_date);

        const isWithinTwoYears = (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24 * 365) <= 2;
        const isToDateAfterFromDate = toDate >= fromDate;

        console.log(isWithinTwoYears, fromDate, toDate);
        if(!isToDateAfterFromDate) {
          errors.value = [...errors.value, 'To Date cannot be earlier than From Date'];
        }

        if(!isWithinTwoYears) {
          errors.value = [...errors.value, 'Max Range Date should be 2 years.'];
        }

        if (isWithinTwoYears && isToDateAfterFromDate) {
          errors.value = [];
          emit('update-filters', {
            ...filters,
            period: filters.period?.key,
            employers: selectedEmployers.value.map((employer: Employer) => employer.EmployerID),
            productions: selectedProductions.value.map((production: Production) => production.ProductionID)
        });
        }
      }
    };

    return {
      errors,
      filters,
      employersOptions,
      selectedEmployers,
      dateFilterOptions,
      emplDropdownOpened,
      prodDopdownOpened,
      submitFilters,
      searchEmployers,
      updateEmployerSelection,
      productionsOptions,
      selectedProductions,
      searchProductions,
      updateProductionSelection,
      fundsOptions,
      selectedFunds,
      updateFundSelection,
      printHistory,
      printData,
      printElementById,
      toggleDropdownOpen
    };
  }
});
</script>