import { createApp } from "vue";
import { pinia } from "./stores/main";
import App from "./App.vue";
import DataTable from "@/components/form/DataTable.vue";
import RadioButton from "@/components/form/RadioButton.vue";
import UpdateInfoStep2 from "@/components/profile/personal-info/UpdateInfoStep2.vue";
import LoaderComponent from "@/components/utils/LoaderComponent.vue";
import Alerts from "@components/utils/AlertsComponent.vue";
import MessageCard from "@components/cards/MessageCard.vue";
import router from "./router";
import "./services/axiosConfig";
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "./assets/scss/style.scss";
import { VueReCaptcha } from 'vue-recaptcha-v3'

const app = createApp(App);
app.use(pinia); // Using the Pinia store
app.use(VueReCaptcha, {
    siteKey: '6Ld-28YqAAAAAGQoC9Pq4ClLJBj_Nt6JRuxRWWT9',
    loaderOptions: {
        autoHideBadge: true,
    }
});

app.component("DataTable", DataTable);
app.component("RadioButton", RadioButton);
app.component("UpdateInfoStep2", UpdateInfoStep2);
app.component("LoaderComponent", LoaderComponent);
app.component("AlertsComponent", Alerts);
app.component("MessageCard", MessageCard);
app.use(router);
app.mount("#app");
