<template>
    <AlertsComponent :error="error" />

    <table class="small striped toggle no-underline">
        <thead class="large large_padding">
            <tr>
                <th class="col-md-1"> Local </th>
                <th class="col-md-1"> First Name </th>
                <th class="col-md-1"> Last Name </th>
                <th class="col-md-2"> Email Address </th>
                <th class="col-md-1"> Title </th>
                <th class="col-md-1"> Days to Expiration </th>
                <th class="col-md-1"> Type </th>
                <th class="col-md-2"> Account actions </th>
            </tr>
        </thead>
        <tbody>
            <tr class="large" v-for="local in locals" :key="local.LocalID">
                <td class="col-md-1 plan-modal"
                    @click="openModal('edit-user', 'Locals', 'plan-profile plan-tables', null, { UserID: local.UserID })">
                    {{ local.OrgName }}
                </td>
                <td class="col-md-1">{{ local.FirstName }}</td>
                <td class="col-md-1">{{ local.LastName }}</td>
                <td class="col-md-2">{{ local.Email }}</td>
                <td class="col-md-2">{{ local.Title }}</td>
                <td class="col-md-1">{{ local.DaysToExpiration }}</td>
                <td class="col-md-1">{{ local.Role }}</td>
                <td class="col-md-2">
                    <div class="actions">
                        <!-- Activate/Deactivate -->
                        <span v-if="local.Inactive == 1" class="tooltip-wrapper" data-tooltip="Activate User">
                            <i @click="activate(local.UserID)" class="plan-modal fs-xs iatse-icon-noun-reset"></i>
                        </span>
                        <span v-else class="tooltip-wrapper" data-tooltip="Deactivate User">
                            <i @click="deactivate(local.UserID)" class="plan-modal iatse-icon-deactivate-user"></i>
                        </span>
                        <p> / </p>

                        <!-- Make/Remove Admin -->
                        <span v-if="local.Role == 'Admin'" class="tooltip-wrapper" data-tooltip="Remove Admin Role">
                            <i @click="changeRole(local.UserID)" class="plan-modal iatse-icon-person-remove"></i>
                        </span>
                        <span v-else class="tooltip-wrapper" data-tooltip="Make User Admin">
                            <i @click="changeRole(local.UserID)" class="plan-modal iatse-icon-person"></i>
                        </span>

                        <p> / </p>
                        <!-- Reset Pin -->
                        <span class="tooltip-wrapper" data-tooltip="Reset PIN">
                            <i @click="resetPin(local.UserID)"
                                :class="['plan-modal', 'iatse-icon-reset', { 'icon-green': local?.DaysToExpiration === 0 }]"></i>
                        </span>
                        <p> / </p>
                        <!-- Login -->

                        <span class="tooltip-wrapper" data-tooltip="Masquerade">
                            <i @click="loginAsUser(local.UserID)" class="plan-modal iatse-icon-noun-login">
                            </i>
                        </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from 'vue';
import { SearchLocal } from '@/interfaces/admin';
import { useAuthStore } from '@/stores/auth';
import { useRouter } from 'vue-router';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';

export default defineComponent({
    props: {
        locals: {
            type: Object as PropType<SearchLocal[]>,
            required: true
        },
    },
    setup(_, { emit }) {
        const error = ref<string|null>(null);
        const authStore = useAuthStore();
        const router = useRouter();

        const resetPin = (userID: number) => {
            emit('reset-pin', userID);
        }

        const changeRole = (userID: number) => {
            emit('change-role', userID);
        }

        const activate = (userID: number) => {
            emit('activate', userID);
        }

        const deactivate = (userID: number) => {
            emit('deactivate', userID);
        }

        const loginAsUser = async (userID: number) => {
            try {
                await authStore.startImpersonation(userID);
                useParticipantStore().reset();
                await useParticipantStore().getUserEmail();

                router.push({ name: 'local.dashboard' });
            } catch (impersonationError: unknown) {
                error.value = impersonationError as string;

                setTimeout(() => error.value = null, 2000);
                console.error("Impersonation error:", impersonationError);
            }
        };

        return {
            error,
            loginAsUser,
            resetPin,
            changeRole,
            activate,
            deactivate,
            openModal: useModalStore().openModal
        };
    }
});
</script>