<template>
    <div class="plan-content">
        <!-- General Plan Detail Cards -->
        <div class="plan-general-details">
            <div class="plan-general-detail-container">
                <div class="plan-general-detail-line">
                    <h5> Participant ID </h5>
                    <h4>{{ planSummary.PersonID }}</h4>
                </div>
            </div>
            <div class="plan-general-detail-container">
                <div class="plan-general-detail-line">
                    <h5> Current Coverage Status </h5>
                    <h4>{{ planSummary.Coverage.Plan }}</h4>
                </div>
            </div>
            <div class="plan-general-detail-container">
                <div class="plan-general-detail-line">
                    <h5> Current Coverage Period </h5>
                    <h4>{{ planSummary.Coverage.From }} to {{ planSummary.Coverage.To }}</h4>
                </div>
            </div>
            <div class="plan-general-detail-container" v-if="rmrpBalance > 0">
                <div class="plan-general-detail-line">
                    <h5> Frozen R-MRP Balance </h5>
                    <h4>{{ formatCurrency(rmrpBalance) }}</h4>
                </div>
            </div>
        </div>

        <!-- Coverage History -->
        <div class="contributions-table-item">
            <div class="contributions-table-item-header">
                <h4> Coverage History </h4>
                <p class="btn btn-secondary plan-modal"><a @click.prevent="openModal('coverage-history', 'Coverage History', 'plan-coverage-history')">See all Coverage History</a></p>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Coverage Type</th>
                        <th>Carrier Code</th>
                        <th>Effective Date</th>
                        <th>Term/Susp</th>
                        <th>Reason</th>
                    </tr>
                </thead>
                <tbody v-if="planSummary.CoverageHistory.length > 0">
                    <tr v-for="coverage in planSummary.CoverageHistory" :key="coverage.CoverageID">
                        <td>{{ coverage.FundName || 'N/A' }}</td>
                        <td>{{ coverage.CarrierCode || 'N/A' }}</td>
                        <td>{{ coverage.EffectiveDate }}</td>
                        <td>{{ coverage.TerminationDate }}</td>
                        <td>{{ coverage.TerminationReason || 'N/A' }}</td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="8">No coverage history found</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <DaysWorkedCarousel v-if="!loading" :items="workPerMonthArray" :startingMonth="planSummary.StartingMonth" />

        <!-- Account Actions -->
        <div class="plan-summary__footer row w-100  sm-mt-500">
            <div class="col-12 col-md-4 d-flex flex-column justify-content-between">
                <h4>Other Coverages</h4>
                <div class="account-actions">
                    <!-- Other Coverages -->
                    <OtherCoverages :otherCoverages="planSummary.OtherCoverages"/>
                </div>
            </div>
            <div class="col-12 col-md-8">
                <h4>Account Actions</h4>
                <div class="action-buttons mt-10 flex-md-row flex-column">
                    <a href="" class="btn btn-secondary plan-modal"
                        @click.prevent="openModal('add-beneficiary', 'My Beneficiary(ies)', 'plan-tables-striped beneficiary', null, null, null
                        )">
                        Beneficiary(ies)
                    </a>
                    <a  v-if="isAvailableForClaim"
                        href="#"
                        class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('claims', 'Claims', 'plan-upload plan-card-info plan-c-mrp-claims')">
                        Claims
                    </a>
                    <a href="" @click.prevent="openModal('request-card', 'Request for Insurance ID Card(s)', 'plan-upload plan-card-info plan-profile-update')" class="btn btn-secondary">Request ID Card(s)</a>
                    <a href="" class="btn btn-secondary btn-large plan-modal"
                        @click.prevent="openModal('dependents', 'My Dependents', null)">My Dependents
                    </a>
                </div>
            </div>
        </div>
        <!-- end actions -->
    </div>
</template>

<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import { useModalStore } from '@/stores/modal';
import { useCoverageStore } from '@/stores/coverage';
import { PlanASummary, PlanACoverageItem } from '@/interfaces/health';
import { useCurrencyFormatter } from "@/composable/useCurrencyFormatter";
import DaysWorkedCarousel from '@/components/participant/health-welfare/DaysWorkedCarousel.vue';
import OtherCoverages from '@/components/participant/health-welfare/OtherCoverages.vue';

export default {
    setup() {
        const modalStore = useModalStore();
        const isAvailableForClaim = ref(false);
        const coverageStore = useCoverageStore();
        const loading = ref(true);
        const rmrpBalance = ref(0);
        const { formatCurrency } = useCurrencyFormatter();

        if (coverageStore) {
            if (coverageStore.isAvailableForClaim === true) {
                isAvailableForClaim.value = true;
            }

            const rmrpTotal = coverageStore.healthData?.RmrpTotal;
            if (rmrpTotal !== undefined && rmrpTotal !== null && rmrpTotal > 0) {
                rmrpBalance.value = rmrpTotal;
            }
        }

        const planSummary = ref<PlanASummary>({
            PersonID: 0,
            Coverage: {
                From: '',
                To: '',
                PlanClass: null,
                Plan: ''
            },
            StartingMonth: '',
            CoverageHistory: [],
            WorkPerMonth: []
        })

        const fetchData = () => {
            const fromDate = modalStore.currentModal?.queryParams?.FromDate;

            console.log('>>> FRomDate: ' + fromDate);

            let endpoint = 'api/participant/health-welfare/plan-a-summary';
            if (fromDate !== undefined) {
                endpoint += '?From=' + fromDate;
            }

            axios.get(endpoint)
                .then(response => {
                    planSummary.value = response.data;
                })
                .catch(error => {
                    console.error('There was an error!', error);
                })
                .finally(() => loading.value = false);
        };

        onMounted(fetchData);

        // Transform WorkPerMonth from object to array
        const workPerMonthArray = computed<PlanACoverageItem[]>(() => {
            return Object.entries(planSummary.value.WorkPerMonth).map(([date, data]) => ({
                date,
                displayDate: data.displayDate,
                isEligible: data.isEligible,
                daysWorked: data.daysWorked,
                coveragePeriod: data.coveragePeriod,
                contributions: data.contributions
            }));
        });

        return {
            loading,
            isAvailableForClaim,
            workPerMonthArray,
            openModal: modalStore.openModal,
            rmrpBalance,
            planSummary,
            formatCurrency
        };
    },

    components: {
        DaysWorkedCarousel,
        OtherCoverages
    }
};
</script>