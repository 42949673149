<template>
  <div :class="['drag-drop', customClasses]" ref="dragDropArea" @dragover.prevent @dragenter.prevent="handleDragEnter"
    @dragleave.prevent="handleDragLeave" @drop.prevent="handleDrop">
    <!-- Overlay when loading -->
    <div v-if="loading" class="overlay">
      <div class="spinner"></div>
    </div>

    <div class="choose-files" v-if="!files.length">
      <i :class="icon"></i>
      <h5 class="browse" v-if="text" @click.prevent="triggerFileInput"> {{ text }} </h5>
      <h5 v-else> Drag and Drop or
        <span class="browse" @click.prevent="triggerFileInput">{{browseText}}</span>
      </h5>
      <input type="file" :accept="accept" ref="fileInput" style="display: none;" multiple @change="handleFileChange" />
      <p v-if="accept !== ''" class="fs-xs text-grey text-center">
        Supported: {{ accept }}
      </p>
    </div>


    <div class="drag-drop-files">
      <div v-for="(file, index) in files" :key="index" class="file-preview">
        <img src="../../assets/images/doc.png" :alt="file.name" class="file-image-preview" width="95" height="55" />
        <small class="file-name">{{ file.name }}</small>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, ref, toRefs, watch, computed } from 'vue';

export default defineComponent({
  props: {
    customClasses: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: ".csv, .rtf, .txt, .doc, .docx, .xls, .xlsx, .dwg, .dxf, .eml, .msg, .pdf, .bmp, .jpg, .jpeg, .gif, .pcx, .png, .psd, .tga, .tif, .tiff, .wbmp, .wmf, .ppt, .pptx",
    },
    browseText: {
      type: String,
      default: 'Choose From Device',
    },
    icon: {
      type: String,
      default: 'iatse-icon-document-upload',
    },
    text: String,
    reset: Boolean,
    loading: Boolean,
  },
  setup(props, { emit }) {
    const files = ref<File[]>([]);
    const fileInput = ref<HTMLInputElement | null>(null);
    const dragDropArea = ref<HTMLDivElement | null>(null);
    const { reset } = toRefs(props);
    const acceptPattern = computed(() => new RegExp(
      `(${props.accept
        .split(',')
        .map(ext => ext.trim().replace(/\./g, '\\.'))
        .join('|')})$`,
      'i'
    ));

    watch(() => reset.value, (newValue) => {
      console.log("File reset.", newValue);

      if (newValue) {
        files.value = [];

        if (fileInput.value) {
          fileInput.value.value = "";
          console.log("File input has been reset.");
        }

        fileInput.value = null;
        emit('reset');
      }
    });

    const handleDragEnter = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '2px dashed #8EC986';
      }
    };

    const handleDragLeave = (event: DragEvent) => {
      if (dragDropArea.value) {
        dragDropArea.value.style.border = '1px dashed #8EC986';
      }
    };

    const handleDrop = (event: DragEvent) => {
      if (event.dataTransfer && event.dataTransfer.files) {
        // files.value = [...files.value, ...Array.from(event.dataTransfer.files)];
        const droppedFiles = Array.from(event.dataTransfer.files);

        const validFiles = props.accept !== '*' ? droppedFiles.filter(file => acceptPattern.value.test(file.name)) : [...files.value];
        const invalidFiles =  props.accept !== '*' ? droppedFiles.filter(file => !acceptPattern.value.test(file.name)) : [];

        if (invalidFiles.length > 0) {
          files.value = [];
          fileInput.value = null;
          emit('stop');
          alert(`Some files are not allowed: ${invalidFiles.map(file => file.name).join(', ')}`);
        }

        files.value = [...files.value, ...validFiles];

        if (validFiles.length > 0) {
          emit('allow');
          emit('update-files', validFiles);
        }
      }
    };

    const handleFileChange = async (event: Event) => {
      const target = event.target as HTMLInputElement;

      if (target.files) {
        // files.value = [...files.value, ...Array.from(target.files)];
        const droppedFiles = Array.from(target.files);
        console.log(droppedFiles, acceptPattern);

        const validFiles = props.accept !== '*' ? droppedFiles.filter(file => acceptPattern.value.test(file.name)) : [...files.value];
        const invalidFiles =  props.accept !== '*' ? droppedFiles.filter(file => !acceptPattern.value.test(file.name)) : [];

        if (invalidFiles.length > 0) {
          files.value = [];
          fileInput.value = null;
          emit('stop');
          console.log('stop');
          alert(`Some files are not allowed: ${invalidFiles.map(file => file.name).join(', ')}`);
        }

        if (validFiles.length > 0) {
          files.value = [...files.value, ...validFiles];
          emit('allow');
          emit('update-files', validFiles);
        }
      }
    };

    const triggerFileInput = () => {
      if (fileInput.value) {
        fileInput.value.click();
      }
    }

    return {
      files,
      fileInput,
      dragDropArea,
      handleDrop,
      handleDragLeave,
      handleDragEnter,
      triggerFileInput,
      handleFileChange
    };
  },
});
</script>