<template>
    <Alerts :error="localErrors.General" :success="success" />
    <LoaderComponent v-if="loading" />
    <div v-else class="plan-content gap-25">
        <div class="d-flex gap-20 justify-content-between w-100 flex-md-row flex-column">
            <TextInput type="text" label="First Name" placeholder="Enter First Name" :max=25
                v-model:modelValue="userInfo.FirstName" :readonly="true" />
            <TextInput type="text" label="Middle Name" placeholder="Enter Middle Name" :max=12
                v-model:modelValue="userInfo.MiddleName" :readonly="true" />
            <TextInput type="text" label="Last Name" placeholder="Enter Last Name" :max=25
                v-model:modelValue="userInfo.LastName" :readonly="true" />
            <TextInput type="text" label="Designation" placeholder="Enter Designation" :max=100
                v-model:modelValue="userInfo.Designation" :readonly="true" />
        </div>

        <!-- Update Phone Number -->
        <form class="d-flex gap-20 justify-content-between align-items-end w-100 flex-md-row flex-column">
            <TextInput type="text" name="phone" label="Phone" placeholder="Phone" :max=30
                v-model:modelValue="userPhone" :required="true" custom-class="w-75 w-sm-100" :readonly="true" />
            <div class="h-fit d-flex justify-content-end w-25 w-sm-100 flex-column">
                <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit"
                    :disabled="loadingUpdate['password']" @click.prevent="updatePhone">
                    Change Phone no
                </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

        <!-- Update Email -->
        <form id="email-change-form" class="d-flex gap-20 justify-content-between align-items-end w-100 flex-md-row flex-column" @submit.prevent="updateEmail">
            <TextInput type="email" v-model:modelValue="userEmail" label="Email Address" :placeholder=participantStore.userEmail :readonly="true" custom-class="w-75  w-sm-100" />
            <div class="h-fit d-flex justify-content-end w-25 w-sm-100 flex-column">
            <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit"
                :disabled="loadingUpdate['password']"> Change Email Address </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

        <!-- Update Password -->
        <form id="password-change-form" class="d-flex gap-20 justify-content-between align-items-end w-100 flex-md-row flex-column" @submit.prevent="updatePassword">
            <div class="w-75 d-flex gap-20 w-sm-100">
                <TextInput type="password" label="New Password" placeholder="Enter New password"
                    v-model:modelValue="password" name="password" :error="localErrors.password"/>
                <TextInput type="password" label="Confirm Password" placeholder="Enter Confirm New password"
                    v-model:modelValue="passwordConfirmation" name="password_confirmation" :error="localErrors.password_confirmation"/>
            </div>
            <div class="h-fit d-flex justify-content-end w-25 flex-column w-sm-100">
                <button type="submit" class="btn btn-secondary toggle-control lh-1 text-nowrap h-fit" :disabled="loadingUpdate['password']"> Change Password </button>
            </div>
        </form>

        <div class="divider mt-0 mb-0"></div>

          <!-- Update Security Questions -->
          <form id="security-questions-form" class="form account-settings" @submit.prevent="updateSecurityQuestion">
            <h3> Security Questions
                <small v-if="numberOfQuestions > 1" class="fs-sm pdl-20 fw-light"> *Setup at least 3 Security Questions.</small>
            </h3>
            <template v-if="userQuestions.length || selectedQuestions.length">
                <SecurityQuestionSelect v-for="index in selectedQuestions.length" :key="index"
                    label="Security Question"
                    :index="index"
                    :options="availableQuestionsForSelect(`select${index}`).value"
                    :userQuestion="userQuestions[index - 1]"
                    :selected-question="selectedQuestions[index - 1]"
                    :questionId="userQuestions[index - 1]?.ID"
                    :resetInputs="resetInputs"
                    :showAddNewQuestion="showAddNewQuestion"
                    @handle-questions="handleQuestions"
                    @delete="removeQuestion"
                    @toggle-edit="editQuestion"
                />
            </template>

            <a href="" v-if="selectedQuestions.length < 3" @click.prevent="addNewQuestion" class="btn btn-secondary add-btn">
                <p class="icon-wrapper"> <i class="iatse-icon-plus"> </i> </p> Add New Security Question
            </a>

            <div class="d-flex w-full gap-2" v-if="hasEditable">
                <button type="submit" class="btn btn-primary action-btn"> Update </button>
                <a href="" @click.prevent="discardChanges" class="btn btn-secondary action-btn"> Discard </a>
            </div>
        </form>

        <!-- 2 Factor Authentication -->
        <form id="two-fa-form">
            <h3 class="w-100 mb-30"> Two-Factor Authentication (Required) </h3>
            <p>Preferred Method</p>
            <div class="radio-group primary bold">
                <div class="radio-buttons align-items-start flex-md-row flex-column">
                    <div
                        class="radio-button flex-column w-fit align-items-start rounded"
                        :class="{ 'bg-white': preferredMethod == 'P' }"
                        >
                        <label for="participant-phone" class="d-flex pdr-15">
                        <input
                            type="radio"
                            id="participant-phone"
                            name="method"
                            v-model="preferredMethod"
                            class="pdr-5"
                            value="P"
                            :disabled="!userPhone"
                            @change="updatePreferredMethod('P')"
                        /><span class="pdl-10">Mobile Phone</span></label>
                        <template v-if="preferredMethod == 'P' && !loadingPreferred">
                            <small class="pdt-10">Phone number for Authentication</small>
                            <p class="fw-medium pdt-10">{{  userPhone  }} <i class="iatse-icon-edit ml-10 fw-bold plan-modal"  @click.prevent="updatePhone"></i></p>
                        </template>
                    </div>
                    <div
                        class="radio-button flex-column w-fit align-items-start rounded"
                        :class="{ 'bg-white': preferredMethod == 'E' }"
                        >
                        <label for="participant-email" class="d-flex">
                            <input
                                type="radio"
                                id="participant-email"
                                name="method"
                                v-model="preferredMethod"
                                class="pdr-5"
                                value="E"
                                @change="updatePreferredMethod('E')"
                            />
                            <span class="pdl-10">Email Code</span>
                        </label>
                        <template v-if="preferredMethod == 'E' && !loadingPreferred">
                            <small class="pdt-10">Email Address for Authentication</small>
                            <p class="fw-medium pdt-10">{{ participantStore.userEmail }} <i class="iatse-icon-edit ml-10 fw-bold plan-modal" @click.prevent="updateEmail"></i></p>
                        </template>
                    </div>
                </div>
            </div>
            <small v-if="!userPhone">Please add your phone number above to enable this option.</small>
        </form>

    </div>
</template>

<script lang="ts">
import { ref, onMounted, Ref, computed } from 'vue';
import { SecurityQuestion } from '@/interfaces/interfaces';
import { SelectedQuestion } from '@/interfaces/employer';
import { useLocalStore } from '@/stores/local';
import { useEmployerStore } from '@/stores/employer';
import { useAuthStore } from '@/stores/auth';
import { useModalStore } from '@/stores/modal';
import { useParticipantStore } from '@/stores/participant';
import axios from 'axios';

import SecurityQuestionSelect from '@components/user/SecurityQuestionSelect.vue';
import TextInput from '@components/form/TextInput.vue';
import Alerts from '@components/utils/AlertsComponent.vue';
import { useVerificationCode } from '@/composable/useVerificationCode';

type SettingsState = {
    password: boolean;
    email: boolean;
    securityQuestion: boolean;
    twoFactorAuth: boolean;
    newEmail: boolean;
};

type SettingType = keyof SettingsState;

export default {
    components: {
        SecurityQuestionSelect,
        TextInput,
        Alerts
    },
    setup() {
        const localStore = useLocalStore();
        const employerStore = useEmployerStore();
        const participantStore = useParticipantStore();
        const authStore = useAuthStore();
        const securityQuestionsArray: Ref<Array<{ key: number, value: string }>> = ref([]);
        const userQuestions: Ref<SecurityQuestion[]> = ref([]);
        const isEmployer = computed(() => authStore.isEmployer);
        const userInfo = computed(() => isEmployer.value ? employerStore.general: localStore.general);
        const showPasswordConfirmation = ref(false);
        const currentPassword = ref('');
        const password = ref('');
        const passwordConfirmation = ref('');
        const email = ref('');
        const newEmail = ref('');
        const emailConfirmation = ref('');
        const emailReadOnly = ref(true);
        const phoneNumber = ref('');
        const localErrors = ref<Record<string,string>>({General:'' });
        const success = ref('');
        const selectedQuestions: Ref<SelectedQuestion[]> = ref([]);
        const loading = ref(true);
        const loadingPreferred = ref(false);
        const resetInputs = ref(false);
        const hasEditable = computed(() => Object.values(selectedQuestions.value).some(question => question.IsEditable === true));
        const showAddNewQuestion = ref(selectedQuestions.value.length < 3);
        const numberOfQuestions = 3;
        const userEmail = computed(() => useParticipantStore().userEmail);
        const userPhone = computed(() => useParticipantStore().userPhone);
        const oldPreferredMethod = computed(() => useParticipantStore().oldPreferredMethod);
        const preferredMethod = computed({
            get: () => useParticipantStore().userTwoFaMethod,
            set: (value) => {
                useParticipantStore().userTwoFaMethod = value;
            },
        });
        const updateSettingsState = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });

        const loadingUpdate = ref<SettingsState>({
            password: false,
            email: false,
            securityQuestion: false,
            twoFactorAuth: false,
            newEmail: false,
        });

        // Toggle the visibility of editable fields
        const toggleUpdateSetting = (settingType: SettingType) => {
            updateSettingsState.value[settingType] = !updateSettingsState.value[settingType];
        };


        const togglePasswordConfirmation = () => {
            showPasswordConfirmation.value = !showPasswordConfirmation.value;
        }

        const discardChanges = () => {
            resetInputs.value = true;

            selectedQuestions.value = selectedQuestions.value.map((question: SelectedQuestion) => {
                if (question.IsEditable) {
                    return {
                        ...question,
                        Answer: "",
                        Question: 0,
                    };
                }

                return question;
            });
        }

        const editQuestion = (index: number) => {
            showAddNewQuestion.value = false;

            (selectedQuestions.value[index] as SelectedQuestion).IsEditable = !(selectedQuestions.value[index] as SelectedQuestion)?.IsEditable;
        }

        const removeQuestion = (id: number) => {
            axios.delete(`api/user/settings-remove-question/${id}`)
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);
                })
                .catch((error: any) => {
                    if (error.response) {
                        localErrors.value.General = error.response.data.errors;
                    }
                })
        }

        const addNewQuestion = () => {
            showAddNewQuestion.value = true;

            const questionObj: SelectedQuestion = {
                Answer: "",
                ExistingQuestion: 0,
                Question: 0,
                IsEditable: true,
            }

            selectedQuestions.value.push(questionObj);
        }

        const selectedQuestionsId = ref<{ [key: string]: number }>({ select1: 0, select2: 0, select3: 0 });

        // Get Available Security Questions
        const availableQuestionsForSelect = (selectKey: string) => computed(() => {
            return securityQuestionsArray.value?.filter(q => {
                return !Object.entries(selectedQuestionsId.value)
                    .filter(([key, value]) => key !== selectKey && value !== 0)
                    .map(([, value]) => value)
                    .includes(q.key as number);
            });
        })

        // Handle Security Questions Changes
        const handleQuestions = (payload: SelectedQuestion, index: number) => {
            selectedQuestionsId.value[`select${index}`] = payload.Question;
        }

        // [Get] Security Questions
        const fetchData = async (isLoading: boolean) => {
            if (isLoading)
                loading.value = true;

            await axios.get('api/user/settings')
                .then(response => {
                    securityQuestionsArray.value = Object.entries(response.data.SecurityQuestionsArray).map(([key, value]) => ({
                        key: Number(key),
                        value: String(value)
                    }));
                    email.value = response.data.Email;

                    if (response.data.UserSecurityQuestions.length > 0) {
                        response.data.UserSecurityQuestions.forEach((element: SecurityQuestion, index: number) => {
                            selectedQuestionsId.value[`select${index + 1}`] = element.SecurityQuestionID as number;
                        });
                    }

                    const updatedUserQuestions: SelectedQuestion[] = [];
                    userQuestions.value = response.data.UserSecurityQuestions;
                    userQuestions.value.map((question: SecurityQuestion) => {
                        const questionObj: SelectedQuestion = {
                            Answer: "",
                            ExistingQuestion: question.ID,
                            Question: question.SecurityQuestionID as number,
                            IsEditable: false,
                        }

                        updatedUserQuestions.push(questionObj);
                    });

                    selectedQuestions.value = updatedUserQuestions;
                });

            if(!useParticipantStore().userEmail || !useParticipantStore().userPhone || !useParticipantStore().userTwoFaMethod ) {
                useParticipantStore().getUserEmail();
            }

            if(isEmployer.value) {
                await employerStore.fetchGeneralInfo();
            } else {
                await localStore.fetchGeneralInfo();
            }

            loading.value = false;
        };

        // Submit Form
        const updateSettings = async (settingType: SettingType, formData: Record<string, any>) => {
            resetInputs.value = false;
            loadingUpdate.value[settingType] = true;

            axios.post('api/user/settings', { ...formData, _method: 'PATCH' })
                .then(response => {
                    success.value = response.data.success;

                    fetchData(false);
                    localErrors.value = {General: ''};

                    setTimeout(() => {
                        if (settingType === 'password') {
                            password.value = '';
                            passwordConfirmation.value = '';
                        }
                    }, 1000);

                    setTimeout(() => {
                        success.value = '';
                    }, 3500);
                })
                .catch((error: any) => {
                    if (error.response) {
                        localErrors.value = error.response.data.errors;
                    }
                })
                .finally(() => {
                    updateSettingsState.value[settingType] = false;
                    loadingUpdate.value[settingType] = false;
                });
        };

        // Password form submit
        const updatePassword = () => {
            const formData = {
                password: password.value,
                password_confirmation: passwordConfirmation.value,
            };

            updateSettings('password', formData);
        };

        // Email form submit
        const updateEmail = () => {
            useModalStore().openModal(
                'update-email',
                'Update Email',
                'modal-success',
                null,
                null,
                undefined,
                undefined,
                );
        };

        // Phone form submit
        const updatePhone = () => {
            useModalStore().openModal(
                'update-phone',
                'Update Phone',
                'modal-success',
                null,
                null,
                undefined,
                undefined,
                );
        };

        // Security Question form submit
        const updateSecurityQuestion = () => {
            const filteredQuestions = Object.values(selectedQuestions.value).filter(question => question.IsEditable);

            const formData = {
                security_questions: filteredQuestions,
            };

            updateSettings('securityQuestion', formData);
        };

       // Set 2 Factor Authentication
       const updatePreferredMethod = async (method: string) => {
            if (method !== oldPreferredMethod.value) {
                const { errors, submitCode } = useVerificationCode(method, 3);

                try {
                    loadingPreferred.value = true;
                    await submitCode(true);
                    await useParticipantStore().getUserEmail();

                    if (!errors.value) {
                        useModalStore().openModal(
                            "success-modal",
                            null,
                            "modal-success",
                            null,
                            {
                                title: "Preferred method changed successfully",
                                content: {
                                    type: "text",
                                    value: 'Your preferred method has been successfully updated, all upcoming codes will be sent to your new method',
                                    icon: "iatse-icon-user-verified"
                                },
                                onConfirmButton: "Close",
                                onConfirm: () => {
                                    useModalStore().closeAbove(0);
                                }
                            }
                        );
                    } else {
                        localErrors.value.General = errors.value;
                    }
                } catch (error) {
                    useParticipantStore().getUserEmail();
                    console.error('Error during submission:', error);
                }
            }
            else {
                localErrors.value.General = 'This is already your preferred method';
            }

            loadingPreferred.value = false;

            setTimeout(() => {
                localErrors.value.General = '';
            }, 3500);
        };

        const addNewEmail = () => {
            updateSettingsState.value['newEmail'] = !updateSettingsState.value['newEmail'];
        }

        onMounted(async () => await fetchData(true));

        return {
            localErrors,
            loadingPreferred,
            success,
            loading,
            loadingUpdate,
            userInfo,
            resetInputs,
            discardChanges,
            password,
            currentPassword,
            passwordConfirmation,
            newEmail,
            email,
            emailConfirmation,
            updateEmail,
            addNewEmail,
            userEmail,
            emailReadOnly,
            phoneNumber,
            selectedQuestions,
            toggleUpdateSetting,
            updateSettingsState,
            updateSettings,
            togglePasswordConfirmation,
            updatePassword,
            hasEditable,
            updateSecurityQuestion,
            availableQuestionsForSelect,
            securityQuestionsArray,
            userQuestions,
            numberOfQuestions,
            updatePreferredMethod,
            participantStore,
            showAddNewQuestion,
            addNewQuestion,
            editQuestion,
            removeQuestion,
            handleQuestions,
            updatePhone,
            userPhone,
            preferredMethod,

        };
    },
}
</script>