<template>
    <div class="action-button w-100s" v-if="otherCoverages">
      <a
        v-if="otherCoverages?.Retiree?.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-retiree-payments-history', 'Payments History', 'plan-retiree')"
      >
        Retiree Payments History
      </a>

      <a
        v-if="otherCoverages.A.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-plan-a-summary', 'Health & Welfare', 'plan-a-family', null, null, 'Plan A Summary', null, { FromDate: otherCoverages.A.From })"
      >
        Plan A
      </a>

      <a
        v-if="otherCoverages.C.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-plan-c-summary', 'Health & Welfare', 'plan-c-mrp active', null, null, 'Plan C Summary',  null, { FromDate: otherCoverages.C.From, ToDate: otherCoverages.C.To })"
      >
        Plan C
      </a>

      <a
        v-if="otherCoverages.RMRP.Show"
        class="btn btn-primary plan-modal"
        @click.prevent="openModal('hw-plan-rmrp-summary', 'Health & Welfare', 'plan-c-mrp active', null, null, 'Plan RMRP Summary', null, { FromDate: otherCoverages.RMRP.From, ToDate: otherCoverages.RMRP.To })"
      >
        Plan RMRP
      </a>
    </div>
  </template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue';
  import { OtherCoverages, OtherCoverageParams } from '@/interfaces/health';
  import { useModalStore } from '@/stores/modal';

  export default defineComponent({
    name: 'OtherCoverages',
    props: {
      otherCoverages: {
        type: Object as PropType<OtherCoverages>,
        required: true,
      },
    },
    setup(props, { emit }) {
        const openModal = useModalStore().openModal;
      // Emitting the openModal event when the link is clicked
      const handleOpenModal = () => {
        emit('openModal', 'hw-retiree-payments-history', 'Payments History', 'plan-retiree');
      };

      return {
        openModal, handleOpenModal
      };
    },
  });
</script>
