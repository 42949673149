<template>
     <div class="pdl-10 pdt-30 pdr-10">
        <LoaderComponent v-if="loading" />
        <div v-else class="d-flex flex-column gap-25">
            <div class="d-flex gap-25 flex-md-row flex-column">
                <div class="w-35 d-flex flex-column gap-25 w-sm-100">
                    <ProfilePanel :data="dashboard?.Profile" type="Employer" />
                    <EmployerActions />
                </div>
                <div class="w-70 w-sm-100">
                    <RecentAnnouncements :announcements="announcements"/>
                </div>
            </div>
            <!-- <FundMaterialsPanel :links="fundMaterialLinks" /> -->
            <ReportsPanel />
            <ResourcesPanel :resources="resources" />
        </div>
    </div>
</template>

<script lang="ts">
import { Ref, onMounted, ref,computed } from 'vue';
import { FundMaterialLinks, PaymentLinks } from '@/interfaces/employer';
import { FundMaterials, Announcements } from '@interfaces/local';
import { ProfileData } from '@interfaces/interfaces';
import { getFundResources, getAnnouncements } from '@/services/publicService';
import { useParticipantStore } from '@/stores/participant';
import EmployerActions from '@/components/employer/dashboard/EmployerActions.vue';
import ProfilePanel from '@components/user/dashboard/ProfilePanel.vue';
import ReportsPanel from '@components/employer/dashboard/ReportsPanel.vue';
import ResourcesPanel from '@components/local/dashboard/ResourcesPanel.vue';
import RecentAnnouncements from '@components/user/dashboard/RecentAnnouncements.vue';
import { useAuthStore } from '@/stores/auth';
import { useEmployerStore } from '@/stores/employer';
import router from '@/router';

export default {
    components: {
        EmployerActions,
        ProfilePanel,
        RecentAnnouncements,
        ReportsPanel,
        ResourcesPanel
    },
    setup() {
        const fundMaterialLinks: Ref<FundMaterialLinks | null> = ref(null);
        const paymentLinks: Ref<PaymentLinks | null> = ref(null);
        const profileData: Ref<ProfileData | null> = ref(null);
        const loading = ref(true);
        const type = ref('Employer');
        const alerts = computed(() => useParticipantStore().alerts?.fundData);
        const dashboard = computed(() => useEmployerStore().dashboard);
        const resources = computed(() => useEmployerStore().resources);
        const announcements =computed(() => useEmployerStore().announcements);

        const fetchData = async () => {
            loading.value = true;

            if (!alerts?.value) {
                useParticipantStore().fetchAlerts();
            }

            if (!resources?.value || !announcements?.value) {
                useEmployerStore().fetchResources();
            }

            if(!dashboard.value) {
                await useEmployerStore().getDashboard();
            }

            loading.value = false;
        };

        onMounted(async() => {
            if(!useAuthStore().twoFaValidated && useAuthStore().user?.active2FA) {
                if(useAuthStore().user?.preferredMethod) {
                    await router.push({ name: '2fa.verify'});
                } else {
                    await router.push({ name: '2fa.setup'});
                }
            } else {
                await fetchData();
            }
        })

        return {
            loading,
            type,
            dashboard,
            fundMaterialLinks,
            paymentLinks,
            profileData,
            resources,
            announcements,
        };
    }
}
</script>